import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppService } from '../../services/app.service';
import { Observable } from 'rxjs-compat/Observable';
import { t } from '@angular/core/src/render3';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit,OnDestroy {

  public OnlineCnt: number;
  public AccessCnt: number;

  myIntervalIdea: any;

  FooterImg: any;
  footerphoto: any;

  AccessCntArr: Array<number>;
  FooterMenu : Array<string>;
  FooterContent : Array<string>;

  constructor(
    private appService: AppService,
    private domSanitizer: DomSanitizer,
  ) { 
    this.OnlineCnt = 0;
    this.AccessCnt = 0;
    this.getAccessSysTimes();
    // this.getFooterImg();
    // this.getTypeFooter()
    // this.getFooterContent();
    // this.myIntervalIdea = Observable.interval(5000).subscribe(() => {
      // this.getAccessSysTimes();
    // });
  }

  ngOnInit() {
    
  }

  ngOnDestroy(): void {
    if (this.myIntervalIdea) { this.myIntervalIdea.unsubscribe(); }
    this.closeAccessSys();
  }

  async getAccessSysTimes() {
    var accessID = sessionStorage.getItem("AccessID");
    const dataRequest = {
      AccessID:(typeof accessID !== 'undefined' && accessID != null)?accessID:"",
      Page: window.location.origin
    };

    const result = await this.appService.doGET('api/Footer/GetAccessSysTimes', dataRequest);
    if (result && result.Status === 1 && result.Data) {
      sessionStorage.setItem("AccessID",result.Data.AccessID);
      this.OnlineCnt = (typeof result.Data.OnlineCnt !== 'undefined' && result.Data.OnlineCnt != null)?result.Data.OnlineCnt:0;
      this.AccessCnt = (typeof result.Data.AccessCnt !== 'undefined' && result.Data.AccessCnt != null)?result.Data.AccessCnt:0;
      if (result.Data.FooterContent && result.Data.FooterContent.Description){
        this.FooterContent = result.Data.FooterContent;
      }
      this.FooterMenu = result.Data.FooterMenu;
      if (result.Data.FooterImg){
        this.FooterImg = result.Data.FooterImg;
        this.footerphoto = this.appService.apiRoot + this.FooterImg.FileUrl;
      }     
      
    }
    if(this.AccessCnt.toString().length > 1 ){
     this.AccessCntArr = this.AccessCnt.toString(10).replace(/\D/g, '0').split('').map(Number);
    }
  }

  async closeAccessSys() {
    var accessID = sessionStorage.getItem("AccessID");
    const dataRequest = {
      AccessID:(typeof accessID !== 'undefined' && accessID != null)?accessID:""
    };
    const result = await this.appService.doGET('api/Footer/CloseAccessSys', dataRequest);
  }

  // async getFooterImg() {
  //   const resultImage = await this.appService.doGET('api/File/GetFooterImg', null);
    
  //   if (resultImage && resultImage.Status === 1) {
  //    if(resultImage.Data.Item){
  //     this.FooterImg = resultImage.Data.Item;
  //     this.footerphoto = this.appService.apiRoot + this.FooterImg.FileUrl;
  //    }
  //   }
  // }

  // async getTypeFooter() {
  //   const resutl = await this.appService.doGET('api/Type/GetTypeFooter', null);
    
  //   if (resutl && resutl.Status === 1) {
  //    if(resutl.Data){
  //     this.FooterMenu = resutl.Data;
  //    }
  //   }
  // }

  scrollTop(){
    window.scrollTo(0, 0);
  }

  // async getFooterContent() {
  //   const resutl = await this.appService.doGET('api/MailConfig/getFooterContent', null);
    
  //   if (resutl && resutl.Status === 1) {
  //    if(resutl.Data){
  //      if(resutl.Data.Description){
  //       this.FooterContent = resutl.Data;
  //      }
  //    }
  //   }
    
  // }
}

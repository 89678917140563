import { Component, OnInit, HostListener } from '@angular/core';
import { AppService } from '../../services/app.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-user-office-detail',
  templateUrl: './user-office-detail.component.html',
  styleUrls: ['./user-office-detail.component.css']
})

export class UserOfficeDetailComponent implements OnInit {
  isSticky: boolean = false;
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >0;
  }
  dataOfficeDetail : any;
  FileUrlUI :string;
  arrayFile: string[];
  urlDownload = this.appService.apiRoot;
  loading = false;
  constructor(
    private appService: AppService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.queryParams.subscribe((p: any) => {
      if (p && p.title) {
        this.getOffices(p.title);
      }
    });
   }

  ngOnInit() {
  }

  async getOffices(title) {
    this.loading = true;
    const dataRequest = {
      urlPath: title
    };

    // const dataRequest = {
    //   imagePath: `${listurlArr[0]}`
    // };
    // const result = await this.appService.doGET('api/RpResultByUnit/GetBase64ImageResult', dataRequest);
    const result = await this.appService.doGET('api/Office', dataRequest);
    if (result && result.Status === 1) {

      this.FileUrlUI =  result.Data.FileUrl;
      this.dataOfficeDetail = result.Data;

    }
    // this.FileUrlUI =this.FileUrlUI.slice(1, result.Data.FileUrl.lastIndexOf("]"));
    
    this.arrayFile = this.FileUrlUI ? JSON.parse(this.FileUrlUI) : [];
   
    this.loading = false;
    // this.checkSelectionID();
  }

  getUrlDownload(item) {
    // return "http://localhost:8080/Upload/Office/tax.docx";
    let url = this.urlDownload.replace(/\"/g,"") + item;
    url = url.replace(/\"/g, '')
    return url;
  }
}

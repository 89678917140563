import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { AppLanguage } from '../../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../services/app.service';
import { SelectableSettings, PageChangeEvent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { AppSwal } from 'src/app/services/app.swal';
import { IntlService } from '@progress/kendo-angular-intl';
import { Notification } from '../../services/app.notification';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
import { AppGuid } from 'src/app/services/app.guid';
import { NullInjector } from '@angular/core/src/di/injector';
import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';
import * as XLSX from 'xlsx';
import { AppFile } from 'src/app/services/app.file';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.css']
})
export class ConfigComponent implements OnInit, OnDestroy {

  user: any;
  loading = false;
  configs = [];
  configSelectableSettings: SelectableSettings;
  configSort = {
    allowUnsort: true,
    mode: 'multiple'
  };
  public configFocus = {
    Name: true
  };
  configSortByField: SortDescriptor[] = [{
    field: 'Name',
    dir: 'asc'
  }];

  public configState: State = {
    // skip: 0,
    // take: 5,{ field: 'ProductName', operator: 'contains', value: 'Chef' }
    filter: {
      logic: 'and',
      filters: []
    }
  };
  configGridDataResult: GridDataResult;

  configSkip = 0;
  configPageSize = 50;
  configSelection: number[] = [];
  configItem: any;
  myInterval: any;
  configNames: Array<{ Name: string, ID: number }>;
  configNamesFilter: Array<{ Name: string, ID: number }>;

  public uploadSaveUrl = 'saveUrl';
  public uploadRemoveUrl = 'removeUrl';
  public configOpened = false;
  isEnabledSaveAll = false;
  data: any;
  control: any;
  controlDefault = true;

  constructor(
    private translate: TranslateService,
    private language: AppLanguage,
    private appService: AppService,
    private appSwal: AppSwal,
    public intl: IntlService,
    private notification: Notification,
    private guid: AppGuid,
    private file: AppFile,
    private authenticationService: AuthenticationService,
    public appControls: AppControls
  ) {
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
    this.getControl();
    this.setDefault();
    this.language.default();
    this.setSelectableSettings();
    this.onReload();
    this.initDisplay();
  }

  handleFilter(value) {
    this.configNamesFilter = this.configNames.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  ngOnDestroy(): void {
    if (this.myInterval) { this.myInterval.unsubscribe(); }
  }

  ngOnInit() {
  }

  async getControl() {
    this.control = await this.appControls.getControls(this.user.RoleID);
    this.controlDefault = false;
  }

  setSelectableSettings(): void {
    this.configSelectableSettings = {
      checkboxOnly: false,
      mode: 'multiple'
    };
  }

  async getConfigs() {
    this.loading = true;
    const result = await this.appService.doGET('api/Config', null);
    if (result) {
      this.configs = result.Data;
      this.bindConfigs();
    }
    this.loading = false;
    this.checkSelectionID();
  }

  checkSelectionID() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = this.configSelection.length - 1; i >= 0; i--) {
      const selectedItem = this.configs.find((item) => {
        return item.ID === this.configSelection[i];
      });
      if (!selectedItem) {
        this.configSelection.splice(i, 1);
      }
    }
  }

  setDefault() {
    this.configItem = {
      IsAdd: true,
      ID: '',
      Name: '',
      Description: '',
    };
    this.configFocus.Name = true;
  }

  onConfigPageChange(event: PageChangeEvent) {
    this.configSkip = event.skip;
    this.bindConfigs();
  }

  onConfigSelectedKeysChange() {
  }

  bindConfigs() {
    this.configGridDataResult = {
      data: orderBy(this.configs, this.configSortByField),
      total: this.configs.length
    };
  }

  onConfigSortChange(sort: SortDescriptor[]): void {
    this.configSortByField = sort;
    this.bindConfigs();
  }

  public onConfigDataStateChange(state: DataStateChangeEvent): void {
    this.configState = state;
    this.configGridDataResult = process(this.configs, this.configState);
  }

  async selectEventHandler(e: SelectEvent) {

    const fileData = (await this.file.readXLSX(e.files[0].rawFile)) as Array<any>;

    try {
      const fileData = e.files[0];// await this.file.readFile(e.files[0].rawFile);

      const maxMB = 25;
      const maxSizeKB = 1024 * 1024 * maxMB;
      if (fileData.size > maxSizeKB) {
        this.appSwal.showWarning(`Kích thước tập tin không được lớn hơn ${maxMB} MB`, false);
        return false;
      }
    } catch {
      return false;
    }

    // tslint:disable-next-line:prefer-const
    for (let i = 1; i < fileData.length; i++) {
      this.configs.push({
        ID: fileData[i][0],
        Name: fileData[i][1],
        Description: fileData[i][2],
      });
    }
    this.bindConfigs();
    this.isEnabledSaveAll = true;
  }

  removeEventHandler() {
    this.isEnabledSaveAll = false;
    this.onReload();
  }

  doInterval() {
    this.myInterval = Observable.interval(this.appService.timeInterval).subscribe(() => {
      this.onReload();
    });
  }

  async initDisplay() {
    const resultConfig = await this.appService.doGET('api/Enum/GetEConfig', null);
    if (resultConfig) {
      this.configNames = resultConfig.Data;
      this.configNamesFilter = this.configNames.slice();
    }
  }
  onReload() {
    this.getConfigs();
  }

  onClearConfig() {
    this.setDefault();
  }

  onAddNewConfig() {
    this.configOpened = true;
    this.setDefault();
  }

  onSaveConfig() {
    if (this.configItem.IsAdd) { this.addConfig(); } else { this.updateConfig(); }
  }

  async onSaveConfigs() {
    const dataRequests = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.configs.length; i++) {
      dataRequests.push(this.createDataRequest(this.configs[i]));
    }
    const result = await this.appService.doPOST('api/Config/SaveAll', dataRequests);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.isEnabledSaveAll = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
  }

  createDataRequest(data) {
    const temp = data ? data : this.configItem;
    return {
      ID: temp.ID,
      Name: temp.Name,
      Description: temp.Description,
    };
  }

  onCloseConfig(status) {
    this.configOpened = false;
  }
  onCloseUser(status) {
    this.configOpened = false;
    this.configNamesFilter = this.configNames.slice();
  }

  onEditConfig() {
    if (this.configSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }

    if (this.configSelection.length > 1) {
      this.appSwal.showWarning(this.translate.instant('SelectSingle'), false);
    } else {
      const selectedID = this.configSelection[0];
      const selectedItem = this.configs.find((item) => {
        return item.ID === selectedID;
      });

      if (selectedItem) {
        selectedItem.IsAdd = false;
        this.configItem = selectedItem;
        this.configOpened = true;
      }
    }
  }

  async addConfig() {
    const dataRequest = this.createDataRequest(null);
    const result = await this.appService.doPOST('api/Config', dataRequest);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.onAddNewConfig();
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
  }

  async updateConfig() {
    const id = this.configItem.ID;
    const dataRequest = this.createDataRequest(null);

    const result = await this.appService.doPUT('api/Config', dataRequest, { id });
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
  }

  async onDeleteConfig() {
    if (this.configSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }

    const dataRequest = {
      IDSs: this.configSelection,
      DeleteBy: this.user.UserName
    };

    const option = await this.appSwal.showWarning(this.translate.instant('AreYouSure'), true);
    if (option) {
      const result = await this.appService.doPOST('api/Config/Deletes', dataRequest);
      if (result && result.Status === 1) {
        this.notification.showSuccess(result.Msg);
        this.onReload();
        this.configSelection = [];
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
  }
}

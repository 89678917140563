import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';
import { AppLanguage } from '../../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../services/app.service';
import { SelectableSettings, PageChangeEvent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { AppSwal } from 'src/app/services/app.swal';
import { IntlService } from '@progress/kendo-angular-intl';
import { Notification } from '../../services/app.notification';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
import { AppGuid } from 'src/app/services/app.guid';
import { NullInjector } from '@angular/core/src/di/injector';
import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';
import * as XLSX from 'xlsx';
import { AppFile } from 'src/app/services/app.file';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppUtils } from 'src/app/services/app.utils';
import { AppComponent } from '../../app.component';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpProgressEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { of, concat } from 'rxjs';
import { delay } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import { ConfigComponent } from 'src/app/ad/config/config.component';
// import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
//import CKFinder from '@ckeditor/ckeditor5-ckfinder/src/ckfinder';
declare var CKEDITOR: any;

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.css']
})



export class PostComponent implements OnInit {

  isSticky = false
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 10;
  }

  //codeimage
  commentInput = null;
  uploadFlag = {
    banner: false,
    image: false,
    video: false,
  };

  public uploadFlgDefault = {
    banner: false,
    image: false,
    video: false,
  };

  public Editor = DecoupledEditor;

  uploadFlg = this.uploadFlgDefault;
  public websiteDefault = null;
  websiteLink = this.websiteDefault;
  public bannerDef = '../../assets/images/default-01.png';
  public imageDef = '../../assets/images/default-02.png';
  bannerUrl: any;
  public imgSaveUrl: any;
  imgImageDefault: any;
  imgImageMain: any;
  //
  dateNow = new Date();

  user: any;
  loading = false;
  dataPosts = [];
  dataPostSelectableSettings: SelectableSettings;
  dataPostSort = {
    allowUnsort: true,
    mode: 'multiple'
  };
  public dataPostFocus = {
    Name: true
  };
  dataPostSortByField: SortDescriptor[] = [
    // {
    //   field: 'OrderIdx',
    //   dir: 'asc'
    // }
    //   field: 'OrdinalNumber',
    //   dir: 'asc'
    // }
  ];

  dataLogPostSelectableSettings: SelectableSettings;
  logPostOpened = false;
  reViewOpened = false;
  postTemp = {
    MenuName: '',
    Title: '',
    Description: '',
    ImageUrls: '',
    FileUrl: [],
    Reference: ''
  };
  //imagesUploadName = "";

  public LBM_NUM_PAGING_SKIP = 0;
  public LBM_NUM_PAGING_TAKE = 50;
  public LBM_NUM_PAGING_BTN = 5;

  dataPostSkip = this.LBM_NUM_PAGING_SKIP;
  dataPostPageSize = this.LBM_NUM_PAGING_TAKE;
  dataPostSelection: number[] = [];
  dataPostItem: any;
  dataPostItemTemp: any;
  dataPostItemEnLanguage: any;
  myInterval: any;
  infoOpened = false;
  isReviewDialog = false;



  public buttonCount = this.LBM_NUM_PAGING_BTN;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;

  public dataPostState: State = {
    skip: this.dataPostSkip,
    take: this.dataPostSkip + this.dataPostPageSize,
    filter: {
      logic: 'and',
      filters: []
    },

    //group: [{ field: 'TypeName' }]
  };



  dataPostGridDataResult: GridDataResult;

  dataFileUrlsGridDataResult: GridDataResult;
  dataFileUrlsSelectableSettings: SelectableSettings;
  dataFileUrlsSkip = this.LBM_NUM_PAGING_SKIP;
  dataFileUrlsPageSize = this.LBM_NUM_PAGING_TAKE;
  dataFileUrlsSelection: number[] = [];

  dataLogPostGridDataResult: GridDataResult;
  dataLogPosts: any[];

  dataMenuID: any[];
  pageName: any;

  menus: Array<{ Name: string, ID: string }>;
  menusFilter: Array<{ Name: string, ID: string }>;
  menusFilterSearch: Array<{ Name: string, ID: string }>;
  filesUpload: Array<FileInfo>;
  dataFileUrls: any[];
  public fileSaveUrl: any;

  public uploadSaveUrl = 'saveUrl';
  public uploadRemoveUrl = 'removeUrl';
  public enabled = false;
  public enabledID = false;
  isEnabledSaveAll = false;
  control: any;
  controlDefault = true;
  allowMulti = true;

  searchOption = {
    SearchText: '',
    PortalId: 14,
    MenuID: ''
  };

  portalIds: any[];
  ckeConfig: any;
  editorOptions = { theme: 'vs-dark', language: 'javascript' };
  //CKEDITOR: any;
  urlDownload = this.appService.apiRoot;
  allowInsertFile = true;
  filesUploadName = "";
  typeReferences:  Array<{ Name: string, ID: string }>;
  typeReferenceFilter: Array<{ Name: string, ID: string }>;

  constructor(
    private translate: TranslateService,
    private language: AppLanguage,
    public appService: AppService,
    private appSwal: AppSwal,
    public intl: IntlService,
    private notification: Notification,
    private guid: AppGuid,
    private file: AppFile,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    private appUtils: AppUtils,
    private appComponent: AppComponent,
    private domSanitizer: DomSanitizer,
  ) {
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
    this.imgSaveUrl = `${this.appService.apiRoot}api/Upload/MediaWeb?accountID=${this.user.UserName}&typeData=image`;
    this.getControl();
    this.setDefault();
    this.language.default();
    this.setSelectableSettings();
    this.initDisplay();
    this.allData = this.allData.bind(this);
    this.getPageName();
    this.get4Type();
    this.getSearchMenus();
    this.getMenus();

  }

  ngOnDestroy(): void {
    // if (this.myInterval) { this.myInterval.unsubscribe(); }
  }


  ngOnInit() {
    this.ckeConfig = {
      allowedContent: true,
      extraPlugins: 'divarea',
      forcePasteAsPlainText: true
    };
  }

  onReady(eventData) {
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      console.log('loader : ', loader)
      console.log(btoa(loader.file));
      return new UploadAdapter(loader);
    };
    eventData.ui.getEditableElement().parentElement.insertBefore(
      eventData.ui.view.toolbar.element,
      eventData.ui.getEditableElement()
    );
  }
  public ckeditorContent: string = "";

  // ngOnInit(): void {
  //   // Set The Name of ckEditor
  //   CKEDITOR.on("instanceCreated", function(event, data) {
  //     var editor = event.editor,
  //       element = editor.element;
  //     editor.name = "content";
  //   });
  // }

  getData() {
    console.log(CKEDITOR.instances.content.getData());
  }

  async getPageName() {
    this.pageName = await this.appControls.getPageName();
  }

  async getControl() {
    this.control = await this.appControls.getControls(this.user.RoleID);
    this.controlDefault = false;
  }

  setSelectableSettings(): void {

    this.allowMulti = !this.allowMulti;
    this.dataPostSelectableSettings = {
      checkboxOnly: false,
      mode: this.allowMulti ? 'multiple' : 'single'
    };

    this.dataFileUrlsSelectableSettings = {
      checkboxOnly: false,
      mode: 'single'
    };
  }

  onMenusHandleFilter(value) {
    this.menusFilter = this.menus.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  onMenusSearchHandleFilter(value) {
    this.menusFilterSearch = this.menus.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  onTypeRefHandleFilter(value) {
    this.typeReferenceFilter = this.typeReferences.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  onSearchKeyPress(e: any) {
    if (e.keyCode === 13 && this.searchOption.SearchText) {
      this.onSearch();
    }
  }

  async getPosts() {
    this.loading = true;
    const dataRequest = {
      searchText: this.searchOption.SearchText,
      menuID: this.searchOption.MenuID ? this.searchOption.MenuID : ''
    };

    const result = await this.appService.doGET('api/Post/Search', dataRequest);
    var menus = []
    if (result && result.Data) {
      for (let i = 0; i < result.Data.length; i++) {
        if(result.Data[i].FileUrl == null || result.Data[i].FileUrl == ""){
          result.Data[i].FileUrl = [];
        }
        else{
          result.Data[i].FileUrl = JSON.parse(result.Data[i].FileUrl);
        }

        // if(result.Data[i].Reference == null || result.Data[i].Reference == ""){
        //   result.Data[i].Reference = "";
        // }
        // else{
        //   result.Data[i].Reference = JSON.parse(result.Data[i].Reference);
        //   result.Data[i].Reference = (result.Data[i].Reference).join(";")
        // }
        menus = []
        menus =this.menus.filter((s) => result.Data[i].MenuID.indexOf(s.ID.toString()) !== -1);
        
        result.Data[i].menuNames = [];
        for(let j = 0; j < menus.length; j++) {
          result.Data[i].menuNames.push(menus[j].Name);
        }        
        result.Data[i].menuNames = result.Data[i].menuNames.join("; ");
      }
      this.dataPosts = result.Data;
      this.bindPosts();
    }
    this.loading = false;
    this.checkSelectionID();
  }

  async onGetPost15p() {
    this.loading = true;
    const dataRequest = {
      id: this.searchOption.PortalId
    };

    const result = await this.appService.doGET('api/Post/GetPost15p', dataRequest);
    if (result) {
     
      this.dataPosts = result.Data;
      this.bindPosts();
    }
    this.loading = false;
    this.checkSelectionID();
  }

  onTabChanged(e) {
    if (e.index === 0) {
      this.onReload();
    } else {
      this.onGetPost15p();
    }
  }

  checkSelectionID() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = this.dataPostSelection.length - 1; i >= 0; i--) {
      const selectedItem = this.dataPosts.find((item) => {
        return item.ID === this.dataPostSelection[i];
      });
      if (!selectedItem) {
        this.dataPostSelection.splice(i, 1);
      }
    }
  }

  setDefault() {
    this.dataPostItem = {
      IsAdd: true,
      ID: null,
      MenuID: null,
      Title: null,
      Summary: null,
      Description: null,
      ImageUrls: null,
      FileUrls: null,
      CreateAt: new Date(),
      UpdateAt: new Date(),
      MobileFlg: null,
      ApprovedFlg: null,
      ApprovedAt: null,
      ApprovedBy: null,
      OrderIdx: 0,
      HotFlg: false,
      FileUrl: [],
      Note: '',
      UrlPath: '',
      Reference: ''
    };
    this.dataPostItemTemp = {
      IsAdd: true,
      ID: null,
      MenuID: null,
      Title: null,
      Summary: null,
      Description: null,
      ImageUrls: null,
      FileUrls: null,
      CreateAt: new Date(),
      UpdateAt: new Date(),
      MobileFlg: null,
      ApprovedFlg: null,
      ApprovedAt: null,
      ApprovedBy: null,
      OrderIdx: 0,
      HotFlg: false,
      FileUrl: [],
      Note: '',
      UrlPath: '',
      Reference: ''
    };
    this.dataPostItemEnLanguage = {
      ID: null,
      PostID: null,
      Title: null,
      Summary: null,
      Description: null,
      CreateAt: null,
      CreateBy: null,
      UpdateAt: null,
      UpdateBy: null,
      LanguageID: null,
    };
    this.imageDef = '../../assets/images/default-02.png';
    this.imgImageDefault = [
      this.imageDef,
      // this.imageDef,
      // this.imageDef,
    ];
    //this.imagesUploadName = "";
    this.imgImageMain = [''];
    this.uploadFlg = this.uploadFlgDefault;
    this.websiteLink = this.websiteDefault;
    this.enabled = true;
    this.enabledID = true;

    this.dataMenuID = [];
    this.dataPostSelection = [];
    this.filesUpload = [];
    this.dataFileUrls = [];
    this.postTemp = {
      MenuName: '',
      Title: '',
      Description: '',
      ImageUrls: '',
      FileUrl: [],
      Reference: ''
    };
    //this.bindFileUrls();
    this.fileSaveUrl = `${this.appService.apiRoot}api/Upload/MediaWeb?accountID=${this.user.UserName}&typeData=files&checkFileSize=true`;
    this.filesUploadName = "";
  }

  bindTemp(data) {
    this.dataPostItemTemp = Object.assign({}, data);
  }

  async onImageUrlSelectEventHandler(e: SelectEvent, index) {
    if (!e.files || e.files.length <= 0) {
      return false;
    }
    try {
      const fileData = e.files[0];// await this.file.readFile(e.files[0].rawFile);
      const maxMB = 25;
      const maxSizeKB = 1024 * 1024 * maxMB;
      if (fileData.size > maxSizeKB) {
        this.allowInsertFile = false;
        this.appSwal.showWarning(`Kích thước tập tin không được lớn hơn ${maxMB} MB`, false);
        return false;
      }
    } catch {
      return false;
    }

    // tslint:disable-next-line: max-line-length
    if (e.files[0].extension !== '.jpg' &&
      e.files[0].extension !== '.png' &&
      e.files[0].extension !== '.gif' &&
      e.files[0].extension !== '.jpeg' &&
      e.files[0].extension !== '.JPEG' &&
      e.files[0].extension !== '.GIF' &&
      e.files[0].extension !== '.PNG' &&
      e.files[0].extension !== '.JPG') {
      this.appSwal.showWarning(this.translate.instant('MsgImgOnly'), false);
      return false;
    }

    const fileData = await this.file.readImage(e.files[0].rawFile);
    const base64 = `${fileData}`;
    this.imgImageDefault[index] = this.domSanitizer.bypassSecurityTrustResourceUrl(base64);
    this.imgImageDefault.push(this.imageDef);
    //this.imagesUploadName = e.files[0].rawFile.name;
  }

  onImageUrlSuccessEventHandle(e: any, index) {
    try {
      this.uploadFlg.image = true;
      this.imgImageMain[index] = e.response.body.Data.MediaNm[0];
    } catch {
      this.uploadFlg.image = false;
      // this.imgImageDefault[index] = this.imageDef;
    }
  }

  onImageUrlRemoveEventHandler(index) {
    this.imgImageMain[index] = '';
  }

  onPostPageChange(event: PageChangeEvent) {
    this.dataPostSkip = event.skip;
    this.bindPosts();
  }

  async onPostSelectedKeysChange() {

    if (this.dataPostSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }

    if (this.dataPostSelection.length > 1) {
      if (this.allowMulti) {
        return;
      }
      this.appSwal.showWarning(this.translate.instant('SelectSingle'), false);
    } else {
      const selectedID = this.dataPostSelection[0];
      const selectedItem = this.dataPosts.find((item) => {
        return item.ID === selectedID;
      });
      selectedItem.IsAdd = false;      
      this.dataPostItem = selectedItem;      

      this.uploadFlg.image = false;
      this.enabled = false;
      this.enabledID = false;
    }
  }

  bindPosts() {
    this.dataPostGridDataResult = {
      data: orderBy(this.dataPosts, this.dataPostSortByField),
      total: this.dataPosts.length
    };

    this.dataPostGridDataResult = process(this.dataPosts, this.dataPostState);
  }

  bindFileUrls() {
    this.dataFileUrlsGridDataResult = {
      data: this.dataFileUrls,
      total: this.dataFileUrls.length
    };

    this.dataPostGridDataResult = process(this.dataPosts, this.dataPostState);
  }

  bindLogPosts() {
    this.dataLogPostGridDataResult = {
      data: this.dataLogPosts,
      total: this.dataLogPosts.length
    };

    this.dataPostGridDataResult = process(this.dataPosts, this.dataPostState);
  }

  onPostSortChange(sort: SortDescriptor[]): void {
    this.dataPostSortByField = sort;
    this.bindPosts();
  }

  public onPostDataStateChange(state: DataStateChangeEvent): void {
    this.dataPostSelection = [];
    this.dataPostState = state;
    this.dataPostGridDataResult = process(this.dataPosts, this.dataPostState);
  }

  removeEventHandler(index) {
    this.isEnabledSaveAll = false;
    this.imgImageMain[index] = '',
      this.onReload();
  }

  doInterval() {
    // this.myInterval = Observable.interval(this.appService.timeInterval).subscribe(() => {
    //   this.onReload();
    // });
  }

  onSearchTextChange(e: any) {
    if (!this.searchOption.SearchText) {
      this.onReload();
    }
  }

  onRemoveSearchText() {
    this.searchOption.SearchText = '';

  }

  async initDisplay() {
    const resultPost = await this.appService.doGET('api/Post/GetMenu', null);
    if (resultPost && resultPost.Status === 1) {
      this.menus = resultPost.Data.filter((r) => 'portal/post-list;chucnangnhiemvu'.indexOf(r.PageID) >= 0);
      this.menusFilter = this.menus.slice();
      this.menusFilterSearch = this.menus.slice();

      if (!this.searchOption.MenuID && this.menus.length > 0) {
        this.searchOption.MenuID = '';
      }
      this.onReload();
    }

    this.portalIds = [
      { Name: 'Phường 1 Quận 10', ID: 14 },
      { Name: 'Phường 2 Quận 10', ID: 15 },
      { Name: 'Phường 3 Quận 10', ID: 16 },
      { Name: 'Phường 4 Quận 10', ID: 2 },
      { Name: 'Phường 5 Quận 10', ID: 17 },
      { Name: 'Phường 6 Quận 10', ID: 18 },
      { Name: 'Phường 7 Quận 10', ID: 19 },
      { Name: 'Phường 8 Quận 10', ID: 20 },
      { Name: 'Phường 9 Quận 10', ID: 10 },
      { Name: 'Phường 10 Quận 10', ID: 21 },
      { Name: 'Phường 11 Quận 10', ID: 22 },
      { Name: 'Phường 12 Quận 10', ID: 23 },
      { Name: 'Phường 13 Quận 10', ID: 24 },
      { Name: 'Phường 14 Quận 10', ID: 11 },
      { Name: 'Phường 15 Quận 10', ID: 7 }
    ];

    const resultReference = await this.appService.doGET('api/Type/GetReferenceFilter', null);
    if (resultReference && resultReference.Status === 1) {
      this.typeReferences = resultReference.Data
      this.typeReferenceFilter = this.typeReferences.slice();  
    }
  }

  onSearch() {
    this.getPosts();
    this.isEnabledSaveAll = false;
  }

  onReload() {
    this.searchOption.SearchText = '';
    this.getPosts();
    this.isEnabledSaveAll = false;
  }

  onClearPost() {
    this.setDefault();
  }

  onAllowSelectMulti() {
    this.setSelectableSettings();
  }

  onAddNewPost() {
    this.setDefault();
    this.infoOpened = true;
  }

  async onSavePost() {
    if (this.dataPostItem.IsAdd) { this.addPost(); } else { this.updatePost(); }
  }

  async onSendPost() {

    if (this.dataPostSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }
    this.appComponent.loading = true;
    const dataRequest = {
      IDList: JSON.stringify(this.dataPostSelection),
      FlgRevert: false
    };

    const option = await this.appSwal.showWarning('Bạn có chắc chắn muốn xuất tin bài này không?', true);
    if (option) {
      const result = await this.appService.doPOST('api/Post/Send', dataRequest);
      if (result && result.Status === 1) {
        this.notification.showSuccess(result.Msg);
        this.onReload();
        this.enabled = false;
        this.enabledID = false;
        this.dataPostSelection = [];
        this.allowMulti = false;
        
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
    this.appComponent.loading = false;
  }

  async onViewLogPost() {

    const dataRequest = {
      id: this.dataPostItem.ID
    };

    const result = await this.appService.doGET('api/Post/GetLog', dataRequest);
    if (result && result.Status === 1) {
      this.dataLogPosts = result.Data;
      this.bindLogPosts();

      this.logPostOpened = true;

    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
  }

  onCloseLogPost(e) {
    this.logPostOpened = false;
  }

  onCloseReViewPost(e) {
    this.reViewOpened = false;
    
    if (this.isReviewDialog) {
      this.infoOpened = true;
    } else {
      this.infoOpened = false;
    }
  }

  onReviewPost() {
    this.reViewOpened = true;
    this.infoOpened = false;
    this.getPostTemp();
  }

  onReviewPostDialog() {
   this.getPostsByIdReview(this.dataPostItem.ID);
  }
  isUploadFromServe = false;
  getPostTemp() {
    const menuIDJSON: any[] = [];
    if (this.dataMenuID.length > 0) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.dataMenuID.length; i++) {
        menuIDJSON.push(this.dataMenuID[i].Name);
      }
    }
    this.postTemp.ImageUrls = this.dataPostItemTemp.ImageUrls;
    //var content = document.getElementById("content").outerHTML;
    this.postTemp.MenuName = this.dataPostItem.IsAdd ? menuIDJSON.join(';') : this.dataPostItem.MenuName;
    this.postTemp.Title = this.dataPostItem.Title;
    this.postTemp.Description = this.dataPostItemTemp.Description;
    this.postTemp.FileUrl = this.dataPostItemTemp.FileUrl;
    this.postTemp.Reference = this.dataPostItemTemp.Reference;
    var content = this.postTemp.Description;
    try {
      var require = content.indexOf("<oembed");
      if (require >= 0) {
        while (content.indexOf("<oembed") >= 0) {
          var st_Index = content.indexOf("<oembed");
          var end_Index = content.indexOf("</oembed>")
          var sub1 = content.substring(st_Index, end_Index + "</oembed>".length);

          var st_key = sub1.indexOf("?v="); var end_key = sub1.indexOf('">');
          var key = sub1.substring(st_key + "?v=".length, end_key);

          var replace = '<iframe width="100%" height="470px" src="//www.youtube.com/embed/' + key + '" frameborder="0" allowfullscreen></iframe>';
          
          //`${}0${}`
          this.postTemp.Description = this.postTemp.Description.replace(sub1, replace);
          content = this.postTemp.Description;
        }
      }
    } catch{
      content = this.postTemp.Description;
    }
    try {
      if (this.postTemp.ImageUrls.indexOf("Multimedia") >= 0) {
        this.isUploadFromServe = true;
      }
    } catch{
      this.isUploadFromServe = false;
    }
  }

  async onCancelPost() {

    const dataRequest = {
      ID: this.dataPostItem.ID
    };

    const option = await this.appSwal.showWarning('Bạn có chắc chắn muốn hủy xuất tin bài này không?', true);
    if (option) {
      const result = await this.appService.doPOST('api/Post/Send', dataRequest);
      if (result && result.Status === 1) {
        this.dataPostItem.ApprovedFlg = false;
        this.dataPostItem.ApprovedAt = null;
        this.dataPostItem.ApprovedBy = null;

        this.notification.showSuccess(result.Msg);

      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
  }


  // async onSavePosts() {
  //   this.appComponent.loading = true;
  //   const dataRequests = [];
  //   // tslint:disable-next-line:prefer-for-of
  //   for (let i = 0; i < this.dataPosts.length; i++) {
  //     dataRequests.push(this.createDataRequest(this.dataPosts[i]));
  //   }
  //   const result = await this.appService.doPOST('api/Post/Saves', dataRequests);
  //   this.dataPostItem.ImageUrls = (this.uploadFlg.image == true) ? this.parseToArrayTxt(this.imgImageMain) : null;
  //   if (result && result.Status === 1) {
  //     this.notification.showSuccess(result.Msg);
  //     this.onReload();
  //     this.isEnabledSaveAll = false;
  //   } else {
  //     this.appSwal.showWarning(result.Msg, false);
  //   }
  //   this.appComponent.loading = false;
  // }

  createDataRequest(data) {
    const temp = data ? data : this.dataPostItemTemp;
    const tempEnLanguage = this.dataPostItemEnLanguage;
    // let fileName = '';
    // if (this.dataFileUrls.length > 0) {
    //   fileName = this.dataFileUrls[0].Url;
    // }

    const menuIDJSON: any[] = [];
    if (this.dataMenuID.length > 0) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.dataMenuID.length; i++) {
        menuIDJSON.push(this.dataMenuID[i].ID);
      }
    }

    var files: any[] = [];
    if (this.dataPostItemTemp.FileUrl) {
      for (let i = 0; i < this.dataPostItemTemp.FileUrl.length; i++) {
        files.push(this.dataPostItemTemp.FileUrl[i]);
      }
    }

    // var reference: any[] = [];
    // reference = temp.Reference.split(";")

    return {
      Post: {
        ID: temp.ID,
        MenuID: menuIDJSON.length > 0 ? JSON.stringify(menuIDJSON) : null,
        Title: temp.Title,
        Summary: temp.Summary,
        Description: temp.Description,
        ImageUrls: temp.ImageUrls,
        FileUrls: null,
        CreateAt: temp.CreateAt ? this.intl.formatDate(new Date(temp.CreateAt), 'yyyy-MM-ddTHH:mm:ss') : null,
        UpdateAt: temp.UpdateAt,
        MobileFlg: temp.MobileFlg,
        ApprovedFlg: temp.ApprovedFlg,
        ApprovedAt: temp.ApprovedAt,
        ApprovedBy: temp.ApprovedBy,
        OrderIdx: temp.OrderIdx,
        HotFlg: temp.HotFlg,
        FileUrl: JSON.stringify(files),
        Note: temp.Note,
        UrlPath: temp.UrlPath,
        Reference: temp.Reference
      },
      EnLanguage: {
        ID: tempEnLanguage.ID,
        PostID: tempEnLanguage.PostID,
        Title: tempEnLanguage.Title,
        Summary: tempEnLanguage.Summary,
        Description: tempEnLanguage.Description,
        CreateAt: tempEnLanguage.CreateAt,
        CreateBy: tempEnLanguage.CreateBy,
        UpdateAt: tempEnLanguage.UpdateAt,
        UpdateBy: tempEnLanguage.UpdateBy,
        LanguageID: 'en-US'
      }
    };
  }

  onClosePost(status: any) {
    this.enabled = false;
    this.enabledID = false;
  }

  onEditPost() {
    this.getPostsById(this.dataPostItem.ID);
  }

  parseToArrayTxt(arrayObject) {
    var arrayTxt = "";
    for (var i = 0; i < arrayObject.length; i++) {
      if (arrayObject[i] != "" && arrayObject[i] != null && arrayObject[i] != this.imageDef) {
        if (arrayTxt == "") {
          arrayTxt = "[" + "\"" + arrayObject[i] + "\"";
        } else {
          arrayTxt = arrayTxt + "," + "\"" + arrayObject[i] + "\"";
        }
      }
    }

    if (arrayTxt != "") {
      arrayTxt = arrayTxt + "]";
    }

    return arrayTxt;
  }

  async addPost() {
    this.appComponent.loading = true;
    this.dataPostItemTemp.ImageUrls = (this.uploadFlg.image === true && this.imgImageMain && this.imgImageMain.length > 0)
      ? this.imgImageMain[0] : null;
    const dataRequest = this.createDataRequest(null);
    const result = await this.appService.doPOST('api/Post', dataRequest);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.onAddNewPost();
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  async updatePost() {
    this.appComponent.loading = true;
    // tslint:disable-next-line: max-line-length
    this.dataPostItemTemp.ImageUrls = (this.uploadFlg.image === true && this.imgImageMain && this.imgImageMain.length > 0)
      ? this.imgImageMain[0] : this.dataPostItem.ImageUrls;
    const id = this.dataPostItem.ID;
    //this.dataPostItem.Description = this.postTemp.Description;
    const dataRequest = this.createDataRequest(null);
    const result = await this.appService.doPUT('api/Post', dataRequest, { id });
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      //this.onAddNewPost();
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }
  //////////////////////
  public valueChange(value: any = this.dataPostItem): void {
  }

  /////////////////////
  async onDeletePost() {
    if (this.dataPostSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }
    this.appComponent.loading = true;
    const dataRequest = {
      IDList: JSON.stringify(this.dataPostSelection),
      FlgRevert: false
    };

    const option = await this.appSwal.showWarning(this.translate.instant('AreYouSure'), true);
    if (option) {
      const result = await this.appService.doPOST('api/Post/Deletes', dataRequest);
      if (result && result.Status === 1) {
        this.notification.showSuccess(result.Msg);
        this.onReload();
        //this.onAddNewPost();
        this.enabled = false;
        this.enabledID = false;
        this.dataPostSelection = [];
        this.allowMulti = false;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
    this.appComponent.loading = false;
  }

  getPostName(dataItem) {
    var nameFile = "temp.txt";
    var fileUrls = dataItem.ImageUrls;
    if (fileUrls != "" && fileUrls != null) {
      var listurlArr = JSON.parse(fileUrls);
      if (listurlArr.length > 0) {
        var nameObj = listurlArr[0];
        var urlArr = nameObj.split("/");
        if (urlArr.length > 0) {
          nameFile = urlArr[urlArr.length - 1];
          if (nameFile != "" && nameFile != null) {
            var indexOfFirst = nameFile.indexOf("_");
            nameFile = nameFile.substring(indexOfFirst + 1);
          }
        }
      }
    }

    return nameFile;
  }

  getNameByUrl(fileUrl) {
    const nameFile = fileUrl.replace(/^.*[\\\/]/, '');
    // const lastIdx = fileUrl.lastIndexOf('/');
    // if (lastIdx > 0) {
    //   nameFile = fileUrl.substring(lastIdx + 1);
    // }

    return nameFile;
  }

  async onDownloadFile(dataItem: any) {

    const dataRequest = {
      url: dataItem.Url
    };
    const result = await this.appService.doDownload('api/Upload/DownloadByUrl', dataRequest);
    if (result) {
      FileSaver.saveAs(result, dataItem.Name);
    }
  }

  onDeleteFile(dataItem: any) {

    for (let i = this.dataFileUrls.length - 1; i >= 0; i--) {
      if (this.dataFileUrls[i].Url === dataItem.Url) {
        this.dataFileUrls.splice(i, 1);
        break;
      }
    }

    this.bindFileUrls();
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.dataPosts, {}).data
    };

    return result;
  }

  async onSelectFileToUpload(e: SelectEvent) {
    if (!e.files || e.files.length <= 0) {
      return false;
    }
    try {
      const fileData = await this.file.readFile(e.files[0].rawFile);
    } catch {
      this.appSwal.showError(e);
    }
  }

  onSuccessFileToUpload(e: any) {
    try {
      const k = `${e.response.body.Data.DirMedia}${e.response.body.Data.MediaNm[0]}`;
      this.dataFileUrls.push({
        Name: this.getNameByUrl(k),
        Url: k
      });

      this.bindFileUrls();
    } catch {
      this.appSwal.showError(e);
    }
  }

  onRemoveFileToUpload() {

  }
  onCloseInfo(e) {
    this.setDefault();
    this.infoOpened = false;
  }

  manageOpen = false;
  parentMenus: Array<{ Name: string, ID: string }>;
  parentMenusFilter: Array<{ Name: string, ID: string }>;

  parentMenusHandleFilter(value) {
    this.parentMenusFilter = this.parentMenus.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }
  async getMenus() {
    this.loading = true;
    const dataRequest = {
      pageID: 'portal/multimedia'
    };

    const result = await this.appService.doGET('api/Menu/GetMenuMultimedia', null);
    if (result) {
      this.parentMenus = [];
      result.Data.forEach(item => {
        this.parentMenus.push(item);
      });
      this.parentMenusFilter = this.parentMenus.slice();
    }
    this.loading = false;
  }

  Types: Array<{ Name: string, ID: number }>;
  TypesFilter: Array<{ Name: string, ID: number }>;

  get4Type() {
    this.Types = [
      { Name: 'Không hiển thị (Trang chủ)', ID: -1 },
      { Name: 'Banner trái (Trang chủ)', ID: 0 },
      { Name: 'Banner phải (Trang chủ)', ID: 1 }
    ];
    this.TypesFilter = this.Types.slice();
    this.loading = false;
  }

  searchMenus: Array<{ Name: string, ID: string }>;
  searchMenusFilter: Array<{ Name: string, ID: string }>;

  searchMenusHandleFilter(value) {
    this.searchMenusFilter = this.searchMenus.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  getNameByList(menuID) {
    const menus = this.menus.filter((s) => menuID.indexOf(s.ID.toString()) !== -1);

    const menuNames = [];
    for(let i = 0; i < menus.length; i++) {
      menuNames.push(menus[i].Name);
    }
    return menuNames.join("; ");
  }
  async getSearchMenus() {

    this.loading = true;
    const dataRequest = {
      pageID: 'portal/multimedia'
    };

    const result = await this.appService.doGET('api/Menu/GetMenuMultimedia', null);
    if (result) {
      this.searchMenus = [];

      const HA = {
        PName: 'Hình Ảnh', Type: 1, child: []
      };
      const VID = {
        PName: 'Video', Type: 0, child: []
      };

      //this.data = result.Data;
      result.Data.forEach(item => {
        this.searchMenus.push(item);
        if (item.PageID === "portal/multimedia") {
          const temp = {
            PName: item.Name, IDchild: item.ID
          };
          HA.child.push(temp);
        }
        else {
          const temp = {
            PName: item.Name, IDchild: item.ID
          };
          VID.child.push(temp)
        }
      });
      this.data.push(HA);
      this.data.push(VID);
      this.searchMenusFilter = this.searchMenus.slice();
    }
    this.loading = false;
  }
  public data: any[] = [];
  public selectedKeys: any[];
  public menuID: any;
  imagesGrid: any;
  imagesSlide: any;
  isGridMode = true;
  videos: any;
  img = false;
  vid = false;
  async getImages(ID) {
    this.menuID = ID;
    const dataRequest = {
      menuID: this.menuID
    };

    const result = await this.appService.doGET('api/Multimedia/ImageManage', dataRequest);
    if (result) {
      this.imagesGrid = result.Data;
      this.imagesSlide = result.Data.SlideViewData;
    }
  }
  async getVideos(ID) {
    this.menuID = ID;
    const dataRequest = {
      menuID: this.menuID
    };

    const result = await this.appService.doGET('api/Multimedia/Video', dataRequest);
    if (result) {
      this.videos = result.Data;
    }
  }
  linkVid: any;

  public handleSelection({ index }: any): void {
    this.selectedKeys = [index];
    /*Get Parent Node*/
    var id = this.selectedKeys.toString();
    var idx = id.substring(0, 1);
    var Type = this.data[idx].Type;
    if (Type == 1) {
      this.img = true;
      this.vid = false;
      var tempChild = this.data[idx].child;

      /*Get Child by Parent*/
      var child_idx = id.substring(id.length - 1, id.length);
      var MenuMutilmedia_ID = tempChild[child_idx].IDchild;
      this.getImages(MenuMutilmedia_ID);
    }
    else {
      this.img = false;
      this.vid = true;
      var tempChild = this.data[idx].child;

      /*Get Child by Parent*/
      var child_idx = id.substring(id.length - 1, id.length);
      var MenuMutilmedia_ID = tempChild[child_idx].IDchild;
      this.getVideos(MenuMutilmedia_ID);
    }
  }
  item_ID: any;
  link: any;
  main_URL_IMG: any;
  async getLink(ID) {
    this.item_ID = ID;
    const dataRequest = {
      menuId: this.item_ID
    };

    const result = await this.appService.doGET('api/Multimedia/getLink', dataRequest);
    if (result) {
      this.link = this.appService.apiRoot + result.Data;
      this.uploadFlg.image = true;
      this.imgImageDefault[0] = this.link;
      this.imgImageMain[0] = this.imgImageDefault[0];
      this.manageOpen = false;
    }
  }
  public onGetLink(ID_item) {
    this.getLink(ID_item);
  }
  public onManageOpen() {
    this.manageOpen = true;
  }
  public onCloseManage() {
    this.manageOpen = false;
  }

  async getLanguage() {
    var k = 0;
    if (this.dataPostItem.ID != null) {
      const dataRequest = {
        id: this.dataPostItem.ID
      }
      const result = await this.appService.doGET('api/Post/GetPostLanguage', dataRequest);
      if (result && result.Status == 1) {
        for (let i = 0; i < result.Data.length; i++) {
          if (result.Data[i].LanguageID == "en-US") {
            this.dataPostItemEnLanguage = result.Data[i];
          }
        }
      }
    }
  }

  public onTabSelect(e) {
  }

  async getPostsById(id) {

    const dataRequest = {
      id : id
    };

    const selectedItem = await this.appService.doGET('api/Post/GetById', dataRequest);
    if (selectedItem && selectedItem.Status ===1){
      if (selectedItem.Data) {
        selectedItem.Data.IsAdd = false;
        if(selectedItem.Data.FileUrl == null || selectedItem.Data.FileUrl == ""){
          selectedItem.Data.FileUrl = [];
        }
        else{
          selectedItem.Data.FileUrl = JSON.parse(selectedItem.Data.FileUrl);
        }

        // if(selectedItem.Data.Reference == null || selectedItem.Data.Reference == ""){
        //   selectedItem.Data.Reference = "";
        // }
        // else{
        //   selectedItem.Data.Reference = JSON.parse(selectedItem.Data.Reference);
        //   selectedItem.Data.Reference = (selectedItem.Data.Reference).join(";")          
        // }
        
        this.dataPostItem = selectedItem.Data;
        this.dataPostItem.CreateAt = this.dataPostItem.CreateAt ? new Date(this.dataPostItem.CreateAt) : null;
        this.bindTemp(this.dataPostItem);
        this.imgImageDefault = [];
        //call api get base 64 to render image
        if (this.dataPostItem.ImageUrls) {
          //var listurlArr = JSON.parse();
          const dataRequest = {
            imagePath: `${this.dataPostItem.ImageUrls}`
          };
          if (this.dataPostItem.ImageUrls.indexOf("Multimedia") > 0) {
            this.imgImageDefault[0] = this.dataPostItem.ImageUrls;
          }
          else {
            const result = await this.appService.doGET('api/Post/GetBase64ImageResult', dataRequest);
            if (result && result.Status === 1) {
              if (result.Data != null && result.Data.Base64 != null && result.Data.Base64 != "") {
                const url = result.Data.Base64;
                this.imgImageDefault[0] = url;
              }
            }
          }
        } else {
          this.imageDef = '../../assets/images/default-02.png';
          this.imgImageDefault = [
            this.imageDef,
          ];
        }
  
        const temp = [];
        if (selectedItem.Data.MenuID) {
          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < this.menus.length; i++) {
            if (selectedItem.Data.MenuID.indexOf(this.menus[i].ID) >= 0) {
              temp.push(this.menus[i]);
            }
          }
        }
        this.dataMenuID = temp;
      }
    }
    
    await this.getLanguage();
    this.enabled = true;
    this.enabledID = false;
    this.infoOpened = true;
  }

  async getPostsByIdReview(id) {

    const dataRequest = {
      id : id
    };

    const selectedItem = await this.appService.doGET('api/Post/GetById', dataRequest);
    
    if (selectedItem && selectedItem.Status ===1){
      if (selectedItem.Data) {
      
        selectedItem.Data.IsAdd = false;
        if(selectedItem.Data.FileUrl == null || selectedItem.Data.FileUrl == ""){
          selectedItem.Data.FileUrl = [];
        }
        else{
          selectedItem.Data.FileUrl = JSON.parse(selectedItem.Data.FileUrl);
        }

        // if(selectedItem.Data.Reference == null || selectedItem.Data.Reference == ""){
        //   selectedItem.Data.Reference = "";
        // }
        // else{
        //   selectedItem.Data.Reference = JSON.parse(selectedItem.Data.Reference);
        //   selectedItem.Data.Reference = (selectedItem.Data.Reference).join(";");
        // }

        this.dataPostItem = selectedItem.Data;
        this.bindTemp(this.dataPostItem);
  
        this.imgImageDefault = [];
        //call api get base 64 to render image
        if (this.dataPostItem.ImageUrls) {
          //var listurlArr = JSON.parse();
          const dataRequest = {
            imagePath: `${this.dataPostItem.ImageUrls}`
          };
          if (this.dataPostItem.ImageUrls.indexOf("Multimedia") > 0) {
            this.imgImageDefault[0] = this.dataPostItem.ImageUrls;
          }
          else {
            const result = await this.appService.doGET('api/Post/GetBase64ImageResult', dataRequest);
            if (result && result.Status === 1) {
              if (result.Data != null && result.Data.Base64 != null && result.Data.Base64 != "") {
                const url = result.Data.Base64;
                this.imgImageDefault[0] = url;
              }
            }
          }
        } else {
          this.imageDef = '../../assets/images/default-02.png';
          this.imgImageDefault = [
            this.imageDef,
          ];
        }
  
        const temp = [];
        if (selectedItem.Data.MenuID) {
          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < this.menus.length; i++) {
            if (selectedItem.Data.MenuID.indexOf(this.menus[i].ID) >= 0) {
              temp.push(this.menus[i]);
            }
          }
        }
        this.dataMenuID = temp;
      }
    }
    
    await this.getPostTemp();
    this.reViewOpened = true;
    this.infoOpened = false;
    this.isReviewDialog = true;
  }

  getUrlDownload(item) {
    let url = this.urlDownload.replace(/\"/g, "") + item;
    url = url.replace(/\"/g, '')
    return url;
  }

  onRemoveFile(file){
    var isExistedInFile = this.dataPostItemTemp.FileUrl.findIndex(x => x == file);
    if (isExistedInFile != -1) {
      this.dataPostItemTemp.FileUrl.splice(isExistedInFile, 1);
    }
  }

  onSuccessFileDocToUpload(e: any) {
    if (!this.allowInsertFile) {
      return;
    }
    try {
      if(this.dataPostItemTemp.FileUrl == undefined){
        this.dataPostItemTemp.FileUrl = [];
      }
      this.dataPostItemTemp.FileUrl.push(`${e.response.body.Data.DirMedia}${e.response.body.Data.MediaNm[0]}`);
    } catch {
      this.appSwal.showError(e);
    }
  }

  async onSelectFileDocToUpload(e: SelectEvent) {
    if (!e.files || e.files.length <= 0) {
      return;
    }
    const extension = e.files[0].extension.toLowerCase();
    this.allowInsertFile = true;
    try {
      const fileData = e.files[0]; // await this.file.readFile(e.files[0].rawFile);
      const maxMB = 25;
      const maxSizeKB = 1024 * 1024 * maxMB;
      if (fileData.size > maxSizeKB) {
        this.allowInsertFile = false;
        this.appSwal.showWarning(`Kích thước tập tin không được lớn hơn ${maxMB} MB`, false);
        return false;
      }
    } catch {
      this.appSwal.showError(e);
    }
    // tslint:disable-next-line: max-line-length
    if (!extension || (extension.toLowerCase() !== '.doc' && extension.toLowerCase() !== '.docx' && extension.toLowerCase() !== '.pdf'
    && extension.toLowerCase() !== '.xlsx' && extension.toLowerCase() !== '.xls' && extension.toLowerCase() !== '.zip')) {
      this.allowInsertFile = false;
      this.appSwal.showWarning('Vui lòng chọn tập tin có định dạng .doc, .docx, .pdf, .xlsx, .xls, .zip', false);
      return false;
    }
  }

  onRemoveFileDocToUpload() {
    this.dataPostItemTemp.FileUrl = [];
    this.filesUploadName = '';  
    this.filesUpload = [];
  }

  getFileName(fileUrls) {
    var nameFile = "";
    if (fileUrls != "" && fileUrls != null) {
      var urlArr = fileUrls.split("/");
      if (urlArr.length > 0) {
        nameFile = urlArr[urlArr.length - 1];
        if (nameFile != "" && nameFile != null) {
          var indexOfFirst = nameFile.indexOf("_");
          nameFile = nameFile.substring(indexOfFirst + 1);
        }
      }
    }
    return nameFile;
  }
  
  titleChange(e : any){
    if (e){
      this.dataPostItemTemp.UrlPath = this.cleanAccents(e);
    }    
  } 
  cleanAccents (str: string){
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g,"a"); 
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g,"e"); 
    str = str.replace(/ì|í|ị|ỉ|ĩ/g,"i"); 
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g,"o"); 
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g,"u"); 
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g,"y"); 
    str = str.replace(/đ/g,"d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    // Some system encode vietnamese combining accent as individual utf-8 characters
    // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư    
    str = str.trim();
    str = str.replace(/[!&\/\\#,+()$~%.'":*?<>{} ]/g,'-');         
    return str;
  }
}

export class UploadAdapter {
  private loader;
  constructor(loader: any) {
    this.loader = loader;
  }

  upload() {
    return this.loader.file
      .then(file => new Promise((resolve, reject) => {
        var myReader = new FileReader();
        myReader.onloadend = (e) => {
          resolve({ default: myReader.result });
        }

        myReader.readAsDataURL(file);
      }));
  };
  
}
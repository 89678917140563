import { Component, OnInit, OnDestroy, ViewChild, ElementRef, HostListener } from '@angular/core';
import { AppLanguage } from '../../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../services/app.service';
import { SelectableSettings, PageChangeEvent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { AppSwal } from 'src/app/services/app.swal';
import { IntlService } from '@progress/kendo-angular-intl';
import { Notification } from '../../services/app.notification';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
import { AppGuid } from 'src/app/services/app.guid';
import { NullInjector } from '@angular/core/src/di/injector';
import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';
import * as XLSX from 'xlsx';
import { AppFile } from 'src/app/services/app.file';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppUtils } from 'src/app/services/app.utils';
import { AppComponent } from '../../app.component';
import { st } from '@angular/core/src/render3';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit, OnDestroy {

  isSticky = false
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 180;
  }

  user: any;
  loading = false;
  dataMenus = [];
  dataMenuSelectableSettings: SelectableSettings;
  dataMenuSort = {
    allowUnsort: true,
    mode: 'multiple'
  };
  public dataMenuFocus = {
    Name: true
  };
  dataMenuSortByField: SortDescriptor[] = [
    // {
    //   field: 'ParentName',
    //   dir: 'asc'
    // }, {
    //   field: 'OrderIdx',
    //   dir: 'asc'
    // }
  ];

  //Config: Constant for paging
  public LBM_NUM_PAGING_SKIP = 0;
  public LBM_NUM_PAGING_TAKE = 50;
  public LBM_NUM_PAGING_BTN = 5;

  // dataPageSkip = this.LBM_NUM_PAGING_SKIP;
  // dataMenuPageSize = this.LBM_NUM_PAGING_TAKE;
  //dataPagePageSize = this.LBM_NUM_PAGING_TAKE;
  dataMenuSelection: number[] = [];
  //dataPageSelection: number[] = [];
  dataMenuItem: any;
  dataMenuItemtemp: any;
  myInterval: any;
  pageName: any;

  public buttonCount = this.LBM_NUM_PAGING_BTN;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  // public pageSizes = true;
  // public previousNext = true;
  public test = false;

  public dataMenuState: State = {
    // skip: this.dataPageSkip,
    // take: this.dataPageSkip + this.dataMenuPageSize,
    filter: {
      logic: 'and',
      filters: []
    },
    group: [{ field: 'ParentName' }]
  };
  // public dataPageState: State = {
  //   skip: this.dataPageSkip,
  //   take: this.dataPageSkip + this.dataMenuPageSize,
  //   filter: {
  //     logic: 'and',
  //     filters: []
  //   },
  //   group: [{ field: 'ParentName' }]
  // };
  dataMenuGridDataResult: GridDataResult;

  parentMenus: Array<{ Name: string, ID: string }>;
  parentMenusFilter: Array<{ Name: string, ID: string }>;


  public uploadSaveUrl = 'saveUrl';
  public uploadRemoveUrl = 'removeUrl';
  public enabled = false;
  public enabledID = false;
  isEnabledSaveAll = false;
  control: any;
  controlDefault = true;
  allowMulti = true;
  infoOpened = false;

  searchOption = {
    SearchText: ''
  };

  constructor(
    private translate: TranslateService,
    private language: AppLanguage,
    private appService: AppService,
    private appSwal: AppSwal,
    public intl: IntlService,
    private notification: Notification,
    private guid: AppGuid,
    private file: AppFile,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    private appUtils: AppUtils,
    private appComponent: AppComponent,
  ) {
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
    this.getControl();
    this.setDefault();
    this.getPages();
    this.getAllMenus();
    this.get4Type();
    this.language.default();
    this.setSelectableSettings();
    this.onReload();
    this.getPageName();
  }

  parentMenusHandleFilter(value) {
    this.parentMenusFilter = this.parentMenus.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  ngOnDestroy(): void {
    if (this.myInterval) { this.myInterval.unsubscribe(); }
  }

  ngOnInit() {
  }
  async getPageName() {
    this.pageName = await this.appControls.getPageName();
  }

  async getControl() {
    this.control = await this.appControls.getControls(this.user.RoleID);
    this.controlDefault = false;
  }

  setSelectableSettings(): void {

    this.allowMulti = !this.allowMulti;
    this.dataMenuSelectableSettings = {
      checkboxOnly: false,
      mode: this.allowMulti ? 'multiple' : 'single'
    };
  }

  onSearchKeyPress(e: any) {
    if (e.keyCode === 13 && this.searchOption.SearchText) {
      this.onSearch();
    }
  }
  public GUID_EMPTY = "00000000-0000-0000-0000-000000000000";
  async getMenus() {
    this.loading = true;
    const dataRequest = {
      searchText: this.searchOption.SearchText
    };

    const result = await this.appService.doGET('api/Menu/Search', dataRequest);
    if (result) {
      this.dataMenus = result.Data;
      this.bindMenus();
    }
    this.loading = false;
    this.checkSelectionID();
  }

  async getAllMenus() {
    //this.loading = true;
    const dataRequest = {
      searchText: ''
    };

    const result = await this.appService.doGET('api/Menu/Search', dataRequest);
    if (result) {
      this.parentMenus = result.Data;
      this.parentMenus.splice(0, 0, {
        ID: this.GUID_EMPTY,
        Name: 'Root'
      })
      this.parentMenusFilter = this.parentMenus.slice();
    }
    this.loading = false;
  }
  async getPageParent() {
    const dataRequest = {
      searchText: ''
    };
    const result = await this.appService.doGET('api/Page/Search', dataRequest);
    if (result && result.Status === 1) {
      this.parentPages = [];
      this.parentPages.push({
        ID: null,
        Name: 'Root'
      });

      result.Data.forEach(item => {
        if (!item.ParentID) {
          this.parentPages.push(item);
        }
      });

      this.parentPagesFilter = this.parentPages.slice();
    }
  }

  checkSelectionID() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = this.dataMenuSelection.length - 1; i >= 0; i--) {
      const selectedItem = this.dataMenus.find((item) => {
        return item.ID === this.dataMenuSelection[i];
      });
      if (!selectedItem) {
        this.dataMenuSelection.splice(i, 1);
      }
    }
  }
  public onPageDataStateChange(state: DataStateChangeEvent): void {
    this.dataMenuSelection = [];
    this.dataMenuState = state;
    this.dataMenuGridDataResult = process(this.dataMenus, this.dataMenuState);
  }

  setDefault() {
    this.dataMenuItem = {
      IsAdd: true,
      ID: null,
      Name: null,
      ParentID: '',
      MenuID: this.GUID_EMPTY,
      OrderIdx: 0,
      MenuFlg: true,
      ButtonFlg: false,
      DelFlg: false,
      UrlPath: ''
    };
    this.dataMenuItemtemp = Object.assign({}, this.dataMenuItem);
    this.dataMenuSelection = [];
    this.dataMenuFocus.Name = true;
    this.enabled = true;
    this.enabledID = true;
  }

  bindtemp(item) {
    this.dataMenuItemtemp = Object.assign({}, item);
  }

  // onMenuPageChange(event: PageChangeEvent) {
  //   this.dataPageSkip = event.skip;
  //   this.bindMenus();
  // }

  onMenuSelectedKeysChange() {
    if (this.dataMenuSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }
    if (this.dataMenuSelection.length > 1) {
      if (this.allowMulti) {
        return;
      }
      this.appSwal.showWarning(this.translate.instant('SelectSingle'), false);
    } else {
      const selectedID = this.dataMenuSelection[0];
      const selectedItem = this.dataMenus.find((item) => {
        return item.ID === selectedID;
      });
      selectedItem.IsAdd = false;
      this.dataMenuItem = selectedItem;
      this.bindtemp(selectedItem);
      this.enabled = false;
      this.enabledID = false;
    }
  }

  bindMenus() {
    this.dataMenuGridDataResult = {
      data: orderBy(this.dataMenus, this.dataMenuSortByField),
      total: this.dataMenus.length
    };
    this.dataMenuGridDataResult = process(this.dataMenus, this.dataMenuState);
  }

  onMenuSortChange(sort: SortDescriptor[]): void {
    this.dataMenuSortByField = sort;
    this.bindMenus();
  }

  public onMenuDataStateChange(state: DataStateChangeEvent): void {
    this.dataMenuSelection = [];
    this.dataMenuState = state;
    this.dataMenuGridDataResult = process(this.dataMenus, this.dataMenuState);
  }

  getColumnIndex(name) {
    const columns = [
      'NameParentMenu',
      'ParentID',
      'ID',
      'Name',
      'OrderIdx',
      'MenuFlg',
      'ButtonFlg'
    ];

    return columns.indexOf(name);
  }

  async selectEventHandler(e: SelectEvent) {
    this.appComponent.loading = true;
    const fileData = (await this.file.readXLSX(e.files[0].rawFile)) as Array<any>;
    let parentMenu: any;
    this.dataMenus = [];
    for (let i = 1; i < fileData.length; i++) {
      parentMenu = this.parentMenus.find(item => {
        return this.appUtils.compareString(fileData[i][this.getColumnIndex('ParentID')], item.Name, item.ID);
      });

      if (fileData[i].indexOf(fileData[i][this.getColumnIndex('NameParentMenu')]) == -1) {
        this.dataMenus.push({
          IsAdd: false,
          ParentID: parentMenu ? parentMenu.ID : null,
          ParentName: parentMenu ? parentMenu.Name : null,
          ID: fileData[i][this.getColumnIndex('ID')],
          Name: fileData[i][this.getColumnIndex('Name')],
          OrderIdx: fileData[i][this.getColumnIndex('OrderIdx')],
          MenuFlg: fileData[i][this.getColumnIndex('MenuFlg')],
          ButtonFlg: fileData[i][this.getColumnIndex('ButtonFlg')]
        });
      }
    }
    this.bindMenus();
    this.isEnabledSaveAll = true;
    this.appComponent.loading = false;
  }

  removeEventHandler() {
    this.isEnabledSaveAll = false;
    this.onReload();
  }

  doInterval() {
    this.myInterval = Observable.interval(this.appService.timeInterval).subscribe(() => {
      this.onReload();
    });
  }

  onSearchTextChange(e: any) {
    if (!this.searchOption.SearchText) {
      this.onReload();
    }
  }

  onRemoveSearchText() {
    this.searchOption.SearchText = '';
    this.getMenus();
  }

  onSearch() {
    this.getMenus();
    this.isEnabledSaveAll = false;
  }

  onReload() {
    this.searchOption.SearchText = '';
    this.getMenus();
    this.isEnabledSaveAll = false;
  }

  onClearMenu() {
    this.setDefault();
  }

  onAllowSelectMulti() {
    this.setSelectableSettings();
  }

  onAddNewMenu() {

    this.setDefault();

    this.infoOpened = true;
  }

  onSaveMenu() {
    if (this.dataMenuItem.IsAdd) { this.addMenu(); } else { this.updateMenu(); }
  }

  async onSaveMenus() {
    this.appComponent.loading = true;
    const dataRequests = [];
    for (let i = 0; i < this.dataMenus.length; i++) {
      dataRequests.push(this.createDataRequest(this.dataMenus[i]));
    }
    const result = await this.appService.doPOST('api/Menu/Saves', dataRequests);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.isEnabledSaveAll = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  createDataRequest(data) {
    const temp = data ? data : this.dataMenuItemtemp;
    return {
      ID: temp.ID,
      Name: temp.Name,
      ParentID: temp.ParentID,
      PageID: temp.PageID,
      MenuID: temp.MenuID,
      MenuFlg: temp.MenuFlg,
      HomeFlg: temp.HomeFlg,
      HomeOrderIdx: temp.HomeOrderIdx,
      HomeDisplayType: temp.HomeDisplayType,
      ButtonFlg: temp.ButtonFlg,
      DelFlg: temp.DelFlg,
      OrderIdx: temp.OrderIdx,
      OrderIdxInPost: temp.OrderIdxInPost,
      WidthBanner: temp.WidthBanner,
      HeightBanner: temp.HeightBanner,
      UrlPath: temp.UrlPath
    };
  }

  onCloseMenu(status: any) {
    this.enabled = true;
    this.enabledID = false;
    this.infoOpened = true;
  }

  onEditMenu() {
    this.enabled = true;
    this.enabledID = false;
    this.infoOpened = true;
  }
  parentPages: Array<{ Name: string, ID: string }>;
  parentPagesFilter: Array<{ Name: string, ID: string }>;
  async getPages() {
    this.loading = true;
    const dataRequest = {
      searchText: this.searchOption.SearchText
    };

    const result = await this.appService.doGET('api/Menu/GetAllPage', null);
    if (result) {
      this.parentPages = [];
      result.Data.forEach(item => {
        this.parentPages.push(item);
      });
      this.parentPagesFilter = this.parentPages.slice();
    }
    this.loading = false;
    this.checkSelectionID();
  }

  parentPagesHandleFilter(value) {
    this.parentPagesFilter = this.parentPages.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  async addMenu() {
    this.appComponent.loading = true;
    const dataRequest = this.createDataRequest(null);
    const result = await this.appService.doPOST('api/Menu', dataRequest);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.setDefault();
      this.getAllMenus();
      this.infoOpened = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  async updateMenu() {
    this.appComponent.loading = true;
    const id = this.dataMenuItem.ID;
    const dataRequest = this.createDataRequest(null);

    const result = await this.appService.doPUT('api/Menu', dataRequest, { id });
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.setDefault();
      this.getAllMenus();
      this.infoOpened = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  async onDeleteMenu() {
    if (this.dataMenuSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);

      return;
    }
    this.appComponent.loading = true;
    const dataRequest = {
      IDList: JSON.stringify(this.dataMenuSelection),
      FlgRevert: false
    };

    const option = await this.appSwal.showWarning(this.translate.instant('AreYouSure'), true);
    if (option) {
      const result = await this.appService.doPOST('api/Menu/Deletes', dataRequest);
      if (result && result.Status === 1) {
        this.notification.showSuccess(result.Msg);
        this.onReload();
        //this.onAddNewMenu();
        this.enabled = false;
        this.enabledID = false;
        this.dataMenuSelection = [];
        this.allowMulti = false;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
    this.appComponent.loading = false;
  }

  Types: Array<{ Name: string, ID: number }>;
  TypesFilter: Array<{ Name: string, ID: number }>;
  get4Type() {
    var a = [];
    a[0] = this.translate.instant('TypeHomepage0');
    a[1] = this.translate.instant('TypeHomepage1');
    a[2] = this.translate.instant('TypeHomepage2');
    a[3] = this.translate.instant('TypeHomepage3');
    a[4] = this.translate.instant('TypeHomepage4');
    this.Types = [];
    for (let i = 0; i < 5; i++) {
      this.Types.push({
        ID: i,
        Name: a[i]
      });
    }
    this.TypesFilter = this.Types.slice();
    this.loading = false;
  }
  onCloseInfo(e) {
    this.setDefault();
    this.infoOpened = false;
  }
  menuNameChange(e : any){
    if (e){
      this.dataMenuItemtemp.UrlPath = this.cleanAccents(e);
    }    
  } 
  cleanAccents (str: string){
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g,"a"); 
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g,"e"); 
    str = str.replace(/ì|í|ị|ỉ|ĩ/g,"i"); 
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g,"o"); 
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g,"u"); 
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g,"y"); 
    str = str.replace(/đ/g,"d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    // Some system encode vietnamese combining accent as individual utf-8 characters
    // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư    
    str = str.replace(/[!&\/\\#,+()$~%.'":*?<>{} ]/g,'-');     
    str = str.trim();
    return str;
  }
}

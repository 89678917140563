import { Component, OnInit, HostListener } from '@angular/core';
import { AppService } from '../../services/app.service';
import { ActivatedRoute } from '@angular/router';
import { AppControls } from 'src/app/services/app.controls';

@Component({
  selector: 'app-multimedia',
  templateUrl: './multimedia.component.html',
  styleUrls: ['./multimedia.component.css']
})
export class MultimediaComponent implements OnInit {
  isSticky: boolean = false;
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset > 0;
  }
  constructor (
  public appService: AppService,
  private route: ActivatedRoute,
  private appControls: AppControls
     ) { }

imagesGrid: any;
imagesSlide: any;
videos: any;

isImagePage: boolean;

srcGrid = '../assets/images/icon-grid-active.png';
srcSlide = '../assets/images/icon-slide-inactive.png';

menuID;

isGridMode = true;
pageName: any;

ngOnInit() {
  this.menuID = this.route.snapshot.queryParamMap.get('menuID');

  this.isImagePage = true;
  this.getImages();
  this.getMenuName();
}

async getMenuName() {
  const dataRequest = {
    id: this.menuID,
  };
  const result = await this.appService.doGET('api/Menu', dataRequest);
  if (result && result.Status === 1) {
    this.pageName = result.Data.Name;
  }
}

imgGridClicked() {
  this.srcGrid = '../assets/images/icon-grid-active.png';
  this.srcSlide = '../assets/images/icon-slide-inactive.png';

  this.isGridMode = true;
}
imgSlideClicked() {
  this.srcGrid = '../assets/images/icon-grid-inactive.png';
  this.srcSlide = '../assets/images/icon-slide-active.png';

  this.isGridMode = false;
}

async getImages() {
  const dataRequest = {
    menuID: this.menuID
  };

  const result = await this.appService.doGET('api/Multimedia/Image', dataRequest);
  if (result) {
    this.imagesGrid = result.Data.SlideViewData;
    this.imagesSlide = result.Data.SlideViewData;
  }
}

async getVideos() {
  const dataRequest = {
    menuID: this.menuID
  };

  const result = await this.appService.doGET('api/Multimedia/Video', dataRequest);
  if (result) {
    this.videos = result.Data;
  }
}

}

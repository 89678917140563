import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppComponent } from 'src/app/app.component';
import { AppService } from 'src/app/services/app.service';
import { AppLanguage } from 'src/app/services/app.language';
import { AppSwal } from 'src/app/services/app.swal';

@Component({
  selector: 'app-login-ask',
  templateUrl: './login-ask.component.html',
  styleUrls: ['./login-ask.component.css']
})
export class LoginAskComponent implements OnInit {

  @Input() urlPath = '';
  @Output() loginSuccess = new EventEmitter<string>();

  loginInfo = {
    UserName: '',
    Password: ''
  };
  dataRequest = {
    UserName: '',
    Password: ''
  };
  languageName = '';

  searchOption = {
    UrlPath: ''
  };

  languages: Array<any> = [{
    id: 0,
    culture: 'vi-VN',
    text: 'Tiếng Việt',
    click: (dataItem) => {
      this.onChangeLanguage(dataItem);
    }
  }, {
    id: 1,
    culture: 'en-US',
    text: 'English',
    click: (dataItem) => {
      this.onChangeLanguage(dataItem);
    }
  }];

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private appService: AppService,
    private appComponent: AppComponent,
    private language: AppLanguage,
    private activatedRoute: ActivatedRoute,
    private appSwal: AppSwal
  ) {
    // this.activatedRoute.queryParams.subscribe((params: any) => {
    //   if (params && params.menuID) {
    //     this.searchOption.MenuID = params.menuID;
    //   }
    // });
    this.getLanguageName();
  }

  ngOnInit() {
    this.searchOption.UrlPath = this.urlPath;
  }

  // async onLogin() {
  //   this.appComponent.loading = true;
  //   const result = await this.authenticationService.doSignIn(this.loginInfo.UserName, this.loginInfo.Password);
  //   if (result.Status === 1) {
  //     this.router.navigate(['portal/comment-list']);
  //   } else {
  //     this.appSwal.showWarning(result.Msg, false);
  //   }
  //   this.appComponent.loading = false;
  // }

  async onLogin() {
    this.appComponent.loading = true;
    const result = await this.appService.doPOST("api/LoginAsk/doLogin", this.dataRequest);
    if (result.Status === 1) {
      const accountInfo = {
        UserName: this.dataRequest.UserName,
        FullName: result.Data
      };
      localStorage.setItem('accountInfoPortal', JSON.stringify(accountInfo));
      this.loginSuccess.emit();
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  AddNew(){
    this.router.navigate(['portal/create-account'], { queryParams: { title: this.searchOption.UrlPath } });
  }

  ChangePass(){
    this.router.navigate(['portal/change-account-password']);
  }

  onChangeLanguage(dataItem) {
    this.appComponent.switchLanguage(dataItem.culture);
    this.getLanguageName();
  }

  getLanguageName() {
    let lang = this.language.get();
    if (!lang) {
      lang = 'vi-VN';
      this.appComponent.switchLanguage(lang);
    }
    const language = this.languages.find(item => {
      return item.culture === lang;
    });

    if (language) { this.languageName = language.text; }
  }

  onKeyPress(e: any) {
    if (e.keyCode === 13) {
      this.onLogin();
    }
  }
}

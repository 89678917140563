import { Component, OnInit, OnDestroy, ViewChild, ElementRef, HostListener } from '@angular/core';
import { AppLanguage } from '../../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../services/app.service';
import { SelectableSettings, PageChangeEvent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { AppSwal } from 'src/app/services/app.swal';
import { IntlService } from '@progress/kendo-angular-intl';
import { Notification } from '../../services/app.notification';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Observable } from 'rxjs/Observable';
import { AppGuid } from 'src/app/services/app.guid';
import { NullInjector } from '@angular/core/src/di/injector';
import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';
import * as XLSX from 'xlsx';
import { AppFile } from 'src/app/services/app.file';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppUtils } from 'src/app/services/app.utils';
import { AppComponent } from '../../app.component';
import { DomSanitizer } from '@angular/platform-browser';
import { saveAs } from '@progress/kendo-file-saver';
import { Http } from '@angular/http';
import { KendoButtonService } from '@progress/kendo-angular-buttons/dist/es2015/button/button.service';
import { DataSource } from '@angular/cdk/table';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

@Component({
  selector: 'app-unit',
  templateUrl: './unit.component.html',
  styleUrls: ['./unit.component.css']
})
export class UnitComponent implements OnInit, OnDestroy {

  isStickySearch = false
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isStickySearch = window.pageYOffset >= 140;
  }

  user: any;
  loading = false;
  dataUnits = [];
  dataUnitSelectableSettings: SelectableSettings;
  dataUnitSort = {
    allowUnsort: true,
    mode: 'multiple'
  };
  public dataUnitFocus = {
    Name: true
  };
  dataUnitSortByField: SortDescriptor[] = [];

  public WORKING_NUM_PAGING_SKIP = 0;
  public WORKING_NUM_PAGING_TAKE = 50;
  public WORKING_NUM_PAGING_BTN = 5;

  dataUnitSkip = this.WORKING_NUM_PAGING_SKIP;
  dataUnitPageSize = this.WORKING_NUM_PAGING_TAKE;
  dataUnitSelection: number[] = [];
  dataUnitItem: any;
  dataUnitItemEnLanguage: any;
  dataUnitItemtemp: any;
  myInterval: any;
  infoOpened = false;

  public buttonCount = this.WORKING_NUM_PAGING_BTN;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;

  public dataUnitState: State = {
    skip: this.dataUnitSkip,
    take: this.dataUnitSkip + this.dataUnitPageSize,
    filter: {
      logic: 'and',
      filters: []
    }
  };
  dataUnitGridDataResult: GridDataResult;

  units: Array<{ Name: string, ID: string }>;
  unitsFilter: Array<{ Name: string, ID: string }>;
  unitfile: Array<{ Name: string, ID: string }>;


  public uploadSaveUrl = 'saveUrl';
  public uploadRemoveUrl = 'removeUrl';
  public enabled = false;
  public enabledID = false;
  isEnabledSaveAll = false;
  control: any;
  pageName: any;
  controlDefault = true;
  allowMulti = true;
  InputingFile = false;

  searchOption = {
    SearchText: ''
  };

  public Editor = DecoupledEditor;
  config = {
    toolbar: ['heading',
      '|',
      'bold',
      'italic',
      'link',
      'bulletedList',
      'numberedList',
      '|',
      'indent',
      'outdent',
      '|',
      'insertTable',
      'mediaEmbed',
      'undo',
      'redo']
  }

  constructor(
    private translate: TranslateService,
    private language: AppLanguage,
    private appService: AppService,
    private appSwal: AppSwal,
    public intl: IntlService,
    private notification: Notification,
    private guid: AppGuid,
    private file: AppFile,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    private appUtils: AppUtils,
    private appComponent: AppComponent,
    private domSanitizer: DomSanitizer,
    private http: Http,
  ) {
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
    this.getControl();
    this.setDefault();
    this.language.default();
    this.setSelectableSettings();
    this.onReload();
    this.initDisplay();
    this.getPageName();
  }

  unitsHandleFilter(value) {
    this.unitsFilter = this.units.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }


  ngOnDestroy(): void {
    if (this.myInterval) { this.myInterval.unsubscribe(); }
  }

  ngOnInit() {
  }
  async getPageName() {
    this.pageName = await this.appControls.getPageName();
  }

  async getControl() {
    this.control = await this.appControls.getControls(this.user.RoleID);
    this.controlDefault = false;
  }

  setSelectableSettings(): void {
    this.allowMulti = !this.allowMulti;
    if (this.InputingFile === true) {
      this.allowMulti = false;
      this.dataUnitSelectableSettings = {
        enabled: false,
        mode: 'single'
      };
    } else {
      this.dataUnitSelectableSettings = {
        checkboxOnly: false,
        mode: this.allowMulti ? 'multiple' : 'single'
      };
    }
  }

  setSelectableSettingsFile(): void {
    this.dataUnitSelectableSettings = {
      enabled: true,
      checkboxOnly: false,
      mode: this.allowMulti ? 'multiple' : 'single'
    };
  }

  onSearchKeyPress(e: any) {
    if (e.keyCode === 13 && this.searchOption.SearchText) {
      this.onSearch();
    }
  }

  async getUnits() {
    this.loading = true;
    const dataRequest = {
      searchText: this.searchOption.SearchText
    };

    const result = await this.appService.doGET('api/Unit/Search', dataRequest);
    if (result && result.Status) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < result.Data.length; i++) {
        if (result.Data[i].ParentID !== '') {
          // tslint:disable-next-line:prefer-for-of
          for (let j = 0; j < result.Data.length; j++) {
            if (result.Data[i].ParentID === result.Data[j].ID) {
              result.Data[i].ParentName = result.Data[j].Name
            }
          }
        }
      }

      this.dataUnits = result.Data;
      this.bindUnits();
    }
    this.loading = false;
    this.checkSelectionID();

    this.getUnitParent();
  }

  async getUnitParent() {
    const result = await this.appService.doGET('api/Unit', null);
    if (result && result.Status) {
      this.units = result.Data;
      this.unitsFilter = this.units.slice();
    }
  }

  checkSelectionID() {
    for (let i = this.dataUnitSelection.length - 1; i >= 0; i--) {
      const selectedItem = this.dataUnits.find((item) => {
        return item.ID === this.dataUnitSelection[i];
      });
      if (!selectedItem) {
        this.dataUnitSelection.splice(i, 1);
      }
    }
  }

  setDefault() {
    this.dataUnitItem = {
      IsAdd: true,
      ID: '',
      Name: '',
      ParentID: '',
      ParentName: '',
      DelFlg: false,
      OrderIdx: 0,
      Description: ''
    };
    this.dataUnitItemEnLanguage = {
      ID: null,
      UnitID: null,
      Name: null,
      Description: null,
      CreateAt: null,
      CreateBy: null,
      UpdateAt: null,
      UpdateBy: null,
      LanguageID: null,
    };
    this.dataUnitItemtemp = Object.assign({}, this.dataUnitItem);

    this.enabled = true;
    this.enabledID = false;
    this.dataUnitSelection = [];
  }
  bindtemp(item) {
    this.dataUnitItemtemp = Object.assign({}, item);
  }

  onUnitPageChange(event: PageChangeEvent) {
    this.dataUnitSkip = event.skip;
    this.bindUnits();
  }

  async onUnitSelectedKeysChange() {
    if (this.dataUnitSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }
    if (this.dataUnitSelection.length > 1) {
      if (this.allowMulti) {
        return;
      }
      this.appSwal.showWarning(this.translate.instant('SelectSingle'), false);
    } else {
      if (this.dataUnits.length > 0 && this.InputingFile === false) {
        const selectedID = this.dataUnitSelection[0];
        const selectedItem = this.dataUnits.find((item) => {
          return item.ID === selectedID;
        });
        selectedItem.IsAdd = false;
        this.dataUnitItem = selectedItem;
        this.bindtemp(this.dataUnitItem);
      }
    }
  }

  bindUnits() {
    this.dataUnitGridDataResult = {
      data: orderBy(this.dataUnits, this.dataUnitSortByField),
      total: this.dataUnits.length
    };

    this.dataUnitGridDataResult = process(this.dataUnits, this.dataUnitState);
  }

  onUnitSortChange(sort: SortDescriptor[]): void {
    this.dataUnitSortByField = sort;
    this.bindUnits();
  }

  public onUnitDataStateChange(state: DataStateChangeEvent): void {
    this.dataUnitSelection = [];
    this.dataUnitState = state;
    this.dataUnitGridDataResult = process(this.dataUnits, this.dataUnitState);
  }

  getColumnIndex(name) {
    const columns = [
      // 'ID',
      'Name',
      'ParentID',
      'OrderIdx',
      'Description'
    ];

    return columns.indexOf(name);
  }

  async selectEventHandler(e: SelectEvent) {
    this.appComponent.loading = true;
    try {
      const fileData = e.files[0];// await this.file.readFile(e.files[0].rawFile);
      const maxMB = 25;
      const maxSizeKB = 1024 * 1024 * maxMB;
      if (fileData.size > maxSizeKB) {
        this.appSwal.showWarning(`Kích thước tập tin không được lớn hơn ${maxMB} MB`, false);
        return false;
      }
    } catch {
      return false;
    }
    const extension = e.files[0].extension.toLowerCase();
    if (!extension || (extension.toLowerCase() !== '.xlsx' && extension.toLowerCase() !== '.xls' && extension.toLowerCase() !== '.pdf')) {

      this.appSwal.showWarning('Vui lòng chọn tập tin có định dạng .xlsx, .xls.', false);

      return false;
    }

    const fileData = (await this.file.readXLSX(e.files[0].rawFile)) as Array<any>;
    this.InputingFile = true;
    this.setSelectableSettings();
    this.dataUnits = [];
    const unitListTemp = this.units.slice();

    const parentIdx = this.getColumnIndex('ParentID');
    let checkUnit;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 1; i < fileData.length; i++) {
      checkUnit = unitListTemp.find(x => x.ID === fileData[i][parentIdx] || x.Name === fileData[i][parentIdx]);

      const obj = {
        IsAdd: false,
        Name: fileData[i][this.getColumnIndex('Name')],
        ParentID: checkUnit ? checkUnit.ID : null,
        ParentName: checkUnit ? checkUnit.Name : null,
        OrderIdx: fileData[i][this.getColumnIndex('OrderIdx')],
        Description: fileData[i][this.getColumnIndex('Description')]
      };

      this.dataUnits.push(obj);
    }
    this.bindUnits();
    this.isEnabledSaveAll = true;
    this.appComponent.loading = false;
  }

  removeEventHandler() {
    this.isEnabledSaveAll = false;
    this.onReload();
  }

  onSearchTextChange(e: any) {
    if (!this.searchOption.SearchText) {
      this.onReload();
    }
  }

  onRemoveSearchText() {
    this.searchOption.SearchText = '';
    this.getUnits();
  }

  async initDisplay() {

  }

  onSearch() {
    this.getUnits();
    this.isEnabledSaveAll = false;
  }

  onReload() {
    this.InputingFile = false;
    this.setSelectableSettingsFile();
    this.searchOption.SearchText = '';
    this.getUnits();
    this.isEnabledSaveAll = false;
  }

  onClearUnit() {
    this.setDefault();
  }

  onAllowSelectMulti() {
    this.setSelectableSettings();
  }

  async getUnit() {
    const result = await this.appService.doGET('api/Unit', null);
    if (result.Status) {
      this.unitfile = [];
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < result.Data.length; i++) {
        this.unitfile.push({
          ID: result.Data[i].ID,
          Name: result.Data[i].Name
        });
      }
    }
  }

  async onAddNewUnit() {
    this.enabled = true;
    this.enabledID = true;
    this.setDefault();
    this.infoOpened = true;
    const resultUnit = await this.appService.doGET('api/Unit', null);
    if (resultUnit && resultUnit.Status === 1) {
      this.units = resultUnit.Data;
      this.unitsFilter = this.units.slice();
    }

  }

  onSaveUnit() {
    if (this.dataUnitItem.IsAdd) {
      this.addUnit();
    } else {
      this.updateUnit();
    }

  }

  async onSaveUnits() {
    this.appComponent.loading = true;
    const dataRequests = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.dataUnits.length; i++) {
      // check duplicate data
      let unit = this.dataUnits[i];
      const dataRequest = {
        searchText: unit.Name
      };
      const resultUnitListFromServer = await this.appService.doGET('api/Part/Search', dataRequest);
      let isDuplicate = false;
      if (resultUnitListFromServer && resultUnitListFromServer.Data &&
        resultUnitListFromServer.Data.length > 0) {
        resultUnitListFromServer.Data.forEach(element => {
          if (element.Name == unit.Name) {
            isDuplicate = true;
          }
        });
      }
      if (isDuplicate == false) {
        dataRequests.push(this.createDataRequest(this.dataUnits[i]));
      }
    }
    const result = await this.appService.doPOST('api/Unit/Saves', dataRequests);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.isEnabledSaveAll = false;
      this.infoOpened = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  createDataRequest(data) {
    const temp = data ? data : this.dataUnitItemtemp;
    const tempEnLanguage = this.dataUnitItemEnLanguage;
    return {
      Unit: {
        ID: temp.ID,
        Name: temp.Name,
        ParentID: temp.ParentID,
        ParentName: temp.ParentName,
        DelFlg: temp.DelFlg,
        OrderIdx: temp.OrderIdx,
        Description: temp.Description
      },
      EnLanguage: {
        ID: tempEnLanguage.ID,
        UnitID: tempEnLanguage.UnitID,
        Name: tempEnLanguage.Name,
        Description: tempEnLanguage.Description,
        CreateAt: tempEnLanguage.CreateAt,
        CreateBy: tempEnLanguage.CreateBy,
        UpdateAt: tempEnLanguage.UpdateAt,
        UpdateBy: tempEnLanguage.UpdateBy,
        LanguageID: 'en-US'
      }
      
    };
  }

  onEditUnit() {
    this.infoOpened = true;
    this.enabled = true;
    this.enabledID = false;
    this.getLanguage();
  }

  async addUnit() {
    this.appComponent.loading = true;
    const dataRequest = this.createDataRequest(null);
    const result = await this.appService.doPOST('api/Unit', dataRequest);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.setDefault();
      this.infoOpened = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  async updateUnit() {
    this.appComponent.loading = true;
    const id = this.dataUnitItem.ID;
    const dataRequest = this.createDataRequest(null);
    const result = await this.appService.doPUT('api/Unit', dataRequest, { id });
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();

    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  async onDeleteUnit() {
    if (this.dataUnitSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }
    this.appComponent.loading = true;
    const dataRequest = {
      IDList: JSON.stringify(this.dataUnitSelection),
      FlgRevert: false
    };
    const option = await this.appSwal.showWarning(this.translate.instant('AreYouSure'), true);
    if (option) {
      const result = await this.appService.doPOST('api/Unit/Deletes', dataRequest);
      if (result && result.Status === 1) {
        this.notification.showSuccess(result.Msg);
        this.onReload();
        this.setDefault();
        this.enabled = false;
        this.enabledID = false;
        this.dataUnitSelection = [];
        this.allowMulti = false;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
    this.appComponent.loading = false;
  }
  onCloseInfo(e) {
    this.setDefault();
    this.infoOpened = false;
  }

  async getLanguage(){
    var k = 0;
    if(this.dataUnitItem.ID != null){
      const dataRequest = {
        id: this.dataUnitItem.ID
       }
       const result = await this.appService.doGET('api/Unit/GetUnitLanguage', dataRequest);
       if (result && result.Status == 1) {
         for(let i = 0; i < result.Data.length; i++){
          if(result.Data[i].LanguageID == "en-US"){
            this.dataUnitItemEnLanguage = result.Data[i];
          }
         }
       }
    }
  }

  public onTabSelect(e) {
    
  }

  async onReOpenRp(){
    this.appComponent.loading = true;
    // tslint:disable-next-line: max-line-length
    const id = this.dataUnitItem.ID;

    const result = await this.appService.doGET('api/Unit/ReOpenRp', { id });
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.setDefault();
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

}





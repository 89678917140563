import { Component, OnInit, HostListener } from '@angular/core';
import { AppLanguage } from '../../services/app.language';
import { AppService } from '../../services/app.service';
import { AppSwal } from 'src/app/services/app.swal';
import { AppComponent } from '../../app.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import * as FileSaver from 'file-saver';
import { IntlService } from '@progress/kendo-angular-intl';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { forEach } from '@angular/router/src/utils/collection';
import { ElementRef, AfterViewInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-post-detail',
  templateUrl: './post-detail.component.html',
  styleUrls: ['./post-detail.component.css']
})
export class PostDetailComponent implements OnInit {

  @Input() url = window.location.href;
  @Input() url_g = window.location.href;
  isSticky: boolean = false;
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset > 0;
  }

  public imageDef = '../../assets/images/default-02.png';

  public apiRoot;
  public dataPageItem: any;
  public dataPages = [];
  public listComment: any[];
  public listPostLQ: any[];
  public listPostViewMore: any[];
  public listPostRelates: any[];
  mySubscription: any;

  postItem: any;
  postItemEnLanguage: any;
  postNLD: any;
  commentItem: any;
  param = {
    urlPath: '',
    menuId: ''
  };

  isNLD = false;

  languages: Array<any> = [{
    id: 0,
    culture: 'vi-VN',
    text: 'Tiếng Việt'
  }, {
    id: 1,
    culture: 'en-US',
    text: 'English'
  }];

  languageName = '';
  urlDownload = this.appService.apiRoot;
  hasData = false;

  constructor(
    private language: AppLanguage,
    public appControls: AppControls,
    public appService: AppService,
    public intl: IntlService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public domSanitizer: DomSanitizer,
    private appComponent: AppComponent,
    private authenticationService: AuthenticationService,
  ) {
    this.apiRoot = this.appService.apiRoot;
    this.setCommentDefault();
    this.setPostDefault();
    this.setPostNLD();
    this.getLanguageName();
    this.activatedRoute.queryParams.subscribe((p: any) => {
      this.param.urlPath = p.title;
      this.param.menuId = p.menuId
      this.getPostItem();
    });

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.router.navigated = false;
      }
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }

  sanitize(url: string) {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }

  setPostDefault() {
    this.postItem = {
      ImageUrls: '',
      Summary: '',
      Title: '',
      MenuName: '',
      ApprovedAt: new Date(),
      CountView: 0,
      Description: '',
      UrlPath: '',
      Reference: ''
    };
  }

  setPostNLD() {
    this.postNLD = {
      Title: '',
      Description: '',
      Link: '',
      Guid: '',
      PubDate: ''
    };
  }

  setCommentDefault() {
    this.commentItem = {
      FullName: '',
      Phone: '',
      Summary: '',
      Description: ''
    };
  }
  isUploadFromServe = false;

  public changeLinkVid(des){
    var content = des;
      try {
        var require = content.indexOf("<oembed");
        if (require >= 0) {
          while (content.indexOf("<oembed") >= 0) {
            var st_Index = content.indexOf("<oembed");
            var end_Index = content.indexOf("</oembed>")
            var sub1 = content.substring(st_Index, end_Index + "</oembed>".length);

            var st_key = sub1.indexOf("?v="); var end_key = sub1.indexOf('">');
            var key = sub1.substring(st_key + "?v=".length, end_key);

            var mainLinkVidYTB = "//www.youtube.com/embed/" + key;
            var vidLink = this.domSanitizer.bypassSecurityTrustResourceUrl(mainLinkVidYTB);
            var replace = '<iframe width="100%" height="470px" src="' + mainLinkVidYTB + '" frameborder="0" allowfullscreen></iframe>';

            des = des.replace(sub1, replace);
            content = des;
          }          
        }
        return content;
      } catch{
        content = des;
        return content;

      }
  }

  async getPostItem() {
    this.hasData = false;
    if (this.param.menuId == "nld") {
      const result = await this.appService.doGET('api/NLDNews/getNewsDetail', this.param);
      this.isNLD = true;
      this.postNLD = result.Data;
    }
    else {
      const dataRequest = {
        urlPath: this.param.urlPath,
        languageID: this.languageName
      }
      const resultPostItem = await this.appService.doGET('api/Post/GetPostDetail', dataRequest);
      if (resultPostItem && resultPostItem.Status === 1) {
        if (resultPostItem.Data.PostItem){
          if(resultPostItem.Data.PostItem.FileUrl == null || resultPostItem.Data.PostItem.FileUrl == ""){
            resultPostItem.Data.PostItem.FileUrl = [];
          }
          else{
            resultPostItem.Data.PostItem.FileUrl = JSON.parse(resultPostItem.Data.PostItem.FileUrl);
          }
          // if(resultPostItem.Data.PostItem.Reference == null || resultPostItem.Data.PostItem.Reference == ""){
          //   resultPostItem.Data.PostItem.Reference = "";
          // }
          // else{
          //   resultPostItem.Data.PostItem.Reference = JSON.parse(resultPostItem.Data.PostItem.Reference);
          //   resultPostItem.Data.PostItem.Reference = (resultPostItem.Data.PostItem.Reference).join(";")          
          // }
          if (resultPostItem.Data.PostItem.MenuName){
            resultPostItem.Data.PostItem.MenuTemp = [];
            resultPostItem.Data.PostItem.MenuName = resultPostItem.Data.PostItem.MenuName.split(";")
            if (resultPostItem.Data.PostItem.UrlPathMenu){
              resultPostItem.Data.PostItem.UrlPathMenu = resultPostItem.Data.PostItem.UrlPathMenu.split(";")
            }
            for (let i = 0; i < resultPostItem.Data.PostItem.MenuName.length; i++){
              resultPostItem.Data.PostItem.MenuTemp.push({
                MenuName: resultPostItem.Data.PostItem.MenuName[i],
                UrlPathMenu: resultPostItem.Data.PostItem.UrlPathMenu.length > 0 ? resultPostItem.Data.PostItem.UrlPathMenu[i].trim() : ''
              })
            }                
          }
        }        
        this.hasData = true;
        this.postItem = resultPostItem.Data.PostItem;
        this.listPostViewMore = resultPostItem.Data.PostViewMores;
        this.listPostRelates = resultPostItem.Data.PostRelates;
        if (!this.postItem.ImageUrls) {
          this.postItem.ImageUrls = "";
        }
        this.postItem.Description = this.changeLinkVid(this.postItem.Description);
        var content = this.postItem.Description;
        //check màu đổi sang navy -> Duy
        try {
          var check = "hsl(0,0%,0%)";
          var require = content.indexOf(check);
          if (require >= 0) {
            while (content.indexOf(check) >= 0) {
              this.postItem.Description = this.postItem.Description.replace(check, "navy !important");
              content = this.postItem.Description;
            }
          }
        } catch{
          content = this.postItem.Description;
        }
        this.postItem.Description = this.domSanitizer.bypassSecurityTrustHtml(this.postItem.Description);
        try {
          if (this.postItem.ImageUrls.indexOf("Multimedia") > 0) {
            this.isUploadFromServe = true;
          }
        } catch{
          this.isUploadFromServe = false;
        }
      }
    }
  }

  createDataRequest(data) {
    const temp = data ? data : this.commentItem;
    return {
      Title: this.postItem.Title,
      PostID: this.postItem.ID,
      FullName: temp.FullName,
      Phone: temp.Phone,
      Description: temp.Description,
      PortalFlg: true
    };
  }

  async onSendComment() {

    const dataRequest = this.createDataRequest(null);

    const result = await this.appService.doPOST('api/Comment/SendByPost', dataRequest);
    if (result && result.Status === 1) {
      this.setCommentDefault();
    }
  }

  public onGoToDetail(id: any) {
    this.router.navigate(['portal/post-detail'], { queryParams: { id } });
  }


  onClickPostItem(item) {
    this.router.navigate(['portal/post-detail'], { queryParams: { title: item.UrlPath } });
  }

  onChangeLanguage(e: any) {
    this.languageName = e;
  }

  getLanguageName() {
    let lang = localStorage.getItem('currentLanguage');
    if (lang == null || lang == undefined) {
      lang = "vi-VN";
    }
    this.languageName = lang;
  }

  getUrlDownload(item) {
    let url = this.urlDownload.replace(/\"/g, "") + item;
    url = url.replace(/\"/g, '')
    return url;
  }

  getDayInWeek() {
    var day_name = '';
    if (this.postItem && this.postItem.ApprovedAt){
      var date = new Date(this.postItem.ApprovedAt);
      switch (date.getDay()) {
        case 0:
          day_name = "Chủ nhật";
          break;
        case 1:
          day_name = "Thứ hai";
          break;
        case 2:
          day_name = "Thứ ba";
          break;
        case 3:
          day_name = "Thứ tư";
          break;
        case 4:
          day_name = "Thứ năm";
          break;
        case 5:
          day_name = "Thứ sáu";
          break;
        case 6:
          day_name = "Thứ bảy";
      }
    }
    
    return day_name
  }
}

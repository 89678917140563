import { Component, OnInit, Input } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-meeting-week-grid',
  templateUrl: './meeting-week-grid.component.html',
  styleUrls: ['./meeting-week-grid.component.css']
})
export class MeetingWeekGridComponent implements OnInit {

  @Input() data: GridDataResult;
  constructor() { }

  ngOnInit() {
  }

}

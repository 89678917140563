import { Component, OnInit, Output, Input, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { AppService } from '../services/app.service';
import { AppComponent } from '../app.component';
import { AppSwal } from '../services/app.swal';
import { ResourceLoader } from '@angular/compiler';
import { IntlService } from '@progress/kendo-angular-intl';
import { Location } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  @Output() searchChange = new EventEmitter<string>();
  @Output() clickNotify = new EventEmitter<string>();

  user: any;
  showToggleUser = false;
  uploadImageOpened = false;
  menus: any;
  check: number;
  searchOption = {
    searchString: ''
  };

  notify = {
    dispalyFlg: false,
    list: []
  };

  ngOnInit(): void {
    this.check = 0;
  }

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private appService: AppService,
    private appComponent: AppComponent,
    private appSwal: AppSwal,
    public intl: IntlService,
    public location: Location,
  ) {
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
    this.getMenu();
    this.getNotify();
  }

  async getMenu() {
    const roleID = this.user.RoleID;
    const result = await this.appService.doGET('api/Page/GetMenu', { roleID });
    if (result && result.Status === 1) {
      this.menus = result.Data;
    } else {
      this.appSwal.showError(result.Msg);
    }
  }

  async getNotify() {

    const result = await this.appService.doGET('api/MeetingNofity/GetNofity', null);
    if (result && result.Status === 1) {
      this.notify.list = result.Data;
    } else {
      this.appSwal.showError(result.Msg);
    }
    // this.notify.list.forEach(r => {
    //   console.log(r.StartAt);
    //   console.log(r.Today);
    // })
  }

  onNotifyClick(notify: any) {
    this.notify.dispalyFlg = false;
    const pageUrl = this.location.path();

    // if (notify.CommentFlg) {
      if (pageUrl && pageUrl.indexOf('data/schedule') < 0) {
        this.router.navigate(['data/schedule']);
        // , { queryParams: { menuID: notify.MenuID } }
      } else {
        this.clickNotify.emit(notify.MenuID);
      }
    // }

  }

  onChangePasswordPage($event) {
    this.router.navigate(['change-password']);
  }

  onToggleUser() {
    this.notify.dispalyFlg = false;
    this.showToggleUser = !this.showToggleUser;
  }

  onToggleNotify() {
    this.showToggleUser = false;
    this.notify.dispalyFlg = !this.notify.dispalyFlg;
  }


  onLogOut() {
    this.authenticationService.doSignout();
    this.router.navigate(['login']);
    //window.location.reload();
  }

  onClickMenu(pageUrl) {
    this.router.navigate([pageUrl]);
  }

  onUploadImageClose(status) {
    this.uploadImageOpened = false;
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
  }

  onUploadImage() {
    this.showToggleUser = false;
    this.uploadImageOpened = true;
  }

  onSearchKeyPress(e: any) {
    if (e.keyCode === 13) {
      this.onSearch();
    }
  }

  onSearch() {
    const searchString = this.searchOption.searchString;
    this.router.navigate(['wo/issue'], { queryParams: { searchparam: searchString } });
    this.emitSearchChange(searchString);
  }

  emitSearchChange(searchString) {
    this.searchChange.emit(searchString);
  }
}

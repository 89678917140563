import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cmt-user',
  templateUrl: './cmt-user.component.html',
  styleUrls: ['./cmt-user.component.css']
})
export class CmtUserComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

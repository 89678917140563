import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { Location } from '@angular/common';
import { AppService } from './app.service';
import { AuthenticationService } from './authentication.service';
import { AppSwal } from './app.swal';
import { TranslateService } from '@ngx-translate/core';
/**
 * Decides if a route can be activated.
 */
@Injectable() export class AuthGuard implements CanActivate {

    constructor(
        public authService: AuthService,
        public router: Router,
        public appService: AppService,
        public authenticationService: AuthenticationService,
        public appSwal: AppSwal,
        public location: Location
    ) { }

    public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if (this.authService.isAuthenticated()) {
            const result = await this.checkRole(route.routeConfig.path);
            return result;
        }
        localStorage.setItem('PageUrlRollback', route.routeConfig.path);
        this.router.navigate(['login']);
        return false;
    }

    async checkRole(url: string) {
        this.authenticationService.getUser();
        const user = this.authenticationService.user;
        const roleID = user.RoleID;

        const result = await this.appService.doGET('api/Page/Check', { roleID, url });
        if (result) {
            if (result.Status === 1) {
                return true;
            } else {
                await this.appSwal.showError(result.Msg);
                this.router.navigate(['']);
            }
        }
        return false;
    }

}
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Output, EventEmitter, Input, HostListener } from '@angular/core';
import { AppLanguage } from '../../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../services/app.service';
import { SelectableSettings, PageChangeEvent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { AppSwal } from 'src/app/services/app.swal';
import { IntlService } from '@progress/kendo-angular-intl';
import { Notification } from '../../services/app.notification';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
import { AppGuid } from 'src/app/services/app.guid';
import { NullInjector } from '@angular/core/src/di/injector';
import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';
import * as XLSX from 'xlsx';
import { AppFile } from 'src/app/services/app.file';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppUtils } from 'src/app/services/app.utils';
import { AppComponent } from '../../app.component';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpProgressEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { of, concat } from 'rxjs';
import { delay } from 'rxjs/operators';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-weekly-report-portal',
  templateUrl: './weekly-report-portal.component.html',
  styleUrls: ['./weekly-report-portal.component.css']
})
export class WeeklyReportPortalComponent implements OnInit, OnDestroy {

  isSticky: boolean = false;
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset > 0;
  }
  commentInput = null;

  user: any;
  loading = false;
  dataWeeklyReportPortals = [];
  dataWeeklyReportPortalSelectableSettings: SelectableSettings;
  dataWeeklyReportPortalSort = {
    allowUnsort: true,
    mode: 'multiple'
  };
  public dataWeeklyReportPortalFocus = {
    Name: true
  };
  dataWeeklyReportPortalSortByField: SortDescriptor[] = [
  ];

  public SETTING_NUM_PAGING_SKIP = 0;
  public SETTING_NUM_PAGING_TAKE = 50;
  public SETTING_NUM_PAGING_BTN = 5;

  dataWeeklyReportPortalSkip = this.SETTING_NUM_PAGING_SKIP;
  dataWeeklyReportPortalPageSize = this.SETTING_NUM_PAGING_TAKE;
  dataWeeklyReportPortalSelection: number[] = [];
  dataWeeklyReportPortalItem: any;
  myInterval: any;
  pageName: any;
  infoOpened = false;
  IDActive = "";

  public buttonCount = this.SETTING_NUM_PAGING_BTN;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;

  public dataWeeklyReportPortalState: State = {
    skip: this.dataWeeklyReportPortalSkip,
    take: this.dataWeeklyReportPortalSkip + this.dataWeeklyReportPortalPageSize,
    filter: {
      logic: 'and',
      filters: []
    }
  };
  dataWeeklyReportPortalGridDataResult: GridDataResult;

  dataFileUrlsGridDataResult: GridDataResult;
  dataFileUrlsSelectableSettings: SelectableSettings;
  dataFileUrlsSkip = this.SETTING_NUM_PAGING_SKIP;
  dataFileUrlsPageSize = this.SETTING_NUM_PAGING_TAKE;
  dataFileUrlsSelection: number[] = [];

  groupTypes: Array<{ Name: string, ID: string }>;
  groupTypesFilter: Array<{ Name: string, ID: string }>;
  groupTypesSearch: Array<{ Name: string, ID: string }>;

  filesUpload: Array<FileInfo>;
  dataFileUrls: any[];
  public fileSaveUrl: any;

  public uploadSaveUrl = 'saveUrl';
  public uploadRemoveUrl = 'removeUrl';
  public enabled = false;
  public enabledID = false;
  isEnabledSaveAll = false;
  control: any;
  controlDefault = true;
  allowMulti = true;
  allowInsertFile = true;

  searchOption = {
    SearchText: '',
    GroupTypeID: null,
    FromDate: null,
    ToDate: null
  };
  constructor(
    private translate: TranslateService,
    private language: AppLanguage,
    private appService: AppService,
    private appSwal: AppSwal,
    public intl: IntlService,
    private notification: Notification,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    private appComponent: AppComponent,
  ) {
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
  }

  ngOnDestroy(): void {
  }

  ngOnInit() {
    this.getControl();
    this.setDefault();
    this.language.default();
    this.setSelectableSettings();
    this.initDisplay();
    this.getPageName();
    this.onReload();
    this.allData = this.allData.bind(this);
    
  }

  async getPageName() {
    this.pageName = await this.appControls.getPageName();
  }

  async getControl() {
    this.control = await this.appControls.getControls(this.user.RoleID);
    this.controlDefault = false;
  }

  setSelectableSettings(): void {

    this.allowMulti = !this.allowMulti;
    this.dataWeeklyReportPortalSelectableSettings = {
      checkboxOnly: false,
      mode: this.allowMulti ? 'multiple' : 'single'
    };

    this.dataFileUrlsSelectableSettings = {
      checkboxOnly: false,
      mode: 'single'
    };
  }

  onGroupTypeHandleFilter(value) {
    this.groupTypesFilter = this.groupTypes.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  onGroupTypeSearchHandleFilter(value) {
    this.groupTypesSearch = this.groupTypes.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  onTypeSearchHandleFilter(value) {
    this.onReload();
  }


  onSearchKeyPress(e: any) {

  }

  showLinkUrl(itemSelected) {
    this.IDActive = itemSelected.ID;
  }

  async getWeeklyReportPortals() {
    this.loading = true;
    const dataRequest = {
      SearchText: this.searchOption.SearchText,
      GroupTypeID: this.searchOption.GroupTypeID,
      GroupFlg: true
    };
    const result = await this.appService.doPOST('api/WeeklyReport/Search', dataRequest);
    if (result && result.Status === 1) {
      this.dataWeeklyReportPortals = result.Data;
      this.bindWeeklyReportPortals();
    }
    this.loading = false;
    this.checkSelectionID();
  }

  checkSelectionID() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = this.dataWeeklyReportPortalSelection.length - 1; i >= 0; i--) {
      const selectedItem = this.dataWeeklyReportPortals.find((item) => {
        return item.ID === this.dataWeeklyReportPortalSelection[i];
      });
      if (!selectedItem) {
        this.dataWeeklyReportPortalSelection.splice(i, 1);
      }
    }
  }

  setDefault() {
    this.dataWeeklyReportPortalItem = {
      IsAdd: true,
      Name: '',
      Description: null,
      LinkUrl: '',
      CreateAt: '',
      CreateBy: null,
      UpdateAt: '',
      UpdateBy: '',
      DelFlg: '',
      GroupTypeID: null
    };
    this.enabled = true;
    this.enabledID = true;
    this.dataWeeklyReportPortalSelection = [];
    this.filesUpload = [];
    this.dataFileUrls = [];
    this.bindFileUrls();
    this.fileSaveUrl = `${this.appService.apiRoot}api/Upload/MediaWeb?accountID=${this.user.UserName}&typeData=files`;
  }

  onPostPageChange(event: PageChangeEvent) {
    this.dataWeeklyReportPortalSkip = event.skip;
    this.bindWeeklyReportPortals();
  }

  async onWeeklyReportPortalselectedKeysChange() {

    if (this.dataWeeklyReportPortalSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }

    if (this.dataWeeklyReportPortalSelection.length > 1) {
      if (this.allowMulti) {
        return;
      }
      this.appSwal.showWarning(this.translate.instant('SelectSingle'), false);
    } else {
      const selectedID = this.dataWeeklyReportPortalSelection[0];
      const selectedItem = this.dataWeeklyReportPortals.find((item) => {
        return item.ID === selectedID;
      });
      selectedItem.IsAdd = false;
      selectedItem.PublishAt = selectedItem.PublishAt ? new Date(selectedItem.PublishAt) : null;

      this.dataWeeklyReportPortalItem = selectedItem;

      const fileUrl = selectedItem.FileUrl;
      this.dataFileUrls = [];
      if (fileUrl) {
        this.dataFileUrls.push({
          Name: this.getNameByUrl(selectedItem.FileUrl),
          Url: selectedItem.FileUrl
        });
      }
      this.bindFileUrls();

      this.enabled = false;
      this.enabledID = false;
    }
  }

  bindWeeklyReportPortals() {
    this.dataWeeklyReportPortalGridDataResult = {
      data: orderBy(this.dataWeeklyReportPortals, this.dataWeeklyReportPortalSortByField),
      total: this.dataWeeklyReportPortals.length
    };

    this.dataWeeklyReportPortalGridDataResult = process(this.dataWeeklyReportPortals, this.dataWeeklyReportPortalState);
  }

  bindFileUrls() {
    this.dataFileUrlsGridDataResult = {
      data: this.dataFileUrls,
      total: this.dataFileUrls.length
    };

    this.dataWeeklyReportPortalGridDataResult = process(this.dataWeeklyReportPortals, this.dataWeeklyReportPortalState);
  }

  onWeeklyReportPortalsortChange(sort: SortDescriptor[]): void {
    this.dataWeeklyReportPortalSortByField = sort;
    this.bindWeeklyReportPortals();
  }

  public onPostDataStateChange(state: DataStateChangeEvent): void {
    this.dataWeeklyReportPortalSelection = [];
    this.dataWeeklyReportPortalState = state;
    this.dataWeeklyReportPortalGridDataResult = process(this.dataWeeklyReportPortals, this.dataWeeklyReportPortalState);
  }

  removeEventHandler(index) {
    this.isEnabledSaveAll = false;
    this.onReload();
  }

  doInterval() {
    // this.myInterval = Observable.interval(this.appService.timeInterval).subscribe(() => {
    //   this.onReload();
    // });
  }

  onSearchTextChange(e: any) {
    if (!this.searchOption.SearchText) {
      this.onReload();
    }
  }

  onRemoveSearchText() {
    this.searchOption.SearchText = '';

  }

  async initDisplay() {
    // nhom bao cao
    const resultPublishType = await this.appService.doGET('api/WeeklyReport/GetGroupType', null);
    if (resultPublishType && resultPublishType.Status === 1) {
      this.groupTypes = resultPublishType.Data;
      this.groupTypesFilter = this.groupTypes.slice();
      this.groupTypesSearch = this.groupTypes.slice();
    }
  }

  onSearch() {
    this.getWeeklyReportPortals();
    this.isEnabledSaveAll = false;
  }

  onReload() {
    this.getWeeklyReportPortals();
    this.isEnabledSaveAll = false;
  }

  onClearPost() {
    this.setDefault();
  }

  onAllowSelectMulti() {
    this.setSelectableSettings();
  }

  onAddNewPost() {

    this.setDefault();
    this.infoOpened = true;
  }

  async onSavePost() {
    if (this.dataWeeklyReportPortalItem.IsAdd) { this.addPost(); } else { this.updatePost(); }
  }

  async onSaveWeeklyReportPortals() {
    this.appComponent.loading = true;
    const dataRequests = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.dataWeeklyReportPortals.length; i++) {
      dataRequests.push(this.createDataRequest(this.dataWeeklyReportPortals[i]));
    }
    const result = await this.appService.doPOST('api/WeeklyReport/Saves', dataRequests);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.isEnabledSaveAll = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  createDataRequest(data) {
    const temp = data ? data : this.dataWeeklyReportPortalItem;

    // let fileName = '';
    // if (this.dataFileUrls.length > 0) {
    //   fileName = this.dataFileUrls[0].Url;
    // }

    //
    return {
      ID: temp.ID,
      Name: temp.Name,
      Description: temp.Description,
      LinkUrl: temp.LinkUrl,
      CreateAt: temp.CreateAt,
      CreateBy: temp.CreateBy,
      UpdateAt: temp.UpdateAt,
      UpdateBy: temp.UpdateBy,
      DelFlg: temp.DelFlg,
      GroupTypeID: temp.GroupTypeID
    };
  }

  onClosePost(status: any) {
    this.enabled = false;
    this.enabledID = false;
    this.infoOpened = true;
  }

  onEditPost() {
    this.enabled = true;
    this.enabledID = false;
    this.infoOpened = true;
  }

  async addPost() {
    this.appComponent.loading = true;
    const dataRequest = this.createDataRequest(null);

    const result = await this.appService.doPOST('api/WeeklyReport', dataRequest);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.setDefault();
      this.infoOpened = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  async updatePost() {
    this.appComponent.loading = true;
    // tslint:disable-next-line: max-line-length
    const id = this.dataWeeklyReportPortalItem.ID;
    const dataRequest = this.createDataRequest(this.dataWeeklyReportPortalItem);

    const result = await this.appService.doPUT('api/WeeklyReport', dataRequest, { id });
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.setDefault();
      this.infoOpened = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  async onDeletePost() {
    if (this.dataWeeklyReportPortalSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }
    this.appComponent.loading = true;
    const dataRequest = {
      IDList: JSON.stringify(this.dataWeeklyReportPortalSelection),
      FlgRevert: false
    };

    const option = await this.appSwal.showWarning(this.translate.instant('AreYouSure'), true);
    if (option) {
      const result = await this.appService.doPOST('api/WeeklyReport/Deletes', dataRequest);
      if (result && result.Status === 1) {
        this.notification.showSuccess(result.Msg);
        this.onReload();
        this.setDefault();
        this.enabled = false;
        this.enabledID = false;
        this.dataWeeklyReportPortalSelection = [];
        this.allowMulti = false;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
    this.appComponent.loading = false;
  }

  getNameByUrl(fileUrl) {
    const nameFile = fileUrl.replace(/^.*[\\\/]/, '');

    return nameFile;
  }

  async onDownloadFile(dataItem: any) {

    const dataRequest = {
      url: dataItem.Url
    };
    const result = await this.appService.doDownload('api/Upload/DownloadByUrl', dataRequest);
    if (result) {
      FileSaver.saveAs(result, dataItem.Name);
    }
  }

  onDeleteFile(dataItem: any) {

    for (let i = this.dataFileUrls.length - 1; i >= 0; i--) {
      if (this.dataFileUrls[i].Url === dataItem.Url) {
        this.dataFileUrls.splice(i, 1);
        break;
      }
    }

    this.bindFileUrls();
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.dataWeeklyReportPortals, {}).data
    };

    return result;
  }

  async onSelectFileToUpload(e: SelectEvent) {
    if (!e.files || e.files.length <= 0) {
      return;
    }

    const extension = e.files[0].extension.toLowerCase();

    this.allowInsertFile = true;

    try {
      const fileData = e.files[0]; // await this.file.readFile(e.files[0].rawFile);

      const maxMB = 30;
      const maxSizeKB = 1024 * 1024 * maxMB;
      if (fileData.size > maxSizeKB) {
        this.allowInsertFile = false;
        this.appSwal.showWarning(`Kích thước tập tin không được lớn hơn ${maxMB} MB`, false);
      }
    } catch {
    }

    // tslint:disable-next-line: max-line-length
    if (!extension || (extension.toLowerCase() !== '.doc' && extension.toLowerCase() !== '.docx' && extension.toLowerCase() !== '.pdf')) {
      this.allowInsertFile = false;
      this.appSwal.showWarning('Vui lòng chọn tập tin có định dạng .doc, .docx, .pdf', false);

      return false;
    }
  }

  onSuccessFileToUpload(e: any) {

    if (!this.allowInsertFile) {
      return;
    }

    try {
      this.dataFileUrls = [];
      const k = `${e.response.body.Data.DirMedia}${e.response.body.Data.MediaNm[0]}`;
      this.dataFileUrls.push({
        Name: this.getNameByUrl(k),
        Url: k
      });

      this.bindFileUrls();
    } catch {
      this.appSwal.showError(e);
    }
  }

  onRemoveFileToUpload() {

  }
  onCloseInfo(e) {
    this.setDefault();
    this.infoOpened = false;
  }
}


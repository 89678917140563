import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';
import { AppLanguage } from '../../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../services/app.service';
import { SelectableSettings, PageChangeEvent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { AppSwal } from 'src/app/services/app.swal';
import { IntlService } from '@progress/kendo-angular-intl';
import { Notification } from '../../services/app.notification';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
import { AppGuid } from 'src/app/services/app.guid';
import { NullInjector } from '@angular/core/src/di/injector';
import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';
import * as XLSX from 'xlsx';
import { AppFile } from 'src/app/services/app.file';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppUtils } from 'src/app/services/app.utils';
import { AppComponent } from '../../app.component';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpProgressEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { of, concat } from 'rxjs';
import { delay } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-post-result',
  templateUrl: './post-result.component.html',
  styleUrls: ['./post-result.component.css']
})
export class PostResultComponent implements OnInit, OnDestroy {
  isSticky: boolean = false;
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset > 0;
  }
  //codeimage
  postNewInput = null;

  searchTxtInput= '';

  uploadFlag = {
    banner: false,
    image: false,
    video: false,
  };

  user: any;
  loading = false;
  dataPostNews = [];
  dataPostNewsLatest = [];
  dataPostNewDetail = null;
  dataPostNewSelectableSettings: SelectableSettings;
  dataPostNewSort = {
    allowUnsort: true,
    mode: 'multiple'
  };
  public dataPostNewFocus = {
    Name: true
  };
  dataPostNewSortByField: SortDescriptor[] = [
    // {
    //   field: 'ParentName',
    //   dir: 'asc'
    // }, {
    //   field: 'OrdinalNumber',
    //   dir: 'asc'
    // }
  ];

  //imagesUploadName = "";

  public LBM_NUM_PAGING_SKIP = 0;
  public LBM_NUM_PAGING_TAKE = 50;
  public LBM_NUM_PAGING_BTN = 5;

  dataPostNewSkip = this.LBM_NUM_PAGING_SKIP;
  dataPostNewPageSize = this.LBM_NUM_PAGING_TAKE;
  dataPostNewSelection: number[] = [];
  dataPostNewItem: any;
  myInterval: any;

  public buttonCount = this.LBM_NUM_PAGING_BTN;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;

  public postNewFlg = false;

  public dataPostNewState: State = {
    skip: this.dataPostNewSkip,
    take: this.dataPostNewSkip + this.dataPostNewPageSize,
    filter: {
      logic: 'and',
      filters: []
    },
    //group: [{ field: 'TypeName' }]
  };
  dataPostNewGridDataResult: GridDataResult;

  dataFileUrlsGridDataResult: GridDataResult;
  dataFileUrlsSelectableSettings: SelectableSettings;
  dataFileUrlsSkip = this.LBM_NUM_PAGING_SKIP;
  dataFileUrlsPageSize = this.LBM_NUM_PAGING_TAKE;
  dataFileUrlsSelection: number[] = [];

  filesUpload: Array<FileInfo>;
  dataFileUrls: any[];
  public fileSaveUrl: any;

  public uploadSaveUrl = 'saveUrl';
  public uploadRemoveUrl = 'removeUrl';
  public enabled = false;
  public enabledID = false;
  isEnabledSaveAll = false;
  control: any;
  controlDefault = true;
  allowMulti = true;
  isReplyPage = false;
  tabIndex = 0;

  searchOption = {
    SearchText: '',
    FromDate: null,
    ToDate: null,
    MenuID: 0,
    Status: 0
  };

  public imageDef = '../../assets/images/default-02.png';
  listPostViewMore: any[];
  listPostRelates: any[];

  constructor(
    private translate: TranslateService,
    private language: AppLanguage,
    public appService: AppService,
    private appSwal: AppSwal,
    public intl: IntlService,
    private notification: Notification,
    private guid: AppGuid,
    private file: AppFile,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    private appUtils: AppUtils,
    private appComponent: AppComponent,
    private domSanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {

    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (params) {
        this.searchTxtInput = params.searchparam;
        this.onSearchChange(this.searchTxtInput);
      }
      
    });

    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
    this.language.default();
    this.setSelectableSettings();
  }

  ngOnDestroy(): void {
  }

  ngOnInit() {
  }

  async getControl() {
    this.control = await this.appControls.getControls(this.user.RoleID);
    this.controlDefault = false;
  }

  setSelectableSettings(): void {

    this.allowMulti = !this.allowMulti;
    this.dataPostNewSelectableSettings = {
      checkboxOnly: false,
      mode: this.allowMulti ? 'multiple' : 'single'
    };

    this.dataFileUrlsSelectableSettings = {
      checkboxOnly: false,
      mode: 'single'
    };
  }


  onSearchKeyPress(e: any) {
    // if (e.keyCode === 13 && this.searchOption.SearchText) {
    //   this.onSearch();
    // }
  }
  isUploadFromServe = false;
  async getPostNews() {
    this.loading = true;
    const dataRequest = {
      menuID: this.searchOption.MenuID,
    };

    const result = await this.appService.doGET('api/Post/GetPostList', dataRequest);
    if (result && result.Status === 1) {
      this.dataPostNews = result.Data.PostNews;
      this.listPostViewMore = result.Data.PostViewMores;
      this.listPostRelates = result.Data.PostRelates;

      if (this.dataPostNews.length == 1) {
        this.router.navigate(['portal/post-detail'], { queryParams: { title: this.dataPostNews[0].UrlPath } });
      }

      this.bindPostNews();
    }
    this.loading = false;
    this.checkSelectionID();
  }

  checkSelectionID() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = this.dataPostNewSelection.length - 1; i >= 0; i--) {
      const selectedItem = this.dataPostNews.find((item) => {
        return item.ID === this.dataPostNewSelection[i];
      });
      if (!selectedItem) {
        this.dataPostNewSelection.splice(i, 1);
      }
    }
  }

  onPostNewPageChange(event: PageChangeEvent) {
    this.dataPostNewSkip = event.skip;
    this.bindPostNews();
  }

  bindPostNews() {
    this.dataPostNewGridDataResult = {
      data: orderBy(this.dataPostNews, this.dataPostNewSortByField),
      total: this.dataPostNews.length
    };

    this.dataPostNewGridDataResult = process(this.dataPostNews, this.dataPostNewState);
  }

  onPostNewsortChange(sort: SortDescriptor[]): void {
    this.dataPostNewSortByField = sort;
    this.bindPostNews();
  }

  public onPostNewDataStateChange(state: DataStateChangeEvent): void {
    this.dataPostNewSelection = [];
    this.dataPostNewState = state;
    this.dataPostNewGridDataResult = process(this.dataPostNews, this.dataPostNewState);
  }

  onReload() {
    this.getPostNews();
  }

  onClickPostNew(dataItem) {
    this.router.navigate(['portal/post-detail'], { queryParams: { title: dataItem.UrlPath } });
  }

  async onSearchChange(e: any) {
    const dataRequest = {
      searchText: e ? e : ''
    };

    const result = await this.appService.doGET('api/Post/SearchText', dataRequest);
    if (result && result.Status === 1) {
      this.dataPostNews = result.Data.PostNews;
      this.bindPostNews();
    }
    this.loading = false;
    this.checkSelectionID();
  }
}

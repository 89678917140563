import { Component, Renderer2, NgZone, AfterViewInit, OnInit, OnDestroy, ViewEncapsulation, ViewChild, ElementRef, Output, EventEmitter, Input, HostListener } from '@angular/core';
import { AppLanguage } from '../../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../services/app.service';
import { SelectableSettings, PageChangeEvent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { AppSwal } from 'src/app/services/app.swal';
import { IntlService } from '@progress/kendo-angular-intl';
import { Notification } from '../../services/app.notification';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
import { AppGuid } from 'src/app/services/app.guid';
import { NullInjector } from '@angular/core/src/di/injector';
import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';
import * as XLSX from 'xlsx';
import { AppFile } from 'src/app/services/app.file';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppUtils } from 'src/app/services/app.utils';
import { AppComponent } from '../../app.component';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpProgressEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { of, concat } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { WebSocketService } from 'src/app/websocket.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-comment-list',
  templateUrl: './comment-list.component.html',
  styleUrls: ['./comment-list.component.css']
})
export class CommentListComponent implements OnInit, OnDestroy {

  isSticky: boolean = false;
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset > 0;
  }
  //codeimage
  commentInput = null;

  myRestrictions: FileRestrictions = {
    maxFileSize: 1024 * 1024 * 120
  };


  uploadFlag = {
    banner: false,
    image: false,
    video: false,
  };

  user: any;
  loading = false;
  dataComments = [];
  dataCommentsLatest = [];
  dataCommentMostView = [];
  dataCommentDetail = null;
  dataCommentSelectableSettings: SelectableSettings;
  dataCommentSort = {
    allowUnsort: true,
    mode: 'multiple'
  };
  public dataCommentFocus = {
    Name: true
  };
  dataCommentSortByField: SortDescriptor[] = [
    // {
    //   field: 'ParentName',
    //   dir: 'asc'
    // }, {
    //   field: 'OrdinalNumber',
    //   dir: 'asc'
    // }
  ];

  //imagesUploadName = "";

  public LBM_NUM_PAGING_SKIP = 0;
  public LBM_NUM_PAGING_TAKE = 50;
  public LBM_NUM_PAGING_BTN = 5;

  dataCommentSkip = this.LBM_NUM_PAGING_SKIP;
  dataCommentPageSize = this.LBM_NUM_PAGING_TAKE;
  dataCommentSelection: number[] = [];
  dataCommentItem: any;
  myInterval: any;

  public buttonCount = this.LBM_NUM_PAGING_BTN;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;

  public commentFlg = false;

  public dataCommentState: State = {
    skip: this.dataCommentSkip,
    take: this.dataCommentSkip + this.dataCommentPageSize,
    filter: {
      logic: 'and',
      filters: []
    },
    //group: [{ field: 'TypeName' }]
  };
  dataCommentGridDataResult: GridDataResult;

  dataFileUrlsGridDataResult: GridDataResult;
  dataFileUrlsSelectableSettings: SelectableSettings;
  dataFileUrlsSkip = this.LBM_NUM_PAGING_SKIP;
  dataFileUrlsPageSize = this.LBM_NUM_PAGING_TAKE;
  dataFileUrlsSelection: number[] = [];

  filesUpload: Array<FileInfo>;
  filesUploadName = "";
  dataFileUrls: any[];
  public fileSaveUrl: any;

  public uploadSaveUrl = 'saveUrl';
  public uploadRemoveUrl = 'removeUrl';
  public enabled = false;
  public enabledID = false;
  isEnabledSaveAll = false;
  control: any;
  controlDefault = true;
  allowMulti = true;
  isReplyPage = false;
  tabIndex = 0;
  menuNames = '';
  searchOption = {
    SearchText: '',
    FromDate: null,
    ToDate: null,
    UrlPath: '',
    Status: 0
  };


  askInfoOpened = false;
  loginOpened = false;
  accountInfoPortal = {
    UserName: '',
    FullName: ''
  };

  constructor(
    private translate: TranslateService,
    private language: AppLanguage,
    private appService: AppService,
    private appSwal: AppSwal,
    public intl: IntlService,
    private notification: Notification,
    private guid: AppGuid,
    private file: AppFile,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    private appUtils: AppUtils,
    private appComponent: AppComponent,
    private domSanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {

    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (params && params.title) {
        this.searchOption.UrlPath = params.title;
      }
      this.initDisplay();
    });
    
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
    //this.getControl();
    this.setDefault();
    this.language.default();
    this.setSelectableSettings();
    this.allData = this.allData.bind(this);
  }

  ngOnDestroy(): void {
  }

  ngOnInit() {
    this.checkLogin(false);
  }

  public checkLogin(required) {
    const account = localStorage.getItem('accountInfoPortal');
    if (account) {
      this.accountInfoPortal = JSON.parse(account);
      this.loginOpened = false;
      return true;
    } else if (required) {
      this.loginOpened = true;
      return false;
    }
  }

  async getControl() {
    this.control = await this.appControls.getControls(this.user.RoleID);
    this.controlDefault = false;
  }

  async getMenuName() {
    const dataRequest = {
      urlPath: this.searchOption.UrlPath,
    };
    const result = await this.appService.doGET('api/Menu', dataRequest);
    if (result && result.Status === 1) {
      this.menuNames = result.Data.Name;
    }
  }
  setSelectableSettings(): void {

    this.allowMulti = !this.allowMulti;
    this.dataCommentSelectableSettings = {
      checkboxOnly: false,
      mode: this.allowMulti ? 'multiple' : 'single'
    };

    this.dataFileUrlsSelectableSettings = {
      checkboxOnly: false,
      mode: 'single'
    };
  }

  onSearchKeyPress(e: any) {
    // if (e.keyCode === 13 && this.searchOption.SearchText) {
    //   this.onSearch();
    // }
  }



  async getComments() {
    this.loading = true;
    const dataRequest = {
      SearchText: this.searchOption.SearchText,
      urlPath: this.searchOption.UrlPath,
      Status: 1
    };

    const result = await this.appService.doPOST('api/Comment/Search', dataRequest);
    if (result && result.Status === 1) {
      this.dataComments = result.Data;
      this.bindComments();
    }
    this.loading = false;
    this.checkSelectionID();
  }

  async getCommentsLastest() {

    this.loading = true;
    const dataRequest = {
      urlPath: this.searchOption.UrlPath,
    };
    const result = await this.appService.doGET('api/Comment/GetCommentLatest', dataRequest);
    if (result && result.Status === 1) {
      this.dataCommentsLatest = result.Data;
    }
    this.loading = false;
  }

  async getCommentsMostView() {

    this.loading = true;
    const dataRequest = {
      urlPath: this.searchOption.UrlPath,
    };
    const result = await this.appService.doGET('api/Comment/GetCommentMostView', dataRequest);
    if (result && result.Status === 1) {
      this.dataCommentMostView = result.Data;
    }
    this.loading = false;
  }

  checkSelectionID() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = this.dataCommentSelection.length - 1; i >= 0; i--) {
      const selectedItem = this.dataComments.find((item) => {
        return item.ID === this.dataCommentSelection[i];
      });
      if (!selectedItem) {
        this.dataCommentSelection.splice(i, 1);
      }
    }
  }

  onClickNotify(e: any) {
    const menuID = e;
    this.searchOption.UrlPath = menuID;
    this.onReload();
  }

  setDefault() {
    this.dataCommentItem = {
      viewOnly: false,
      IsAdd: true,
      UrlPath: this.searchOption.UrlPath,
      FullName: null,
      Address: null,
      Phone: null,
      Phone1: null,
      Email: null,
      Title: null,
      PostID: null,
      Description: null,
      ApprovedFlg: null,
      Type: null,
      CreateAt: null,
      FeedbackContent: null,
      FeedbackBy: null,
      FeedbackAt: null,
      OftenFlg: false,
      PortalFlg: true,
      FileUrl: null
    };

    this.dataCommentSelection = [];
    this.filesUpload = [];
    this.filesUploadName = '';
    this.dataFileUrls = [];
    this.bindFileUrls();
    this.fileSaveUrl = `${this.appService.apiRoot}api/Upload/MediaWeb?accountID=portal&typeData=files&checkFileSize=true`;
  }


  onCommentPageChange(event: PageChangeEvent) {
    this.dataCommentSkip = event.skip;
    this.bindComments();
  }

  async onCommentselectedKeysChange() {

    if (this.dataCommentSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }

    if (this.dataCommentSelection.length > 1) {
      if (this.allowMulti) {
        return;
      }
      this.appSwal.showWarning(this.translate.instant('SelectSingle'), false);
    } else {
      //const selectedID = this.dataCommentSelection[0];
      //const selectedItem = this.dataComments.find((item) => {
      //  return item.ID === selectedID;
      //});
      //selectedItem.IsAdd = false;
      //selectedItem.PublishAt = selectedItem.PublishAt ? new Date(selectedItem.PublishAt) : null;

      //this.dataCommentItem = selectedItem;
    }
  }

  bindComments() {
    this.dataCommentGridDataResult = {
      data: orderBy(this.dataComments, this.dataCommentSortByField),
      total: this.dataComments.length
    };

    this.dataCommentGridDataResult = process(this.dataComments, this.dataCommentState);
  }

  bindFileUrls() {
    this.dataFileUrlsGridDataResult = {
      data: this.dataFileUrls,
      total: this.dataFileUrls.length
    };

    this.dataCommentGridDataResult = process(this.dataComments, this.dataCommentState);
  }

  onCommentsortChange(sort: SortDescriptor[]): void {
    this.dataCommentSortByField = sort;
    this.bindComments();
  }

  public onCommentDataStateChange(state: DataStateChangeEvent): void {
    this.dataCommentSelection = [];
    this.dataCommentState = state;
    this.dataCommentGridDataResult = process(this.dataComments, this.dataCommentState);
  }

  removeEventHandler(index) {
    this.isEnabledSaveAll = false;
    this.onReload();
  }

  doInterval() {
    // this.myInterval = Observable.interval(this.appService.timeInterval).subscribe(() => {
    //   this.onReload();
    // });
  }

  onSearchTextChange(e: any) {
    if (!this.searchOption.SearchText) {
      this.onReload();
    }
  }

  onRemoveSearchText() {
    this.searchOption.SearchText = '';

  }

  async initDisplay() {
    this.onReload();
  }

  onSearch() {
    this.getComments();
    this.isEnabledSaveAll = false;
  }

  onReload() {
    this.setDefault();
    //this.searchOption.SearchText = '';
    this.getComments();
    this.getMenuName();
    this.getCommentsLastest();
    this.getCommentsMostView();
    this.isEnabledSaveAll = false;
  }

  onClearComment() {
    this.setDefault();
  }

  onAllowSelectMulti() {
    this.setSelectableSettings();
  }

  onAddNewComment() {
    this.enabled = true;
    this.enabledID = true;
    this.setDefault();
  }

  async onSaveComment() {
    this.addComment();
    // if (this.dataCommentItem.IsAdd) {  } else { this.updateComment(); }
  }

  async onSaveComments() {
    this.appComponent.loading = true;
    const dataRequests = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.dataComments.length; i++) {
      dataRequests.push(this.createDataRequest(this.dataComments[i]));
    }
    const result = await this.appService.doPOST('api/Comment/Saves', dataRequests);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.isEnabledSaveAll = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  createDataRequest(data) {
    const temp = data ? data : this.dataCommentItem;

    // let fileName = '';
    // if (this.dataFileUrls.length > 0) {
    //   fileName = this.dataFileUrls[0].Url;
    // }

    //

    var files: any[] = [];
    if (this.dataCommentItem.FileUrl) {
      for (let i = 0; i < this.dataCommentItem.FileUrl.length; i++) {
        files.push(this.dataCommentItem.FileUrl[i]);
      }
    }
    return {
      ID: temp.ID,
      //PublishAt: temp.PublishAt ? this.intl.formatDate(new Date(temp.PublishAt), 'yyyy-MM-ddT00:00:00') : null,
      UserAsk: this.accountInfoPortal.UserName,
      UrlPath: temp.UrlPath,
      FullName: temp.FullName,
      Address: temp.Address,
      Phone: temp.Phone,
      Phone1: temp.Phone1,
      Email: temp.Email,
      Title: temp.Title,
      PostID: temp.PostID,
      Description: temp.Description,
      ApprovedFlg: temp.ApprovedFlg,
      Type: temp.Type,
      CreateAt: temp.CreateAt,
      FeedbackContent: temp.FeedbackContent,
      FeedbackBy: temp.FeedbackBy,
      FeedbackAt: temp.FeedbackAt,
      OftenFlg: temp.OftenFlg,
      PortalFlg: true,
      FileUrl: JSON.stringify(files),
    };
  }

  onCloseComment(status: any) {
    this.enabled = false;
    this.enabledID = false;
  }

  onEditComment() {
    this.enabled = true;
    this.enabledID = false;
  }

  async addComment() {
    this.appComponent.loading = true;
    const dataRequest = this.createDataRequest(null);
    const result = await this.appService.doPOST('api/Comment/PostQues', dataRequest);
    if (result && result.Status === 1) {
      this.appSwal.showSuccess('Hỏi đáp đã được gửi thành công, Chúng tôi sẽ phản hồi trong thời gian sớm nhất!', false);
      // this.onReload();
      this.onAddNewComment();
      this.askInfoOpened = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }



  async updateComment() {
    this.appComponent.loading = true;
    // tslint:disable-next-line: max-line-length
    const id = this.dataCommentItem.ID;
    const dataRequest = this.createDataRequest(this.dataCommentItem);

    const result = await this.appService.doPUT('api/Comment', dataRequest, { id });
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  async onDeleteComment() {
    if (this.dataCommentSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }
    this.appComponent.loading = true;
    const dataRequest = {
      IDList: JSON.stringify(this.dataCommentSelection),
      FlgRevert: false
    };

    const option = await this.appSwal.showWarning(this.translate.instant('AreYouSure'), true);
    if (option) {
      const result = await this.appService.doPOST('api/Comment/Deletes', dataRequest);
      if (result && result.Status === 1) {
        this.notification.showSuccess(result.Msg);
        this.onReload();
        this.onAddNewComment();
        this.enabled = false;
        this.enabledID = false;
        this.dataCommentSelection = [];
        this.allowMulti = false;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
    this.appComponent.loading = false;
  }

  getNameByUrl(fileUrl) {
    const nameFile = fileUrl.replace(/^.*[\\\/]/, '');

    return nameFile;
  }

  async onDownloadFile(dataItem: any) {

    const dataRequest = {
      url: dataItem.Url
    };
    const result = await this.appService.doDownload('api/Upload/DownloadByUrl', dataRequest);
    if (result) {
      FileSaver.saveAs(result, dataItem.Name);
    }
  }

  onDeleteFile(dataItem: any) {

    for (let i = this.dataFileUrls.length - 1; i >= 0; i--) {
      if (this.dataFileUrls[i].Url === dataItem.Url) {
        this.dataFileUrls.splice(i, 1);
        break;
      }
    }

    this.bindFileUrls();
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.dataComments, {}).data
    };

    return result;
  }

  async onSelectFileToUpload(e: SelectEvent) {
    if (!e.files || e.files.length <= 0) {
      return false;
    }
    try {
      const fileData = e.files[0];// await this.file.readFile(e.files[0].rawFile);
      const maxMB = 25;
      const maxSizeKB = 1024 * 1024 * maxMB;
      if (fileData.size > maxSizeKB) {
        this.appSwal.showWarning(`Kích thước tập tin không được lớn hơn ${maxMB} MB`, false);
        return false;
      }
    } catch {
      return false;
    }
    try {
      const fileData = await this.file.readFile(e.files[0].rawFile);
    } catch {
      this.appSwal.showError(e);
    }
  }

  onSuccessFileToUpload(e: any) {
    try {
      if(e != null && e.response.body != null) {
        if(e.response.body.Status != 0) {
          if (this.dataCommentItem.FileUrl == undefined) {
            this.dataCommentItem.FileUrl = [];
          }
          this.dataCommentItem.FileUrl.push(`${e.response.body.Data.DirMedia}${e.response.body.Data.MediaNm[0]}`);
        } else {
          this.appSwal.showError(e.response.body.Msg);
        }
      } 
    } catch {
      this.appSwal.showError(e);
    }
  }

  onRemoveFileToUpload() {
    this.dataCommentItem.FileUrl = [];
    this.filesUploadName = '';
    this.filesUpload = [];
  }

  onRemoveFile(file){
    var isExistedInFile = this.dataCommentItem.FileUrl.findIndex(x => x == file);
    if (isExistedInFile != -1) {
      this.dataCommentItem.FileUrl.splice(isExistedInFile, 1);
    }
  }

  getFileName(fileUrls) {
    var nameFile = "";
    if (fileUrls != "" && fileUrls != null) {
      var urlArr = fileUrls.split("/");
      if (urlArr.length > 0) {
        nameFile = urlArr[urlArr.length - 1];
        if (nameFile != "" && nameFile != null) {
          var indexOfFirst = nameFile.indexOf("_");
          nameFile = nameFile.substring(indexOfFirst + 1);
        }
      }
    }
    return nameFile;
  }

  onTabChanged(event) {
    this.tabIndex = event.index;
    if (this.tabIndex == 1) {
      this.checkLogin(true);
    } else {
      // this.onSearch();
      this.getComments();
    }
    this.isReplyPage = false;
  }
  manageOpen = false;
  async Count() {
    this.appComponent.loading = true;
    // tslint:disable-next-line: max-line-length
    const id = this.dataCommentItem.ID;
    const dataRequest = this.createDataRequest(this.dataCommentItem);

    const result = await this.appService.doPUT('api/Comment', dataRequest, { id });
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  async onClickLastestComment(item) {
    this.dataCommentDetail = item;
    // this.isReplyPage = true;
    // this.tabIndex = 1;
    // window.scrollTo(0, 0);
    const id = this.dataCommentDetail.ID;
    const dataRequest = this.createDataRequest(this.dataCommentDetail);
    const result = await this.appService.doPUT('api/Comment/Count', dataRequest, { id });
    this.manageOpen = true;
  }
  public onCloseManage() {
    this.manageOpen = false;
  }

  public onCloseLogin() {
    this.loginOpened = false;
  }

  onClickCreateCommentBtn() {
    this.isReplyPage = false;
    this.tabIndex = 0;
  }

  onClickFreQuestion() {
    // this.router.navigate(['portal/comment-often'], { queryParams: { menuID: this.searchOption.MenuID } });
  }

  Logout() {
    localStorage.removeItem('accountInfoPortal');
    window.location.reload();
  }

  Login() {
    this.loginOpened = true;
  }

  loginSuccess() {
    this.checkLogin(false);
    this.askInfoOpened = true;
  }

  onAskInfo() {
    if (this.accountInfoPortal && this.accountInfoPortal.UserName) {
      this.askInfoOpened = true;
    } else {
      this.checkLogin(true);
    }
  }

  onCloseAsk() {
    this.askInfoOpened = false;
  }
}



import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { AppLanguage } from '../../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../services/app.service';
import { SelectableSettings, PageChangeEvent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { AppSwal } from 'src/app/services/app.swal';
import { IntlService } from '@progress/kendo-angular-intl';
import { Notification } from '../../services/app.notification';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
import { AppGuid } from 'src/app/services/app.guid';
import { NullInjector } from '@angular/core/src/di/injector';
import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';
import * as XLSX from 'xlsx';
import { AppFile } from 'src/app/services/app.file';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppUtils } from 'src/app/services/app.utils';
import { AppComponent } from '../../app.component';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpProgressEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { of, concat } from 'rxjs';
import { delay } from 'rxjs/operators';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-ad-procedure',
  templateUrl: './ad-procedure.component.html',
  styleUrls: ['./ad-procedure.component.css']
})
export class AdProcedureComponent implements OnInit, OnDestroy {
  //codeimage
  commentInput = null;

  uploadFlag = {
    banner: false,
    image: false,
    video: false,
  };

  user: any;
  loading = false;
  dataAdProcedures = [];
  adProceduresFilter = [];
  dataAdProcedureSelectableSettings: SelectableSettings;
  dataAdProcedureSort = {
    allowUnsort: true,
    mode: 'multiple'
  };
  public dataAdProcedureFocus = {
    Name: true
  };
  dataAdProcedureSortByField: SortDescriptor[] = [
    // {
    //   field: 'ParentName',
    //   dir: 'asc'
    // }, {
    //   field: 'OrdinalNumber',
    //   dir: 'asc'
    // }
  ];

  //imagesUploadName = "";

  public LBM_NUM_PAGING_SKIP = 0;
  public LBM_NUM_PAGING_TAKE = 50;
  public LBM_NUM_PAGING_BTN = 5;

  dataAdProcedureSkip = this.LBM_NUM_PAGING_SKIP;
  dataAdProcedurePageSize = this.LBM_NUM_PAGING_TAKE;
  dataAdProcedureSelection: number[] = [];
  dataAdProcedureItem: any;
  myInterval: any;
  isRight100p = false;
  pageName: any;
  infoOpened = false;

  public buttonCount = this.LBM_NUM_PAGING_BTN;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;

  public dataAdProcedureState: State = {
    skip: this.dataAdProcedureSkip,
    take: this.dataAdProcedureSkip + this.dataAdProcedurePageSize,
    filter: {
      logic: 'and',
      filters: []
    },
    //group: [{ field: 'TypeName' }]
  };
  dataAdProcedureGridDataResult: GridDataResult;

  dataTemplateFileUrlsGridDataResult: GridDataResult;
  dataTemplateFileUrlsSelectableSettings: SelectableSettings;
  dataTemplateFileUrlsSkip = this.LBM_NUM_PAGING_SKIP;
  dataTemplateFileUrlsPageSize = this.LBM_NUM_PAGING_TAKE;
  dataTemplateFileUrlsSelection: number[] = [];

  fieldTypes: Array<{ Name: string, ID: string }>;
  fieldTypesFilter: Array<{ Name: string, ID: string }>;
  fieldTypesSearch: Array<{ Name: string, ID: string }>;

  templateTypes: Array<{ Name: string, ID: string }> = [];
  templateTypesFilter: Array<{ Name: string, ID: string }> = [];

  baseJuridicalTypes: Array<{ Name: string, ID: string }> = [];
  baseJuridicalTypesFilter: Array<{ Name: string, ID: string }> = [];

  dataTemplateTypeID = [];
  dataBaseJuridicalTypeID = [];
  dataAdProcedure = [];

  filesUpload: Array<FileInfo>;
  dataTemplateFileUrls: any[];
  public fileSaveUrl: any;

  placeActives: Array<{ Name: string, ID: number }>;
  publicServices: Array<{ Name: string, ID: number }>;

  public uploadSaveUrl = 'saveUrl';
  public uploadRemoveUrl = 'removeUrl';
  public enabled = false;
  public enabledID = false;
  isEnabledSaveAll = false;
  control: any;
  controlDefault = true;
  allowMulti = true;
  allowInsertFile = true;

  searchOption = {
    SearchText: '',
    FieldTypeID: null,
    PlaceActive: -1,
    PublicService: -1,
    PostalServiceFlg: null
  };
  constructor(
    private translate: TranslateService,
    private language: AppLanguage,
    private appService: AppService,
    private appSwal: AppSwal,
    public intl: IntlService,
    private notification: Notification,
    private guid: AppGuid,
    private file: AppFile,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    private appUtils: AppUtils,
    private appComponent: AppComponent,
    private domSanitizer: DomSanitizer,
  ) {
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
    this.getControl();
    this.setDefault();
    this.language.default();
    this.setSelectableSettings();
    this.onReload();
    this.initDisplay();
    this.allData = this.allData.bind(this);
    this.getPageName();
  }

  ngOnDestroy(): void {
  }

  ngOnInit() {
  }
  async getPageName() {
    this.pageName = await this.appControls.getPageName();
  }

  async getControl() {
    this.control = await this.appControls.getControls(this.user.RoleID);
    this.controlDefault = false;
  }

  setSelectableSettings(): void {

    this.allowMulti = !this.allowMulti;
    this.dataAdProcedureSelectableSettings = {
      checkboxOnly: false,
      mode: this.allowMulti ? 'multiple' : 'single'
    };

    this.dataTemplateFileUrlsSelectableSettings = {
      checkboxOnly: false,
      mode: 'single'
    };
  }

  onFieldTypeHandleFilter(value) {
    this.fieldTypesFilter = this.fieldTypes.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  onFieldTypeSearchHandleFilter(value) {
    this.fieldTypesSearch = this.fieldTypes.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  onTemplateTypesHandleFilter(value) {
    this.templateTypesFilter = this.templateTypes.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  onBaseJuridicalTypesHandleFilter(value) {
    this.baseJuridicalTypesFilter = this.baseJuridicalTypes.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  onAdProceduresHandleFilter(value) {
    this.adProceduresFilter = this.dataAdProcedures.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  onTypeSearchHandleFilter(value) {
    this.onReload();
  }


  onSearchKeyPress(e: any) {
    // if (e.keyCode === 13 && this.searchOption.SearchText) {
    //   this.onSearch();
    // }
  }

  async getAdProcedures() {
    this.loading = true;
    const dataRequest = {
      SearchText: this.searchOption.SearchText,
      FieldTypeID: this.searchOption.FieldTypeID,
      PlaceActive: this.searchOption.PlaceActive,
      PublicService: this.searchOption.PublicService,
      PostalServiceFlg: this.searchOption.PostalServiceFlg
    };

    const result = await this.appService.doPOST('api/AdProcedure/Search', dataRequest);
    if (result && result.Status === 1) {
      this.dataAdProcedures = result.Data;
      this.adProceduresFilter = this.dataAdProcedures.slice();
      this.bindAdProcedures();
    }
    this.loading = false;
    this.checkSelectionID();
  }

  checkSelectionID() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = this.dataAdProcedureSelection.length - 1; i >= 0; i--) {
      const selectedItem = this.dataAdProcedures.find((item) => {
        return item.ID === this.dataAdProcedureSelection[i];
      });
      if (!selectedItem) {
        this.dataAdProcedureSelection.splice(i, 1);
      }
    }
  }

  setDefault() {
    this.dataAdProcedureItem = {
      IsAdd: true,
      Name: null,
      OrderActive: null,
      MethodActive: null,
      FileInfo: null,
      ResolveDate: null,
      ObjectActive: null,
      PlaceActive: 0,
      ResultActive: null,
      TemplateTypeID: null,
      TemplateFileUrl: null,
      Fees: null,
      ConditionAtive: null,
      BaseJuridicalTypeID: null,
      BaseJuridicalFileUrl: null,
      AdProcedure: null,
      PublicService: null,
      PublicServiceLink: null,
      FieldTypeID: null,
      PostalServiceFlg: null,
      CreateAt: new Date(),
      UpdateAt: new Date(),
      DelFlg: null,
      Code: null
    };

    this.dataTemplateTypeID = [];
    this.dataBaseJuridicalTypeID = [];
    this.dataAdProcedure = [];
    this.dataAdProcedureSelection = [];
    this.filesUpload = [];
    this.dataTemplateFileUrls = [];
    this.bindTemplateFileUrls();
    this.enabled = true;
    this.enabledID = true;
    this.fileSaveUrl = `${this.appService.apiRoot}api/Upload/MediaWeb?accountID=${this.user.UserName}&typeData=files`;
  }

  onPostPageChange(event: PageChangeEvent) {
    this.dataAdProcedureSkip = event.skip;
    this.bindAdProcedures();
  }

  async onAdProcedureselectedKeysChange() {

    if (this.dataAdProcedureSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }

    if (this.dataAdProcedureSelection.length > 1) {
      if (this.allowMulti) {
        return;
      }
      this.appSwal.showWarning(this.translate.instant('SelectSingle'), false);
    } else {
      const selectedID = this.dataAdProcedureSelection[0];
      const selectedItem = this.dataAdProcedures.find((item) => {
        return item.ID === selectedID;
      });
      selectedItem.IsAdd = false;
      selectedItem.PublishAt = selectedItem.PublishAt ? new Date(selectedItem.PublishAt) : null;

      this.dataAdProcedureItem = selectedItem;

      const TemplateFileUrl = selectedItem.TemplateFileUrl;
      this.dataTemplateFileUrls = [];
      if (TemplateFileUrl) {
        this.dataTemplateFileUrls.push({
          Name: this.getNameByUrl(selectedItem.TemplateFileUrl),
          Url: selectedItem.TemplateFileUrl
        });
      }
      this.bindTemplateFileUrls();

      let temp = [];
      if (selectedItem.TemplateTypeID) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.templateTypes.length; i++) {
          if (selectedItem.TemplateTypeID.indexOf(this.templateTypes[i].ID) >= 0) {
            temp.push(this.templateTypes[i]);
          }
        }
      }
      this.dataTemplateTypeID = temp;

      temp = [];
      if (selectedItem.BaseJuridicalTypeID) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.baseJuridicalTypes.length; i++) {
          if (selectedItem.BaseJuridicalTypeID.indexOf(this.baseJuridicalTypes[i].ID) >= 0) {
            temp.push(this.templateTypes[i]);
          }
        }
      }
      this.dataBaseJuridicalTypeID = temp;

      temp = [];
      if (selectedItem.AdProcedure) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.dataAdProcedures.length; i++) {
          if (selectedItem.AdProcedure.indexOf(this.dataAdProcedures[i].ID) >= 0) {
            temp.push(this.dataAdProcedures[i]);
          }
        }
      }
      this.dataAdProcedure = temp;

      this.enabled = false;
      this.enabledID = false;
    }
  }

  bindAdProcedures() {
    this.dataAdProcedureGridDataResult = {
      data: orderBy(this.dataAdProcedures, this.dataAdProcedureSortByField),
      total: this.dataAdProcedures.length
    };

    this.dataAdProcedureGridDataResult = process(this.dataAdProcedures, this.dataAdProcedureState);
  }

  bindTemplateFileUrls() {
    this.dataTemplateFileUrlsGridDataResult = {
      data: this.dataTemplateFileUrls,
      total: this.dataTemplateFileUrls.length
    };

    this.dataAdProcedureGridDataResult = process(this.dataAdProcedures, this.dataAdProcedureState);
  }

  onAdProceduresortChange(sort: SortDescriptor[]): void {
    this.dataAdProcedureSortByField = sort;
    this.bindAdProcedures();
  }

  public onPostDataStateChange(state: DataStateChangeEvent): void {
    this.dataAdProcedureSelection = [];
    this.dataAdProcedureState = state;
    this.dataAdProcedureGridDataResult = process(this.dataAdProcedures, this.dataAdProcedureState);
  }

  removeEventHandler(index) {
    this.isEnabledSaveAll = false;
    this.onReload();
  }

  doInterval() {
    // this.myInterval = Observable.interval(this.appService.timeInterval).subscribe(() => {
    //   this.onReload();
    // });
  }

  onSearchTextChange(e: any) {
    if (!this.searchOption.SearchText) {
      this.onReload();
    }
  }

  onRemoveSearchText() {
    this.searchOption.SearchText = '';

  }

  async initDisplay() {

    // mau don, to khai
    const resultTemplateType = await this.appService.doGET('api/AdProcedure/GetTemplateType', null);
    if (resultTemplateType && resultTemplateType.Status === 1) {
      this.templateTypes = resultTemplateType.Data;
      this.templateTypesFilter = this.templateTypes.slice();
    }

    // can cu phap ly
    const resultBaseJuridicalType = await this.appService.doGET('api/AdProcedure/GetBaseJuridicalType', null);
    if (resultBaseJuridicalType && resultBaseJuridicalType.Status === 1) {
      this.baseJuridicalTypes = resultBaseJuridicalType.Data;
      this.baseJuridicalTypesFilter = this.baseJuridicalTypes.slice();
    }

    // linh vuc
    const resultFieldType = await this.appService.doGET('api/AdProcedure/GetFieldType', null);
    if (resultFieldType && resultFieldType.Status === 1) {
      this.fieldTypes = resultFieldType.Data;
      this.fieldTypesFilter = this.fieldTypes.slice();
      this.fieldTypesSearch = this.fieldTypes.slice();
    }

    this.placeActives = [
      { Name: 'Tất cả', ID: -1 },
      { Name: 'UBND quận', ID: 0 },
      { Name: 'UBND phường', ID: 1 }
    ];

    this.publicServices = [
      { Name: 'Tất cả', ID: -1 },
      { Name: 'Mức độ 2', ID: 2 },
      { Name: 'Mức độ 3', ID: 3 },
      { Name: 'Mức độ 4', ID: 4 }
    ];
  }

  onSearch() {
    this.getAdProcedures();
    this.isEnabledSaveAll = false;
  }

  onReload() {
    //this.searchOption.SearchText = '';
    this.getAdProcedures();
    this.isEnabledSaveAll = false;
  }

  onClearPost() {
    this.setDefault();
  }

  onAllowSelectMulti() {
    this.setSelectableSettings();
  }

  onAddNewPost() {
    
    this.setDefault();
    this.infoOpened = true;
  }

  async onSavePost() {
    if (this.dataAdProcedureItem.IsAdd) { this.addPost(); } else { this.updatePost(); }
  }

  async onSaveAdProcedures() {
    this.appComponent.loading = true;
    const dataRequests = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.dataAdProcedures.length; i++) {
      dataRequests.push(this.createDataRequest(this.dataAdProcedures[i]));
    }
    const result = await this.appService.doPOST('api/AdProcedure/Saves', dataRequests);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.isEnabledSaveAll = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  createDataRequest(data) {
    const temp = data ? data : this.dataAdProcedureItem;

    let templateFileUrl = '';
    if (this.dataTemplateFileUrls.length > 0) {
      templateFileUrl = this.dataTemplateFileUrls[0].Url;
    }

    const templateTypeIDJSON: any[] = [];
    if (this.dataTemplateTypeID.length > 0) {
      for (let i = 0; i < this.dataTemplateTypeID.length; i++) {
        templateTypeIDJSON.push(this.dataTemplateTypeID[i].ID);
      }
    }

    const baseJuridicalTypeIDJSON: any[] = [];
    if (this.dataBaseJuridicalTypeID.length > 0) {
      for (let i = 0; i < this.dataBaseJuridicalTypeID.length; i++) {
        baseJuridicalTypeIDJSON.push(this.dataBaseJuridicalTypeID[i].ID);
      }
    }

    const adProcedureJSON: any[] = [];
    if (this.dataAdProcedure.length > 0) {
      for (let i = 0; i < this.dataAdProcedure.length; i++) {
        adProcedureJSON.push(this.dataAdProcedure[i].ID);
      }
    }

    //
    return {
      ID: temp.ID,
      Name: temp.Name,
      OrderActive: temp.OrderActive,
      MethodActive: temp.MethodActive,
      FileInfo: temp.FileInfo,
      ResolveDate: temp.ResolveDate,
      ObjectActive: temp.ObjectActive,
      PlaceActive: temp.PlaceActive,
      ResultActive: temp.ResultActive,
      TemplateTypeID: templateTypeIDJSON.length > 0 ? JSON.stringify(templateTypeIDJSON) : null,
      TemplateFileUrl: templateFileUrl,
      Fees: temp.Fees,
      ConditionAtive: temp.ConditionAtive,
      BaseJuridicalTypeID: baseJuridicalTypeIDJSON.length > 0 ? JSON.stringify(baseJuridicalTypeIDJSON) : null,
      BaseJuridicalFileUrl: temp.BaseJuridicalFileUrl,
      AdProcedure: adProcedureJSON.length > 0 ? JSON.stringify(adProcedureJSON) : null,
      PublicService: temp.PublicService,
      PublicServiceLink: temp.PublicServiceLink,
      FieldTypeID: temp.FieldTypeID,
      PostalServiceFlg: temp.PostalServiceFlg,
      CreateAt: temp.CreateAt,
      UpdateAt: temp.UpdateAt,
      DelFlg: temp.DelFlg,
      Code: temp.Code
    };
  }

  onClosePost(status: any) {
    this.enabled = false;
    this.enabledID = false;
    this.infoOpened = true;
  }

  onEditPost() {
    this.enabled = true;
    this.enabledID = false;
    this.infoOpened = true;
  }

  async addPost() {
    this.appComponent.loading = true;
    const dataRequest = this.createDataRequest(null);

    const result = await this.appService.doPOST('api/AdProcedure', dataRequest);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.setDefault();
      this.infoOpened = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  async updatePost() {
    this.appComponent.loading = true;
    // tslint:disable-next-line: max-line-length
    const id = this.dataAdProcedureItem.ID;
    const dataRequest = this.createDataRequest(this.dataAdProcedureItem);

    const result = await this.appService.doPUT('api/AdProcedure', dataRequest, { id });
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      //this.setDefault();
      this.infoOpened = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  async onDeletePost() {
    if (this.dataAdProcedureSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }
    this.appComponent.loading = true;
    const dataRequest = {
      IDList: JSON.stringify(this.dataAdProcedureSelection),
      FlgRevert: false
    };

    const option = await this.appSwal.showWarning(this.translate.instant('AreYouSure'), true);
    if (option) {
      const result = await this.appService.doPOST('api/AdProcedure/Deletes', dataRequest);
      if (result && result.Status === 1) {
        this.notification.showSuccess(result.Msg);
        this.onReload();
        //this.onAddNewPost();
        this.enabled = false;
        this.enabledID = false;
        this.dataAdProcedureSelection = [];
        this.allowMulti = false;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
    this.appComponent.loading = false;
  }

  getNameByUrl(TemplateFileUrl) {
    const nameFile = TemplateFileUrl.replace(/^.*[\\\/]/, '');

    return nameFile;
  }

  async onDownloadFile(dataItem: any) {

    const dataRequest = {
      url: dataItem.Url
    };
    const result = await this.appService.doDownload('api/Upload/DownloadByUrl', dataRequest);
    if (result) {
      FileSaver.saveAs(result, dataItem.Name);
    }
  }

  onDeleteFile(dataItem: any) {

    for (let i = this.dataTemplateFileUrls.length - 1; i >= 0; i--) {
      if (this.dataTemplateFileUrls[i].Url === dataItem.Url) {
        this.dataTemplateFileUrls.splice(i, 1);
        break;
      }
    }

    this.bindTemplateFileUrls();
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.dataAdProcedures, {}).data
    };

    return result;
  }

  async onSelectFileToUpload(e: SelectEvent) {

    if (!e.files || e.files.length <= 0) {
      return;
    }

    const extension = e.files[0].extension.toLowerCase();

    this.allowInsertFile = true;

    try {
      const fileData = e.files[0];// await this.file.readFile(e.files[0].rawFile);

      const maxMB = 30;
      const maxSizeKB = 1024 * 1024 * maxMB;
      if (fileData.size > maxSizeKB) {
        this.allowInsertFile = false;
        this.appSwal.showWarning(`Kích thước tập tin không được lớn hơn ${maxMB} MB`, false);
        // this.dataTemplateFileUrls = [];
        // this.bindTemplateFileUrls();
      }
    } catch {
    }

    // tslint:disable-next-line: max-line-length
    if (!extension || (extension.toLowerCase() !== '.doc' && extension.toLowerCase() !== '.docx' && extension.toLowerCase() !== '.pdf')) {
      this.allowInsertFile = false;
      this.appSwal.showWarning('Vui lòng chọn tập tin có định dạng .doc, .docx, .pdf', false);
      // this.dataTemplateFileUrls = [];
      // this.bindTemplateFileUrls();

      return false;
    }


  }

  onSuccessFileToUpload(e: any) {

    if (!this.allowInsertFile) {
      return;
    }
    try {
      this.dataTemplateFileUrls = [];
      const k = `${e.response.body.Data.DirMedia}${e.response.body.Data.MediaNm[0]}`;
      this.dataTemplateFileUrls.push({
        Name: this.getNameByUrl(k),
        Url: k
      });

      this.bindTemplateFileUrls();
    } catch {
      this.appSwal.showError(e);
    }
  }

  onRemoveFileToUpload() {

  }

  onFullScreen() {
    this.isRight100p = !this.isRight100p;
  }
  onCloseInfo(e) {
    this.setDefault();
    this.infoOpened = false;
  }
}


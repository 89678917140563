import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { AppService } from './app.service';

@Injectable({
    providedIn: 'root'
})
export class AppControls {

    constructor(
        public location: Location,
        private appService: AppService
    ) { }

    getPage() {
        let pageUrl = this.location.path();
        if (!pageUrl) {
            return null;
        }

        const idx = pageUrl.indexOf('?');
        if (idx >= 0) {
            pageUrl = pageUrl.substring(0, idx);
        }
        
        pageUrl = pageUrl.substring(1);

        return pageUrl;
    }

    async getControls(roleID) {
        // let pageUrl = this.location.path();
        // if (!pageUrl) {
        //     return null;
        // }

        // const idx = pageUrl.indexOf('?');
        // if (idx >= 0) {
        //     pageUrl = pageUrl.substring(0, idx);
        // }

        let controlDefault = ['AddNew', 'Edit', 'Delete', 'SaveAll', 'ExportExcel', 'Save', 'ImportExcel', 'SelectMulti'];
        let pageUrl = this.getPage();// pageUrl.substring(1);
        if (!pageUrl) {
            return null;
        }

        switch (pageUrl) {
            case 'ad/user-role': {
                controlDefault = ['Save'];
                break;
            }
            case 'data/ask-and-reply': {
                controlDefault.push('Reply');
                controlDefault.push('SendUnit');
                break;
            }
            case 'data/ask-and-reply-feedback': {
                controlDefault = ['Reply', 'Save'];
                break;
            }
            case 'data/weekly-report': {
                controlDefault.push('Approve');
                controlDefault.push('UnApprove');
                controlDefault.push('Reply');
                controlDefault.push('RangeTime');
                controlDefault.push('ViewRPUnapproved');
                controlDefault.push('SeeAllRp');
                controlDefault.push('SeeAllByUnit');
                controlDefault.push('PCTIdea');
                controlDefault.push('TTIdea');
                break;
            }
            case 'data/ask-and-reply': {
                controlDefault = ['Reply', 'Save', 'Approve', 'UnApprove', 'Send'];
                break;
            }
            case 'data/post': {
                controlDefault = ['SelectMulti', 'AddNew', 'Delete', 'Edit', 'ExportExcel', 'Save', 'History', 'WatchFirst'];
                break;
            }
            case 'data/menu': {
                controlDefault = ['SelectMulti', 'AddNew', 'Delete', 'Edit', 'Save'];
                break;
            }
            case 'm/type': {
                controlDefault = ['SelectMulti', 'AddNew', 'Delete', 'Edit', 'Save'];
                break;
            }
            case 'data/mail-config': {
                controlDefault = ['SelectMulti', 'AddNew', 'Delete', 'Edit', 'Save', 'WatchFirst'];
                break;
            }
            case 'data/meeting': {
                controlDefault = ['AddNew', 'Save', 'Edit', 'Delete', 'SelectMulti', 'Approve', 'UnApprove', 'Public', 'UnPublic'];
                break;
            }
            case 'data/meeting-info': {
                controlDefault = ['Save'];
                break;
            }
            case 'data/schedule': {
                controlDefault = ['AddNew', 'Save'];
                break;
            }
            case 'm/unit': {
                controlDefault.push('ReOpenRp');
                break;
            }
            case 'data/document-meeting': {
                controlDefault = ['AddNew', 'Edit', 'Delete', 'SaveAll', 'ExportExcel', 'Save', 'ImportExcel', 'SelectMulti'];
                break;
            }
            default: {
                break;
            }
        }

        const dataRequest = {
            PageUrl: pageUrl,
            RoleID: roleID,
            Controls: controlDefault
        };
        const result = await this.appService.doPOST('api/Control/Check', dataRequest);
        const control = {};
        if (result && result.Data && result.Data.length > 0) {
            result.Data.forEach(item => {
                control[item.ID] = item.ActiveFlg;
            });
        }

        return control;
    }

    async getPageName() {
        let pageUrl = this.location.path();
        if (!pageUrl) {
            return null;
        }

        const idx = pageUrl.indexOf('?');
        if (idx >= 0) {
            pageUrl = pageUrl.substring(0, idx);
        }

        pageUrl = pageUrl.substring(1);

        const dataRequest = {
            id: pageUrl
        };
        const result = await this.appService.doGET('api/Page/GetName', dataRequest);
        if (result && result.Status === 1) {
            return result.Data;
        }

        return '';
    }
}
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { AppLanguage } from '../../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../services/app.service';
import { SelectableSettings, PageChangeEvent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { AppSwal } from 'src/app/services/app.swal';
import { IntlService } from '@progress/kendo-angular-intl';
import { Notification } from '../../services/app.notification';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
import { AppGuid } from 'src/app/services/app.guid';
import { NullInjector } from '@angular/core/src/di/injector';
import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';
import * as XLSX from 'xlsx';
import { AppFile } from 'src/app/services/app.file';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppUtils } from 'src/app/services/app.utils';
import { AppComponent } from '../../app.component';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpProgressEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { of, concat } from 'rxjs';
import { delay } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-ask-and-reply-feedback',
  templateUrl: './ask-and-reply-feedback.component.html',
  styleUrls: ['./ask-and-reply-feedback.component.css']
})
export class AskAndReplyFeedbackComponent implements OnInit, OnDestroy {
  //codeimage
  commentInput = null;

  uploadFlag = {
    banner: false,
    image: false,
    video: false,
  };

  user: any;
  loading = false;
  dataComments = [];
  dataCommentSelectableSettings: SelectableSettings;
  dataCommentSort = {
    allowUnsort: true,
    mode: 'multiple'
  };
  public dataCommentFocus = {
    Name: true
  };
  dataCommentSortByField: SortDescriptor[] = [
    // {
    //   field: 'ParentName',
    //   dir: 'asc'
    // }, {
    //   field: 'OrdinalNumber',
    //   dir: 'asc'
    // }
  ];

  //imagesUploadName = "";

  public LBM_NUM_PAGING_SKIP = 0;
  public LBM_NUM_PAGING_TAKE = 50;
  public LBM_NUM_PAGING_BTN = 5;

  dataCommentSkip = this.LBM_NUM_PAGING_SKIP;
  dataCommentPageSize = this.LBM_NUM_PAGING_TAKE;
  dataCommentSelection: number[] = [];
  dataCommentItem: any;
  myInterval: any;
  pageName: any;

  postItem: any;
  infoOpened = false;
  unitOpened = false;

  public buttonCount = this.LBM_NUM_PAGING_BTN;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;

  public commentFlg = false;

  public dataCommentState: State = {
    skip: this.dataCommentSkip,
    take: this.dataCommentSkip + this.dataCommentPageSize,
    filter: {
      logic: 'and',
      filters: []
    },
    //group: [{ field: 'TypeName' }]
  };
  dataCommentGridDataResult: GridDataResult;

  dataFileUrlsGridDataResult: GridDataResult;
  dataFileUrlsSelectableSettings: SelectableSettings;
  dataFileUrlsSkip = this.LBM_NUM_PAGING_SKIP;
  dataFileUrlsPageSize = this.LBM_NUM_PAGING_TAKE;
  dataFileUrlsSelection: number[] = [];

  filesUpload: Array<FileInfo>;
  dataFileUrls: any[];
  public fileSaveUrl: any;

  menuIDs: Array<{ Name: string, ID: number }>;
  statuss: Array<{ Name: string, ID: number }>;

  public uploadSaveUrl = 'saveUrl';
  public uploadRemoveUrl = 'removeUrl';
  public enabled = false;
  public enabledID = false;
  isEnabledSaveAll = false;
  control: any;
  controlDefault = true;
  allowMulti = true;

  searchOption = {
    SearchText: '',
    FromDate: null,
    ToDate: null,
    MenuID: 0,
    Status: 0
  };

  units: Array<{ Name: string, ID: string }>;
  unitFilters: Array<{ Name: string, ID: string }>;
  dataSendItem = {
    UnitID: null
  };

  constructor(
    private translate: TranslateService,
    private language: AppLanguage,
    public appService: AppService,
    private appSwal: AppSwal,
    public intl: IntlService,
    private notification: Notification,
    private guid: AppGuid,
    private file: AppFile,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    private appUtils: AppUtils,
    private appComponent: AppComponent,
    private domSanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
  ) {

    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (params && params.menuID) {
        this.searchOption.MenuID = params.menuID;
      }
      this.initDisplay();
    });

    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
    this.getControl();
    this.setDefault();
    this.language.default();
    this.setSelectableSettings();

    this.allData = this.allData.bind(this);
    this.getPageName();
  }

  ngOnDestroy(): void {
  }

  ngOnInit() {
  }
  async getPageName() {
    this.pageName = await this.appControls.getPageName();
  }
  async getControl() {
    this.control = await this.appControls.getControls(this.user.RoleID);
    this.controlDefault = false;
  }

  setSelectableSettings(): void {

    this.allowMulti = !this.allowMulti;
    this.dataCommentSelectableSettings = {
      checkboxOnly: false,
      mode: this.allowMulti ? 'multiple' : 'single'
    };

    this.dataFileUrlsSelectableSettings = {
      checkboxOnly: false,
      mode: 'single'
    };
  }

  onMenuIDSearchHandleFilter(value) {
    this.onReload();
  }


  onSearchKeyPress(e: any) {
    // if (e.keyCode === 13 && this.searchOption.SearchText) {
    //   this.onSearch();
    // }
  }

  async getComments() {
    this.loading = true;
    const dataRequest = {
      SearchText: this.searchOption.SearchText,
      FromDate: this.searchOption.FromDate ? this.intl.formatDate(new Date(this.searchOption.FromDate), 'yyyy-MM-ddT00:00:00') : null,
      ToDate: this.searchOption.ToDate ? this.intl.formatDate(new Date(this.searchOption.ToDate), 'yyyy-MM-ddT00:00:00') : null,
      MenuID: this.searchOption.MenuID,
      Status: this.searchOption.Status
    };

    const result = await this.appService.doPOST('api/Comment/SearchByReplyUnit', dataRequest);
    if (result && result.Status === 1) {
      this.dataComments = result.Data;
      this.bindComments();
    }
    this.loading = false;
    this.checkSelectionID();
  }

  checkSelectionID() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = this.dataCommentSelection.length - 1; i >= 0; i--) {
      const selectedItem = this.dataComments.find((item) => {
        return item.ID === this.dataCommentSelection[i];
      });
      if (!selectedItem) {
        this.dataCommentSelection.splice(i, 1);
      }
    }
  }

  onClickNotify(e: any) {
    const menuID = e;
    this.searchOption.MenuID = menuID;
    this.onReload();
  }

  setDefault() {
    this.dataCommentItem = {
      IsAdd: true,
      MenuID: this.searchOption.MenuID,
      FullName: null,
      Address: null,
      Phone: null,
      Phone1: null,
      Email: null,
      Title: null,
      PostID: null,
      Description: null,
      ApprovedFlg: null,
      Type: null,
      CreateAt: null,
      FeedbackContent: null,
      FeedbackBy: null,
      FeedbackAt: null,
      OftenFlg: false,
      SendAt: null,
      ReplyUnitID: null,
      ReplyFlg: null,
      ReplyAt: null,
      Note: null,
    };
    this.enabled = true;
    this.enabledID = true;

    this.dataSendItem = {
      UnitID: null
    };
    this.dataCommentSelection = [];
    this.filesUpload = [];
    this.dataFileUrls = [];
    this.bindFileUrls();
    this.fileSaveUrl = `${this.appService.apiRoot}api/Upload/MediaWeb?accountID=${this.user.UserName}&typeData=files`;
  }

  onCommentPageChange(event: PageChangeEvent) {
    this.dataCommentSkip = event.skip;
    this.bindComments();
  }

  async onCommentselectedKeysChange() {

    if (this.dataCommentSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }

    if (this.dataCommentSelection.length > 1) {
      if (this.allowMulti) {
        return;
      }
      this.appSwal.showWarning(this.translate.instant('SelectSingle'), false);
    } else {
      const selectedID = this.dataCommentSelection[0];
      const selectedItem = this.dataComments.find((item) => {
        return item.ID === selectedID;
      });
      selectedItem.IsAdd = false;
      //selectedItem.PublishAt = selectedItem.PublishAt ? new Date(selectedItem.PublishAt) : null;

      this.dataCommentItem = selectedItem;

      // const fileUrl = selectedItem.FileUrl;
      // this.dataFileUrls = [];
      // if (fileUrl) {
      //   this.dataFileUrls.push({
      //     Name: this.getNameByUrl(selectedItem.FileUrl),
      //     Url: selectedItem.FileUrl
      //   });
      // }
      // this.bindFileUrls();

      this.enabled = false;
      this.enabledID = false;


      const dataRequest = {
        id: this.dataCommentItem.PostID
      };

      this.postItem = null;
      // const resultRequestMenu = await this.appService.doGET('api/Post/GetById', dataRequest);
      // if (resultRequestMenu && resultRequestMenu.Status === 1) {
      //   this.postItem = resultRequestMenu.Data;
      // }
    }
  }

  bindComments() {
    this.dataCommentGridDataResult = {
      data: orderBy(this.dataComments, this.dataCommentSortByField),
      total: this.dataComments.length
    };

    this.dataCommentGridDataResult = process(this.dataComments, this.dataCommentState);
  }

  bindFileUrls() {
    this.dataFileUrlsGridDataResult = {
      data: this.dataFileUrls,
      total: this.dataFileUrls.length
    };

    this.dataCommentGridDataResult = process(this.dataComments, this.dataCommentState);
  }

  onCommentsortChange(sort: SortDescriptor[]): void {
    this.dataCommentSortByField = sort;
    this.bindComments();
  }

  public onCommentDataStateChange(state: DataStateChangeEvent): void {
    this.dataCommentSelection = [];
    this.dataCommentState = state;
    this.dataCommentGridDataResult = process(this.dataComments, this.dataCommentState);
  }

  removeEventHandler(index) {
    this.isEnabledSaveAll = false;
    this.onReload();
  }

  doInterval() {
    // this.myInterval = Observable.interval(this.appService.timeInterval).subscribe(() => {
    //   this.onReload();
    // });
  }

  onSearchTextChange(e: any) {
    if (!this.searchOption.SearchText) {
      this.onReload();
    }
  }

  onRemoveSearchText() {
    this.searchOption.SearchText = '';

  }

  async initDisplay() {
    // binh luan,hoi dap, kien nghi
    const resultRequestMenu = await this.appService.doGET('api/Comment/GetRequestMenu', null);
    if (resultRequestMenu && resultRequestMenu.Status === 1) {
      this.menuIDs = resultRequestMenu.Data;
      if (this.menuIDs.length > 0) {
        if (!this.searchOption.MenuID) {
          this.searchOption.MenuID = this.menuIDs[0].ID;
        } else {
          this.onReload();
        }
      }
    }

    const resultUnit = await this.appService.doGET('api/Unit', null);
    if (resultUnit && resultUnit.Status === 1) {
      this.units = resultUnit.Data;
      this.unitFilters = this.units.slice();
    }

    this.statuss = [
      //{ Name: 'Tất cả', ID: -1 },
      { Name: 'Chưa trả lời', ID: 0 },
      { Name: 'Đã trả lời', ID: 1 }
    ];
  }

  onSearch() {
    this.getComments();
    this.isEnabledSaveAll = false;
  }

  onReload() {
    this.setDefault();
    //this.searchOption.SearchText = '';
    this.getComments();
    this.isEnabledSaveAll = false;
  }

  onClearComment() {
    this.setDefault();
  }

  onAllowSelectMulti() {
    this.setSelectableSettings();
  }

  onAddNewComment() {

    this.setDefault();
    this.infoOpened = true;
  }

  async onSaveComment() {
    this.updateComment();
  }

  async onSaveComments() {
    this.appComponent.loading = true;
    const dataRequests = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.dataComments.length; i++) {
      dataRequests.push(this.createDataRequest(this.dataComments[i]));
    }
    const result = await this.appService.doPOST('api/Comment/Saves', dataRequests);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.isEnabledSaveAll = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  createDataRequest(data) {
    const temp = data ? data : this.dataCommentItem;

    // let fileName = '';
    // if (this.dataFileUrls.length > 0) {
    //   fileName = this.dataFileUrls[0].Url;
    // }

    //
    return {
      ID: temp.ID,
      //PublishAt: temp.PublishAt ? this.intl.formatDate(new Date(temp.PublishAt), 'yyyy-MM-ddT00:00:00') : null,

      MenuID: temp.MenuID,
      FullName: temp.FullName,
      Address: temp.Address,
      Phone: temp.Phone,
      Phone1: temp.Phone1,
      Email: temp.Email,
      Title: temp.Title,
      PostID: temp.PostID,
      Description: temp.Description,
      ApprovedFlg: temp.ApprovedFlg,
      Type: temp.Type,
      CreateAt: temp.CreateAt,
      FeedbackContent: temp.FeedbackContent,
      FeedbackBy: temp.FeedbackBy,
      FeedbackAt: temp.FeedbackAt,
      OftenFlg: temp.OftenFlg,
      SendAt: temp.SendAt,
      ReplyUnitID: temp.ReplyUnitID,
      ReplyFlg: true,
      ReplyAt: this.intl.formatDate(new Date(), 'yyyy-MM-ddTHH:mm:ss'),
      Note: temp.Note
    };
  }

  onCloseComment(status: any) {
    this.enabled = false;
    this.enabledID = false;
  }

  onEditComment() {
    this.enabled = true;
    this.enabledID = false;

    this.infoOpened = true;
  }

  async addComment() {
    this.appComponent.loading = true;
    const dataRequest = this.createDataRequest(null);

    const result = await this.appService.doPOST('api/Comment', dataRequest);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.setDefault();
      this.infoOpened = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  async updateComment() {
    this.appComponent.loading = true;
    // tslint:disable-next-line: max-line-length
    const id = this.dataCommentItem.ID;
    const dataRequest = this.createDataRequest(this.dataCommentItem);

    const result = await this.appService.doPUT('api/Comment', dataRequest, { id });
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.setDefault();
      this.infoOpened = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  async onDeleteComment() {
    if (this.dataCommentSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }
    this.appComponent.loading = true;
    const dataRequest = {
      IDList: JSON.stringify(this.dataCommentSelection),
      FlgRevert: false
    };

    const option = await this.appSwal.showWarning(this.translate.instant('AreYouSure'), true);
    if (option) {
      const result = await this.appService.doPOST('api/Comment/Deletes', dataRequest);
      if (result && result.Status === 1) {
        this.notification.showSuccess(result.Msg);
        this.onReload();
        this.onAddNewComment();
        this.enabled = false;
        this.enabledID = false;
        this.dataCommentSelection = [];
        this.allowMulti = false;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
    this.appComponent.loading = false;
  }

  async onSend() {
    this.appComponent.loading = true;
    // tslint:disable-next-line: max-line-length
    const id = this.dataCommentItem.ID;
    const dataRequest = this.createDataRequest(this.dataCommentItem);

    const result = await this.appService.doPOST('api/Comment/SendUnit', dataRequest);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.setDefault();
      this.unitOpened = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  async onOpenSendUnit() {
    this.unitOpened = true;
  }

  getNameByUrl(fileUrl) {
    const nameFile = fileUrl.replace(/^.*[\\\/]/, '');

    return nameFile;
  }

  async onDownloadFile(dataItem: any) {

    const dataRequest = {
      url: dataItem.Url
    };
    const result = await this.appService.doDownload('api/Upload/DownloadByUrl', dataRequest);
    if (result) {
      FileSaver.saveAs(result, dataItem.Name);
    }
  }

  onDeleteFile(dataItem: any) {

    for (let i = this.dataFileUrls.length - 1; i >= 0; i--) {
      if (this.dataFileUrls[i].Url === dataItem.Url) {
        this.dataFileUrls.slice(i, 1);
        break;
      }
    }

    this.bindFileUrls();
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.dataComments, {}).data
    };

    return result;
  }

  async onSelectFileToUpload(e: SelectEvent) {
    if (!e.files || e.files.length <= 0) {
      return false;
    }
    try {
      const fileData = await this.file.readFile(e.files[0].rawFile);
    } catch {
      this.appSwal.showError(e);
    }
  }

  onSuccessFileToUpload(e: any) {
    try {
      this.dataFileUrls = [];
      const k = `${e.response.body.Data.DirMedia}${e.response.body.Data.MediaNm[0]}`;
      this.dataFileUrls.push({
        Name: this.getNameByUrl(k),
        Url: k
      });

      this.bindFileUrls();
    } catch {
      this.appSwal.showError(e);
    }
  }

  onRemoveFileToUpload() {

  }

  onUnitsHandleFilter(value) {
    this.unitFilters = this.units.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }


  onCloseInfo(e) {
    this.infoOpened = false;
    this.setDefault();
  }

  onCloseUnit(e) {
    this.unitOpened = false;
    this.setDefault();
  }
}


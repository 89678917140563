import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { Headers, Http, RequestOptions, Response, HttpModule } from '@angular/http';
import { FormsModule } from '@angular/forms';
import {
  MatPaginatorModule,
  MatProgressBarModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatDatepickerModule
} from '@angular/material';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { FocusModule } from 'angular2-focus';
// import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';

import { AppRouter } from './app.router';
import { AuthenticationService } from './services/authentication.service';
import { TokenInterceptor } from './services/token.interceptor';
import { AuthGuard } from './services/auth.guard';
import { AuthService } from './services/auth.service';
import { AppLanguage } from './services/app.language';

import { UserComponent } from './ad/user/user.component';
import { RoleComponent } from './ad/role/role.component';
import { AppComponent } from './app.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';

import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DateInputModule, DatePickerModule, DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ToolBarModule } from '@progress/kendo-angular-toolbar';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { IntlModule } from '@progress/kendo-angular-intl';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { UploadModule } from '@progress/kendo-angular-upload';
import { DialogModule, DialogsModule } from '@progress/kendo-angular-dialog';
import { PopupModule } from '@progress/kendo-angular-popup';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { EditorModule } from '@progress/kendo-angular-editor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatVideoModule } from 'mat-video';

import { UserRoleComponent } from './ad/user-role/user-role.component';
import { PageComponent } from './ad/page/page.component';
import { ControlComponent } from './ad/control/control.component';
import { AutoNumberComponent } from './ad/auto-number/auto-number.component';
import { ConfigComponent } from './ad/config/config.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UnitComponent } from './m/unit/unit.component';
import { HomepageComponent } from './portal/homepage/homepage.component';
import { PostDetailComponent } from './portal/post-detail/post-detail.component';
import { PostComponent } from './s/post/post.component';
import { PhotoComponent } from './m/photo/photo.component';
import { MenuComponent } from './m/menu/menu.component';
import { AdmiProcComponent } from './portal/admi-proc/admi-proc.component';
import { MultimediaComponent } from './portal/multimedia/multimedia.component';
import { OfficeComponent } from './data/office/office.component';
import { AdProcedureComponent } from './data/ad-procedure/ad-procedure.component';
import { AdmiProcDetailComponent } from './portal/admi-proc-detail/admi-proc-detail.component';
import { TypeComponent } from './m/type/type.component';
import { UserOfficeComponent } from './portal/user-office/user-office.component';
import { UserOfficeDetailComponent } from './portal/user-office-detail/user-office-detail.component';
import { CommentComponent } from './data/comment/comment.component';
import { CommentListComponent } from './portal/comment-list/comment-list.component';
import { PostWardComponent } from './data/post-ward/post-ward.component';
import { PostListComponent } from './portal/post-list/post-list.component';
import { HeaderComponent } from './portal/header/header.component';
import { FooterComponent } from './portal/footer/footer.component';
import { RightComponent } from './portal/right/right.component';
import { VideosComponent } from './portal/videos/videos.component';
import { CommentOftenComponent } from './portal/comment-often/comment-often.component';
import { AskAndReplyComponent } from './data/ask-and-reply/ask-and-reply.component';
import { VideoComponent } from './m/video/video.component';
import { AskAndReplyFeedbackComponent } from './data/ask-and-reply-feedback/ask-and-reply-feedback.component';
import { ServiceOnlineComponent } from './portal/service-online/service-online.component';
import { ManageMultimediaComponent } from './m/manage-multimedia/manage-multimedia.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ScheduleComponent } from './data/schedule/schedule.component';
import { OrganizeComponent } from './data/organize/organize.component';
import { AccountComponent } from './data/account/account.component';
import { PartComponent } from './data/part/part.component';
import { WeeklyReportComponent } from './data/weekly-report/weekly-report.component';
import { WeeklyReportPortalComponent } from './portal/weekly-report-portal/weekly-report-portal.component';
import { OfficeDrtComponent } from './data/office-drt/office-drt.component';
import { OrganizeChartComponent } from './portal/organization/organize-chart/organize-chart.component';
import { OfficeDrt2Component } from './portal/office-drt2/office-drt2.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { CarouselModule } from 'primeng/carousel';
import { ScrollViewModule } from '@progress/kendo-angular-scrollview';
import { MandatesComponent } from './data/mandates/mandates.component';
import { PostResultComponent } from './portal/post-result/post-result.component';
import { SchedulerModule } from '@progress/kendo-angular-scheduler';
import { DataRoomMeetingComponent } from './data/data-room-meeting/data-room-meeting/data-room-meeting.component';
import { DataDeviceRoomMeetingComponent } from './data/data-device-room-meeting/data-device-room-meeting/data-device-room-meeting.component';
import { MeetingComponent } from './data/meeting/meeting.component';
import localeVi from '@angular/common/locales/vi';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeVi);

import '@progress/kendo-angular-intl/locales/de/all';
import '@progress/kendo-angular-intl/locales/es/all';
import '@progress/kendo-angular-intl/locales/vi/all';
import { PortalMeetingComponent } from './portal/portal-meeting/portal-meeting.component';
import { MeetingInfoComponent } from './data/meeting-info/meeting-info.component';
import { NLDNewsComponent } from './portal/nldnews/nldnews.component';
import { LoginAskComponent } from './portal/login-ask/login-ask.component';
import { CreateAccountComponent } from './portal/create-account/create-account.component';
import { ChangeAccountPasswordComponent } from './portal/change-account-password/change-account-password.component';
import { MeetingWeekComponent } from './portal/portal_meeting_week/meeting-week/meeting-week.component';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { WebSocketService } from "src/app/websocket.service";
import { MeetingWeekGridComponent } from './portal/meeting-week-grid/meeting-week-grid.component';
import { MailConfigComponent } from './data/mail-config/mail-config.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import { DatePipe } from '@angular/common';
import { CmtUserComponent } from './ad/cmt-user/cmt-user.component';
import { OrgChartModule } from '@mondal/org-chart';
import { DocumentMeetingComponent } from './data/document-meeting/document-meeting.component';
import { LoadDataAutoComponent } from './ad/load-data-auto/load-data-auto.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    HomeComponent,
    ChangePasswordComponent,
    UserComponent,
    RoleComponent,
    UserRoleComponent,
    PageComponent,
    ControlComponent,
    AutoNumberComponent,
    ConfigComponent,
    DashboardComponent,
    UnitComponent,
    HomepageComponent,
    PostComponent,
    PhotoComponent,
    MenuComponent,
    PostDetailComponent,
    AdmiProcComponent,
    MultimediaComponent,
    OfficeComponent,
    AdProcedureComponent,
    AdmiProcDetailComponent,
    TypeComponent,
    UserOfficeComponent,
    UserOfficeDetailComponent,
    CommentComponent,
    CommentListComponent,
    PostWardComponent,
    PostListComponent,
    HeaderComponent,
    FooterComponent,
    RightComponent,
    VideosComponent,
    CommentOftenComponent,
    AskAndReplyComponent,
    VideoComponent,
    AskAndReplyFeedbackComponent,
    VideoComponent,
    ServiceOnlineComponent,
    ManageMultimediaComponent,
    ScheduleComponent,
    OrganizeComponent,
    AccountComponent,
    PartComponent,
    WeeklyReportComponent,
    WeeklyReportPortalComponent,
    OfficeDrtComponent,
    OrganizeChartComponent,
    OfficeDrt2Component,
    MandatesComponent,
    PostResultComponent,
    DataRoomMeetingComponent,
    DataDeviceRoomMeetingComponent,
    MeetingComponent,
    PortalMeetingComponent,
    MeetingInfoComponent,
    NLDNewsComponent,
    MeetingWeekComponent,
    LoginAskComponent,
    CreateAccountComponent,
    ChangeAccountPasswordComponent,
    MeetingWeekGridComponent,
    MailConfigComponent,
    CmtUserComponent,
    DocumentMeetingComponent,
    LoadDataAutoComponent,
  ],
  imports: [
    BrowserModule,
    ButtonsModule,
    DropDownsModule,
    DateInputModule,
    DateInputsModule,
    DatePickerModule,
    GridModule,
    ExcelModule,
    ExcelExportModule,
    LayoutModule,
    ToolBarModule,
    TooltipModule,
    UploadModule,
    DialogModule,
    DialogsModule,
    PopupModule,
    TreeViewModule,
    ChartsModule,
    EditorModule,
    MatDatepickerModule,
    IntlModule,
    InputsModule,
    NotificationModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientInMemoryWebApiModule,
    HttpModule,
    FormsModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    AngularFontAwesomeModule,
    NgbModule,
    MatVideoModule,
    CKEditorModule,
    RouterModule.forRoot(AppRouter.routes, { useHash: true }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BsDropdownModule.forRoot(),
    FocusModule.forRoot(),
    ModalModule.forRoot(),
    GoogleChartsModule,
    CarouselModule,
    ScrollViewModule,
    SchedulerModule,
    PDFExportModule,
    MatToolbarModule,
    OrgChartModule
  ],
  exports: [BsDropdownModule, TooltipModule, ModalModule],
  providers: [AuthGuard,
    AuthService,
    AppLanguage,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'vi-VN',
    },
    WebSocketService,
    { provide: LOCALE_ID, useValue: 'vi-VN' },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

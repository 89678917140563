import { Component, OnInit, HostListener } from '@angular/core';
import { AppService } from '../../services/app.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.css']
})
export class VideosComponent implements OnInit {

  isSticky: boolean = false;
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset > 0;
  }
  constructor(public appService: AppService, private route: ActivatedRoute) { }

  imagesGrid: any;
  imagesSlide: any;
  videos: any;

  isImagePage: boolean;

  srcGrid = '../assets/images/icon-grid-active.png';
  srcSlide = '../assets/images/icon-slide-inactive.png';

  menuID;

  isGridMode = true;

  ngOnInit() {
    this.menuID = this.route.snapshot.queryParamMap.get('menuID');

    this.isImagePage = false;
    this.getVideos();
  }

  imgGridClicked() {
    this.srcGrid = '../assets/images/icon-grid-active.png';
    this.srcSlide = '../assets/images/icon-slide-inactive.png';

    this.isGridMode = true;
  }
  imgSlideClicked() {
    this.srcGrid = '../assets/images/icon-grid-inactive.png';
    this.srcSlide = '../assets/images/icon-slide-active.png';

    this.isGridMode = false;
  }

  async getImages() {
    const dataRequest = {
      menuID: this.menuID
    };

    const result = await this.appService.doGET('api/Multimedia/Image', dataRequest);
    if (result) {
      this.imagesGrid = result.Data.GridViewData;
      this.imagesSlide = result.Data.SlideViewData;
    }
  }

  async getVideos() {
    const dataRequest = {
      menuID: this.menuID
    };

    const result = await this.appService.doGET('api/Multimedia/Video', dataRequest);
    if (result) {
      this.videos = result.Data;
    }
  }

}

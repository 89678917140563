import { Component, OnInit, Output, Input, EventEmitter, OnDestroy, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { AppService } from '../../services/app.service';
import { AppComponent } from '../../app.component';
import { AppSwal } from '../../services/app.swal';
import { ResourceLoader } from '@angular/compiler';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Output() searchChange = new EventEmitter<string>();
  @Output() reloadPosts = new EventEmitter<string>();
  @Input() isSticky = false;
  user: any;
  showToggleUser = false;
  uploadImageOpened = false;
  menus: any;
  check: number;
  searchOption = {
    searchString: ''
  };
  acrive = true;
  selectedID: '';
  searchTxt: '';

  isCollapsed = false;
  isDropDown = false;

  languageName = '';

  currentDate = new Date();

  BaneerImg: any;
  BannerMedalPhoto: any;
  BannerLogoPhoto: any;
  BannerRightPhoto: any;

  references: Array<{ Name: string, Content: string, NumPost: number }>;
  referenceFilter: Array<{ Name: string, Content: string, NumPost: number }>;

  ngOnInit(): void {
    this.check = 0;
  }

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private appService: AppService,
    private appComponent: AppComponent,
    private appSwal: AppSwal,
    private activatedRoute: ActivatedRoute,
  ) {
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
    this.activatedRoute.url.subscribe(activeUrl =>{
      if (activeUrl && activeUrl.length > 0){
        if (activeUrl[1].path == 'post-result'){
          this.activatedRoute.queryParams.subscribe((params: any) => {
            if (params) {            
              this.searchTxt = params.searchparam;
            }          
          });
        }
      }      
    });
    
    this.getMenu();
    // this.getReference();
    this.getLanguageName();
    // this.getBannerImg();
  }

  async getMenu() {

    const result = await this.appService.doGET('api/Menu/GetMenu', null);
    if (result && result.Status === 1 && result.Data) {
      this.menus = result.Data.Pages;
      if (result.Data.References){
        this.references = result.Data.References.filter(r => r.NumPost > 0);;
        for (let i = 0; i < this.references.length; i ++){
          this.references[i].Content = this.references[i].Name +"("+ this.references[i].NumPost + ")";
        }
        this.referenceFilter = this.references.slice();
        if (result.Data.BannerImage){
          for(let i = 0; i < result.Data.BannerImage.length; i ++){
            if(result.Data.BannerImage[i].Item){
              switch (result.Data.BannerImage[i].Item.BannerType) {
                case 5:
                  this.BannerMedalPhoto = this.appService.apiRoot + result.Data.BannerImage[i].Item.FileUrl;
                  break;
                case 6: 
                  this.BannerLogoPhoto = this.appService.apiRoot + result.Data.BannerImage[i].Item.FileUrl;
                  break;
                case 7: 
                  this.BannerRightPhoto = this.appService.apiRoot + result.Data.BannerImage[i].Item.FileUrl;
                  break;
                default:
                  break;
              }
            }
          }
        }
        
      }      
    } else {
      this.appSwal.showError(result.Msg);
    }
  }

  onChangePasswordPage($event) {
    this.router.navigate(['change-password']);
  }

  onToggleUser() {
    this.showToggleUser = !this.showToggleUser;
  }

  onLogOut() {
    this.authenticationService.doSignout();
    this.router.navigate(['portal']);
    window.location.reload();
  }

  onClickMenu(menu, level) {
    // if((typeof(childrens) != "undefined") && childrens != null && childrens.length > 0) {
    //   this.selectedID = pageUrl;
    // } else {
    //   this.router.navigate([pageUrl]);
    // }
    this.acrive = true;
    let drop = document.getElementsByClassName('dropdown-menu') as HTMLCollectionOf<HTMLElement>;
    var flag = false;
    // if ((menu.Childrens && menu.Childrens.length > 0)) {
    //   return;
    // }
    if (menu.PageID) {
      if (menu.PageID.indexOf('http') >= 0) {
        window.open(menu.PageID, '_blank');
      } else {
        if (menu.ID == '3c8ecfb0-76ba-4629-a022-89777d156935') {
          window.location.href = '';
        } else {
          this.changeMenu();
          if (drop.length != 0) {
            for (let i = 0; i < drop.length; i++) {
              drop[i].style.visibility = "hidden";
            }
            flag = true;
          }
          try {
            this.router.navigate([menu.PageID], { queryParams: { title: menu.UrlPath } });
            if (menu.PageID.indexOf('portal/post-list')) {
              this.reloadPosts.emit(menu.UrlPath);
            }
          } catch{
            this.router.navigate([menu.PageID], { queryParams: { title: menu.UrlPath } });
          }
        }
      }
    }
    // if(flag == true){
    //   for(let i = 0; i < drop.length ; i++){
    //     drop[i].style.visibility = "visibility";
    //   }
    //   flag = true;
    // }
    this.acrive = false;
    this.getMenu();
  }

  onMouseOver(ID, childrens) {
    if ((typeof (childrens) != "undefined") && childrens != null && childrens.length > 0) {
      this.selectedID = ID;
    } else {
      this.selectedID = "";
    }
  }

  onUploadImageClose(status) {
    this.uploadImageOpened = false;
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
  }

  onUploadImage() {
    this.showToggleUser = false;
    this.uploadImageOpened = true;
  }

  onSearchKeyPress(e: any) {
    if (e.keyCode === 13) {
      this.onSearch();
    }
  }

  onSearch() {
    const searchString = this.searchTxt;
    this.router.navigate(['portal/post-result'], { queryParams: { searchparam: searchString } });
    this.emitSearchChange(searchString);
  }

  emitSearchChange(searchString) {
    this.searchChange.emit(searchString);
  }

  async changeMenu() {
    this.isCollapsed = !this.isCollapsed;
    var k = 0;
  }

  onGoHome() {
    {
      this.router.navigate(['portal']);
      // window.location.href = '';
    }
  }


  async changeLanguage(languageID) {
    if (this.languageName != languageID) {
      this.languageName = languageID;
      localStorage.setItem('currentLanguage', `${languageID}`);
      window.location.reload();
    }
  }

  getLanguageName() {
    let lang = localStorage.getItem('currentLanguage');
    if (lang == null || lang == undefined) {
      lang = "vi-VN";
    }
    this.languageName = lang;
  }

  getDayInWeek() {
    var day_name = '';
    switch (this.currentDate.getDay()) {
      case 0:
        day_name = "Chủ nhật";
        break;
      case 1:
        day_name = "Thứ hai";
        break;
      case 2:
        day_name = "Thứ ba";
        break;
      case 3:
        day_name = "Thứ tư";
        break;
      case 4:
        day_name = "Thứ năm";
        break;
      case 5:
        day_name = "Thứ sáu";
        break;
      case 6:
        day_name = "Thứ bảy";
    }
    return day_name
  }

  // async getBannerImg() {
  //   const resultImage = await this.appService.doGET('api/File/GetBannerImg', null);
  //   if (resultImage && resultImage.Status === 1) {
  //     for(let i = 0; i < resultImage.Data.length; i ++){
  //       if(resultImage.Data[i].Item){
  //         switch (resultImage.Data[i].Item.BannerType) {
  //           case 5:
  //             this.BannerMedalPhoto = this.appService.apiRoot + resultImage.Data[i].Item.FileUrl;
  //             break;
  //           case 6: 
  //             this.BannerLogoPhoto = this.appService.apiRoot + resultImage.Data[i].Item.FileUrl;
  //             break;
  //           case 7: 
  //             this.BannerRightPhoto = this.appService.apiRoot + resultImage.Data[i].Item.FileUrl;
  //             break;
  //           default:
  //             break;
  //         }
  //       }
  //     }            
  //   }
  // }

  referenceHandleFilter(value) {
    this.referenceFilter = this.references.filter((s) => s.Content.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  // async getReference() {
  //   const result = await this.appService.doGET('api/Type/GetReference', null);
  //   if (result && result.Data) {
  //     this.references = result.Data.filter(r => r.NumPost > 0);;
  //     for (let i = 0; i < this.references.length; i ++){
  //       this.references[i].Content = this.references[i].Name +"("+ this.references[i].NumPost + ")";
  //     }
  //     this.referenceFilter = this.references.slice();
  //   }
  // }  
}

import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';
import { AppLanguage } from '../../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../services/app.service';
import { SelectableSettings, PageChangeEvent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { AppSwal } from 'src/app/services/app.swal';
import { IntlService } from '@progress/kendo-angular-intl';
import { Notification } from '../../services/app.notification';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
import { AppGuid } from 'src/app/services/app.guid';
import { NullInjector } from '@angular/core/src/di/injector';
import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';
import * as XLSX from 'xlsx';
import { AppFile } from 'src/app/services/app.file';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppUtils } from 'src/app/services/app.utils';
import { AppComponent } from '../../app.component';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpProgressEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { of, concat } from 'rxjs';
import { delay } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import { ConfigComponent } from 'src/app/ad/config/config.component';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
// import CKFinder from '@ckeditor/ckeditor5-ckfinder/src/ckfinder';
declare var CKEDITOR: any;

@Component({
  selector: 'app-mail-config',
  templateUrl: './mail-config.component.html',
  styleUrls: ['./mail-config.component.css']
})
export class MailConfigComponent implements OnInit {

  isSticky = false
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 140;
  }

  public uploadFlgDefault = {
    banner: false,
    image: false,
    video: false,
  };

  public Editor = DecoupledEditor;

  uploadFlg = this.uploadFlgDefault;
  public websiteDefault = null;
  websiteLink = this.websiteDefault;
  public bannerDef = '../../assets/images/default-01.png';
  public imageDef = '../../assets/images/default-02.png';
  public imgSaveUrl: any;
  imgImageDefault: any;
  imgImageMain: any;

  user: any;
  loading = false;
  dataPosts = [];
  dataPostSelectableSettings: SelectableSettings;
  dataPostSort = {
    allowUnsort: true,
    mode: 'multiple'
  };
  public dataPostFocus = {
    Name: true
  };
  dataPostSortByField: SortDescriptor[] = [];

  dataLogPostSelectableSettings: SelectableSettings;
  logPostOpened = false;
  reViewOpened = false;
  postTemp = {
    MenuName: '',
    Title: '',
    Description: '',
    ImageUrls: ''
  };

  public LBM_NUM_PAGING_SKIP = 0;
  public LBM_NUM_PAGING_TAKE = 50;
  public LBM_NUM_PAGING_BTN = 5;

  dataPostSkip = this.LBM_NUM_PAGING_SKIP;
  dataPostPageSize = this.LBM_NUM_PAGING_TAKE;
  dataPostSelection: number[] = [];
  dataPostItem: any;
  myInterval: any;
  infoOpened = false;
  isReviewDialog = false;

  public dataPostState: State = {
    skip: this.dataPostSkip,
    take: this.dataPostSkip + this.dataPostPageSize,
    filter: {
      logic: 'and',
      filters: []
    },
  };



  dataPostGridDataResult: GridDataResult;

  dataFileUrlsGridDataResult: GridDataResult;
  dataFileUrlsSelectableSettings: SelectableSettings;
  dataFileUrlsSkip = this.LBM_NUM_PAGING_SKIP;
  dataFileUrlsPageSize = this.LBM_NUM_PAGING_TAKE;
  dataFileUrlsSelection: number[] = [];

  dataLogPostGridDataResult: GridDataResult;
  dataLogPosts: any[];

  dataMenuID: any[];
  pageName: any;

  menus: Array<{ Name: string, ID: string }>;
  menusFilter: Array<{ Name: string, ID: string }>;
  menusFilterSearch: Array<{ Name: string, ID: string }>;
  filesUpload: Array<FileInfo>;
  dataFileUrls: any[];
  public fileSaveUrl: any;

  public uploadSaveUrl = 'saveUrl';
  public uploadRemoveUrl = 'removeUrl';
  public enabled = false;
  public enabledID = false;
  isEnabledSaveAll = false;
  control: any;
  controlDefault = true;
  allowMulti = true;

  searchOption = {
    SearchText: '',
    PortalId: 14,
    MenuID: ''
  };

  IDValue: any;

  portalIds: any[];
  ckeConfig: any;
  editorOptions = { theme: 'vs-dark', language: 'javascript' };
  //CKEDITOR: any;

  constructor(
    private translate: TranslateService,
    private language: AppLanguage,
    public appService: AppService,
    private appSwal: AppSwal,
    public intl: IntlService,
    private notification: Notification,
    private guid: AppGuid,
    private file: AppFile,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    private appUtils: AppUtils,
    private appComponent: AppComponent,
    private domSanitizer: DomSanitizer,
  ) {
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
    this.imgSaveUrl = `${this.appService.apiRoot}api/Upload/MediaWeb?accountID=${this.user.UserName}&typeData=image`;
    this.getControl();
    this.setDefault();
    this.language.default();
    this.setSelectableSettings();
    this.allData = this.allData.bind(this);
    this.getPageName();
    this.onReload();
    this.getIDConfig();
  }

  ngOnDestroy(): void {
    // if (this.myInterval) { this.myInterval.unsubscribe(); }
  }


  ngOnInit() {
    this.ckeConfig = {
      allowedContent: true,
      extraPlugins: 'divarea',
      forcePasteAsPlainText: true
    };
  }

  async getIDConfig(){
    const result = await this.appService.doGET('api/Enum/GetEConfig', null);
    if(result || result.Status === 1){
      this.IDValue = result.Data;
    }
  }

  onReady(eventData) {
    eventData.plugins.get('FileRepository').createUploadAdapter = function (loader) {
      console.log('loader : ', loader)
      console.log(btoa(loader.file));
      return new UploadAdapter(loader);
    };
    eventData.ui.getEditableElement().parentElement.insertBefore(
      eventData.ui.view.toolbar.element,
      eventData.ui.getEditableElement()
    );
  }
  public ckeditorContent: string = "";

  getData() {
    console.log(CKEDITOR.instances.content.getData());
  }

  async getPageName() {
    this.pageName = await this.appControls.getPageName();
  }

  async getControl() {
    this.control = await this.appControls.getControls(this.user.RoleID);
    this.controlDefault = false;
  }

  setSelectableSettings(): void {

    this.allowMulti = !this.allowMulti;
    this.dataPostSelectableSettings = {
      checkboxOnly: false,
      mode: this.allowMulti ? 'multiple' : 'single'
    };

    this.dataFileUrlsSelectableSettings = {
      checkboxOnly: false,
      mode: 'single'
    };
  }

  onMenusSearchHandleFilter(value) {
    this.menusFilterSearch = this.menus.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  onSearchKeyPress(e: any) {
    if (e.keyCode === 13 && this.searchOption.SearchText) {
      this.onSearch();
    }
  }

  async getPosts() {
    this.loading = true;
    const dataRequest = {
      searchText: this.searchOption.SearchText
    };

    const result = await this.appService.doGET('api/MailConfig/getMail', dataRequest);

    if (result) {
      this.dataPosts = result.Data;
      this.bindPosts();
    }
    this.loading = false;
    this.checkSelectionID();
  }

  onTabChanged(e) {
    if (e.index === 0) {
      this.onReload();
    }
  }

  checkSelectionID() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = this.dataPostSelection.length - 1; i >= 0; i--) {
      const selectedItem = this.dataPosts.find((item) => {
        return item.ID === this.dataPostSelection[i];
      });
      if (!selectedItem) {
        this.dataPostSelection.splice(i, 1);
      }
    }
  }

  setDefault() {
    this.dataPostItem = {
      IsAdd: true,
      Title: null,
      Description: null,
      CreateAt: new Date(),
      UpdateAt: new Date(),
    };
    this.enabled = true;
    this.enabledID = true;
  }

  onPostPageChange(event: PageChangeEvent) {
    this.dataPostSkip = event.skip;
    this.bindPosts();
  }

  async onPostSelectedKeysChange() {

    if (this.dataPostSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }

    if (this.dataPostSelection.length > 1) {
      if (this.allowMulti) {
        return;
      }
      this.appSwal.showWarning(this.translate.instant('SelectSingle'), false);
    } else {
      const selectedID = this.dataPostSelection[0];
      const selectedItem = this.dataPosts.find((item) => {
        return item.ID === selectedID;
      });
      selectedItem.IsAdd = false;
      this.dataPostItem = selectedItem;
      this.enabled = false;
      this.enabledID = false;
    }
  }

  bindPosts() {
    this.dataPostGridDataResult = {
      data: orderBy(this.dataPosts, this.dataPostSortByField),
      total: this.dataPosts.length
    };

    this.dataPostGridDataResult = process(this.dataPosts, this.dataPostState);
  }

  bindFileUrls() {
    this.dataFileUrlsGridDataResult = {
      data: this.dataFileUrls,
      total: this.dataFileUrls.length
    };

    this.dataPostGridDataResult = process(this.dataPosts, this.dataPostState);
  }

  bindLogPosts() {
    this.dataLogPostGridDataResult = {
      data: this.dataLogPosts,
      total: this.dataLogPosts.length
    };

    this.dataPostGridDataResult = process(this.dataPosts, this.dataPostState);
  }

  onPostSortChange(sort: SortDescriptor[]): void {
    this.dataPostSortByField = sort;
    this.bindPosts();
  }

  public onPostDataStateChange(state: DataStateChangeEvent): void {
    this.dataPostSelection = [];
    this.dataPostState = state;
    this.dataPostGridDataResult = process(this.dataPosts, this.dataPostState);
  }

  removeEventHandler(index) {
    this.isEnabledSaveAll = false;
    this.imgImageMain[index] = '',
      this.onReload();
  }

  onSearchTextChange(e: any) {
    if (!this.searchOption.SearchText) {
      this.onReload();
    }
  }

  onRemoveSearchText() {
    this.searchOption.SearchText = '';

  }


  onSearch() {
    this.getPosts();
    this.isEnabledSaveAll = false;
  }

  onReload() {
    this.searchOption.SearchText = '';
    this.getPosts();
    this.isEnabledSaveAll = false;
  }

  onClearPost() {
    this.setDefault();
  }

  onAllowSelectMulti() {
    this.setSelectableSettings();
  }

  onAddNewPost() {
    this.setDefault();
    this.infoOpened = true;
  }

  async onSavePost() {
    //this.addPost();
    if (this.dataPostItem.IsAdd) { this.addPost(); } else { this.updatePost(); }
  }


  onCloseReViewPost(e) {
    this.reViewOpened = false;
    if (this.isReviewDialog) {
      this.infoOpened = true;
    } else {
      this.infoOpened = false;
    }
  }

  onReviewPost() {
    this.reViewOpened = true;
    this.infoOpened = false;
    this.getPostTemp();
  }

  onReviewPostDialog() {
    this.reViewOpened = true;
    this.infoOpened = false;
    this.isReviewDialog = true;
    this.getPostTemp();
  }
  isUploadFromServe = false;

  getPostTemp() {
    
    this.postTemp.Title = this.dataPostItem.Title;
    this.postTemp.Description = this.dataPostItem.Description;

    var content = this.postTemp.Description;
    var require = content.indexOf("<oembed");
    if (require >= 0) {
      while (content.indexOf("<oembed") >= 0) {
        var st_Index = content.indexOf("<oembed");
        var end_Index = content.indexOf("</oembed>")
        var sub1 = content.substring(st_Index, end_Index + "</oembed>".length);

        var st_key = sub1.indexOf("?v="); var end_key = sub1.indexOf('">');
        var key = sub1.substring(st_key + "?v=".length, end_key);

        var replace = '<iframe width="100%" height="470px" src="//www.youtube.com/embed/' + key + '" frameborder="0" allowfullscreen></iframe>';

        this.postTemp.Description = this.postTemp.Description.replace(sub1, replace);
        content = this.postTemp.Description;
      }
    }


    if (this.postTemp.ImageUrls.indexOf("Multimedia") >= 0) {
      this.isUploadFromServe = true;
    }
    else {
      this.isUploadFromServe = false;
    }

  }

  createDataRequest(data) {
    const temp = data ? data : this.dataPostItem;

    return {
      ID: temp.ID,
      Value: temp.Value,
      Description: temp.Description,
      SercurityValue: temp.SercurityValue
    };
  }

  onClosePost(status: any) {
    this.enabled = false;
    this.enabledID = false;
  }

  onEditPost() {
    this.enabled = true;
    this.enabledID = false;
    this.infoOpened = true;
  }

  parseToArrayTxt(arrayObject) {
    var arrayTxt = "";
    for (var i = 0; i < arrayObject.length; i++) {
      if (arrayObject[i] != "" && arrayObject[i] != null && arrayObject[i] != this.imageDef) {
        if (arrayTxt == "") {
          arrayTxt = "[" + "\"" + arrayObject[i] + "\"";
        } else {
          arrayTxt = arrayTxt + "," + "\"" + arrayObject[i] + "\"";
        }
      }
    }

    if (arrayTxt != "") {
      arrayTxt = arrayTxt + "]";
    }

    return arrayTxt;
  }

  async addPost() {
    this.appComponent.loading = true;
    const dataRequest = this.createDataRequest(null);
    const result = await this.appService.doPOST('api/MailConfig/addMail', dataRequest);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.onAddNewPost();
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  async updatePost() {
    this.appComponent.loading = true;
    const id = this.dataPostItem.ID;
    const dataRequest = this.createDataRequest(null);
    const result = await this.appService.doPUT('api/MailConfig/updateMail', dataRequest, { id });
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }
  async onDeletePost() {
    if (this.dataPostSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }
    this.appComponent.loading = true;
    const dataRequest = {
      IDList: JSON.stringify(this.dataPostSelection),
      FlgRevert: false
    };

    const option = await this.appSwal.showWarning(this.translate.instant('AreYouSure'), true);
    if (option) {
      const result = await this.appService.doPOST('api/Post/Deletes', dataRequest);
      if (result && result.Status === 1) {
        this.notification.showSuccess(result.Msg);
        this.onReload();
        //this.onAddNewPost();
        this.enabled = false;
        this.enabledID = false;
        this.dataPostSelection = [];
        this.allowMulti = false;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
    this.appComponent.loading = false;
  }

  getNameByUrl(fileUrl) {
    const nameFile = fileUrl.replace(/^.*[\\\/]/, '');
    // const lastIdx = fileUrl.lastIndexOf('/');
    // if (lastIdx > 0) {
    //   nameFile = fileUrl.substring(lastIdx + 1);
    // }

    return nameFile;
  }

  async onDownloadFile(dataItem: any) {

    const dataRequest = {
      url: dataItem.Url
    };
    const result = await this.appService.doDownload('api/Upload/DownloadByUrl', dataRequest);
    if (result) {
      FileSaver.saveAs(result, dataItem.Name);
    }
  }

  onDeleteFile(dataItem: any) {

    for (let i = this.dataFileUrls.length - 1; i >= 0; i--) {
      if (this.dataFileUrls[i].Url === dataItem.Url) {
        this.dataFileUrls.splice(i, 1);
        break;
      }
    }

    this.bindFileUrls();
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.dataPosts, {}).data
    };

    return result;
  }

  async onSelectFileToUpload(e: SelectEvent) {
    if (!e.files || e.files.length <= 0) {
      return false;
    }
    try {
      const fileData = await this.file.readFile(e.files[0].rawFile);
    } catch {
      this.appSwal.showError(e);
    }
  }

  onSuccessFileToUpload(e: any) {
    try {
      const k = `${e.response.body.Data.DirMedia}${e.response.body.Data.MediaNm[0]}`;
      this.dataFileUrls.push({
        Name: this.getNameByUrl(k),
        Url: k
      });

      this.bindFileUrls();
    } catch {
      this.appSwal.showError(e);
    }
  }

  onRemoveFileToUpload() {

  }
  onCloseInfo(e) {
    this.setDefault();
    this.infoOpened = false;
  }

}

export class UploadAdapter {
  private loader;
  constructor(loader: any) {
    this.loader = loader;
  }

  upload() {
    return this.loader.file
      .then(file => new Promise((resolve, reject) => {
        var myReader = new FileReader();
        myReader.onloadend = (e) => {
          resolve({ default: myReader.result });
        }
        myReader.readAsDataURL(file);
      }));
  };

}
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';
import { AppLanguage } from '../../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../services/app.service';
import { SelectableSettings, PageChangeEvent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { AppSwal } from 'src/app/services/app.swal';
import { IntlService } from '@progress/kendo-angular-intl';
import { Notification } from '../../services/app.notification';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
import { AppGuid } from 'src/app/services/app.guid';
import { NullInjector } from '@angular/core/src/di/injector';
import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';
import * as XLSX from 'xlsx';
import { AppFile } from 'src/app/services/app.file';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppUtils } from 'src/app/services/app.utils';
import { AppComponent } from '../../app.component';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpProgressEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { of, concat } from 'rxjs';
import { delay } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-post-list',
  templateUrl: './post-list.component.html',
  styleUrls: ['./post-list.component.css']
})
export class PostListComponent implements OnInit {

  isSticky: boolean = false;
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset > 0;
  }
  //codeimage
  postNewInput = null;

  uploadFlag = {
    banner: false,
    image: false,
    video: false,
  };

  user: any;
  loading = false;
  dataPostNews = [];
  dataPostNewsLatest = [];
  dataPostNewDetail = null;
  dataPostNewSelectableSettings: SelectableSettings;
  dataPostNewSort = {
    allowUnsort: true,
    mode: 'multiple'
  };
  public dataPostNewFocus = {
    Name: true
  };
  dataPostNewSortByField: SortDescriptor[] = [
    // {
    //   field: 'ParentName',
    //   dir: 'asc'
    // }, {
    //   field: 'OrdinalNumber',
    //   dir: 'asc'
    // }
  ];

  //imagesUploadName = "";

  public LBM_NUM_PAGING_SKIP = 0;
  public LBM_NUM_PAGING_TAKE = 50;
  public LBM_NUM_PAGING_BTN = 5;

  dataPostNewSkip = this.LBM_NUM_PAGING_SKIP;
  dataPostNewPageSize = this.LBM_NUM_PAGING_TAKE;
  dataPostNewSelection: number[] = [];
  dataPostNewItem: any;
  myInterval: any;

  public buttonCount = this.LBM_NUM_PAGING_BTN;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;

  public postNewFlg = false;

  public dataPostNewState: State = {
    skip: this.dataPostNewSkip,
    take: this.dataPostNewSkip + this.dataPostNewPageSize,
    filter: {
      logic: 'and',
      filters: []
    },
    //group: [{ field: 'TypeName' }]
  };
  dataPostNewGridDataResult: GridDataResult;

  dataFileUrlsGridDataResult: GridDataResult;
  dataFileUrlsSelectableSettings: SelectableSettings;
  dataFileUrlsSkip = this.LBM_NUM_PAGING_SKIP;
  dataFileUrlsPageSize = this.LBM_NUM_PAGING_TAKE;
  dataFileUrlsSelection: number[] = [];
  mySubscription: any;

  filesUpload: Array<FileInfo>;
  dataFileUrls: any[];
  public fileSaveUrl: any;

  public uploadSaveUrl = 'saveUrl';
  public uploadRemoveUrl = 'removeUrl';
  public enabled = false;
  public enabledID = false;
  isEnabledSaveAll = false;
  control: any;
  controlDefault = true;
  allowMulti = true;
  isReplyPage = false;
  tabIndex = 0;

  searchOption = {
    SearchText: '',
    FromDate: null,
    ToDate: null,
    UrlPath: 0,
    Status: 0
  };

  languageName = '';

  languages: Array<any> = [{
    id: 0,
    culture: 'vi-VN',
    text: 'Tiếng Việt'
  }, {
    id: 1,
    culture: 'en-US',
    text: 'English'
  }];

  public imageDef = '../../assets/images/default-02.png';
  listPostViewMore: any[];
  listPostRelates: any[];
  dataPostNewsTemp = [1, 2, 3,4,5,6,7,8];

  constructor(
    private translate: TranslateService,
    private language: AppLanguage,
    public appService: AppService,
    private appSwal: AppSwal,
    public intl: IntlService,
    private notification: Notification,
    private guid: AppGuid,
    private file: AppFile,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    private appUtils: AppUtils,
    private appComponent: AppComponent,
    private domSanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {

    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (params && params.title) {
        this.searchOption.UrlPath = params.title;
        this.onReload();
      }
    });
    //window.location.reload();
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
    this.setSelectableSettings();
    this.getLanguageName();
    //this.onChangeLanguage('vi-VN');
    //this.onReload();

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.router.navigated = false;
      }
    });
  }



  ngOnInit() {
  }


  ngOnDestroy() {
    if (this.mySubscription) {
      this.mySubscription.unsubscribe();
    }
  }


  async getControl() {
    this.control = await this.appControls.getControls(this.user.RoleID);
    this.controlDefault = false;
  }

  setSelectableSettings(): void {

    this.allowMulti = !this.allowMulti;
    this.dataPostNewSelectableSettings = {
      checkboxOnly: false,
      mode: this.allowMulti ? 'multiple' : 'single'
    };

    this.dataFileUrlsSelectableSettings = {
      checkboxOnly: false,
      mode: 'single'
    };
  }


  onSearchKeyPress(e: any) {
    // if (e.keyCode === 13 && this.searchOption.SearchText) {
    //   this.onSearch();
    // }
  }
  isUploadFromServe = false;
  async getPostNews() {
    this.loading = true;
    const dataRequest = {
      urlPath: this.searchOption.UrlPath,
    };

    const result = await this.appService.doGET('api/Post/GetPostList', dataRequest);
    if (result && result.Status === 1) {
      this.dataPostNews = result.Data.PostNews;
      this.dataPostNewsTemp = null;
      this.listPostViewMore = result.Data.PostViewMores;
      this.listPostRelates = result.Data.PostRelates;     
      this.bindPostNews();
    }
    this.loading = false;
    this.checkSelectionID();
  }

  checkSelectionID() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = this.dataPostNewSelection.length - 1; i >= 0; i--) {
      const selectedItem = this.dataPostNews.find((item) => {
        return item.ID === this.dataPostNewSelection[i];
      });
      if (!selectedItem) {
        this.dataPostNewSelection.splice(i, 1);
      }
    }
  }

  onPostNewPageChange(event: PageChangeEvent) {
    this.dataPostNewSkip = event.skip;
    this.bindPostNews();
  }

  bindPostNews() {
    this.dataPostNewGridDataResult = {
      data: orderBy(this.dataPostNews, this.dataPostNewSortByField),
      total: this.dataPostNews.length
    };

    this.dataPostNewGridDataResult = process(this.dataPostNews, this.dataPostNewState);
  }

  onPostNewsortChange(sort: SortDescriptor[]): void {
    this.dataPostNewSortByField = sort;
    this.bindPostNews();
  }

  public onPostNewDataStateChange(state: DataStateChangeEvent): void {
    this.dataPostNewSelection = [];
    this.dataPostNewState = state;
    this.dataPostNewGridDataResult = process(this.dataPostNews, this.dataPostNewState);
  }

  onReload() {
    this.getPostNews();
  }

  onReloadPost(e: any) {
    let drop = document.getElementsByClassName('dropdown-menu') as HTMLCollectionOf<HTMLElement>;
    // for(let i = 0; i < drop.length ; i++){
    //   drop[i].style.visibility = null;
    // }
    this.searchOption.UrlPath = e;
    this.getPostNews();
  }

  onClickPostNew(dataItem) {
    this.router.navigate(['portal/post-detail'], { queryParams: { title: dataItem.UrlPath, menuId: dataItem.MenuID } });
  }

  onChangeLanguage(e: any) {
    this.languageName = e;
  }

  getLanguageName() {
    let lang = localStorage.getItem('currentLanguage');
    if (lang == null || lang == undefined) {
      lang = "vi-VN";
    }
    this.languageName = lang;
  }
}


import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { Routes } from '@angular/router';
import { AuthGuard } from './services/auth.guard';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { UserComponent } from './ad/user/user.component';
import { RoleComponent } from './ad/role/role.component';
import { UserRoleComponent } from './ad/user-role/user-role.component';
import { PageComponent } from './ad/page/page.component';
import { ControlComponent } from './ad/control/control.component';
import { AutoNumberComponent } from './ad/auto-number/auto-number.component';
import { ConfigComponent } from './ad/config/config.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UnitComponent } from './m/unit/unit.component';
import { HomepageComponent } from './portal/homepage/homepage.component';
import { PostComponent } from './s/post/post.component';
import { PhotoComponent } from './m/photo/photo.component';
import { MenuComponent } from './m/menu/menu.component';
import { PostDetailComponent } from './portal/post-detail/post-detail.component';
import { AdmiProcComponent } from './portal/admi-proc/admi-proc.component';
import { MultimediaComponent } from './portal/multimedia/multimedia.component';
import { OfficeComponent } from './data/office/office.component';
import { AdProcedureComponent } from './data/ad-procedure/ad-procedure.component';
import { AdmiProcDetailComponent } from './portal/admi-proc-detail/admi-proc-detail.component';
import { TypeComponent } from './m/type/type.component';
import { UserOfficeComponent } from './portal/user-office/user-office.component';
import { UserOfficeDetailComponent } from './portal/user-office-detail/user-office-detail.component';
import { CommentComponent } from './data/comment/comment.component';
import { CommentListComponent } from './portal/comment-list/comment-list.component';
import { PostWardComponent } from './data/post-ward/post-ward.component';
import { PostListComponent } from './portal/post-list/post-list.component';
import { VideosComponent } from './portal/videos/videos.component';
import { CommentOftenComponent } from './portal/comment-often/comment-often.component';
import { AskAndReplyComponent } from './data/ask-and-reply/ask-and-reply.component';
import { VideoComponent } from './m/video/video.component';
import { AskAndReplyFeedbackComponent } from './data/ask-and-reply-feedback/ask-and-reply-feedback.component';
import { ServiceOnlineComponent } from './portal/service-online/service-online.component';
import { ManageMultimediaComponent } from './m/manage-multimedia/manage-multimedia.component';
import { ScheduleComponent } from './data/schedule/schedule.component';
import { AccountComponent } from './data/account/account.component';
import { PartComponent } from './data/part/part.component';
import { WeeklyReportComponent } from './data/weekly-report/weekly-report.component';
import { WeeklyReportPortalComponent } from './portal/weekly-report-portal/weekly-report-portal.component';
import { OfficeDrtComponent } from './data/office-drt/office-drt.component';
import { OrganizeChartComponent } from './portal/organization/organize-chart/organize-chart.component';
import { OfficeDrt2Component } from './portal/office-drt2/office-drt2.component';
import { MandatesComponent } from './data/mandates/mandates.component';
import { PostResultComponent } from './portal/post-result/post-result.component';
import { DataRoomMeetingComponent } from './data/data-room-meeting/data-room-meeting/data-room-meeting.component';
import { DataDeviceRoomMeetingComponent } from './data/data-device-room-meeting/data-device-room-meeting/data-device-room-meeting.component';
import { MeetingComponent } from './data/meeting/meeting.component';
import { PortalMeetingComponent } from './portal/portal-meeting/portal-meeting.component';
import { MeetingInfoComponent } from './data/meeting-info/meeting-info.component';
import { NLDNewsComponent } from './portal/nldnews/nldnews.component';
import { LoginAskComponent } from './portal/login-ask/login-ask.component';
import { CreateAccountComponent } from './portal/create-account/create-account.component';
import { ChangeAccountPasswordComponent } from './portal/change-account-password/change-account-password.component';
import { MeetingWeekComponent } from './portal/portal_meeting_week/meeting-week/meeting-week.component';
import { MailConfigComponent } from './data/mail-config/mail-config.component';
import { CmtUserComponent } from './ad/cmt-user/cmt-user.component';
import { DocumentMeetingComponent } from './data/document-meeting/document-meeting.component';
import { LoadDataAutoComponent } from './ad/load-data-auto/load-data-auto.component';

export class AppRouter {
    public static routes: Routes = [
        { path: 'login', component: LoginComponent },
        { path: '', component: HomepageComponent },
        { path: 'change-password', component: ChangePasswordComponent, canActivate: [AuthGuard] },
        { path: 'ad/user', component: UserComponent, canActivate: [AuthGuard] },
        { path: 'ad/role', component: RoleComponent, canActivate: [AuthGuard] },
        { path: 'ad/user-role', component: UserRoleComponent, canActivate: [AuthGuard] },
        { path: 'ad/page', component: PageComponent, canActivate: [AuthGuard] },
        { path: 'ad/control', component: ControlComponent, canActivate: [AuthGuard] },
        { path: 'ad/auto-number', component: AutoNumberComponent, canActivate: [AuthGuard] },
        { path: 'ad/config', component: ConfigComponent, canActivate: [AuthGuard] },

        { path: 'm/unit', component: UnitComponent, canActivate: [AuthGuard] },
        { path: 'm/type', component: TypeComponent, canActivate: [AuthGuard] },
        { path: 'm/menu', component: MenuComponent, canActivate: [AuthGuard] },

        { path: 'dashboard', component: DashboardComponent },

        { path: 'portal', component: HomepageComponent },
        { path: 'portal/adminis-proc', component: AdmiProcComponent },
        { path: 'portal/multimedia', component: MultimediaComponent },
        { path: 'portal/admi-proc-detail', component: AdmiProcDetailComponent },
        { path: 'portal/post-detail', component: PostDetailComponent },
        { path: 'portal/office', component: UserOfficeComponent },
        { path: 'portal/office-detail', component: UserOfficeDetailComponent },
        { path: 'portal/comment-list', component: CommentListComponent },
        { path: 'portal/post-list', component: PostListComponent },
        { path: 'portal/videos', component: VideosComponent },
        { path: 'portal/comment-often', component: CommentOftenComponent },
        { path: 'portal/service-online', component: ServiceOnlineComponent },
        { path: 'portal/organize', component: OrganizeChartComponent },
        // { path: 'portal/weekly-report-portal', component: WeeklyReportPortalComponent},
        // { path: 'portal/portal-meeting', component: PortalMeetingComponent},
        { path: 'portal/nldnews', component: NLDNewsComponent},
        { path: 'portal/ask-and-reply', component: AskAndReplyComponent},
        { path: 'portal/login-ask', component: LoginAskComponent},
        { path: 'portal/create-account', component: CreateAccountComponent},
        { path: 'portal/change-account-password', component: ChangeAccountPasswordComponent},

        { path: 'data/menu', component: MenuComponent, canActivate: [AuthGuard]},
        { path: 'data/post', component: PostComponent, canActivate: [AuthGuard]},
        { path: 'data/meeting', component: MeetingComponent, canActivate: [AuthGuard]},
        { path: 'data/meeting-info', component: MeetingInfoComponent, canActivate: [AuthGuard]},
        { path: 'data/office', component: OfficeComponent, canActivate: [AuthGuard]},
        { path: 'data/ad-procedure', component: AdProcedureComponent, canActivate: [AuthGuard]},
        { path: 'data/post-ward', component: PostWardComponent, canActivate: [AuthGuard]},
        { path: 'data/comment', component: CommentComponent, canActivate: [AuthGuard]},
        { path: 'data/ask-and-reply', component: AskAndReplyComponent, canActivate: [AuthGuard]},
        { path: 'data/multimedia', component: PhotoComponent, canActivate: [AuthGuard]},
        { path: 'data/videos', component: VideoComponent, canActivate: [AuthGuard]},
        { path: 'data/ask-and-reply-feedback', component: AskAndReplyFeedbackComponent, canActivate: [AuthGuard]},
        { path: 'm/manage-multimedia', component: ManageMultimediaComponent, canActivate: [AuthGuard]},
        { path: 'data/schedule', component: ScheduleComponent, canActivate: [AuthGuard]},
        { path: 'data/account', component: AccountComponent, canActivate: [AuthGuard]},
        { path: 'data/part', component: PartComponent, canActivate: [AuthGuard]},
        { path: 'data/weekly-report', component: WeeklyReportComponent, canActivate: [AuthGuard]},
        { path: 'data/office-drt', component: OfficeDrtComponent, canActivate: [AuthGuard]},
        { path: 'portal/request', component: CommentListComponent },
        { path: 'portal/office-drt', component: OfficeDrt2Component },
        { path: 'data/mandates', component: MandatesComponent },
        { path: 'portal/post-result', component: PostResultComponent },
        { path: 'data/data-room-meeting', component: DataRoomMeetingComponent },
        { path: 'data/data-device-room-meeting', component: DataDeviceRoomMeetingComponent },
        { path: 'portal/meeting-week', component: MeetingWeekComponent },
        { path: 'data/mail-config', component: MailConfigComponent },
        { path: 'portal/meeting', component: MeetingComponent, canActivate: [AuthGuard]},
        { path: 'portal/weekly-report', component: WeeklyReportComponent, canActivate: [AuthGuard]},
        { path: 'data/cmt-user', component: CmtUserComponent, canActivate: [AuthGuard]},
        { path: 'data/document-meeting', component: DocumentMeetingComponent, canActivate: [AuthGuard]},
        { path: 'data/post/loadAuto', component: LoadDataAutoComponent}
    ];
}

import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ɵConsole, HostListener } from '@angular/core';
import { AppLanguage } from '../../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../services/app.service';
import { SelectableSettings, PageChangeEvent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { AppSwal } from 'src/app/services/app.swal';
import { IntlService } from '@progress/kendo-angular-intl';
import { Notification } from '../../services/app.notification';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Observable } from 'rxjs/Observable';
import { AppGuid } from 'src/app/services/app.guid';
import { NullInjector } from '@angular/core/src/di/injector';
import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';
import * as XLSX from 'xlsx';
import { AppFile } from 'src/app/services/app.file';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppUtils } from 'src/app/services/app.utils';
import { AppComponent } from '../../app.component';
import { DomSanitizer } from '@angular/platform-browser';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit, OnDestroy {

  isStickySearch = false
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isStickySearch = window.pageYOffset >= 140;
  }

  user: any;
  loading = false;
  dataAccounts = [];
  dataAccountSelectableSettings: SelectableSettings;
  dataAccountSort = {
    allowUnsort: true,
    mode: 'multiple'
  };
  public dataAccountFocus = {
    Name: true
  };
  dataAccountSortByField: SortDescriptor[] = [
  ];

  public WKS_NUM_PAGING_SKIP = 0;
  public WKS_NUM_PAGING_TAKE = 50;
  public WKS_NUM_PAGING_BTN = 5;

  dataAccountSkip = this.WKS_NUM_PAGING_SKIP;
  dataAccountPageSize = this.WKS_NUM_PAGING_TAKE;
  dataAccountSelection: number[] = [];
  dataAccountItem: any;
  dataAccountItemEnLanguage: any;
  myInterval: any;

  public Editor = DecoupledEditor;
  config = {
    toolbar: ['heading',
      '|',
      'bold',
      'italic',
      'link',
      'bulletedList',
      'numberedList',
      '|',
      'indent',
      'outdent',
      '|',
      'insertTable',
      'mediaEmbed',
      'undo',
      'redo']
  }

  public buttonCount = this.WKS_NUM_PAGING_BTN;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;
  infoOpened = false;

  public dataAccountState: State = {
    skip: this.dataAccountSkip,
    take: this.dataAccountSkip + this.dataAccountPageSize,
    filter: {
      logic: 'and',
      filters: []
    },
    //group: [{ field: 'RoleName' }]
  };
  dataAccountGridDataResult: GridDataResult;

  units: Array<{ Name: string, ID: string }>;
  unitsFilter: Array<{ Name: string, ID: string }>;
  genders: Array<{ Name: string, ID: string }>;
  parts: Array<{ Name: string, ID: string }>;
  partsFilter: Array<{ Name: string, ID: string }>;

  accountParents: Array<{ Name: string, ID: string }>;
  accountParentsFilter: Array<{ Name: string, ID: string }>;

  public uploadSaveUrl = 'saveUrl';
  public uploadRemoveUrl = 'removeUrl';
  public enabled = false;
  public enabledID = false;
  isEnabledSaveAll = false;
  control: any;
  pageName: any;
  controlDefault = true;
  allowMulti = true;

  searchOption = {
    SearchText: ''
  };

  InputingFile = false;
  dataAccountItemtemp: any;
  imgImageDefault: any;
  imageDef: any;
  public imgSaveUrl: any;
  imgImageMain: any;
  public uploadFlgDefault = {
    banner: false,
    image: false,
    video: false,
  };

  uploadFlg = this.uploadFlgDefault;

  constructor(
    private translate: TranslateService,
    private language: AppLanguage,
    public appService: AppService,
    private appSwal: AppSwal,
    public intl: IntlService,
    private notification: Notification,
    private guid: AppGuid,
    private file: AppFile,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    public appUtils: AppUtils,
    private appComponent: AppComponent,
    private domSanitizer: DomSanitizer
  ) {
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
    this.imgSaveUrl = `${this.appService.apiRoot}api/Upload/MediaWeb?accountID=${this.user.UserName}&typeData=image`;
    this.getControl();
    this.setDefault();
    this.language.default();
    this.setSelectableSettings();
    this.onReload();
    this.initDisplay();
    // bind current context(this) of our component instance to [fetchData]
    this.allData = this.allData.bind(this);
  }

  // used to export all kendo-grid data(not care for paging) to excel file(s)
  public allData(): ExcelExportData {
    // show loading icon to let user know heavy-export is occurring
    this.appComponent.loading = true;
    const result: ExcelExportData = {
      data: process(this.dataAccounts, { sort: this.dataAccountSortByField }).data
    };
    // unshow loading icon
    this.appComponent.loading = false;
    return result;
  }

  unitsHandleFilter(value) {
    this.unitsFilter = this.units.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  partsHandleFilter(value) {
    this.partsFilter = this.parts.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  accountParentsHandleFilter(value) {
    this.accountParentsFilter = this.accountParents.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  ngOnDestroy(): void {
    if (this.myInterval) { this.myInterval.unsubscribe(); }
  }

  ngOnInit() {
    this.getPageName();
  }

  async getPageName() {
    this.pageName = await this.appControls.getPageName();
  }

  async getControl() {
    this.control = await this.appControls.getControls(this.user.RoleID);
    this.controlDefault = false;
  }

  setSelectableSettings(): void {
    this.allowMulti = !this.allowMulti;
    if (this.InputingFile == true) {
      this.allowMulti = false;
      this.dataAccountSelectableSettings = {
        enabled: false,
        mode: 'single'
      };
    } else {
      this.dataAccountSelectableSettings = {
        checkboxOnly: false,
        mode: this.allowMulti ? 'multiple' : 'single'
      };
    }
  }

  setSelectableSettingsFile(): void {
    this.dataAccountSelectableSettings = {
      enabled: true,
      checkboxOnly: false,
      mode: this.allowMulti ? 'multiple' : 'single'
    };
  }

  onSearchKeyPress(e: any) {
    if (e.keyCode === 13 && this.searchOption.SearchText) {
      this.onSearch();
    }
  }

  async getAccounts() {
    this.loading = true;
    const dataRequest = {
      searchText: this.searchOption.SearchText
    };
    this.dataAccountSelection = [];

    const result = await this.appService.doGET('api/Account/Search', dataRequest);
    if (result) {
      this.dataAccounts = result.Data;
      this.bindAccounts();
    }
    this.loading = false;
    this.checkSelectionID();

    const resultParent = await this.appService.doGET('api/Account', null);
    if (resultParent) {
      this.accountParents = resultParent.Data;
      this.accountParentsFilter = this.accountParents.slice();
    }
  }

  checkSelectionID() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = this.dataAccountSelection.length - 1; i >= 0; i--) {
      const selectedItem = this.dataAccounts.find((item) => {
        return item.ID === this.dataAccountSelection[i];
      });
      if (!selectedItem) {
        this.dataAccountSelection.splice(i, 1);
      }
    }
  }

  setDefault() {
    this.dataAccountItem = {
      IsAdd: true,
      ID: this.guid.empty,
      Name: '',
      Gender: 0,
      Phone: '',
      Email: '',
      UnitID: null,
      LockFlg: false,
      DelFlg: false,
      BirthDay: null,
      AvatarUrl: '',
      PartID: null,
      ParentID: null,
      Description: null,
      OrderIdx: null,
      DisplayFlg: false
    };
    this.dataAccountItemEnLanguage = {
      ID: null,
      AccountID: null,
      Description: null,
      CreateAt: null,
      CreateBy: null,
      UpdateAt: null,
      UpdateBy: null,
      LanguageID: null,
    };

    this.dataAccountItemtemp = Object.assign({}, this.dataAccountItem);
    this.imageDef = '../../assets/images/default-02.png';
    this.imgImageDefault = [
      this.imageDef,
    ];
    this.imgImageMain = [''];
    this.uploadFlg = this.uploadFlgDefault;
  }
  bindtemp(item) {

    this.dataAccountItemtemp = Object.assign({}, item);
  }

  onAccountPageChange(event: PageChangeEvent) {
    this.dataAccountSkip = event.skip;
    this.bindAccounts();
  }

  async onAccountSelectedKeysChange() {

    if (this.dataAccountSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }

    if (this.dataAccountSelection.length > 1) {
      if (this.allowMulti) {
        return;
      }
      this.appSwal.showWarning(this.translate.instant('SelectSingle'), false);
    } else {
      if (this.dataAccounts.length > 0 && this.InputingFile == false) {
        const selectedID = this.dataAccountSelection[0];
        const selectedItem = this.dataAccounts.find((item) => {
          return item.ID === selectedID;
        });
        selectedItem.IsAdd = false;
        this.dataAccountItem = selectedItem;
        this.dataAccountItem.BirthDay = this.dataAccountItem.BirthDay ? new Date(this.dataAccountItem.BirthDay) : null;

        this.imgImageDefault = [];
        //call api get base 64 to render image
        if (this.dataAccountItem.AvatarUrl) {
          const dataRequest = {
            imagePath: `${this.dataAccountItem.AvatarUrl}`
          };

          const result = await this.appService.doGET('api/Post/GetBase64ImageResult', dataRequest);

          if (result && result.Status === 1) {
            if (result.Data != null && result.Data.Base64 != null && result.Data.Base64 !== '') {
              const url = result.Data.Base64;
              this.imgImageDefault[0] = url;
            }
          }
        } else {
          this.imageDef = '../../assets/images/default-02.png';
          this.imgImageDefault = [
            this.imageDef,
          ];
        }

        this.bindtemp(this.dataAccountItem);
        this.enabled = false;
        this.enabledID = false;
        this.uploadFlg.image = false;
      }
    }
  }

  bindAccounts() {
    this.dataAccountGridDataResult = {
      data: orderBy(this.dataAccounts, this.dataAccountSortByField),
      total: this.dataAccounts.length
    };

    this.dataAccountGridDataResult = process(this.dataAccounts, this.dataAccountState);
  }

  onAccountSortChange(sort: SortDescriptor[]): void {
    this.dataAccountSortByField = sort;
    this.bindAccounts();
  }

  public onAccountDataStateChange(state: DataStateChangeEvent): void {
    this.dataAccountSelection = [];
    this.dataAccountState = state;
    this.dataAccountGridDataResult = process(this.dataAccounts, this.dataAccountState);
  }

  getColumnIndex(name) {
    const columns = [
      'Name',
      'Gender',
      'Phone',
      'Email',
      'BirthDay',
      'PartID'
    ];
    return columns.indexOf(name);
  }

  async selectEventHandler(e: SelectEvent) {
    this.appComponent.loading = true;
    try {
      const fileData = e.files[0];// await this.file.readFile(e.files[0].rawFile);
      const maxMB = 25;
      const maxSizeKB = 1024 * 1024 * maxMB;
      if (fileData.size > maxSizeKB) {
        this.appSwal.showWarning(`Kích thước tập tin không được lớn hơn ${maxMB} MB`, false);
        return false;
      }
    } catch {
      return false;
    }
    const extension = e.files[0].extension.toLowerCase();
    if (!extension || (extension.toLowerCase() !== '.xlsx' && extension.toLowerCase() !== '.xls' && extension.toLowerCase() !== '.pdf')) {
      
      this.appSwal.showWarning('Vui lòng chọn tập tin có định dạng .xlsx, .xls.', false);

      return false;
    }

    const fileData = (await this.file.readXLSX(e.files[0].rawFile)) as Array<any>;
    this.InputingFile = true;
    this.setSelectableSettings();
    this.dataAccounts = [];
    let part;
    let gender;
    let brithDay;
    for (let i = 1; i < fileData.length; i++) {

      part = this.appUtils.getObjectByList(this.parts, fileData[i][this.getColumnIndex('PartID')]);
      gender = this.appUtils.getObjectByList(this.genders, fileData[i][this.getColumnIndex('Gender')]);
      brithDay = this.appUtils.getDate(fileData[i][this.getColumnIndex('BirthDay')]);

      this.dataAccounts.push({
        IsAdd: false,
        ID: this.guid.empty,
        Name: fileData[i][this.getColumnIndex('Name')],
        Gender: gender ? gender.ID : null,
        GenderName: gender ? gender.Name : '',
        Phone: fileData[i][this.getColumnIndex('Phone')],
        Email: fileData[i][this.getColumnIndex('Email')],
        BirthDay: brithDay ? brithDay.Value : null,
        PartID: part ? part.ID : null,
        PartName: part ? part.Name : null,
        LockFlg: false,
        DelFlg: false
      });
    }
    this.bindAccounts();
    this.isEnabledSaveAll = true;
    this.appComponent.loading = false;
  }

  removeEventHandler() {
    this.isEnabledSaveAll = false;
    this.onReload();
  }



  onSearchTextChange(e: any) {
    if (!this.searchOption.SearchText) {
      this.onReload();
    }
  }

  onRemoveSearchText() {
    this.searchOption.SearchText = '';

  }

  async initDisplay() {
    const resultRole = await this.appService.doGET('api/Unit', null);
    if (resultRole && resultRole.Status === 1) {
      this.units = resultRole.Data;
      this.unitsFilter = this.units.slice();
    }

    const resultGender = await this.appService.doGET('api/Enum/GetEGender', null);
    if (resultGender && resultGender.Status === 1) {
      this.genders = resultGender.Data;
    }

    const resultPart = await this.appService.doGET('api/Part', null);
    if (resultPart && resultPart.Status === 1) {
      this.parts = resultPart.Data;
      this.partsFilter = this.parts.slice();
    }
  }

  onSearch() {
    this.getAccounts();
    this.isEnabledSaveAll = false;
  }

  onReload() {
    this.InputingFile = false;
    this.setSelectableSettingsFile();
    this.searchOption.SearchText = '';
    this.getAccounts();
    this.isEnabledSaveAll = false;
  }

  onClearAccount() {
    this.setDefault();
  }

  onAllowSelectMulti() {
    this.setSelectableSettings();
  }

  onAddNewAccount() {
    this.enabled = true;
    this.enabledID = true;
    this.setDefault();
    this.infoOpened = true;
  }

  onSaveAccount() {
    if (this.dataAccountItem.IsAdd) { this.addAccount(); } else { this.updateAccount(); }
  }

  async onSaveAccounts() {
    this.appComponent.loading = true;
    const dataRequests = [];
    for (let i = 0; i < this.dataAccounts.length; i++) {
      dataRequests.push(this.createDataRequest(this.dataAccounts[i]));
    }
    if (dataRequests.length < 1) {
      this.onReload();
      this.isEnabledSaveAll = false;
      this.appComponent.loading = false;
      this.appSwal.showWarning(this.translate.instant('Error_NoItem_Save'), false);
      return;
    }
    const result = await this.appService.doPOST('api/Account/Saves', dataRequests);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.isEnabledSaveAll = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  createDataRequest(data) {
    const temp = data ? data : this.dataAccountItemtemp;
    const tempEnLanguage = this.dataAccountItemEnLanguage;
    return {
      Account: {
        IsAdd: temp.IsAdd,
        ID: temp.ID,
        Name: temp.Name,
        Gender: temp.Gender,
        Phone: temp.Phone,
        Email: temp.Email,
        UnitID: temp.UnitID,
        LockFlg: temp.LockFlg,
        DelFlg: temp.DelFlg,
        BirthDay: temp.BirthDay ? this.intl.formatDate(new Date(temp.BirthDay), 'yyyy-MM-ddT00:00:00') : null,
        AvatarUrl: temp.AvatarUrl,
        CreateAt: temp.CreateAt,
        UpdateAt: temp.UpdateAt,
        PartID: temp.PartID,
        ParentID: temp.ParentID,
        Description: temp.Description,
        OrderIdx: temp.OrderIdx,
        DisplayFlg: temp.DisplayFlg
      },
      EnLanguage: {
        ID: tempEnLanguage.ID,
        AccountID: tempEnLanguage.AccountID,
        Description: tempEnLanguage.Description,
        CreateAt: tempEnLanguage.CreateAt,
        CreateBy: tempEnLanguage.CreateBy,
        UpdateAt: tempEnLanguage.UpdateAt,
        UpdateBy: tempEnLanguage.UpdateBy,
        LanguageID: 'en-US'
      }
      
    };
  }

  onCloseAccount(status: any) {
    this.enabled = false;
    this.enabledID = false;
  }

  onEditAccount() {
    this.infoOpened = true;
    this.enabled = true;
    this.enabledID = false;
    this.getLanguage();
  }

  async addAccount() {
    this.appComponent.loading = true;
    this.dataAccountItemtemp.AvatarUrl = (this.uploadFlg.image === true && this.imgImageMain && this.imgImageMain.length > 0)
      ? this.imgImageMain[0] : null;

    const dataRequest = this.createDataRequest(null);
    const result = await this.appService.doPOST('api/Account', dataRequest);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.onAddNewAccount();
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.infoOpened = false;
    this.appComponent.loading = false;
  }

  async updateAccount() {
    this.appComponent.loading = true;
    this.dataAccountItemtemp.AvatarUrl = (this.uploadFlg.image === true && this.imgImageMain && this.imgImageMain.length > 0)
      ? this.imgImageMain[0] : this.dataAccountItemtemp.AvatarUrl;
    const id = this.dataAccountItem.ID;
    const dataRequest = this.createDataRequest(null);

    const result = await this.appService.doPUT('api/Account', dataRequest, { id });
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.infoOpened = false;
    this.appComponent.loading = false;
  }

  async onDeleteAccount() {
    if (this.dataAccountSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }
    this.appComponent.loading = true;
    const dataRequest = {
      IDList: JSON.stringify(this.dataAccountSelection),
      FlgRevert: false
    };

    const option = await this.appSwal.showWarning(this.translate.instant('AreYouSure'), true);
    if (option) {
      const result = await this.appService.doPOST('api/Account/Deletes', dataRequest);
      if (result && result.Status === 1) {
        this.notification.showSuccess(result.Msg);
        this.onReload();
        this.setDefault();
        this.enabled = false;
        this.enabledID = false;
        this.dataAccountSelection = [];
        this.allowMulti = false;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
    this.appComponent.loading = false;
  }

  onCloseInfo(e) {
    this.setDefault();
    this.infoOpened = false;
  }

  async onImageUrlSelectEventHandler(e: SelectEvent, index) {
    if (!e.files || e.files.length <= 0) {
      return false;
    }
    try {
      const fileData = e.files[0];// await this.file.readFile(e.files[0].rawFile);
      const maxMB = 25;
      const maxSizeKB = 1024 * 1024 * maxMB;
      if (fileData.size > maxSizeKB) {
        this.appSwal.showWarning(`Kích thước tập tin không được lớn hơn ${maxMB} MB`, false);
        return false;
      }
    } catch {
      return false;
    }
    // tslint:disable-next-line: max-line-length
    if (e.files[0].extension !== '.jpg' && e.files[0].extension !== '.png' && e.files[0].extension !== '.gif' && e.files[0].extension !== '.jpeg') {
      this.appSwal.showWarning(this.translate.instant('MsgImgOnly'), false);
      return false;
    }

    this.appComponent.loading = true;
    const fileData = await this.file.readImage(e.files[0].rawFile);
    const base64 = `${fileData}`;
    this.imgImageDefault[index] = this.domSanitizer.bypassSecurityTrustResourceUrl(base64);
    this.imgImageDefault.push(this.imageDef);
  }

  onImageUrlSuccessEventHandle(e: any, index) {
    try {
      this.appComponent.loading = false;
      this.uploadFlg.image = true;
      this.imgImageMain[index] = `${e.response.body.Data.DirMedia}${e.response.body.Data.MediaNm[0]}`;
    } catch {
      this.uploadFlg.image = false;
    }
  }

  onImageUrlRemoveEventHandler(index) {
    this.imgImageMain[index] = '';
  }

  public onTabSelect(e) {
    
  }

  async getLanguage(){
    var k = 0;
    if(this.dataAccountItem.ID != null){
      const dataRequest = {
        id: this.dataAccountItem.ID
       }
       const result = await this.appService.doGET('api/Account/GetAccountLanguage', dataRequest);
       if (result && result.Status == 1) {
         for(let i = 0; i < result.Data.length; i++){
          if(result.Data[i].LanguageID == "en-US"){
            this.dataAccountItemEnLanguage = result.Data[i];
          }
         }
       }
    }
  }
}

import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';
import { AppLanguage } from '../../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../services/app.service';
import { SelectableSettings, PageChangeEvent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { AppSwal } from 'src/app/services/app.swal';
import { IntlService } from '@progress/kendo-angular-intl';
import { Notification } from '../../services/app.notification';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
import { AppGuid } from 'src/app/services/app.guid';
import { NullInjector } from '@angular/core/src/di/injector';
import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';
import * as XLSX from 'xlsx';
import { AppFile } from 'src/app/services/app.file';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppUtils } from 'src/app/services/app.utils';
import { AppComponent } from '../../app.component';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpProgressEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { of, concat } from 'rxjs';
import { delay } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { t } from '@angular/core/src/render3';
import { WebSocketService } from 'src/app/websocket.service';
import { DatePipe } from '@angular/common'
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-meeting',
  templateUrl: './meeting.component.html',
  styleUrls: ['./meeting.component.css'],
})
export class MeetingComponent implements OnInit, OnDestroy {

  isSticky = false
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 140;
  }

  user: any;
  loading = false;
  dataMeetings = [];
  dataMeetingSelectableSettings: SelectableSettings;
  dataMeetingSort = {
    allowUnsort: true,
    mode: 'multiple'
  };
  dataMeetingSortByField: SortDescriptor[] = [
    // {
    //   field: 'ParentName',
    //   dir: 'asc'
    // }
  ];

  public Editor = DecoupledEditor;
  config = {
    toolbar: ['heading',
      '|',
      'bold',
      'italic',
      'link',
      'bulletedList',
      'numberedList',
      '|',
      'indent',
      'outdent',
      '|',
      'insertTable',
      'mediaEmbed',
      'undo',
      'redo']
  }

  public fileSaveUrl: any;

  public NUM_PAGING_SKIP = 0;
  public NUM_PAGING_TAKE = 50;
  public NUM_PAGING_BTN = 5;

  dataMeetingSkip = this.NUM_PAGING_SKIP;
  dataMeetingPageSize = this.NUM_PAGING_TAKE;
  dataMeetingSelection: number[] = [];
  dataMeetingItem: any;
  dataMeetingItemTemp: any;
  myInterval: any;
  pageName: any;
  infoOpened = false;

  public buttonCount = this.NUM_PAGING_BTN;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;

  public dataMeetingState: State = {
    skip: this.dataMeetingSkip,
    take: this.dataMeetingSkip + this.dataMeetingPageSize,
    filter: {
      logic: 'and',
      filters: []
    },
    // tslint:disable-next-line:comment-format
    group: [{ field: 'MeetingDate', dir: 'desc' }]
  };
  dataMeetingGridDataResult: GridDataResult;

  meetingApproveStatuses: Array<{ Name: string, ID: number }>;
  meetingPublicStatuses: Array<{ Name: string, ID: number }>;

  isEnabledSaveAll = false;
  control: any;
  controlDefault = true;
  allowMulti = true;
  currentDay = new Date();
  lastestDayOfYear = new Date(new Date().getFullYear(),11,31);
  searchOption = {
    SearchText: '',
    ApproveFlg: -1,
    PublicFlg: -1,
    FromDate: null,
    ToDate: null,
    Week: this.getWeekNumber()
  };

  
  WeekNoFromDB: number;
  centralContent = '';
  infoCentralContentOpened = false;
  SEND_DATA_WHEN_NOTIFY = '';

  previewOpened = false;

  weeks: any;
  reviewRpbtn = true;
  toDatePrintDraft  = new Date();
  fromDatePrintDraft  = new Date();

  public dataMeetingStateRP: State = {
    skip: this.dataMeetingSkip,
    take: this.dataMeetingSkip + this.dataMeetingPageSize,
    filter: {
      logic: 'and',
      filters: []
    },
    // tslint:disable-next-line:comment-format
  };
  dataMeetingGridDataResultRP: GridDataResult;
  public slideDayOfWeek : Array<{ Ob: any, ID: string, ObGrid: GridDataResult }> = [];
  @ViewChild('ngcarousel') ngCarousel: NgbCarousel;


  constructor(
    private translate: TranslateService,
    private language: AppLanguage,
    private appService: AppService,
    private appSwal: AppSwal,
    public intl: IntlService,
    private notification: Notification,
    private guid: AppGuid,
    private file: AppFile,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    private appUtils: AppUtils,
    private appComponent: AppComponent,
    private domSanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    public wsService: WebSocketService,
    private datePipe: DatePipe
  ) {
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
    this.getControl();
    this.getPageName();
    this.language.default();
    this.setSelectableSettings();
    this.setDefault();
    this.initDisplay();
    this.onReload();
    this.allData = this.allData.bind(this);
    this.SEND_DATA_WHEN_NOTIFY = appService.domainAPI + "api/NotifyWs";
  }

  ngOnDestroy(): void {
  }

  ngOnInit() {
  }

  async getPageName() {
    this.pageName = await this.appControls.getPageName();
  }

  async getControl() {
    this.control = await this.appControls.getControls(this.user.RoleID);
    this.controlDefault = false;
  }

  setSelectableSettings(): void {

    this.allowMulti = !this.allowMulti;
    this.dataMeetingSelectableSettings = {
      checkboxOnly: false,
      mode: this.allowMulti ? 'multiple' : 'single'
    };
  }

  async getMeetings() {
    this.loading = true;
    const dataRequest = {
      SearchText: this.searchOption.SearchText,
      ApproveFlg: this.searchOption.ApproveFlg,
      PublicFlg: this.searchOption.PublicFlg,
      FromDate: this.searchOption.FromDate ? this.intl.formatDate(new Date(this.searchOption.FromDate), 'yyyy-MM-ddT00:00:00') : null,
      ToDate: this.searchOption.ToDate ? this.intl.formatDate(new Date(this.searchOption.ToDate), 'yyyy-MM-ddT00:00:00') : null
    };
    const result = await this.appService.doPOST('api/Meeting/Search', dataRequest);
    if (result && result.Status === 1) {
      const dayOfWeek = [];
      for (let i = 0; i < result.Data.length; i++) {
        result.Data[i].UnitList = JSON.parse(result.Data[i].UnitString);
        for(let j = 0; j < result.Data[i].UnitList.length; j++){
          if(result.Data[i].ShowUnit == undefined){
            result.Data[i].ShowUnit = "";
          }
          if(j != result.Data[i].UnitList.length - 1){
            result.Data[i].ShowUnit = result.Data[i].ShowUnit + " " + result.Data[i].UnitList[j].Name + ",";
          } else{
            result.Data[i].ShowUnit = result.Data[i].ShowUnit + " " + result.Data[i].UnitList[j].Name;
          }
        }
        result.Data[i].Admin = JSON.parse(result.Data[i].AdminString);
        for(let k = 0; k < result.Data[i].Admin.length; k++){
          if(result.Data[i].ShowAdmin == undefined){
            result.Data[i].ShowAdmin = "";
          }
          if(k != result.Data[i].Admin.length - 1){
            result.Data[i].ShowAdmin = result.Data[i].ShowAdmin + " " + result.Data[i].Admin[k].FullName + ",";
          } else{
            result.Data[i].ShowAdmin = result.Data[i].ShowAdmin + " " + result.Data[i].Admin[k].FullName;
          }
        }
        result.Data[i].RoomMeetingList = JSON.parse(result.Data[i].RoomMeetingString);
        if (result.Data[i].RoomMeetingList){
          for(let j = 0; j < result.Data[i].RoomMeetingList.length; j++){
            if(result.Data[i].RoomMeetingName == undefined){
              result.Data[i].RoomMeetingName = "";
            }
            if(j != result.Data[i].RoomMeetingList.length - 1){
              result.Data[i].RoomMeetingName = result.Data[i].RoomMeetingName + " " + result.Data[i].RoomMeetingList[j].Name + ",";
            } else{
              result.Data[i].RoomMeetingName = result.Data[i].RoomMeetingName + " " + result.Data[i].RoomMeetingList[j].Name;
            }
          }
        } 
        result.Data[i].MemberList = JSON.parse(result.Data[i].MemberString);
        for (let j = 0; j < result.Data[i].MemberList.length; j++) {
          if (result.Data[i].ShowMembers == undefined) {
            result.Data[i].ShowMembers = "";
          }
          if (j != result.Data[i].MemberList.length - 1) {
            result.Data[i].ShowMembers = result.Data[i].ShowMembers + " " + result.Data[i].MemberList[j].FullName + ",";
          } else {
            result.Data[i].ShowMembers = result.Data[i].ShowMembers + " " + result.Data[i].MemberList[j].FullName;
          }
        }
        if(result.Data[i].FileUrl == null || result.Data[i].FileUrl == ""){
          result.Data[i].FileUrl = [];
        }
        else{
          result.Data[i].FileUrl = JSON.parse(result.Data[i].FileUrl);
        }
        result.Data[i].MeetingDayName = this.getDayInWeek(result.Data[i].MeetingDate);
        result.Data[i].DayName = this.getDayInWeek(result.Data[i].StartAt);
        result.Data[i].DayHour = this.getHour(result.Data[i].StartAt);
        result.Data[i].StartAtRp = result.Data[i].StartAt;
        if (dayOfWeek.find(dayItem => {
          return dayItem === result.Data[i].DayName;
        })) {
          result.Data[i].DayName = '';
          result.Data[i].StartAtRp = null;
        } else {
          dayOfWeek.push(result.Data[i].DayName);
        }
      }
      this.dataMeetings = result.Data;
      this.bindMeetings();
    }

    this.loading = false;
    this.checkSelectionID();
  }

  checkSelectionID() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = this.dataMeetingSelection.length - 1; i >= 0; i--) {
      const selectedItem = this.dataMeetings.find((item) => {
        return item.ID === this.dataMeetingSelection[i];
      });
      if (!selectedItem) {
        this.dataMeetingSelection.splice(i, 1);
      }
    }
  }

  onCloseInfo(e) {
    this.onReload();
    this.setDefault();
    this.infoOpened = false;
    this.infoCentralContentOpened = false;
  }

  setDefault() {
    this.dataMeetingItem = {
      viewOnly: false,
      IsAdd: true,
      Name: '',
      Admin: null,
      Address: '',
      MeetingDate: null,
      StartAt: null,
      EndAt: null,
      ApproveFlg: false,
      FileUrl: [],
      Description: ''
    };
    this.dataMeetingItemTemp = Object.assign({}, this.dataMeetingItem);
    this.dataMeetingSelection = [];
    this.allowMulti = false;
  }

  onMeetingPageChange(event: PageChangeEvent) {
    this.dataMeetingSkip = event.skip;
    this.bindMeetings();
  }

  async onMeetingselectedKeysChange() {
    if (this.dataMeetingSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }

    if (this.dataMeetingSelection.length > 1) {
      if (this.allowMulti) {
        return;
      }
      this.appSwal.showWarning(this.translate.instant('SelectSingle'), false);
    } else {
      const selectedID = this.dataMeetingSelection[0];
      const selectedItem = this.dataMeetings.find((item) => {
        return item.ID === selectedID;
      });
      selectedItem.IsAdd = false;
      selectedItem.viewOnly = false;
      selectedItem.MeetingDate = selectedItem.MeetingDate ? new Date(selectedItem.MeetingDate) : null;
      selectedItem.StartAt = selectedItem.StartAt ? new Date(selectedItem.StartAt) : null;
      selectedItem.EndAt = selectedItem.EndAt ? new Date(selectedItem.EndAt) : null;
      this.dataMeetingItem = selectedItem;
      this.bindTemp(this.dataMeetingItem);
    }
  }

  bindMeetings() {
    this.dataMeetingGridDataResult = {
      data: orderBy(this.dataMeetings, this.dataMeetingSortByField),
      total: this.dataMeetings.length
    };

    this.dataMeetingGridDataResult = process(this.dataMeetings, this.dataMeetingState);
    this.dataMeetingGridDataResultRP = process(this.dataMeetings, this.dataMeetingStateRP);
  }

  onMeetingsortChange(sort: SortDescriptor[]): void {
    this.dataMeetingSortByField = sort;
    this.bindMeetings();
  }

  public onMeetingDataStateChange(state: DataStateChangeEvent): void {
    this.dataMeetingSelection = [];
    this.dataMeetingState = state;
    this.dataMeetingStateRP = state;
    this.dataMeetingGridDataResult = process(this.dataMeetings, this.dataMeetingState);
    this.dataMeetingGridDataResultRP = process(this.dataMeetings, this.dataMeetingStateRP);
  }

  removeEventHandler(index) {
    this.isEnabledSaveAll = false;
    this.onReload();
  }

  doInterval() {
    // this.myInterval = Observable.interval(this.appService.timeInterval).subscribe(() => {
    //   this.onReload();
    // });
  }

  onSearchTextChange(e: any) {
    if (!this.searchOption.SearchText) {
      this.onReload();
    }
  }

  onRemoveSearchText() {
    this.searchOption.SearchText = '';
  }

  async initDisplay() {

    this.meetingApproveStatuses = [
      { Name: 'Tất cả', ID: -1 },
      { Name: 'Chưa duyệt', ID: 0 },
      { Name: 'Đã duyệt', ID: 1 },
    ];

    this.meetingPublicStatuses = [
      { Name: 'Tất cả', ID: -1 },
      { Name: 'Chưa công bố', ID: 0 },
      { Name: 'Đã công bố', ID: 1 },
    ];

    this.weeks = [];
    for (let i = 1; i <= 52; i++) {
      this.weeks.push({
        ID: i,
        Name: i
      });
    }
  }

  onSearch() {
  
    this.getMeetings();
    this.isEnabledSaveAll = false;
    this.reviewRpbtn = false;
  }

  onReload() {
    // this.getMeetings();
    this.getMeetingByWeek();
    this.isEnabledSaveAll = false;
  }

  onAllowSelectMulti() {
    this.setSelectableSettings();
    this.dataMeetingItem = {
      IsAdd: true,
      viewOnly: false,
      Name: '',
      Admin: null,
      Address: '',
      MeetingDate: null,
      StartAt: null,
      EndAt: null,
      ApproveFlg: false
    };
    this.dataMeetingItemTemp = {
      IsAdd: true,
      viewOnly: false,
      Name: '',
      Admin: null,
      Address: '',
      MeetingDate: null,
      StartAt: null,
      EndAt: null,
      ApproveFlg: false
    };

    this.dataMeetingSelection = [];
  }

  onAddNewMeeting() {
    this.setDefault();
    this.infoOpened = true;
  }

  async onSaveMeetings() {
    this.appComponent.loading = true;
    const dataRequests = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.dataMeetings.length; i++) {
      dataRequests.push(this.createDataRequest(this.dataMeetings[i]));
    }
    const result = await this.appService.doPOST('api/Meeting/Saves', dataRequests);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.isEnabledSaveAll = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }
  bindTemp(data) {
    this.dataMeetingItemTemp = Object.assign({}, data);
  }

  createDataRequest(data) {
    const temp = data ? data : this.dataMeetingItemTemp;

    return {
      ID: temp.ID,
      Name: temp.Name,
      Admin: temp.Admin,
      UnitID: temp.UnitID,
      MeetingDate: temp.MeetingDate ? this.intl.formatDate(new Date(temp.MeetingDate), 'yyyy-MM-ddT00:00:00') : null,
      StartAt: temp.StartAt ? this.intl.formatDate(new Date(temp.StartAt), 'yyyy-MM-ddTHH:mm:ss') : null,
      EndAt: temp.EndAt ? this.intl.formatDate(new Date(temp.EndAt), 'yyyy-MM-ddTHH:mm:ss') : null,
      RoomMeetingID: temp.RoomMeetingID,
      RoomOther: temp.RoomOther,
      ApproveFlg: temp.ApproveFlg,
      ApproveBy: temp.ApproveBy,
      ApproveAt: temp.ApproveAt,
      DelFlg: temp.DelFlg,
      DelAt: temp.DelAt,
      DelBy: temp.DelBy,
      CreateAt: temp.CreateAt,
      CreateBy: temp.CreateBy,
      UpdateAt: temp.UpdateAt,
      UpdateBy: temp.UpdateBy,
      PublicFlg: temp.PublicFlg,
      PublicBy: temp.PublicBy,
      PublicAt: temp.PublicAt,
      Description: temp.Description,
      FileUrl: temp.FileUrl,
    };
  } 

  async onEditMeeting() {
    if(this.dataMeetingItem.ApproveFlg == true && this.dataMeetingItem.PublicFlg != true){
      this.appSwal.showWarning(this.translate.instant('MsgErrorEditMeeting'), false);
      return;
    }
    this.infoOpened = true;
  }

  async onDeleteMeeting() {
    if (this.dataMeetingSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }
    this.appComponent.loading = true;
    const dataRequest = {
      IDList: JSON.stringify(this.dataMeetingSelection),
      FlgRevert: false
    };    
    const option = await this.appSwal.showWarning(this.translate.instant('AreYouSure'), true);
      if (option) {
        const result = await this.appService.doPOST('api/Meeting/Deletes', dataRequest);
        if (result && result.Status === 1) {
          this.notification.showSuccess(result.Msg);
          this.onReload();
          this.setDefault();
        } else {
          this.appSwal.showWarning(result.Msg, false);
        }
    }         
    this.appComponent.loading = false;
  }

  async onApproved() {
    this.appComponent.loading = true;
    // tslint:disable-next-line: max-line-length
    const id = this.dataMeetingItem.ID;

    const result = await this.appService.doGET('api/Meeting/Approved', { id });
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.setDefault();
      this.infoOpened = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  async onUnApproved() {
    this.appComponent.loading = true;
    // tslint:disable-next-line: max-line-length
    const id = this.dataMeetingItem.ID;

    const result = await this.appService.doGET('api/Meeting/UnApproved', { id });
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.setDefault();
      this.infoOpened = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  async onPublic() {
    this.appComponent.loading = true;
    // tslint:disable-next-line: max-line-length
    const id = this.dataMeetingItem.ID;

    const result = await this.appService.doGET('api/Meeting/Public', { id });
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.sendWSMessage(id);
      this.onReload();
      this.setDefault();
      this.infoOpened = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  async onUnPublic() {
    this.appComponent.loading = true;
    // tslint:disable-next-line: max-line-length
    const id = this.dataMeetingItem.ID;

    const result = await this.appService.doGET('api/Meeting/UnPublic', { id });
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.sendWSMessage(id);
      this.onReload();
      this.setDefault();
      this.infoOpened = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  async onDelFlg() {
    this.appComponent.loading = true;
    // tslint:disable-next-line: max-line-length
    const id = this.dataMeetingItem.ID;

    const result = await this.appService.doGET('api/Meeting/DeleteFlag', { id });
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.sendWSMessage(id);
      this.onReload();
      this.setDefault();
      this.infoOpened = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  // async onUnDelFlg() {
  //   this.appComponent.loading = true;
  //   // tslint:disable-next-line: max-line-length
  //   const id = this.dataMeetingItem.ID;

  //   const result = await this.appService.doGET('api/Meeting/UnDeleteFlag', { id });
  //   if (result && result.Status === 1) {
  //     this.notification.showSuccess(result.Msg);
  //     this.onReload();
  //     this.setDefault();
  //     this.infoOpened = false;
  //   } else {
  //     this.appSwal.showWarning(result.Msg, false);
  //   }
  //   this.appComponent.loading = false;
  // }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.dataMeetings, {}).data
    };
    return result;
  }

  getWeekNumber(): number {
    const today = new Date();
    const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
    const pastDaysOfYear = (today.valueOf() - firstDayOfYear.valueOf()) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  }

  async sendWSMessage(id){
    var dataRequest = {
      meetingID: id
    }
    const result = await this.appService.doGET('api/Meeting/GetAdminMemberList', dataRequest);
    if (result && result.Status == 1) {
      this.wsService.sendMessage(result.Data, this.SEND_DATA_WHEN_NOTIFY);
    }
    
  }

  onExportPdf(pdf: any) {
    
    pdf.saveAs('Lich cong tac tuan.pdf');
  }

  getHour(date) {
    var current_day = new Date(date);

    return ((current_day.getHours() < 10) ? "0" : "") + current_day.getHours() + ":" + ((current_day.getMinutes() < 10) ? "0" : "") + current_day.getMinutes()
  }

  getDayInWeek(date) {
    var current_day = new Date(date);
    var day_name = '';
    switch (current_day.getDay()) {
      case 0:
        day_name = "Chủ nhật";
        break;
      case 1:
        day_name = "Thứ hai";
        break;
      case 2:
        day_name = "Thứ ba";
        break;
      case 3:
        day_name = "Thứ tư";
        break;
      case 4:
        day_name = "Thứ năm";
        break;
      case 5:
        day_name = "Thứ sáu";
        break;
      case 6:
        day_name = "Thứ bảy";
    }
    return day_name
  }

  onReviewDialog(){
    this.previewOpened = true;
  }

  onClosePreview(e) {
    this.previewOpened = false;
  }

  valueChangeWeekFilter(e){
    this.searchOption.SearchText = '';
    this.searchOption.FromDate = null;
    this.searchOption.ToDate = null;
    this.getMeetingByWeek();
  }

  async getMeetingByWeek(){
    this.loading = true;
    const dataRequest = {
      ApproveFlg: this.searchOption.ApproveFlg,
      PublicFlg: this.searchOption.PublicFlg,
      Week: this.searchOption.Week
    };
    const result = await this.appService.doPOST('api/Meeting/SearchByWeek', dataRequest);
    if (result && result.Status === 1) {
      const dayOfWeek = [];
      this.slideDayOfWeek = [];
      let idxDayofWeek = "";
      for (let i = 0; i < result.Data.length; i++) {
        result.Data[i].UnitList = JSON.parse(result.Data[i].UnitString);
        for(let j = 0; j < result.Data[i].UnitList.length; j++){
          if(result.Data[i].ShowUnit == undefined){
            result.Data[i].ShowUnit = "";
          }
          if(j != result.Data[i].UnitList.length - 1){
            result.Data[i].ShowUnit = result.Data[i].ShowUnit + " " + result.Data[i].UnitList[j].Name + ",";
          } else{
            result.Data[i].ShowUnit = result.Data[i].ShowUnit + " " + result.Data[i].UnitList[j].Name;
          }
        }
        result.Data[i].Admin = JSON.parse(result.Data[i].AdminString);
        for(let k = 0; k < result.Data[i].Admin.length; k++){
          if(result.Data[i].ShowAdmin == undefined){
            result.Data[i].ShowAdmin = "";
          }
          if(k != result.Data[i].Admin.length - 1){
            result.Data[i].ShowAdmin = result.Data[i].ShowAdmin + " " + result.Data[i].Admin[k].FullName + ",";
          } else{
            result.Data[i].ShowAdmin = result.Data[i].ShowAdmin + " " + result.Data[i].Admin[k].FullName;
          }
        }
        result.Data[i].RoomMeetingList = JSON.parse(result.Data[i].RoomMeetingString);
        if (result.Data[i].RoomMeetingList){
          for(let j = 0; j < result.Data[i].RoomMeetingList.length; j++){
            if(result.Data[i].RoomMeetingName == undefined){
              result.Data[i].RoomMeetingName = "";
            }
            if(j != result.Data[i].RoomMeetingList.length - 1){
              result.Data[i].RoomMeetingName = result.Data[i].RoomMeetingName + " " + result.Data[i].RoomMeetingList[j].Name + ",";
            } else{
              result.Data[i].RoomMeetingName = result.Data[i].RoomMeetingName + " " + result.Data[i].RoomMeetingList[j].Name;
            }
          }
        }        
        result.Data[i].MemberList = JSON.parse(result.Data[i].MemberString);
        for (let j = 0; j < result.Data[i].MemberList.length; j++) {
          if (result.Data[i].ShowMembers == undefined) {
            result.Data[i].ShowMembers = "";
          }
          if (j != result.Data[i].MemberList.length - 1) {
            result.Data[i].ShowMembers = result.Data[i].ShowMembers + " " + result.Data[i].MemberList[j].FullName + ",";
          } else {
            result.Data[i].ShowMembers = result.Data[i].ShowMembers + " " + result.Data[i].MemberList[j].FullName;
          }
        }
        if(result.Data[i].FileUrl == null || result.Data[i].FileUrl == ""){
          result.Data[i].FileUrl = [];
        }
        else{
          result.Data[i].FileUrl = JSON.parse(result.Data[i].FileUrl);
        }
        result.Data[i].MeetingDayName = this.getDayInWeek(result.Data[i].MeetingDate);
        result.Data[i].DayName = this.getDayInWeek(result.Data[i].StartAt);
        result.Data[i].DayHour = this.getHour(result.Data[i].StartAt);
        result.Data[i].StartAtRp = result.Data[i].StartAt;

        if (idxDayofWeek != result.Data[i].DayName){
          idxDayofWeek = result.Data[i].DayName; 
          this.slideDayOfWeek.push({
            ID: idxDayofWeek,
            Ob: [result.Data[i]],
            ObGrid: null
          })
        }else {
          if(this.slideDayOfWeek.length > 0){
            this.slideDayOfWeek[this.slideDayOfWeek.indexOf(this.slideDayOfWeek.find(r=> r.ID == idxDayofWeek))].Ob.push(result.Data[i])
          }          
        }

        if (dayOfWeek.find(dayItem => {
          return dayItem === result.Data[i].DayName;
        })) {
          result.Data[i].DayName = '';
          result.Data[i].StartAtRp = null;
        } else {
          dayOfWeek.push(result.Data[i].DayName);
        }
        this.fromDatePrintDraft = new Date(result.Data[0].MeetingDate);
      }
      this.dataMeetings = result.Data;

      this.slideDayOfWeek.forEach(r => {
        r.ObGrid = {
          data: orderBy(r.Ob, this.dataMeetingSortByField),
          total: r.Ob.length
        };        
        r.ObGrid = process(r.Ob, this.dataMeetingStateRP);
      });
      this.bindMeetings();
    }
    const day = this.fromDatePrintDraft.getDay();
    this.fromDatePrintDraft = new Date(this.fromDatePrintDraft.setDate(this.fromDatePrintDraft.getDate() - day + (day == 0 ? -6:1)));
    const fromDate = new Date(this.fromDatePrintDraft);
    if(this.fromDatePrintDraft){      
      this.toDatePrintDraft = new Date(fromDate.setDate(fromDate.getDate() + 6));
    }
    this.loading = false;
    this.checkSelectionID();
    this.reviewRpbtn = true;
  }

  // Move to previous slide
  getToPrev() {
    this.ngCarousel.prev();
  }

  // Move to next slide
  goToNext() {
    this.ngCarousel.next();
  }

  // Pause slide
  stopCarousel() {
    this.ngCarousel.pause();
  }
}


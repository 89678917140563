import { Component, OnInit } from '@angular/core';
import { AppService } from '../services/app.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { AppSwal } from '../services/app.swal';
import { Location } from '@angular/common';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  changePassword = {
    UserName: '',
    CurrentPassword: '',
    NewPassword: '',
    ConfirmPassword: ''
  };

  constructor(
    private appService: AppService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private appSwal: AppSwal,
    private location: Location
  ) {
    this.authenticationService.getUser();
  }

  ngOnInit() {
  }

  async onChangePassword() {
    this.changePassword.UserName = this.authenticationService.user.UserName;
    const id =  this.changePassword.UserName;
    const result = await this.appService.doPUT('api/Account/ChangePassword', this.changePassword, { id} );
    if (result && result.Status === 1) {
      this.appSwal.showSuccess(result.Msg, false);
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
  }

  onBackPage() {
    this.location.back();
  }
}

import { Component, OnInit, OnDestroy, ViewChild, ElementRef, HostListener } from '@angular/core';
import { AppLanguage } from '../../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../services/app.service';
import { SelectableSettings, PageChangeEvent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { AppSwal } from 'src/app/services/app.swal';
import { IntlService } from '@progress/kendo-angular-intl';
import { Notification } from '../../services/app.notification';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Observable } from 'rxjs/Observable';
import { AppGuid } from 'src/app/services/app.guid';
import { NullInjector } from '@angular/core/src/di/injector';
import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';
import * as XLSX from 'xlsx';
import { AppFile } from 'src/app/services/app.file';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppUtils } from 'src/app/services/app.utils';
import { AppComponent } from '../../app.component';
import { DomSanitizer } from '@angular/platform-browser';
import { saveAs } from '@progress/kendo-file-saver';
import { Http } from '@angular/http';
import { KendoButtonService } from '@progress/kendo-angular-buttons/dist/es2015/button/button.service';
import { DataSource } from '@angular/cdk/table';

@Component({
  selector: 'app-document-meeting',
  templateUrl: './document-meeting.component.html',
  styleUrls: ['./document-meeting.component.css']
})
export class DocumentMeetingComponent implements OnInit {

  isSticky = false
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 140;
  }

  user: any;
  loading = false;
  dataDocumentMeetings = [];
  dataDocumentMeetingsTemp = [];
  dataDocumentMeetingSelectableSettings: SelectableSettings;
  dataDocumentMeetingSort = {
    allowUnsort: true,
    mode: 'multiple'
  };
  public dataDocumentMeetingFocus = {
    Name: true
  };
  dataDocumentMeetingSortByField: SortDescriptor[] = [];

  public WORKING_NUM_PAGING_SKIP = 0;
  public WORKING_NUM_PAGING_TAKE = 50;
  public WORKING_NUM_PAGING_BTN = 5;

  dataDocumentMeetingSkip = this.WORKING_NUM_PAGING_SKIP;
  dataDocumentMeetingPageSize = this.WORKING_NUM_PAGING_TAKE;
  dataDocumentMeetingSelection: number[] = [];
  dataDocumentMeetingItem: any;
  dataDocumentMeetingItemtemp: any;
  myInterval: any;
  infoOpened = false;

  public buttonCount = this.WORKING_NUM_PAGING_BTN;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;

  public dataDocumentMeetingState: State = {
    skip: this.dataDocumentMeetingSkip,
    take: this.dataDocumentMeetingSkip + this.dataDocumentMeetingPageSize,
    filter: {
      logic: 'and',
      filters: []
    }
  };
  dataDocumentMeetingGridDataResult: GridDataResult;

  DocumentMeetings: Array<{ Name: string, ID: string }>;
  DocumentMeetingfile: Array<{ Name: string, ID: string }>;
  DeviceDocumentMeeting: Array<{ Name: string, ID: string }>;


  public uploadSaveUrl = 'saveUrl';
  public uploadRemoveUrl = 'removeUrl';
  public enabled = false;
  public enabledID = false;
  isEnabledSaveAll = false;
  control: any;
  pageName: any;
  controlDefault = true;
  allowMulti = true;
  InputingFile = false;

  searchOption = {
    SearchText: ''
  };

  filesUpload: Array<FileInfo>;
  allowInsertFile = true;
  filesUploadName = "";
  public fileSaveUrl: any;
  urlDownload = this.appService.apiRoot;

  constructor(
    private translate: TranslateService,
    private language: AppLanguage,
    private appService: AppService,
    private appSwal: AppSwal,
    public intl: IntlService,
    private notification: Notification,
    private guid: AppGuid,
    private file: AppFile,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    private appUtils: AppUtils,
    private appComponent: AppComponent,
    private domSanitizer: DomSanitizer,
    private http: Http,
  ) {
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
    this.getControl();
    this.setDefault();
    this.language.default();
    this.setSelectableSettings();
    this.onReload();
    this.getPageName();
  }


  ngOnDestroy(): void {
    if (this.myInterval) { this.myInterval.unsubscribe(); }
  }

  ngOnInit() {
  }
  async getPageName() {
    this.pageName = await this.appControls.getPageName();
  }

  async getControl() {
    this.control = await this.appControls.getControls(this.user.RoleID);
    this.controlDefault = false;
  }

  setSelectableSettings(): void {
    this.allowMulti = !this.allowMulti;
    if (this.InputingFile === true) {
      this.allowMulti = false;
      this.dataDocumentMeetingSelectableSettings = {
        enabled: false,
        mode: 'single'
      };
    } else {
      this.dataDocumentMeetingSelectableSettings = {
        checkboxOnly: false,
        mode: this.allowMulti ? 'multiple' : 'single'
      };
    }
  }

  setSelectableSettingsFile(): void {
    this.dataDocumentMeetingSelectableSettings = {
      enabled: true,
      checkboxOnly: false,
      mode: this.allowMulti ? 'multiple' : 'single'
    };
  }

  onSearchKeyPress(e: any) {
    if (e.keyCode === 13 && this.searchOption.SearchText) {
      this.onSearch();
    }
  }

  async getDocumentMeetings() {
    this.loading = true;
    const dataRequest = {
      searchText: this.searchOption.SearchText
    };

    const result = await this.appService.doGET('api/Document/Search', dataRequest);
    if (result && result.Status) {     

      this.dataDocumentMeetings = result.Data;
      this.dataDocumentMeetingsTemp = this.dataDocumentMeetings;
      this.bindDocumentMeetings();
    }
    this.loading = false;
    this.checkSelectionID();

  }


  checkSelectionID() {
    for (let i = this.dataDocumentMeetingSelection.length - 1; i >= 0; i--) {
      const selectedItem = this.dataDocumentMeetings.find((item) => {
        return item.ID === this.dataDocumentMeetingSelection[i];
      });
      if (!selectedItem) {
        this.dataDocumentMeetingSelection.splice(i, 1);
      }
    }
  }

  setDefault() {
    this.dataDocumentMeetingItem = {
      IsAdd: true,
      ID: '',
      Name: '',
      Path: '',
    };
    this.dataDocumentMeetingItemtemp = Object.assign({}, this.dataDocumentMeetingItem);

    this.enabled = true;
    this.enabledID = false;
    this.filesUpload = [];
    this.dataDocumentMeetingSelection = [];
    this.filesUploadName = "";
    this.fileSaveUrl = `${this.appService.apiRoot}api/Upload/MediaWeb?accountID=${this.user.UserName}&typeData=files`;
  }
  bindtemp(item) {
    this.dataDocumentMeetingItemtemp = Object.assign({}, item);
  }

  onDocumentMeetingPageChange(event: PageChangeEvent) {
    this.dataDocumentMeetingSkip = event.skip;
    this.bindDocumentMeetings();
  }

  async onDocumentMeetingSelectedKeysChange() {
    if (this.dataDocumentMeetingSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }
    if (this.dataDocumentMeetingSelection.length > 1) {
      if (this.allowMulti) {
        return;
      }
      this.appSwal.showWarning(this.translate.instant('SelectSingle'), false);
    } else {
      if (this.dataDocumentMeetings.length > 0 && this.InputingFile === false) {
        const selectedID = this.dataDocumentMeetingSelection[0];
        const selectedItem = this.dataDocumentMeetings.find((item) => {
          return item.ID === selectedID;
        });
        selectedItem.IsAdd = false;
        this.dataDocumentMeetingItem = selectedItem;
        this.bindtemp(this.dataDocumentMeetingItem);
      }
    }
  }

  bindDocumentMeetings() {
    this.dataDocumentMeetingGridDataResult = {
      data: orderBy(this.dataDocumentMeetings, this.dataDocumentMeetingSortByField),
      total: this.dataDocumentMeetings.length
    };

    this.dataDocumentMeetingGridDataResult = process(this.dataDocumentMeetings, this.dataDocumentMeetingState);
  }

  onDocumentMeetingSortChange(sort: SortDescriptor[]): void {
    this.dataDocumentMeetingSortByField = sort;
    this.bindDocumentMeetings();
  }

  public onDocumentMeetingDataStateChange(state: DataStateChangeEvent): void {
    this.dataDocumentMeetingSelection = [];
    this.dataDocumentMeetingState = state;
    this.dataDocumentMeetingGridDataResult = process(this.dataDocumentMeetings, this.dataDocumentMeetingState);
  }

  getColumnIndex(name) {
    const columns = [
      // 'ID',
      'Name',     
      'Path'
    ];

    return columns.indexOf(name);
  }

  
  async selectEventHandler(e: SelectEvent) {
    this.appComponent.loading = true;
    const extension = e.files[0].extension.toLowerCase();
    if (!extension || (extension.toLowerCase() !== '.xlsx' && extension.toLowerCase() !== '.xls' && extension.toLowerCase() !== '.pdf')) {
      this.appSwal.showWarning('Vui lòng chọn tập tin có định dạng .xlsx, .xls.', false);
      return false;
    }

    const fileData = (await this.file.readXLSX(e.files[0].rawFile)) as Array<any>;
    this.InputingFile = true;
    this.setSelectableSettings();
    this.dataDocumentMeetings = [];
    const DocumentMeetingListTemp = this.DocumentMeetings.slice();

    // tslint:disable-next-line:prefer-for-of
    for (let i = 1; i < fileData.length; i++) {
      let name = fileData[i][this.getColumnIndex('Name')];
      if (this.dataDocumentMeetingsTemp.filter(item => item.Name == name).length > 0) {
				this.appSwal.showError(this.translate.instant('DuplicateDataImport'));
				this.isEnabledSaveAll = true;
        this.appComponent.loading = false;
				return
      }
      
      const obj = {
        IsAdd: false,        
        Name: name,        
        Path: fileData[i][this.getColumnIndex('Path')]
      };

      this.dataDocumentMeetings.push(obj);
    }
    this.bindDocumentMeetings();
    this.isEnabledSaveAll = true;
    this.appComponent.loading = false;
  }

  removeEventHandler() {
    this.isEnabledSaveAll = false;
    this.onReload();
  }

  onSearchTextChange(e: any) {
    if (!this.searchOption.SearchText) {
      this.onReload();
    }
  }

  onRemoveSearchText() {
    this.searchOption.SearchText = '';
    this.getDocumentMeetings();
  }


  onSearch() {
    this.getDocumentMeetings();
    this.isEnabledSaveAll = false;
  }

  onReload() {
    this.InputingFile = false;
    this.setSelectableSettingsFile();
    this.searchOption.SearchText = '';
    this.getDocumentMeetings();
    this.isEnabledSaveAll = false;
  }

  onClearDocumentMeeting() {
    this.setDefault();
  }

  onAllowSelectMulti() {
    this.setSelectableSettings();
  }

  async onAddNewDocumentMeeting() {
    this.enabled = true;
    this.enabledID = true;
    this.setDefault();
    this.infoOpened = true;    
  }

  onSaveDocumentMeeting() {
    if (this.dataDocumentMeetingItem.IsAdd) {
      this.addDocumentMeeting();

    } else {
      this.updateDocumentMeeting();
    }

  }

  async onSaveDocumentMeetings() {
    this.appComponent.loading = true;
    const dataRequests = [];
    
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.dataDocumentMeetings.length; i++) {
      // check duplicate data
      let DocumentMeeting = this.dataDocumentMeetings[i];
      const dataRequest = {
        searchText: DocumentMeeting.Name
      };
      const resultDocumentMeetingListFromServer = await this.appService.doGET('api/Document/Search', dataRequest);
      let isDuplicate = false;
      if (resultDocumentMeetingListFromServer && resultDocumentMeetingListFromServer.Data &&
        resultDocumentMeetingListFromServer.Data.length > 0) {
        resultDocumentMeetingListFromServer.Data.forEach(element => {
          if (element.Name == DocumentMeeting.Name) {
            isDuplicate = true;
          }
        });
      }
      if (isDuplicate == false) {
        dataRequests.push(this.createDataRequest(this.dataDocumentMeetings[i]));
      }
    }
    const result = await this.appService.doPOST('api/Document/Saves', dataRequests);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.isEnabledSaveAll = false;
      this.infoOpened = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  createDataRequest(data) {
   
    const temp = data ? data : this.dataDocumentMeetingItemtemp;
    return {
      ID: temp.ID,
      Name: temp.Name,
      Path: temp.Path
    };
  }

  onEditDocumentMeeting() {
    this.infoOpened = true;
    this.enabled = true;
    this.enabledID = false;
  }

  async addDocumentMeeting() {
    this.appComponent.loading = true;
    const dataRequest = this.createDataRequest(null);
    const result = await this.appService.doPOST('api/Document', dataRequest);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.setDefault();
      this.infoOpened = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  async updateDocumentMeeting() {
    this.appComponent.loading = true;
    const id = this.dataDocumentMeetingItem.ID;
    const dataRequest = this.createDataRequest(null);
    const result = await this.appService.doPUT('api/Document', dataRequest, { id });
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.infoOpened = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
      this.onReload();
    }
    this.appComponent.loading = false;
  }

  async onDeleteDocumentMeeting() {
    if (this.dataDocumentMeetingSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }
    this.appComponent.loading = true;
    const dataRequest = {
      IDList: JSON.stringify(this.dataDocumentMeetingSelection),
      FlgRevert: false
    };
    const option = await this.appSwal.showWarning(this.translate.instant('AreYouSure'), true);
    if (option) {
      const result = await this.appService.doPOST('api/Document/Deletes', dataRequest);
      if (result && result.Status === 1) {
        this.notification.showSuccess(result.Msg);
        this.onReload();
        this.setDefault();
        this.enabled = false;
        this.enabledID = false;
        this.dataDocumentMeetingSelection = [];
        this.allowMulti = false;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
    this.appComponent.loading = false;
  }
  onCloseInfo(e) {
    this.setDefault();
    this.infoOpened = false;
  }

  async onSelectFileToUpload(e: SelectEvent) {
    if (!e.files || e.files.length <= 0) {
      return;
    }

    const extension = e.files[0].extension.toLowerCase();

    this.allowInsertFile = true;

    try {
      const fileData = e.files[0];
      const maxMB = 25;
      const maxSizeKB = 1024 * 1024 * maxMB;
      if (fileData.size > maxSizeKB) {
        this.allowInsertFile = false;
        this.appSwal.showWarning(`Kích thước tập tin không được lớn hơn ${maxMB} MB`, false);
        return false;
      }
    } catch {
      this.appSwal.showError(e);
      return false;
    }
  }

  onRemoveFileToUpload() {
    this.dataDocumentMeetingItemtemp.Path = '';
    this.filesUploadName = '';
    this.filesUpload = [];
  }

  onSuccessFileToUpload(e: any) {
    if (!this.allowInsertFile) {
      return;
    }
    try {
      if(this.dataDocumentMeetingItemtemp.Path == undefined){
        this.dataDocumentMeetingItemtemp.Path = '';
      }
      this.dataDocumentMeetingItemtemp.Path =`${e.response.body.Data.DirMedia}${e.response.body.Data.MediaNm[0]}`;
      this.dataDocumentMeetingItemtemp.Name = this.getFileName(this.dataDocumentMeetingItemtemp.Path);
    } catch {
      this.appSwal.showError(e);
    }
  }

  getFileName(fileUrls) {
    var nameFile = "";
    if (fileUrls != "" && fileUrls != null) {
      var urlArr = fileUrls.split("/");
      if (urlArr.length > 0) {
        nameFile = urlArr[urlArr.length - 1];
        if (nameFile != "" && nameFile != null) {
          var indexOfFirst = nameFile.indexOf("_");
          nameFile = nameFile.substring(indexOfFirst + 1);
        }
      }
    }
    return nameFile;
  }
  onRemoveFile(file){
    this.dataDocumentMeetingItemtemp.Path = '';
  }

  getUrlDownload(item) {   
    let url = this.urlDownload.replace(/\"/g, "") + item;
    url = url.replace(/\"/g, '')
    window.open(url);
  }
}

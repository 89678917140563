import { Component, OnInit, HostListener } from '@angular/core';
import { SelectableSettings, PageChangeEvent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../services/app.service';
import { IntlService } from '@progress/kendo-angular-intl';
import { Router } from '@angular/router';
import { AppSwal } from 'src/app/services/app.swal';
@Component({
  selector: 'app-admi-proc',
  templateUrl: './admi-proc.component.html',
  styleUrls: ['./admi-proc.component.css']
})
export class AdmiProcComponent implements OnInit {

  isSticky: boolean = false;
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset > 0;
  }
  docTypeFilter: Array<{ Name: string, ID: string }> = [];
  dataAdProcedureGridDataResult: GridDataResult;
  dataFileUrlsGridDataResult: GridDataResult;

  placeActives: Array<{ Name: string, ID: number }>;
  publicServices: Array<{ Name: string, ID: number }>;

  public LBM_NUM_PAGING_SKIP = 0;
  public LBM_NUM_PAGING_TAKE = 50;
  public LBM_NUM_PAGING_BTN = 5;
  dataOfficeSelection: number[] = [];
  dataOfficeItem: any;
  myInterval: any;

  dataFileUrls: any[];

  type: 0;

  dataAdProcedureSkip = this.LBM_NUM_PAGING_SKIP;
  dataAdProcedurePageSize = this.LBM_NUM_PAGING_TAKE;
  dataAdProcedureSelection: number[] = [];
  dataAdProcedureItem: any;

  dataAdProcedureSort = {
    allowUnsort: true,
    mode: 'multiple'
  };
  public dataAdProcedureFocus = {
    Name: true
  };
  dataAdProcedureSortByField: SortDescriptor[] = [
  ];
  public dataAdProcedureState: State = {
    skip: this.dataAdProcedureSkip,
    take: this.dataAdProcedureSkip + this.dataAdProcedurePageSize,
    filter: {
      logic: 'and',
      filters: []
    },
    //group: [{ field: 'TypeName' }]
  };

  user: any;
  loading = false;

  searchOption = {
    SearchText: '',
    FieldTypeID: null,
    PlaceActive: -1,
    PublicService: -1,
    PostalServiceFlg: null
  };

  dataAdProcedures = [];
  adProceduresFilter = [];

  fieldTypes: Array<{ Name: string, ID: string }>;
  fieldTypesFilter: Array<{ Name: string, ID: string }>;
  fieldTypesSearch: Array<{ Name: string, ID: string }>;

  templateTypes: Array<{ Name: string, ID: string }> = [];
  templateTypesFilter: Array<{ Name: string, ID: string }> = [];

  baseJuridicalTypes: Array<{ Name: string, ID: string }> = [];
  baseJuridicalTypesFilter: Array<{ Name: string, ID: string }> = [];

  urlDownload = this.appService.apiRoot;
  constructor(
    private appService: AppService,
    public intl: IntlService,
    private router: Router,
    private appSwal: AppSwal,
    private translate: TranslateService
  ) {
    this.initDisplay();
    this.onSearch();
  }

  ngOnInit() {

  }

  bindAdProcedures() {
    this.dataAdProcedureGridDataResult = {
      data: orderBy(this.dataAdProcedures, this.dataAdProcedureSortByField),
      total: this.dataAdProcedures.length
    };

    this.dataAdProcedureGridDataResult = process(this.dataAdProcedures, this.dataAdProcedureState);
  }

  onAdProcedureselectedKeysChange(e) {
    if (this.dataAdProcedureSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }
    const selectedID = this.dataAdProcedureSelection[0];
    // navigate to detail
    // this.onGoToDetail(selectedID);
  }

  onAdProceduresortChange(sort: SortDescriptor[]): void {
    this.dataAdProcedureSortByField = sort;
    this.bindAdProcedures();
  }

  public onPostDataStateChange(state: DataStateChangeEvent): void {
    this.dataAdProcedureSelection = [];
    this.dataAdProcedureState = state;
    this.dataAdProcedureGridDataResult = process(this.dataAdProcedures, this.dataAdProcedureState);
  }

  onSearch() {
    this.getAdProcedures();
    // this.isEnabledSaveAll = false;
  }

  onReload() {
    //this.searchOption.SearchText = '';
    this.getAdProcedures();
    // this.isEnabledSaveAll = false;
  }

  async initDisplay() {

    // mau don, to khai
    const resultTemplateType = await this.appService.doGET('api/AdProcedure/GetTemplateType', null);
    if (resultTemplateType && resultTemplateType.Status === 1) {
      this.templateTypes = resultTemplateType.Data;
      this.templateTypesFilter = this.templateTypes.slice();
    }

    // can cu phap ly
    const resultBaseJuridicalType = await this.appService.doGET('api/AdProcedure/GetBaseJuridicalType', null);
    if (resultBaseJuridicalType && resultBaseJuridicalType.Status === 1) {
      this.baseJuridicalTypes = resultBaseJuridicalType.Data;
      this.baseJuridicalTypesFilter = this.baseJuridicalTypes.slice();
    }

    // linh vuc
    const resultFieldType = await this.appService.doGET('api/AdProcedure/GetFieldType', null);
    if (resultFieldType && resultFieldType.Status === 1) {
      this.fieldTypes = resultFieldType.Data;
      this.fieldTypesFilter = this.fieldTypes.slice();
      this.fieldTypesSearch = this.fieldTypes.slice();
    }

    this.placeActives = [
      { Name: 'Tất cả', ID: -1 },
      { Name: 'UBND quận', ID: 0 },
      { Name: 'UBND phường', ID: 1 }
    ];

    this.publicServices = [
      { Name: 'Tất cả', ID: -1 },
      { Name: 'Mức độ 2', ID: 2 },
      { Name: 'Mức độ 3', ID: 3 },
      { Name: 'Mức độ 4', ID: 4 }
    ];
  }

  async getAdProcedures() {
    this.loading = true;
    const dataRequest = {
      SearchText: this.searchOption.SearchText,
      FieldTypeID: this.searchOption.FieldTypeID,
      PlaceActive: this.searchOption.PlaceActive,
      PublicService: this.searchOption.PublicService,
      PostalServiceFlg: this.searchOption.PostalServiceFlg
    };

    const result = await this.appService.doPOST('api/AdProcedure/Search', dataRequest);
    if (result && result.Status === 1) {
      this.dataAdProcedures = result.Data;
      this.adProceduresFilter = this.dataAdProcedures.slice();
      this.bindAdProcedures();
    }
    this.loading = false;
    this.checkSelectionID();
  }

  checkSelectionID() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = this.dataAdProcedureSelection.length - 1; i >= 0; i--) {
      const selectedItem = this.dataAdProcedures.find((item) => {
        return item.ID === this.dataAdProcedureSelection[i];
      });
      if (!selectedItem) {
        this.dataAdProcedureSelection.splice(i, 1);
      }
    }
  }

  onFieldTypeHandleFilter(value) {
    this.fieldTypesFilter = this.fieldTypes.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  onFieldTypeSearchHandleFilter(value) {
    this.fieldTypesSearch = this.fieldTypes.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  onTemplateTypesHandleFilter(value) {
    this.templateTypesFilter = this.templateTypes.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  onBaseJuridicalTypesHandleFilter(value) {
    this.baseJuridicalTypesFilter = this.baseJuridicalTypes.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  onAdProceduresHandleFilter(value) {
    this.adProceduresFilter = this.dataAdProcedures.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  onTypeSearchHandleFilter(value) {
    this.onReload();
  }

  // onTabChanged(event) {
  //   this.searchOption.Type = event.index;
  //   this.onSearch();
  // }

  // downloadFile(fileUrl) {
  //   let data = this.urlDownload + fileUrl;
  //   const blob = new Blob([data], { type: 'text/csv' });
  //   const url= window.URL.createObjectURL(blob);
  //   window.open(url);
  // }

  onGoToDetail(id) {
    this.router.navigate(['portal/admi-proc-detail'], { queryParams: { id } });
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-office-drt2',
  templateUrl: './office-drt2.component.html',
  styleUrls: ['./office-drt2.component.css']
})
export class OfficeDrt2Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppService } from '../../services/app.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-admi-proc-detail',
  templateUrl: './admi-proc-detail.component.html',
  styleUrls: ['./admi-proc-detail.component.css']
})
export class AdmiProcDetailComponent implements OnInit {

  isSticky: boolean = false;
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset > 0;
  }
  dataAdProcDetail = null;
  loading = false;
  tableTest = [
    {"Name" : '12345',
      "Content": "This is content"
    },
    {"Name" : '12345',
      "Content": "This is content2"
    },
    {"Name" : '12345',
      "Content": "This is content3"
    },
    {"Name" : '12345',
      "Content": "This is content4"
    },
    {"Name" : '12345',
      "Content": "This is content5"
    },
    {"Name" : '12345',
      "Content": "This is content6"
    }
  ]
  constructor(
    private activatedRoute: ActivatedRoute,
    private appService: AppService,
    public domSanitizer: DomSanitizer,
  ) {
    this.activatedRoute.queryParams.subscribe((p: any) => {
      if (p && p.id) {
        this.getAdProcDetail(p.id);
      }
    });
   }

  ngOnInit() {
  }

  async getAdProcDetail(id) {
      this.loading = true;
      const dataRequest = {
        id: id
      };
      const result = await this.appService.doGET('api/AdProcedure', dataRequest);
      if (result && result.Status === 1) {
        this.dataAdProcDetail = result.Data;
      }
      this.loading = false;
    
  }

  getUrlDownload(fileUrl) {
    let url = this.appService.apiRoot.replace("\"","") + fileUrl;
    url = url.replace("\"", '')
    return url;
  }

}

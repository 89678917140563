import { Component, OnInit, OnDestroy, ViewChild, ElementRef, HostListener } from '@angular/core';
import { AppLanguage } from '../../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../services/app.service';
import { SelectableSettings, PageChangeEvent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { AppSwal } from 'src/app/services/app.swal';
import { IntlService } from '@progress/kendo-angular-intl';
import { Notification } from '../../services/app.notification';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
import { AppGuid } from 'src/app/services/app.guid';
import { NullInjector } from '@angular/core/src/di/injector';
import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';
import * as XLSX from 'xlsx';
import { AppFile } from 'src/app/services/app.file';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppUtils } from 'src/app/services/app.utils';
import { AppComponent } from '../../app.component';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.css'],
})
export class RoleComponent implements OnInit, OnDestroy {

  isStickySearch = false
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isStickySearch = window.pageYOffset >= 140;
  }

  user: any;
  loading = false;
  dataRoles = [];
  dataRoleSelectableSettings: SelectableSettings;
  dataRoleSort = {
    allowUnsort: true,
    mode: 'multiple'
  };
  public dataRoleFocus = {
    Name: true
  };
  dataRoleSortByField: SortDescriptor[] = [
    // {
    //   field: 'ParentName',
    //   dir: 'asc'
    // }, {
    //   field: 'OrdinalNumber',
    //   dir: 'asc'
    // }
  ];

  public LBM_NUM_PAGING_SKIP = 0;
  public LBM_NUM_PAGING_TAKE = 50;
  public LBM_NUM_PAGING_BTN = 5;

  dataRoleSkip = this.LBM_NUM_PAGING_SKIP;
  dataRolePageSize = this.LBM_NUM_PAGING_TAKE;
  dataRoleSelection: number[] = [];
  dataRoleItem: any;
  myInterval: any;
  pageName: any;

  public buttonCount = this.LBM_NUM_PAGING_BTN;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;

  public dataRoleState: State = {
    skip: this.dataRoleSkip,
    take: this.dataRoleSkip + this.dataRolePageSize,
    filter: {
      logic: 'and',
      filters: []
    }
  };
  dataRoleGridDataResult: GridDataResult;

  roles: Array<{ Name: string, ID: string }>;
  rolesFilter: Array<{ Name: string, ID: string }>;


  public uploadSaveUrl = 'saveUrl';
  public uploadRemoveUrl = 'removeUrl';
  public enabled = false;
  public enabledID = false;
  isEnabledSaveAll = false;
  control: any;
  controlDefault = true;
  allowMulti = true;
  infoOpened = false;

  searchOption = {
    SearchText: ''
  };

  constructor(
    private translate: TranslateService,
    private language: AppLanguage,
    private appService: AppService,
    private appSwal: AppSwal,
    public intl: IntlService,
    private notification: Notification,
    private guid: AppGuid,
    private file: AppFile,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    private appUtils: AppUtils,
    private appComponent: AppComponent,
    private domSanitizer: DomSanitizer,
  ) {
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
    this.getControl();
    this.setDefault();
    this.language.default();
    this.setSelectableSettings();
    this.onReload();
    this.initDisplay();
    this.allData = this.allData.bind(this);
    this.getPageName();
  }

  rolesHandleFilter(value) {
    this.rolesFilter = this.roles.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  ngOnDestroy(): void {
    if (this.myInterval) { this.myInterval.unsubscribe(); }
  }

  ngOnInit() {
  }
  async getPageName() {
    this.pageName = await this.appControls.getPageName();
  }

  async getControl() {
    this.control = await this.appControls.getControls(this.user.RoleID);
    this.controlDefault = false;
  }

  setSelectableSettings(): void {

    this.allowMulti = !this.allowMulti;
    this.dataRoleSelectableSettings = {
      checkboxOnly: false,
      mode: this.allowMulti ? 'multiple' : 'single'
    };
  }

  onSearchKeyPress(e: any) {
    if (e.keyCode === 13 && this.searchOption.SearchText) {
      this.onSearch();
    }
  }

  async getRoles() {
    this.loading = true;
    const dataRequest = {
      searchText: this.searchOption.SearchText
    };

    const result = await this.appService.doGET('api/Role/Search', dataRequest);
    if (result) {
      this.dataRoles = result.Data;
      this.bindRoles();
    }
    this.loading = false;
    this.checkSelectionID();
  }

  checkSelectionID() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = this.dataRoleSelection.length - 1; i >= 0; i--) {
      const selectedItem = this.dataRoles.find((item) => {
        return item.ID === this.dataRoleSelection[i];
      });
      if (!selectedItem) {
        this.dataRoleSelection.splice(i, 1);
      }
    }
  }

  setDefault() {
    this.dataRoleItem = {
      IsAdd: true,
      ID: '',
      Name: '',
      DefaultFlg: false
    };
    this.dataRoleSelection = [];
    this.enabled = true;
    this.enabledID = true;
  }

  onRolePageChange(event: PageChangeEvent) {
    this.dataRoleSkip = event.skip;
    this.bindRoles();
  }

  onRoleSelectedKeysChange() {

    if (this.dataRoleSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }

    if (this.dataRoleSelection.length > 1) {
      if (this.allowMulti) {
        return;
      }
      this.appSwal.showWarning(this.translate.instant('SelectSingle'), false);
    } else {
      const selectedID = this.dataRoleSelection[0];
      const selectedItem = this.dataRoles.find((item) => {
        return item.ID === selectedID;
      });
      selectedItem.IsAdd = false;
      this.dataRoleItem = selectedItem;
      this.enabled = false;
      this.enabledID = false;
    }
  }

  bindRoles() {
    this.dataRoleGridDataResult = {
      data: orderBy(this.dataRoles, this.dataRoleSortByField),
      total: this.dataRoles.length
    };

    this.dataRoleGridDataResult = process(this.dataRoles, this.dataRoleState);
  }

  onRoleSortChange(sort: SortDescriptor[]): void {
    this.dataRoleSortByField = sort;
    this.bindRoles();
  }

  public onRoleDataStateChange(state: DataStateChangeEvent): void {
    this.dataRoleSelection = [];
    this.dataRoleState = state;
    this.dataRoleGridDataResult = process(this.dataRoles, this.dataRoleState);
  }

  getColumnIndex(name) {
    const columns = [
      'ID',
      'Name',
      'DefaultFlg'
    ];

    return columns.indexOf(name);
  }

  async selectEventHandler(e: SelectEvent) {
    this.appComponent.loading = true;

    try {
      const fileData = e.files[0];// await this.file.readFile(e.files[0].rawFile);

      const maxMB = 25;
      const maxSizeKB = 1024 * 1024 * maxMB;
      if (fileData.size > maxSizeKB) {
        this.appSwal.showWarning(`Kích thước tập tin không được lớn hơn ${maxMB} MB`, false);
        return false;
      }
    } catch {
      return false;
    }

    const extension = e.files[0].extension.toLowerCase();
    if (!extension || (extension.toLowerCase() !== '.xlsx' && extension.toLowerCase() !== '.xls' && extension.toLowerCase() !== '.pdf')) {
      
      this.appSwal.showWarning('Vui lòng chọn tập tin có định dạng .xlsx, .xls.', false);

      return false;
    }

    const fileData = (await this.file.readXLSX(e.files[0].rawFile)) as Array<any>;
    
    
    this.dataRoles = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 1; i < fileData.length; i++) {

      this.dataRoles.push({
        IsAdd: false,
        ID: fileData[i][this.getColumnIndex('ID')],
        Name: fileData[i][this.getColumnIndex('Name')],
        DefaultFlg: fileData[i][this.getColumnIndex('DefaultFlg')],
      });
    }
    this.bindRoles();
    this.isEnabledSaveAll = true;
    this.appComponent.loading = false;
  }

  removeEventHandler() {
    this.isEnabledSaveAll = false;
    this.onReload();
  }

  doInterval() {
    this.myInterval = Observable.interval(this.appService.timeInterval).subscribe(() => {
      this.onReload();
    });
  }

  onSearchTextChange(e: any) {
    if (!this.searchOption.SearchText) {
      this.onReload();
    }
  }

  onRemoveSearchText() {
    this.searchOption.SearchText = '';

  }

  async initDisplay() {
    const resultRole = await this.appService.doGET('api/Role', null);
    if (resultRole && resultRole.Status === 1) {
      this.roles = resultRole.Data;
      this.rolesFilter = this.roles.slice();
    }
  }

  onSearch() {
    this.getRoles();
    this.isEnabledSaveAll = false;
  }

  onReload() {
    this.searchOption.SearchText = '';
    this.getRoles();
    this.isEnabledSaveAll = false;
  }

  onClearRole() {
    this.setDefault();
  }

  onAllowSelectMulti() {
    this.setSelectableSettings();
  }

  onAddNewRole() {
    
    this.setDefault();
    this.infoOpened = true;
  }

  onSaveRole() {
    if (this.dataRoleItem.IsAdd) { this.addRole(); } else { this.updateRole(); }
  }

  async onSaveRoles() {
    this.appComponent.loading = true;
    const dataRequests = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.dataRoles.length; i++) {
			let role = this.dataRoles[i];
      const resultRoleListFromServer = await this.appService.doGET('api/Role/Search', { searchText: role.Name });
			let isDuplicate = false;
			if (resultRoleListFromServer && resultRoleListFromServer.Data &&
				resultRoleListFromServer.Data.length > 0) {
          resultRoleListFromServer.Data.forEach(element => {
					if (element.ID == role.ID) {
						isDuplicate = true;
					}
				});
			}
			if (isDuplicate == false) {
				dataRequests.push(this.createDataRequest(this.dataRoles[i]));
			}
		}
    const result = await this.appService.doPOST('api/Role/Saves', dataRequests);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.isEnabledSaveAll = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  createDataRequest(data) {
    const temp = data ? data : this.dataRoleItem;
    return {
      ID: temp.ID,
      Name: temp.Name,
      DefaultFlg: temp.DefaultFlg
    };
  }

  onCloseRole(status: any) {
    this.enabled = false;
    this.enabledID = false;
    this.infoOpened = true;
  }

  onEditRole() {
    this.enabled = true;
    this.enabledID = false;
    this.infoOpened = true;
  }

  async addRole() {
    this.appComponent.loading = true;
    const dataRequest = this.createDataRequest(null);
    const result = await this.appService.doPOST('api/Role', dataRequest);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.setDefault();
      this.infoOpened = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  async updateRole() {
    this.appComponent.loading = true;
    const id = this.dataRoleItem.ID;
    const dataRequest = this.createDataRequest(null);

    const result = await this.appService.doPUT('api/Role', dataRequest, { id });
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.onAddNewRole();
      this.infoOpened = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  async onDeleteRole() {
    if (this.dataRoleSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }
    this.appComponent.loading = true;
    const dataRequest = {
      IDList: JSON.stringify(this.dataRoleSelection),
      FlgRevert: false
    };

    const option = await this.appSwal.showWarning(this.translate.instant('AreYouSure'), true);
    if (option) {
      const result = await this.appService.doPOST('api/Role/Deletes', dataRequest);
      if (result && result.Status === 1) {
        this.notification.showSuccess(result.Msg);
        this.onReload();
        //this.onAddNewRole();
        this.enabled = false;
        this.enabledID = false;
        this.dataRoleSelection = [];
        this.allowMulti = false;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
    this.appComponent.loading = false;
  }

  public allData(): ExcelExportData {
		const result: ExcelExportData = {
			data: process(this.dataRoles, {}).data
		};

		return result;
  }
  onCloseInfo(e) {
    this.setDefault();
    this.infoOpened = false;
  }
}





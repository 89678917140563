import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Output, EventEmitter, Input, HostListener } from '@angular/core';
import { AppLanguage } from '../../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../services/app.service';
import { SelectableSettings, PageChangeEvent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { AppSwal } from 'src/app/services/app.swal';
import { IntlService } from '@progress/kendo-angular-intl';
import { Notification } from '../../services/app.notification';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
import { AppGuid } from 'src/app/services/app.guid';
import { NullInjector } from '@angular/core/src/di/injector';
import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';
import * as XLSX from 'xlsx';
import { AppFile } from 'src/app/services/app.file';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppUtils } from 'src/app/services/app.utils';
import { AppComponent } from '../../app.component';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpProgressEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { of, concat } from 'rxjs';
import { delay } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import { t } from '@angular/core/src/render3';
import { DatePipe } from '@angular/common';
import { Packer } from 'docx';
import { DocumentCreator } from "../../word-generator";

@Component({
  selector: 'app-weekly-report',
  templateUrl: './weekly-report.component.html',
  styleUrls: ['./weekly-report.component.css']
})
export class WeeklyReportComponent implements OnInit, OnDestroy {

  //codeimage
  commentInput = null;

  uploadFlag = {
    banner: false,
    image: false,
    video: false,
  };

  user: any;
  loading = false;
  dataWeeklyReports = [];
  dataWeeklyReportSelectableSettings: SelectableSettings;
  dataWeeklyReportSort = {
    allowUnsort: true,
    mode: 'multiple'
  };
  public dataWeeklyReportFocus = {
    Name: true
  };
  dataWeeklyReportSortByField: SortDescriptor[] = [

  ];

  //imagesUploadName = "";

  public LBM_NUM_PAGING_SKIP = 0;
  public LBM_NUM_PAGING_TAKE = 50;
  public LBM_NUM_PAGING_BTN = 5;

  dataWeeklyReportSkip = this.LBM_NUM_PAGING_SKIP;
  dataWeeklyReportPageSize = this.LBM_NUM_PAGING_TAKE;
  dataWeeklyReportSelection: number[] = [];
  dataWeeklyReportItem: any;
  myInterval: any;
  pageName: any;
  infoOpened = false;

  public buttonCount = this.LBM_NUM_PAGING_BTN;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;
  editOpinion = false;
  editOpinionTB = false;

  public dataWeeklyReportState: State = {
    skip: this.dataWeeklyReportSkip,
    take: this.dataWeeklyReportSkip + this.dataWeeklyReportPageSize,
    filter: {
      logic: 'and',
      filters: []
    },
    group: [{ field: 'UnitName' }]
  };
  dataWeeklyReportGridDataResult: GridDataResult;

  dataFileUrlsGridDataResult: GridDataResult;
  dataFileUrlsSelectableSettings: SelectableSettings;
  dataFileUrlsSkip = this.LBM_NUM_PAGING_SKIP;
  dataFileUrlsPageSize = this.LBM_NUM_PAGING_TAKE;
  dataFileUrlsSelection: number[] = [];

  groupTypes: Array<{ Name: string, ID: string }>;
  groupTypesFilter: Array<{ Name: string, ID: string }>;
  groupTypesSearch: Array<{ Name: string, ID: string }>;

  filesUpload: Array<FileInfo>;
  dataFileUrls: any[];
  public fileSaveUrl: any;

  public uploadSaveUrl = 'saveUrl';
  public uploadRemoveUrl = 'removeUrl';
  public enabled = false;
  public enabledID = false;
  isEnabledSaveAll = false;
  control: any;
  controlDefault = true;
  allowMulti = true;
  allowInsertFile = true;

  // units: Array<{ Name: string, ID: string }>;
  // unitsFilter: Array<{ Name: string, ID: string }>;
  currentDay = new Date();
  
  lastestDayOfYear = new Date(new Date().getFullYear(),11,31);
  filesUploadName = "";
  myRestrictions: FileRestrictions = {
    maxFileSize: 1024 * 1024 * 120
  };
  dayHour = "";
  
  years: any;
  weeks: any;
  isChairman = true;
  weekCurrent = 0;
  viewOnly = false
  saveBtnFlg = true;
  isListed = false;
  
  closeRpTime : number;
  closeRpDate : any;
  timeReOpenRP : any;
  urlDownload = this.appService.apiRoot;
  infoOpinionOpened = false;
  opinionRegular = [];
  outerWidth: any;
  IsReply = false;
  unitRPList: any;
  searchOption = {
    Year: this.currentDay.getFullYear(),
    Week: 0
  };

  levelList: Array<{ Name: string, ID: string }> = [];
  levelListFilter: Array<{ Name: string, ID: string }> = [];

  selectedItem: any;

  OpinionByName: string;
  OpinionTBByName: string;

  constructor(
    private translate: TranslateService,
    private language: AppLanguage,
    private appService: AppService,
    private appSwal: AppSwal,
    public intl: IntlService,
    private notification: Notification,
    private guid: AppGuid,
    private file: AppFile,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    private appUtils: AppUtils,
    private appComponent: AppComponent,
    private domSanitizer: DomSanitizer,
    private datePipe: DatePipe
  ) {
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
    this.getControl();
    this.setDefault();
    this.language.default();
    this.initDisplay();
    this.setSelectableSettings();
    this.onReload();    
    this.allData = this.allData.bind(this);
    this.getPageName();
    
  }

  ngOnDestroy(): void {
  }

  @HostListener('window:resize', ['$event'])
  ngOnInit() {
    
    this.outerWidth = window.outerWidth;
    this.getLevelListFilter();
  }
  
  async getPageName() {
    this.pageName = await this.appControls.getPageName();
  }

  async getControl() {
    this.control = await this.appControls.getControls(this.user.RoleID);
    
    this.controlDefault = false;
  }

  setSelectableSettings(): void {

    this.allowMulti = !this.allowMulti;
    this.dataWeeklyReportSelectableSettings = {
      checkboxOnly: false,
      mode: 'single'
    };

    this.dataFileUrlsSelectableSettings = {
      checkboxOnly: false,
      mode: 'single'
    };
  }

  onGroupTypeHandleFilter(value) {
    this.groupTypesFilter = this.groupTypes.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  onGroupTypeSearchHandleFilter(value) {
    this.groupTypesSearch = this.groupTypes.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  onTypeSearchHandleFilter(value) {
    this.onReload();
  }

  onSearchKeyPress(e: any) {
    // if (e.keyCode === 13 && this.searchOption.SearchText) {
    //   this.onSearch();
    // }
  }

  async getLevelListFilter() {
    const result = await this.appService.doGET('api/Type/GetCompleteLevel', {});
    if (result && result.Status === 1) {
      this.levelList = result.Data;
      this.levelListFilter = this.levelList.slice();
    }
  }

  levelHandleFilter(value) {
    this.levelListFilter = this.levelList.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  async getWeeklyReports() {
    this.loading = true;
    let result;
    const dataRequest = {
      Year: this.searchOption.Year,
      Week: this.searchOption.Week,
    };

    if (this.control.SeeAllRp) {
      result = await this.appService.doPOST('api/WeeklyReport/Search', dataRequest);
    } else if (this.control.SeeAllByUnit) {
      result = await this.appService.doPOST('api/WeeklyReport/GetByUnit', dataRequest);
    } else {
      result = await this.appService.doPOST('api/WeeklyReport/GetByAccount', dataRequest);
    }
    if (result && result.Status === 1) {
      for (let i = 0; i < result.Data.length; i++) {
        if(result.Data[i].FileUrl == null || result.Data[i].FileUrl == ""){
          result.Data[i].FileUrl = [];
        }
        else{
          result.Data[i].FileUrl = JSON.parse(result.Data[i].FileUrl);
        }

        if(result.Data[i].OpinionRegularCt == null || result.Data[i].OpinionRegularCt == ""){
          result.Data[i].OpinionRegularCt = [];
        }
        else{
          result.Data[i].OpinionRegularCt = JSON.parse(result.Data[i].OpinionRegularCt);
        }

        if(result.Data[i].OpinionRegularBy == null || result.Data[i].OpinionRegularBy == ""){
          result.Data[i].OpinionRegularBy = [];
        }
        else{
          result.Data[i].OpinionRegularBy = JSON.parse(result.Data[i].OpinionRegularBy);
        }
      }

      this.dataWeeklyReports = result.Data;
      this.bindWeeklyReports();
    }
    this.loading = false;
    this.checkSelectionID();
  }

  checkSelectionID() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = this.dataWeeklyReportSelection.length - 1; i >= 0; i--) {
      const selectedItem = this.dataWeeklyReports.find((item) => {
        return item.ID === this.dataWeeklyReportSelection[i];
      });
      if (!selectedItem) {
        this.dataWeeklyReportSelection.splice(i, 1);
      }
    }
  }

  async setDefault() {        
    this.dataWeeklyReportItem = {
      viewOnly: false,
      IsAdd: true,
      ProfessionalWorkByProgram: '',
      ProfessionalWorkUnexpected: '',
      SupportActive: '',
      FocusNextWeek: '',
      Offer:'',
      // Chairman: '',
      Opinion: '',
      CreateAt: '',
      CreateBy: this.user.UserName,
      UpdateAt: '',
      UpdateBy: '',
      DelFlg: '',
      ApproveFlg: false,
      FileUrl: [],
      UnitID: this.user.UnitID,
      FromDate: '',
      ToDate: '',
      Week: '',
      Year: this.currentDay.getFullYear(),
      FullName: '',
      OpinionBy: '',
      OptionTB: '',
      OpinionTBBy: '',
      OpinionRegularCt: [],
      OpinionRegularBy: [],
      TBCompleteLevel: null,
      SelfCompleteLevel: null,
    };
    this.opinionRegular = [];
    this.enabled = true;
    this.enabledID = true;
    this.dataWeeklyReportSelection = [];
    this.filesUpload = [];
    this.dataFileUrls = [];
    this.dayHour = '';
    // this.bindFileUrls();
    this.fileSaveUrl = `${this.appService.apiRoot}api/Upload/MediaWeb?accountID=${this.user.UserName}&typeData=files`;
    this.filesUploadName = "";
  }

  //public userLogin = this.user.UserName;

  onPostPageChange(event: PageChangeEvent) {
    this.dataWeeklyReportSkip = event.skip;
    this.bindWeeklyReports();
  }

  async onWeeklyReportselectedKeysChange() {
    this.weekCurrent = this.getWeekNumber();
    if (this.dataWeeklyReportSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }
    
    if (this.dataWeeklyReportSelection.length > 1) {
      
      if (this.allowMulti) {
        return;
      }
      this.appSwal.showWarning(this.translate.instant('SelectSingle'), false);
    } else {
      const selectedID = this.dataWeeklyReportSelection[0];
      const selectedItem = this.dataWeeklyReports.find((item) => {
        return item.ID === selectedID;
      });

      this.selectedItem = Object.assign([], selectedItem);

      selectedItem.IsAdd = false;
      selectedItem.viewOnly = false;
      selectedItem.PublishAt = selectedItem.PublishAt ? new Date(selectedItem.PublishAt) : null;
      this.dataWeeklyReportItem = selectedItem;

      //Get Name OpinionByName and OpinionByNameTB
      this.OpinionByName = this.dataWeeklyReportItem.OpinionBy.split(" - ")[0];
      this.OpinionTBByName = this.dataWeeklyReportItem.OpinionTBBy.split(" - ")[0];
      
      this.opinionRegular = [];
      for(let i = 0; i < this.dataWeeklyReportItem.OpinionRegularCt.length; i++){
        this.opinionRegular.push({
          Opinion: this.dataWeeklyReportItem.OpinionRegularCt[i] ? this.dataWeeklyReportItem.OpinionRegularCt[i] : '',
          OpinionBy: this.dataWeeklyReportItem.OpinionRegularBy[i] ? this.dataWeeklyReportItem.OpinionRegularBy[i] : ''
        });
      }
      if(!this.control.RangeTime){
        
        if(this.user.UserName != this.dataWeeklyReportItem.CreateBy){
          this.isChairman = false;
        }
        if(this.user.UserName == this.dataWeeklyReportItem.CreateBy || (this.control.Approve && this.user.UnitID == this.dataWeeklyReportItem.UnitID)){
          this.isChairman = true;
        }
        if (this.weekCurrent != this.dataWeeklyReportItem.Week){
          this.isChairman = false;
        }
      }else {
        if(this.user.UserName != this.dataWeeklyReportItem.CreateBy){
          this.isChairman = false;
        }
        if(this.user.UserName == this.dataWeeklyReportItem.CreateBy || (this.control.Approve && this.user.UnitID == this.dataWeeklyReportItem.UnitID)){
          this.isChairman = true;
        }
      }
      if (selectedItem.UpdateAt){
        this.dayHour = this.getHour(selectedItem.UpdateAt);
      }
      if (this.unitRPList.find(r => r == selectedItem.UnitID)){
        this.IsReply = true;
      }else {
        this.IsReply = false;
      }
      
      this.enabled = false;
      this.enabledID = false;
    }
  }

  bindWeeklyReports() {
    this.dataWeeklyReportGridDataResult = {
      data: orderBy(this.dataWeeklyReports, this.dataWeeklyReportSortByField),
      total: this.dataWeeklyReports.length
    };

    this.dataWeeklyReportGridDataResult = process(this.dataWeeklyReports, this.dataWeeklyReportState);
  }

  bindFileUrls() {
    this.dataFileUrlsGridDataResult = {
      data: this.dataFileUrls,
      total: this.dataFileUrls.length
    };

    this.dataWeeklyReportGridDataResult = process(this.dataWeeklyReports, this.dataWeeklyReportState);
  }

  onWeeklyReportsortChange(sort: SortDescriptor[]): void {
    this.dataWeeklyReportSortByField = sort;
    this.bindWeeklyReports();
  }

  public onPostDataStateChange(state: DataStateChangeEvent): void {
    this.dataWeeklyReportSelection = [];
    this.dataWeeklyReportState = state;
    this.dataWeeklyReportGridDataResult = process(this.dataWeeklyReports, this.dataWeeklyReportState);
  }

  removeEventHandler(index) {
    this.isEnabledSaveAll = false;
    this.onReload();
  }

  doInterval() {
  }

  onSearchTextChange(e: any) {
    if (!this.searchOption.Year) {
      this.onReload();
    }
  }

  onRemoveSearchText() {
    this.searchOption.Year = this.currentDay.getFullYear();
    this.searchOption.Week = this.dataWeeklyReportItem.Week;
  }

  async initDisplay() {
    // nhom bao cao  
    this.years = [];
    this.weeks = [];
    for (let i = 2019; i < 2100; i++) {
      this.years.push({
        ID: i,
        Name: i
      });
    }
    for (let i = 1; i <= 52; i++) {
      this.weeks.push({
        ID: i,
        Name: i
      });    
    }

    this.searchOption = {
      Year: 0,
      Week: 0
    };
    this.searchOption.Year = this.currentDay.getFullYear();
    this.searchOption.Week = await this.getWeekNumber();   
    if (this.searchOption.Week == 1 && this.currentDay.getMonth() != 0){
      this.searchOption.Year = this.currentDay.getFullYear() + 1;
    } 
     
    const dataRequests = {
      id: this.user.UnitID
    };
    const resultUnit = await this.appService.doGET('api/Unit/SearchUnitByID', dataRequests);
    if (resultUnit && resultUnit.Status === 1) {
      if(resultUnit.Data){
        this.timeReOpenRP = resultUnit.Data.ReOpenFlg;
      }      
    }

    const dataRequest = {
      username: this.user.UserName
    };
    const result = await this.appService.doGET('api/User/GetUnitRpList', dataRequest);
    this.unitRPList = [];
    if (result && result.Status === 1) {
      if(result.Data == null || result.Data == ""){
        result.Data = [];
      }
      else{
        result.Data = JSON.parse(result.Data);
      }
      if(result.Data){
        for (let i = 0; i < result.Data.length; i ++){
          if (result.Data[i] != null){
            this.unitRPList.push(result.Data[i]);
          }          
        }        
      }      
    } 
    if (!this.unitRPList.find(r => r == this.user.UnitID)){
      this.unitRPList.push(this.user.UnitID);
    }
  }

  onSearch() {
    this.isListed = true;
    this.getWeeklyReports();
    this.isEnabledSaveAll = false;
  }

  async onReload() {
    this.getWeeklyReports();
    this.isEnabledSaveAll = false;
  }

  onClearPost() {
    this.setDefault();
  }

  onAllowSelectMulti() {
    this.setSelectableSettings();
  }

  async onAddNewPost() {
    this.saveBtnFlg = false;
    this.weekCurrent = this.getWeekNumber();
    this.setDefault();
    this.currentDay = new Date();
    this.dataWeeklyReportItem.FromDate = new Date(this.currentDay.setDate(this.currentDay.getDate() - this.currentDay.getDay() + (this.currentDay.getDay() == 0 ? -6:1)));
    this.dataWeeklyReportItem.ToDate = new Date(this.currentDay.setDate(this.dataWeeklyReportItem.FromDate.getDate() + 6));
    this.dataWeeklyReportItem.Week = this.getWeekNumber();      
    this.dataWeeklyReportItem.Year = this.searchOption.Year;      
    await this.getCloseRpTime();
    if (this.closeRpDate){
      this.currentDay = new Date();
      if(this.timeReOpenRP){
        
        var timeReOpenRPAfter30 = new Date(this.timeReOpenRP);
        timeReOpenRPAfter30.setMinutes(timeReOpenRPAfter30.getMinutes() + 30);
        if(this.dataWeeklyReportItem.Week == 1 
          && (new Date(this.timeReOpenRP).getTime() <= this.currentDay.getTime() && timeReOpenRPAfter30.getTime() >= this.currentDay.getTime())){
            this.searchOption.Week == 52;
            this.searchOption.Year = this.currentDay.getFullYear() - 1;
            this.dataWeeklyReportItem.FromDate = this.dataWeeklyReportItem.FromDate.setDate(this.dataWeeklyReportItem.FromDate.getDate() - 7);
            this.dataWeeklyReportItem.ToDate = this.dataWeeklyReportItem.ToDate.setDate(this.dataWeeklyReportItem.ToDate.getDate() - 7);
        }        
        if ( (this.searchOption.Week == this.dataWeeklyReportItem.Week - 1) 
        && (new Date(this.timeReOpenRP).getTime() <= this.currentDay.getTime() && timeReOpenRPAfter30.getTime() >= this.currentDay.getTime())){
          this.dataWeeklyReportItem.Week = this.searchOption.Week;
          this.dataWeeklyReportItem.FromDate = this.dataWeeklyReportItem.FromDate.setDate(this.dataWeeklyReportItem.FromDate.getDate() - 7);
          this.dataWeeklyReportItem.ToDate = this.dataWeeklyReportItem.ToDate.setDate(this.dataWeeklyReportItem.ToDate.getDate() - 7);
        }else if ( (this.searchOption.Week == this.dataWeeklyReportItem.Week)) {
        
        }else {
          this.appSwal.showWarning(this.translate.instant('MsgErrorRangeTimeRp'), false);
          return;
        }
      }
      else if (this.closeRpDate.getTime() < this.currentDay.getTime() || this.weekCurrent != this.searchOption.Week){
        this.appSwal.showWarning(this.translate.instant('MsgErrorRangeTimeRp'), false);
        return;
      }
    }else {
      return;
    }
    this.dataWeeklyReportItem.FullName = this.user.FullName
    this.infoOpened = true;
  }

  async onSavePost() {
    if (this.dataWeeklyReportItem.IsAdd) { this.addPost(); } else { this.updatePost(); }
  }

  async onSaveWeeklyReports() {
    this.appComponent.loading = true;
    const dataRequests = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.dataWeeklyReports.length; i++) {
      dataRequests.push(this.createDataRequest(this.dataWeeklyReports[i]));
    }
    const result = await this.appService.doPOST('api/WeeklyReport/Saves', dataRequests);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.isEnabledSaveAll = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  createDataRequest(data) {
    this.currentDay = new Date();
    if (this.dataWeeklyReportItem.Opinion && this.dataWeeklyReportItem.Opinion !== this.selectedItem.Opinion){
      this.dataWeeklyReportItem.OpinionBy = this.user.FullName + " - " + this.datePipe.transform(this.currentDay, 'dd/MM/yyyy');
    }
    if (this.dataWeeklyReportItem.OpinionTB && this.dataWeeklyReportItem.OpinionTB !== this.selectedItem.OpinionTB){
      this.dataWeeklyReportItem.OpinionTBBy = this.user.FullName + " - " + this.datePipe.transform(this.currentDay, 'dd/MM/yyyy');
    }

    this.dataWeeklyReportItem.Year = this.searchOption.Year;

    const temp = data ? data : this.dataWeeklyReportItem;
    var files: any[] = [];
    if (this.dataWeeklyReportItem.FileUrl) {
      for (let i = 0; i < this.dataWeeklyReportItem.FileUrl.length; i++) {
        files.push(this.dataWeeklyReportItem.FileUrl[i]);
      }
    }
    
    var opinionRegularContent: any[] = [];
    var opinionRegularBy: any[] = [];
    if(this.opinionRegular){
      for(let i = 0; i < this.opinionRegular.length; i ++){
        opinionRegularContent.push(this.opinionRegular[i].Opinion)
        opinionRegularBy.push(this.opinionRegular[i].OpinionBy)
      }
    }
    this.opinionRegular = [];
    return {
      ID: temp.ID,
      ProfessionalWorkByProgram: temp.ProfessionalWorkByProgram,
      ProfessionalWorkUnexpected: temp.ProfessionalWorkUnexpected,
      SupportActive: temp.SupportActive,
      FocusNextWeek: temp.FocusNextWeek,
      Offer: temp.Offer,
      // Chairman: temp.Chairman,
      Opinion: temp.Opinion,
      CreateAt: temp.CreateAt ? this.intl.formatDate(new Date(temp.CreateAt), 'yyyy-MM-ddTHH:mm:ss') : null,
      CreateBy: temp.CreateBy,
      UpdateAt: temp.UpdateAt ? this.intl.formatDate(new Date(temp.UpdateAt), 'yyyy-MM-ddTHH:mm:ss') : null,
      UpdateBy: temp.UpdateBy,
      DelFlg: temp.DelFlg,
      ApproveFlg: temp.ApproveFlg,
      ApproveBy: temp.ApproveBy,
      ApproveAt: temp.ApproveAt,
      PublicFlg: temp.PublicFlg,
      PublicBy: temp.PublicBy,
      PublicAt: temp.PublicAt,
      FileUrl: JSON.stringify(files),
      UnitID: temp.UnitID,
      Year: temp.Year,
      Week: temp.Week,
      FromDate: temp.FromDate ? this.intl.formatDate(new Date(temp.FromDate), 'yyyy-MM-ddTHH:mm:ss') : null,
      ToDate: temp.ToDate ? this.intl.formatDate(new Date(temp.ToDate), 'yyyy-MM-ddTHH:mm:ss') : null,
      OpinionBy: temp.OpinionBy ? temp.OpinionBy : '',
      OpinionTB: temp.OpinionTB,
      OpinionTBBy: temp.OpinionTBBy ? temp.OpinionTBBy : '',
      OpinionRegularCt: JSON.stringify(opinionRegularContent),
      OpinionRegularBy: JSON.stringify(opinionRegularBy),
      TBCompleteLevel: temp.TBCompleteLevel,
      SelfCompleteLevel: temp.SelfCompleteLevel,
    };
  }

  onClosePost(status: any) {
    this.enabled = false;
    this.enabledID = false;
    this.infoOpened = true;
  }

  async onEditPost() {
    this.saveBtnFlg = false;
    this.weekCurrent = this.getWeekNumber();
    this.currentDay = new Date();
    if(this.dataWeeklyReportItem.ApproveFlg == true && this.dataWeeklyReportItem.PublicFlg != true){
      this.appSwal.showWarning(this.translate.instant('MsgErrorEditWeekRp'), false);
      return;
    }
    if (!this.control.RangeTime){
      await this.getCloseRpTime();
      if (this.closeRpDate){
        if(this.closeRpDate.getTime() < this.currentDay.getTime() || this.searchOption.Week != this.weekCurrent){
          this.appSwal.showWarning(this.translate.instant('MsgErrorRangeTimeRp'), false);
          return;
        }
      }else {
        return;
      }
    }
    this.enabled = true;
    this.enabledID = false;
    this.infoOpened = true;
  }

  async addPost() {
    this.appComponent.loading = true;
    const dataRequest = this.createDataRequest(null);

    const result = await this.appService.doPOST('api/WeeklyReport', dataRequest);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.setDefault();
      this.infoOpened = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  async updatePost() {
    this.appComponent.loading = true;
    // tslint:disable-next-line: max-line-length
    const id = this.dataWeeklyReportItem.ID;
    const dataRequest = this.createDataRequest(this.dataWeeklyReportItem);

    const result = await this.appService.doPUT('api/WeeklyReport', dataRequest, { id });
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.setDefault();
      this.infoOpened = false;
      this.editOpinion = false;
      this.editOpinionTB = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  async onDeletePost() {
    this.weekCurrent = this.getWeekNumber();
    this.currentDay = new Date();
    if (this.dataWeeklyReportSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }
    if (!this.control.RangeTime){
      await this.getCloseRpTime();
      if (this.closeRpDate){
        if(this.closeRpDate.getTime() < this.currentDay.getTime() || this.searchOption.Week != this.weekCurrent){
          this.appSwal.showWarning(this.translate.instant('MsgErrorRangeTimeRp'), false);
          return;
        }
      }else {
        return;
      }
    }

    for(let i = 0; i < this.dataWeeklyReportSelection.length; i++){
      var weeklyRp = this.dataWeeklyReports.find(x => x.ID == this.dataWeeklyReportSelection[i]);
      if(weeklyRp.ApproveFlg == true || weeklyRp.PublicFlg == true){
        this.appSwal.showWarning(this.translate.instant('MsgErrorDeleteWeekRp'), false);
        return;
      }
    }
    this.appComponent.loading = true;
    const dataRequest = {
      IDList: JSON.stringify(this.dataWeeklyReportSelection),
      FlgRevert: false
    };
    const option = await this.appSwal.showWarning(this.translate.instant('AreYouSure'), true);
    if (option) {
      const result = await this.appService.doPOST('api/WeeklyReport/Deletes', dataRequest);
      if (result && result.Status === 1) {
        this.notification.showSuccess(result.Msg);
        this.onReload();
        this.setDefault();
        this.enabled = false;
        this.enabledID = false;
        this.dataWeeklyReportSelection = [];
        this.allowMulti = false;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
    this.appComponent.loading = false;
  }

  getNameByUrl(fileUrl) {
    const nameFile = fileUrl.replace(/^.*[\\\/]/, '');
    return nameFile;
  }

  async onDownloadFile(file: any) {
    const dataRequest = {
      url: file
    };
    const result = await this.appService.doDownload('api/Upload/DownloadByUrl', dataRequest);
    if (result) {
      FileSaver.saveAs(result, this.getFileName(file));
    }
  }

  onDeleteFile(dataItem: any) {

    for (let i = this.dataFileUrls.length - 1; i >= 0; i--) {
      if (this.dataFileUrls[i].Url === dataItem.Url) {
        this.dataFileUrls.splice(i, 1);
        break;
      }
    }
    this.bindFileUrls();
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.dataWeeklyReports, {}).data
    };
    return result;
  }

  async onSelectFileToUpload(e: SelectEvent) {
    if (!e.files || e.files.length <= 0) {
      return;
    }
    const extension = e.files[0].extension.toLowerCase();
    this.allowInsertFile = true;
    try {
      const fileData = e.files[0]; // await this.file.readFile(e.files[0].rawFile);
      const maxMB = 25;
      const maxSizeKB = 1024 * 1024 * maxMB;
      if (fileData.size > maxSizeKB) {
        this.allowInsertFile = false;
        this.appSwal.showWarning(`Kích thước tập tin không được lớn hơn ${maxMB} MB`, false);
        return false;
      }
    } catch {
      this.appSwal.showError(e);
    }
    // tslint:disable-next-line: max-line-length
    if (!extension || (extension.toLowerCase() !== '.doc' && extension.toLowerCase() !== '.docx' && extension.toLowerCase() !== '.pdf'
    && extension.toLowerCase() !== '.xlsx' && extension.toLowerCase() !== '.xls' && extension.toLowerCase() !== '.zip')) {
      this.allowInsertFile = false;
      this.appSwal.showWarning('Vui lòng chọn tập tin có định dạng .doc, .docx, .pdf, .xlsx, .xls, .zip', false);
      return false;
    }
  }

  onSuccessFileToUpload(e: any) {
    if (!this.allowInsertFile) {
      return;
    }
    try {
      if(this.dataWeeklyReportItem.FileUrl == undefined){
        this.dataWeeklyReportItem.FileUrl = [];
      }
      this.dataWeeklyReportItem.FileUrl.push(`${e.response.body.Data.DirMedia}${e.response.body.Data.MediaNm[0]}`);
    } catch {
      this.appSwal.showError(e);
    }
  }

  onRemoveFileToUpload() {
    this.dataWeeklyReportItem.FileUrl = [];
    this.filesUploadName = '';
    this.filesUpload = [];
  }

  onRemoveFile(file){
    var isExistedInFile = this.dataWeeklyReportItem.FileUrl.findIndex(x => x == file);
    if (isExistedInFile != -1) {
      this.dataWeeklyReportItem.FileUrl.splice(isExistedInFile, 1);
    }
  }

  onCloseInfo(e) {
    this.setDefault();
    this.onReload();
    this.infoOpened = false;
  }

  // unitsHandleFilter(value) {
  //   this.unitsFilter = this.units.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  // }

  getHour(date) {
    var current_day = new Date(date);
    return ((current_day.getHours() < 10) ? "0" : "") + current_day.getHours() + ":" + ((current_day.getMinutes() < 10) ? "0" : "") + current_day.getMinutes()
  }

  getWeekNumber(): number {
    const currentdate = new Date();
    var d = new Date(Date.UTC(currentdate.getFullYear(), currentdate.getMonth(), currentdate.getDate()));
    var dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));     
    if (1 + Math.ceil((((d.valueOf() - yearStart.valueOf()) / 86400000) + 1)/7) == 53 ){
           
      return 1;      
    } else {
      return 1 + Math.ceil((((d.valueOf() - yearStart.valueOf()) / 86400000) + 1)/7)
    }
    
  }
  
  getFileName(fileUrls) {
    var nameFile = "";
    if (fileUrls != "" && fileUrls != null) {
      var urlArr = fileUrls.split("/");
      if (urlArr.length > 0) {
        nameFile = urlArr[urlArr.length - 1];
        if (nameFile != "" && nameFile != null) {
          var indexOfFirst = nameFile.indexOf("_");
          nameFile = nameFile.substring(indexOfFirst + 1);
        }
      }
    }
    return nameFile;
  }

  viewReport(dataItem){
    this.dataWeeklyReportItem = dataItem;

    this.opinionRegular = [];
      for(let i = 0; i < this.dataWeeklyReportItem.OpinionRegularCt.length; i++){
        this.opinionRegular.push({
          Opinion: this.dataWeeklyReportItem.OpinionRegularCt[i] ? this.dataWeeklyReportItem.OpinionRegularCt[i] : '',
          OpinionBy: this.dataWeeklyReportItem.OpinionRegularBy[i] ? this.dataWeeklyReportItem.OpinionRegularBy[i] : ''
        });
      }
    if (!this.control.ViewRPUnapproved && !this.dataWeeklyReportItem.ApproveFlg && this.user.UserName != this.dataWeeklyReportItem.CreateBy){
      this.appSwal.showWarning(this.translate.instant('MsgViewApproveWeekRp'), false);
      return;
    }
    this.saveBtnFlg = true;
    this.viewOnly = true;
    this.enabled = false;
    this.infoOpened = true;
  }

  async onApproved() {
    this.appComponent.loading = true;
    // tslint:disable-next-line: max-line-length
    const id = this.dataWeeklyReportItem.ID;

    const result = await this.appService.doGET('api/WeeklyReport/Approved', { id });
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.setDefault();
      this.infoOpened = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  async onUnApproved() {
    if(this.dataWeeklyReportItem.PublicFlg == true){
      this.appSwal.showWarning(this.translate.instant('MsgErrorUnApproveWeekRp'), false);
      return;
    }
    this.appComponent.loading = true;
    // tslint:disable-next-line: max-line-length
    const id = this.dataWeeklyReportItem.ID;

    const result = await this.appService.doGET('api/WeeklyReport/UnApproved', { id });
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.setDefault();
      this.infoOpened = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  async onPublic() {
    if(this.dataWeeklyReportItem.ApproveFlg != true){
      this.appSwal.showWarning(this.translate.instant('MsgErrorPublicWeekRp'), false);
      return;
    }
    this.appComponent.loading = true;
    // tslint:disable-next-line: max-line-length
    const id = this.dataWeeklyReportItem.ID;

    const result = await this.appService.doGET('api/WeeklyReport/Public', { id });
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.setDefault();
      this.infoOpened = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  async onUnPublic() {
    this.appComponent.loading = true;
    // tslint:disable-next-line: max-line-length
    const id = this.dataWeeklyReportItem.ID;

    const result = await this.appService.doGET('api/WeeklyReport/UnPublic', { id });
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.setDefault();
      this.infoOpened = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  replyReport() {    
    this.saveBtnFlg = false;
    this.viewOnly = false;
    this.enabled = false;
    this.enabledID = false;
    this.infoOpened = true;
  }

  async getCloseRpTime() {
    const result = await this.appService.doGET('api/MailConfig/getCloseRpTime', null);
    
    if (result && result.Status === 1) {
     if(result.Data){
      // this.closeRpTime = parseInt(); 
      this.closeRpDate = new Date(Date.parse(result.Data.Value));
     }     
     else {
      this.appSwal.showWarning(this.translate.instant('MsgErrorRangeTimeRpConfig'), false);
      return;    
     }
    }else {
      this.appSwal.showWarning(this.translate.instant('MsgErrorRangeTimeRpConfig'), false);
    }
  }

  valueChangeWeekFilter(e :any){
    this.onSearch();
  }

  valueChangeYearFilter(e :any){
    this.onSearch();
  }

  getUrlDownload(item) {
    let url = this.urlDownload.replace(/\"/g, "") + item;
    url = url.replace(/\"/g, '')
    return url;
  }

  onAddNewOpinion(){
    this.infoOpinionOpened = true;
  }

  onCloseInfoOpinion(e) {
    this.infoOpinionOpened = false;
  }

  onSaveOpinionRegular(value){
    this.currentDay = new Date();
    this.opinionRegular.push({
      Opinion: value,
      OpinionBy: value ? this.user.FullName + " - " + this.datePipe.transform(this.currentDay, 'dd/MM/yyyy') : null
    });
    this.infoOpinionOpened = false;
  }

  onDotsVideoString(string){
    if (string){
      var width = this.outerWidth;
      var txtLength = 75;
      if(string.length > 75 && width >= 768){
        var orgString = string;
        if(orgString.indexOf(" ", txtLength) == -1 || orgString.indexOf(" ", txtLength) - txtLength > 10){
          txtLength = orgString.lastIndexOf(" ", txtLength);
        }
        if(string == string.toUpperCase()){
          txtLength = txtLength - 15;
        }
        var newString = orgString.slice(0,orgString.indexOf(" ", txtLength)) + "...";
        return newString;
      } else{
        return string;
      }
    }
  }

  onExportPost(){
    var title = Object.assign({}, this.dataWeeklyReportItem);;
    var updatehour = this.dayHour;
    var opinions = this.opinionRegular;
    const documentCreator = new DocumentCreator(this.datePipe);
    const doc = documentCreator.create(
      title,
      updatehour,
      opinions
    );
    var fileName = "Báo cáo_" + title.UnitName + ".docx";
    Packer.toBlob(doc).then(blob => {
      FileSaver(blob, fileName);
      console.log("Document created successfully");
    });
  }

  onEditOpinion(){
    this.editOpinion = true;
  }

  onEditOpinionTB(){
    this.editOpinionTB = true;
  }
}


import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { AppLanguage } from '../../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../services/app.service';
import { SelectableSettings, PageChangeEvent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { AppSwal } from 'src/app/services/app.swal';
import { IntlService } from '@progress/kendo-angular-intl';
import { Notification } from '../../services/app.notification';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
import { AppGuid } from 'src/app/services/app.guid';
import { NullInjector } from '@angular/core/src/di/injector';
import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, UploadEvent, FileInfo } from '@progress/kendo-angular-upload';
import * as XLSX from 'xlsx';
import { AppFile } from 'src/app/services/app.file';
import { AppConsts } from 'src/app/services/app.consts';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { GroupResult, groupBy } from '@progress/kendo-data-query';
import { AppComponent } from '../../app.component';
import { DomSanitizer } from '@angular/platform-browser';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit {

  user: any;
  loading = false;

  dataFiles = [];
  dataFileSelectableSettings: SelectableSettings;
  dataFileSort = {
    allowUnsort: true,
    mode: 'multiple'
  };

  dataFileSortByField: SortDescriptor[] = [
  ];

  public events: string[] = [];


  public fileSaveUrl: any;


  //used for kendo grid
  public WORKING_NUM_PAGING_SKIP = 0;
  public WORKING_NUM_PAGING_TAKE = 50;
  public WORKING_NUM_PAGING_BTN = 5;
  public buttonCount = this.WORKING_NUM_PAGING_BTN;
  // paging in grid
  dataFileSkip = this.WORKING_NUM_PAGING_SKIP;
  dataFilePageSize = this.WORKING_NUM_PAGING_TAKE;

  dataFileSelection: string[] = [];  //to store track of selected rows by ID
  dataFileItem: any; //selected record
  myInterval: any;  //interval time to do async jobs periodically in Observable

  // vars are used for showed FileUrls file
  dataFileIDFileUrl: any;
  dataFileIDFileUrls: any[];

  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;

  // kendo grid
  public dataFileState: State = {
    skip: this.dataFileSkip,
    take: this.dataFileSkip + this.dataFilePageSize,
    filter: {
      logic: 'and',
      filters: []
    }
  };
  // data source for kendo grid
  dataFileGridDataResult: GridDataResult;

  roles: Array<{ ID: string, Name: string }>;
  rolesFilter: Array<{ ID: string, Name: string }>;

  // 'Phân quyền tài liệu' kendo-combobox
  AssignUnitsFiles: Array<any> = [];  // { ID: string, Name: string, GroupBy : string }
  AssignUnitsFilesFilter: Array<any> = [];
  AssignUnitsFiles_GroupResult: GroupResult[];

  public enabled = false;
  public enabledID = false;
  isEnabledSaveAll = false;
  control: any;
  controlDefault = true;
  allowMulti = true;
  pageName: any;

  searchOption = {
    SearchText: ''
  };

  filesUpload: Array<FileInfo>;
  filesUploadName = "";
  infoOpened = false;
  allowInsertFile = false;

  constructor(
    private translate: TranslateService,
    private language: AppLanguage,
    public appService: AppService,
    private appSwal: AppSwal,
    private notification: Notification,
    private file: AppFile,
    private consts: AppConsts,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    private appComponent: AppComponent,
    private appGuid: AppGuid
  ) {
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
    this.getControl();
    this.getPageName();
    this.setDefault();
    this.language.default();
    this.setSelectableSettings();
    this.get4Type();
    this.getSearchMenus();
    this.getFileType();
    this.getMenus();
    this.onReload();
    this.initDisplay();
    this.allData = this.allData.bind(this);
  }

  // used to export all kendo-grid data(not care for paging) to excel file(s)
  public allData(): ExcelExportData {
    this.appComponent.loading = true;
    const result: ExcelExportData = {
      data: process(this.dataFiles, { sort: this.dataFileSortByField }).data
    };
    this.appComponent.loading = false;
    return result;
  }

  rolesHandleFilter(value) {
    this.rolesFilter = this.roles.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  AssignUnitsFilesHandleFilter(value) {
    this.AssignUnitsFilesFilter = this.AssignUnitsFiles.filter((s) => s.ID != null);
    this.AssignUnitsFiles_GroupResult = groupBy(this.AssignUnitsFilesFilter, [{ field: "GroupBy" }]);
  }

  ngOnDestroy(): void {
    if (this.myInterval) { this.myInterval.unsubscribe(); }
  }



  ngOnInit() {
  }

  async getControl() {
    this.control = await this.appControls.getControls(this.user.RoleID);
    this.controlDefault = false;
  }

  async getPageName() {
    this.pageName = await this.appControls.getPageName();
  }

  setSelectableSettings(): void {
    this.allowMulti = !this.allowMulti;
    this.dataFileSelectableSettings = {
      checkboxOnly: false,
      mode: this.allowMulti ? 'multiple' : 'single'
    };
  }

  onSearchKeyPress(e: any) {
    if (e.keyCode === 13 && this.searchOption.SearchText) {
      this.onSearch();
    }
  }

  async getFiles() {
    this.loading = true;
    const dataRequest = {
      searchText: this.searchOption.SearchText,
      menuid: this.searchMenuText,
      type: 1
    };

    const result = await this.appService.doGET('api/File/Search', dataRequest);
    if (result) {
      this.dataFiles = result.Data;
      this.bindFiles();
    }
    this.loading = false;
    this.checkSelectionID();
  }

  checkSelectionID() {
    for (let i = this.dataFileSelection.length - 1; i >= 0; i--) {
      const selectedItem = this.dataFiles.find((item) => {
        return item.ID === this.dataFileSelection[i];
      });
      if (!selectedItem) {
        this.dataFileSelection.splice(i, 1);
      }
    }
  }

  public GUID_EMPTY = "00000000-0000-0000-0000-000000000000";
  dataFileItemtemp: any;
  setDefault() {
    this.dataFileItem = {
      IsAdd: true,
      ID: null,
      Name: '',
      LinkUrl: '',
      FileUrl: '',
      MenuID: this.GUID_EMPTY,
      Type: 1,
      HotFlg: false,
      ActivedFlg: false,
      BannerType: -1,
      OrderIdx: 0,
      CreateAt: null
    };
    this.dataFileItemtemp = {
      IsAdd: true,
      ID: null,
      Name: '',
      LinkUrl: '',
      FileUrl: '',
      MenuID: this.GUID_EMPTY,
      Type: 1,
      HotFlg: false,
      ActivedFlg: false,
      BannerType: -1,
      OrderIdx: 0,
      CreateAt: null
    };
    this.searchMenuText = this.GUID_EMPTY;
    this.searchFiletype = 0;
    this.filesUpload = [];
    this.filesUploadName = "";
    this.fileSaveUrl = `${this.appService.apiRoot}api/Upload/MediaWeb?accountID=${this.user.UserName}&typeData=files`;

    this.enabled = true;
    this.enabledID = true;
    this.dataFileItemtemp.UserName = this.user.UserName;
    this.dataFileSelection = [];
  }

  bindtemp(item) {
    this.dataFileItemtemp.ID = item.ID;
    this.dataFileItemtemp.Name = item.Name;
    this.dataFileItemtemp.LinkUrl = item.LinkUrl;
    this.dataFileItemtemp.FileUrl = item.FileUrl;
    this.dataFileItemtemp.ActivedFlg = item.ActivedFlg;
    this.dataFileItemtemp.HotFlg = item.HotFlg;
    this.dataFileItemtemp.MenuID = item.MenuID;
    this.dataFileItemtemp.Type = item.Type;
    this.dataFileItemtemp.BannerType = item.BannerType;
    this.dataFileItemtemp.OrderIdx = item.OrderIdx;
    this.dataFileItemtemp.CreateAt = item.CreateAt;
  }

  onFilePageChange(event: PageChangeEvent) {
    this.dataFileSkip = event.skip;
    this.bindFiles();
  }

  // handle event of SelectedKeysChange
  async onFileSelectedKeysChange(event) {
    if (this.dataFileSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }
    // flag to check multi-select allowed
    if (this.dataFileSelection.length > 1) {
      if (this.allowMulti) {
        return;
      }
      this.appSwal.showWarning(this.translate.instant('SelectSingle'), false);
    }
    // single select
    else {
      const selectedID = this.dataFileSelection[0];
      const selectedItem = this.dataFiles.find((item) => {
        return item.ID === selectedID;
      });
      selectedItem.IsAdd = false;
      this.dataFileItem = selectedItem;
      this.bindtemp(this.dataFileItem);
      //show file uploaded
      this.filesUpload = [];
      this.filesUploadName = this.getFileName(this.dataFileItem.FileUrl);

      this.enabled = false;
      this.enabledID = false;
    }
  }

  // rebinding data onto kendo grid based on current page
  bindFiles() {
    this.dataFileGridDataResult = {
      data: orderBy(this.dataFiles, this.dataFileSortByField),
      total: this.dataFiles.length
    };

    this.dataFileGridDataResult = process(this.dataFiles, this.dataFileState);
  }

  onFileSortChange(sort: SortDescriptor[]): void {
    this.dataFileSortByField = sort;
    this.bindFiles();
  }

  public onFileDataStateChange(state: DataStateChangeEvent): void {
    this.dataFileSelection = [];
    this.dataFileState = state;
    this.dataFileGridDataResult = process(this.dataFiles, this.dataFileState);
  }

  // column titles of kendo-grid. support for reading .xlsx files
  getColumnIndex(name) {
    let columns = [];
    columns = [
      'Code',
      'SerialNo',
      'Title',
      'Description',
      'UserName',
      'AssignUnits',
      'FileUrls',
      'DelFlg'
    ];
    return columns.indexOf(name);
  }

  // only read the first sheet of .xlsx files and rebind onto kendo grid
  async selectEventHandler(e: SelectEvent) {
    this.appComponent.loading = true;
    const fileData = (await this.file.readXLSX(e.files[0].rawFile)) as Array<any>;
    this.dataFiles = [];
    for (let i = 1; i < fileData.length; i++) {
      if (fileData[i].length > 1) {
        this.dataFiles.push({
          IsAdd: false,
          Code: fileData[i][this.getColumnIndex('Code')],
          SerialNo: fileData[i][this.getColumnIndex('SerialNo')],
          Title: fileData[i][this.getColumnIndex('Title')],
          Description: fileData[i][this.getColumnIndex('Description')],
          UserName: fileData[i][this.getColumnIndex('UserName')],
          AssignUnits: fileData[i][this.getColumnIndex('AssignUnits')],
          FileUrls: fileData[i][this.getColumnIndex('FileUrls')],
          DelFlg: fileData[i][this.getColumnIndex('DelFlg')]
        });
      }
    }
    this.dataFileSelection = [];
    this.bindFiles();
    this.isEnabledSaveAll = true;
    this.appComponent.loading = false;
  }

  // Fires when an uploaded .xlsx file is about to be removed
  removeEventHandler() {
    this.isEnabledSaveAll = false;
    this.onReload();
  }



  // handle changed search-text event
  onSearchTextChange(e: any) {
    if (!this.searchOption.SearchText) {
      this.onReload();
    }
  }

  onRemoveSearchText() {
    this.searchOption.SearchText = '';
    this.getFiles();
  }

  async initDisplay() {
    const resultRole = await this.appService.doGET('api/Role', null);
    if (resultRole && resultRole.Status === 1) {
      this.roles = resultRole.Data;
      this.rolesFilter = this.roles.slice();
    }
  }

  onSearch() {
    this.getFiles();
    this.isEnabledSaveAll = false;
  }

  onReload() {
    this.searchOption.SearchText = '';
    this.getFiles();
    this.isEnabledSaveAll = false;
  }

  // no use
  onClearFile() {
    this.setDefault();
  }

  // handle event of multi-select allowed button
  onAllowSelectMulti() {
    this.setSelectableSettings();
  }

  // handle button AddNew
  onAddNewFile() {
    this.setDefault();

    this.infoOpened = true;
  }

  onSaveFile() {
    if (this.dataFileItem.IsAdd) {
      this.addFile();
    }
    else {
      this.updateFile();
    }
  }

  // store all current data records on kendo grid into db such as data read from .xlsx files
  async onSaveFiles() {
    this.appComponent.loading = true;
    const dataRequests = [];
    for (let i = 0; i < this.dataFiles.length; i++) {
      dataRequests.push(this.createDataRequest(this.dataFiles[i]));
    }
    const result = await this.appService.doPOST('api/File/Saves', dataRequests);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.isEnabledSaveAll = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  // create request sent to server to add new or update data record
  createDataRequest(data) {
    const temp = data ? data : this.dataFileItemtemp;
    return {
      ID: temp.ID,
      Name: temp.Name,
      LinkUrl: temp.LinkUrl,
      FileUrl: temp.FileUrl,
      Type: 1,
      MenuID: temp.MenuID,
      ActivedFlg: true,
      HotFlg: temp.HotFlg,
      OrderIdx: temp.OrderIdx,
      BannerType: 0,
      CreateAt: temp.CreateAt
    };
  }

  // handle event of editing selected file
  onEditFile() {
    this.enabled = true;
    this.enabledID = false;
    this.infoOpened = true;
  }


  async addFile() {
    this.appComponent.loading = true;
    this.dataFileItemtemp.ID = this.appGuid.create();
    const dataRequest = this.createDataRequest(null);
    const result = await this.appService.doPOST('api/File/AddFile', dataRequest);
    if (result) {
      if (result && result.Status === 1) {
        this.notification.showSuccess(result.Msg);
        this.onReload();
        this.onAddNewFile();
        this.dataFileSelection = [];
        this.infoOpened = false;
      } else if (result && result.Status !== 1) {
        if (typeof (result.Msg) != 'undefined' && result.Msg != null) {
          this.appSwal.showError(result.Msg);
        }
      }
      else {
        this.appSwal.showError(JSON.stringify(result));
      }
    } else {
      this.appSwal.showError("Don't connect server API!");
    }
    this.appComponent.loading = false;
  }

  async updateFile() {
    this.appComponent.loading = true;
    const id = this.dataFileItem.ID;
    const dataRequest = this.createDataRequest(null);
    const result = await this.appService.doPUT('api/File/UpdateFile', dataRequest, { id });
    if (result) {
      if (result && result.Status === 1) {
        this.notification.showSuccess(result.Msg);
        this.onReload();
        this.onAddNewFile();
        this.dataFileSelection = [];
        this.infoOpened = false;
      } else if (result && result.Status !== 1) {
        if (typeof (result.Msg) != 'undefined' && result.Msg != null) {
          this.appSwal.showError(result.Msg);
        }
      }
      else {
        this.appSwal.showError(JSON.stringify(result));
      }
    } else {
      this.appSwal.showError("Don't connect server API!");
    }
    this.appComponent.loading = false;
  }

  // delete multi data records
  async onDeleteFile() {
    if (this.dataFileSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }
    this.appComponent.loading = true;
    const dataRequest = {
      IDList: JSON.stringify(this.dataFileSelection),
      FlgRevert: false
    };

    const option = await this.appSwal.showWarning(this.translate.instant('AreYouSure'), true);
    if (option) {
      const result = await this.appService.doPUT('api/File/Deletes', dataRequest, null);
      // if delete all ok
      if (result && result.Status === 1) {
        this.notification.showSuccess(result.Msg);
        this.onReload();
        this.setDefault();
        this.dataFileItem.UserName = '';
        this.enabled = false;
        this.enabledID = false;
        this.dataFileSelection = [];
        this.allowMulti = false;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
    this.appComponent.loading = false;
  }

  //select file to upload
  async onSelectFileToUpload(e: SelectEvent) {
    if (!e.files || e.files.length <= 0) {
      return;
    }

    const extension = e.files[0].extension.toLowerCase();

    this.allowInsertFile = true;

    try {
      const fileData = e.files[0];// await this.file.readFile(e.files[0].rawFile);

      const maxMB = 25;
      const maxSizeKB = 1024 * 1024 * maxMB;
      if (fileData.size > maxSizeKB) {
        this.allowInsertFile = false;
        this.appSwal.showWarning(`Kích thước tập tin không được lớn hơn ${maxMB} MB`, false);
        // this.dataTemplateFileUrls = [];
        // this.bindTemplateFileUrls();
      }
    } catch {
    }

    // tslint:disable-next-line: max-line-length
    if (!extension || (extension.toLowerCase() !== '.mp4')) {
      this.allowInsertFile = false;
      this.appSwal.showWarning('Vui lòng chọn tập tin có định dạng .mp4', false);
      // this.dataTemplateFileUrls = [];
      // this.bindTemplateFileUrls();

      return false;
    }
  }

  //remove file to upload
  onRemoveFileToUpload() {
    this.dataFileItemtemp.FileUrl = '';
    this.filesUploadName = '';
    this.filesUpload = [];
  }

  // public uploadRestrictions: FileRestrictions = {
  //   //allowedExtensions: ['.jpg', '.jpeg', '.tif', '.gif', '.webm', '.mkv', '.flv', '.vob', '.ogv', '.ogg', '.avi', '.TS', '.mov', '.qt', '.wmv', '.yuv', '.rm', '.rmvb', '.asf', '.amv', '.mp4', '.m4p', '.m4v', '.mpg', '.mp2', '.mpeg', '.mpe', '.mpv', '.m2v', '.3gp', '.3g2', '.svi', '.nsv', '.f4v', '.f4p', '.f4a', '.f4b', '.MTS', '.M2TS', '.png']
  //   allowedExtensions: ['.mp4']
  // };

  //success upload
  onSuccessFileToUpload(e: any) {

    if (!this.allowInsertFile) {
      return;
    }

    try {
      if (e.response.body.Data.MediaNm[0].match(/.(jpg|jpeg|tif|png|gif)$/i)) {
        this.dataFileItemtemp.Type = 0;
      } else if (e.response.body.Data.MediaNm[0].match(/.(webm|mkv|flv|vob|ogv|ogg|avi|TS|mov|qt|wmv|yuv|rm|rmvb|asf|amv|mp4|m4p|m4v|mpg|mp2|mpeg|mpe|mpv|m2v|3gp|3g2|svi|nsv|f4v|f4p|f4a|f4b|MTS|M2TS)$/i)) {
        this.dataFileItemtemp.Type = 1;
      }
      else {
        this.filesUpload = [];
        this.onRemoveFileToUpload();
        this.appSwal.showWarning(this.translate.instant('File_IncorrectFormat'), false);
        return;
      }
      this.dataFileItemtemp.FileUrl = `${e.response.body.Data.DirMedia}${e.response.body.Data.MediaNm[0]}`;
    } catch {
      this.filesUpload = [];
      this.appSwal.showError(e);
    }
  }




  getFileName(fileUrls) {
    var nameFile = "temp.txt";
    if (fileUrls != "" && fileUrls != null) {
      var urlArr = fileUrls.split("/");
      if (urlArr.length > 0) {
        nameFile = urlArr[urlArr.length - 1];
        if (nameFile != "" && nameFile != null) {
          var indexOfFirst = nameFile.indexOf("_");
          nameFile = nameFile.substring(indexOfFirst + 1);
        }
      }
    }
    return nameFile;
  }

  parentMenus: Array<{ Name: string, ID: string }>;
  parentMenusFilter: Array<{ Name: string, ID: string }>;

  parentMenusHandleFilter(value) {
    this.parentMenusFilter = this.parentMenus.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }
  async getMenus() {
    this.loading = true;
    const dataRequest = {
      pageID: 'portal/videos'
    };

    const result = await this.appService.doGET('api/Menu/GetMenuByPageID', dataRequest);
    if (result) {
      this.parentMenus = [];
      result.Data.forEach(item => {
        this.parentMenus.push(item);
      });
      this.parentMenusFilter = this.parentMenus.slice();
    }
    this.loading = false;
  }

  Types: Array<{ Name: string, ID: number }>;
  TypesFilter: Array<{ Name: string, ID: number }>;

  get4Type() {
    this.Types = [
      { Name: 'Không hiển thị (Trang chủ)', ID: -1 },
      { Name: 'Banner trái (Trang chủ)', ID: 0 },
      { Name: 'Banner phải (Trang chủ)', ID: 1 }
    ];
    this.TypesFilter = this.Types.slice();
    this.loading = false;
  }

  searchMenus: Array<{ Name: string, ID: string }>;
  searchMenusFilter: Array<{ Name: string, ID: string }>;

  searchMenusHandleFilter(value) {
    this.searchMenusFilter = this.searchMenus.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }
  async getSearchMenus() {

    this.loading = true;
    const dataRequest = {
      pageID: 'portal/videos'
    };

    const result = await this.appService.doGET('api/Menu/GetMenuByPageID', dataRequest);
    if (result) {
      this.searchMenus = [];
      result.Data.forEach(item => {
        this.searchMenus.push(item);
      });
      this.searchMenusFilter = this.searchMenus.slice();
    }
    this.loading = false;
  }

  searchMenuText: string;
  searchFiletype: number;

  MenuSearchChange(value: any) {
    if (value == undefined) {
      this.searchMenuText = "all";
    } else {
      this.searchMenuText = value;
    }
    this.getFiles();
  }

  FileTypeSearchChange(value: any) {
    if (value == undefined) {
      this.searchFiletype = 0;
    } else {
      this.searchFiletype = value;
    }

    this.getFiles();
  }

  FileTypes: Array<{ Name: string, ID: number }>;
  FileTypesFilter: Array<{ Name: string, ID: number }>;
  getFileType() {
    var a = [];
    a[0] = this.translate.instant('allMenu');
    a[1] = this.translate.instant('FileImage');
    a[2] = this.translate.instant('FileVideo');
    this.FileTypes = [];
    for (let i = 0; i < 3; i++) {
      this.FileTypes.push({
        ID: i,
        Name: a[i]
      });
    }
    this.FileTypesFilter = this.FileTypes.slice();
    this.loading = false;
  }

  searchFileTypesHandleFilter(value) {
    this.FileTypesFilter = this.FileTypes.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  onCloseInfo(e) {
    this.setDefault();
    this.infoOpened = false;
  }

}

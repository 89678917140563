import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Output, EventEmitter, Input, HostListener } from '@angular/core';
import { AppLanguage } from '../../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../services/app.service';
import { SelectableSettings, PageChangeEvent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { AppSwal } from 'src/app/services/app.swal';
import { IntlService } from '@progress/kendo-angular-intl';
import { Notification } from '../../services/app.notification';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
import { AppGuid } from 'src/app/services/app.guid';
import { NullInjector } from '@angular/core/src/di/injector';
import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';
import * as XLSX from 'xlsx';
import { AppFile } from 'src/app/services/app.file';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppUtils } from 'src/app/services/app.utils';
import { AppComponent } from '../../app.component';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpProgressEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { of, concat } from 'rxjs';
import { delay } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import { t } from '@angular/core/src/render3';

@Component({
  selector: 'app-office',
  templateUrl: './office.component.html',
  styleUrls: ['./office.component.css']
})
export class OfficeComponent implements OnInit, OnDestroy {



  @Input() officeDrt = 0;

  isSticky = false
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 260;
  }

  //codeimage
  commentInput = null;

  uploadFlag = {
    banner: false,
    image: false,
    video: false,
  };

  user: any;
  loading = false;
  dataOffices = [];
  dataOfficeSelectableSettings: SelectableSettings;
  dataOfficeSort = {
    allowUnsort: true,
    mode: 'multiple'
  };
  public dataOfficeFocus = {
    Name: true
  };
  dataOfficeSortByField: SortDescriptor[] = [
    // {
    //   field: 'ParentName',
    //   dir: 'asc'
    // }, {
    //   field: 'OrdinalNumber',
    //   dir: 'asc'
    // }
  ];

  //imagesUploadName = "";

  public LBM_NUM_PAGING_SKIP = 0;
  public LBM_NUM_PAGING_TAKE = 20;
  public LBM_NUM_PAGING_BTN = 5;

  dataOfficeSkip = this.LBM_NUM_PAGING_SKIP;
  dataOfficePageSize = this.LBM_NUM_PAGING_TAKE;
  dataOfficeSelection: number[] = [];
  dataOfficeItem: any;
  myInterval: any;
  pageName: any;
  infoOpened = false;

  public buttonCount = this.LBM_NUM_PAGING_BTN;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;

  public dataOfficeState: State = {
    skip: this.dataOfficeSkip,
    take: this.dataOfficeSkip + this.dataOfficePageSize,
    filter: {
      logic: 'and',
      filters: []
    },
    //group: [{ field: 'TypeName' }]
  };
  dataOfficeGridDataResult: GridDataResult;

  dataFileUrlsGridDataResult: GridDataResult;
  dataFileUrlsSelectableSettings: SelectableSettings;
  dataFileUrlsSkip = this.LBM_NUM_PAGING_SKIP;
  dataFileUrlsPageSize = this.LBM_NUM_PAGING_TAKE;
  dataFileUrlsSelection: number[] = [];

  officeTypes: Array<{ Name: string, ID: string, ParentName: string }>;
  officeTypesFilter: Array<{ Name: string, ID: string,  ParentName: string}>;
  officeTypesSearch: Array<{ Name: string, ID: string, ParentName: string }>;

  accountTypes: Array<{ Name: string, ID: string, ParentName: string }>;
  accountTypesFilter: Array<{ Name: string, ID: string, ParentName: string }>;
  accountTypesSearch: Array<{ Name: string, ID: string, ParentName: string }>;

  publishTypes: Array<{ Name: string, ID: string }>;
  publishTypesFilter: Array<{ Name: string, ID: string }>;
  publishTypesSearch: Array<{ Name: string, ID: string }>;

  fieldTypes: Array<{ Name: string, ID: string }>;
  fieldTypesFilter: Array<{ Name: string, ID: string }>;
  fieldTypesSearch: Array<{ Name: string, ID: string }>;

  filesUpload: Array<FileInfo>;
  filesUploadName = "";
  dataFileUrls: any[];
  public fileSaveUrl: any;

  types: Array<{ Name: string, ID: number }>;
  priorities: Array<{ Name: string, ID: number }>;

  public uploadSaveUrl = 'saveUrl';
  public uploadRemoveUrl = 'removeUrl';
  public enabled = false;
  public enabledID = false;
  isEnabledSaveAll = false;
  control: any;
  controlDefault = true;
  allowMulti = true;
  allowInsertFile = true;

  currentDate = new Date();
  currentMonth = this.currentDate.getMonth();
  currentYear = this.currentDate.getFullYear();

  searchOption = {
    SearchText: '',
    OfficeTypeID: null,
    PublishTypeID: null,
    CategoryTypeID: null,
    FieldTypeID: null,
    FromDate: null,
    ToDate: null,
    Type: 0
  };

  public min: Date = new Date(1900, 0, 1);
  public max: Date = new Date();

  myRestrictions: FileRestrictions = {
    maxFileSize: 1024 * 1024 * 120
  };


  constructor(
    private translate: TranslateService,
    private language: AppLanguage,
    private appService: AppService,
    private appSwal: AppSwal,
    public intl: IntlService,
    private notification: Notification,
    private guid: AppGuid,
    private file: AppFile,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    private appUtils: AppUtils,
    private appComponent: AppComponent,
    private domSanitizer: DomSanitizer,
  ) {
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
    
  }

  ngOnDestroy(): void {
  }

  ngOnInit() {
    this.getControl();
    this.setDefault();
    this.language.default();
    this.setSelectableSettings();
    this.onReload();
    this.initDisplay();
    this.allData = this.allData.bind(this);
    this.getPageName();
  }

  async getPageName() {
    this.pageName = await this.appControls.getPageName();
  }

  async getControl() {
    this.control = await this.appControls.getControls(this.user.RoleID);
    this.controlDefault = false;
  }

  setSelectableSettings(): void {

    this.allowMulti = !this.allowMulti;
    this.dataOfficeSelectableSettings = {
      checkboxOnly: false,
      mode: this.allowMulti ? 'multiple' : 'single'
    };

    this.dataFileUrlsSelectableSettings = {
      checkboxOnly: false,
      mode: 'single'
    };
  }

  onOfficeTypeHandleFilter(value) {
    this.officeTypesFilter = this.officeTypes.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  onOfficeTypeSearchHandleFilter(value) {
    this.officeTypesSearch = this.officeTypes.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  onPublishTypeHandleFilter(value) {
    this.publishTypesFilter = this.publishTypes.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  onPublishTypeSearchHandleFilter(value) {
    this.publishTypesSearch = this.publishTypes.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  onAccountTypeHandleFilter(value) {
    this.accountTypesFilter = this.accountTypes.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  onAccountTypeSearchHandleFilter(value) {
    this.accountTypesSearch = this.accountTypes.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  onFieldTypeHandleFilter(value) {
    this.fieldTypesFilter = this.fieldTypes.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  onFieldTypeSearchHandleFilter(value) {
    this.fieldTypesSearch = this.fieldTypes.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  onTypeSearchHandleFilter(value) {
    this.onReload();
  }


  onSearchKeyPress(e: any) {
    // if (e.keyCode === 13 && this.searchOption.SearchText) {
    //   this.onSearch();
    // }
  }

  async getOffices() {
    this.loading = true;
    const dataRequest = {
      SearchText: this.searchOption.SearchText,
      OfficeTypeID: this.searchOption.OfficeTypeID,
      PublishTypeID: this.searchOption.PublishTypeID,
      CategoryTypeID: this.searchOption.CategoryTypeID,
      FieldTypeID: this.searchOption.FieldTypeID,
      FromDate: this.searchOption.FromDate ? this.intl.formatDate(new Date(this.searchOption.FromDate), 'yyyy-MM-ddT00:00:00') : null,
      ToDate: this.searchOption.ToDate ? this.intl.formatDate(new Date(this.searchOption.ToDate), 'yyyy-MM-ddT00:00:00') : null,
      Type: this.searchOption.Type
    };

    const result = await this.appService.doPOST('api/Office/Search', dataRequest);
    if (result && result.Status === 1) {
      for(let k = 0; k < result.Data.length; k++){
       
        if(result.Data[k].FileUrl == null || result.Data.FileUrl == ""){
          result.Data[k].FileUrl = [];
        }
        else{          
          result.Data[k].FileUrl = JSON.parse(result.Data[k].FileUrl);         
        }
        if (result.Data[k].Priority == 0){
          result.Data[k].PriorityName = "Khẩn";
        }else if (result.Data[k].Priority == 1){
          result.Data[k].PriorityName = "Bình thường";
        }
      }
      this.dataOffices = result.Data; 

      this.bindOffices();
    }
    this.loading = false;
    this.checkSelectionID();
  }

  checkSelectionID() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = this.dataOfficeSelection.length - 1; i >= 0; i--) {
      const selectedItem = this.dataOffices.find((item) => {
        return item.ID === this.dataOfficeSelection[i];
      });
      if (!selectedItem) {
        this.dataOfficeSelection.splice(i, 1);
      }
    }
  }

  setDefault() {
    this.dataOfficeItem = {
      IsAdd: true,
      SerialNumber: '',
      OfficeTypeID: null,
      PublishAt: '',
      PublishTypeID: '',
      FieldTypeID: null,
      Summary: '',
      SignatureBy: '',
      FileUrl: [],
      CreateAt: new Date(),
      UpdateAt: new Date(),
      DelFlg: false,
      Type: this.officeDrt,
      Title: '',
      Priority: 1,
      OrderIdx: 0,
      UrlPath: ''
    };

    this.searchOption.Type = this.officeDrt;

    this.enabled = true;
    this.enabledID = true;
    this.dataOfficeSelection = [];
    this.filesUpload = [];
    this.filesUploadName = "";
    this.dataFileUrls = [];
    // this.bindFileUrls();
    this.fileSaveUrl = `${this.appService.apiRoot}api/Upload/MediaWeb?accountID=${this.user.UserName}&typeData=files`;

    if(this.currentMonth == 0){
      this.searchOption.FromDate = new Date(this.currentYear - 1, 11, 1);
    }else {
      this.searchOption.FromDate = new Date(this.currentYear, this.currentMonth -1, 1);
    }
    this.searchOption.ToDate = new Date(this.currentYear, this.currentMonth +1, 0);
  }

  onPostPageChange(event: PageChangeEvent) {
    this.dataOfficeSkip = event.skip;
    this.bindOffices();
  }

  async onOfficeselectedKeysChange() {
    
    if (this.dataOfficeSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }
    if (this.dataOfficeSelection.length > 1) {
      if (this.allowMulti) {
        return;
      }
      this.appSwal.showWarning(this.translate.instant('SelectSingle'), false);
    } else {
      const selectedID = this.dataOfficeSelection[0];
      const selectedItem = this.dataOffices.find((item) => {
        return item.ID === selectedID;
      });
      selectedItem.IsAdd = false;
      selectedItem.PublishAt = selectedItem.PublishAt ? new Date(selectedItem.PublishAt) : null;

      this.dataOfficeItem = selectedItem;

      // const fileUrl = selectedItem.FileUrl;
      // this.dataFileUrls = [];
      // if (fileUrl) {
      //   this.dataFileUrls.push({
      //     Name: this.getNameByUrl(selectedItem.FileUrl),
      //     Url: selectedItem.FileUrl
      //   });
      // }
      // this.bindFileUrls();

      this.enabled = false;
      this.enabledID = false;
    }
  }

  bindOffices() {
    this.dataOfficeGridDataResult = {
      data: orderBy(this.dataOffices, this.dataOfficeSortByField),
      total: this.dataOffices.length
    };

    this.dataOfficeGridDataResult = process(this.dataOffices, this.dataOfficeState);
  }

  // bindFileUrls() {
  //   this.dataFileUrlsGridDataResult = {
  //     data: this.dataFileUrls,
  //     total: this.dataFileUrls.length
  //   };

  //   this.dataOfficeGridDataResult = process(this.dataOffices, this.dataOfficeState);
  // }

  onOfficesortChange(sort: SortDescriptor[]): void {
    this.dataOfficeSortByField = sort;
    this.bindOffices();
  }

  public onPostDataStateChange(state: DataStateChangeEvent): void {
    this.dataOfficeSelection = [];
    this.dataOfficeState = state;
    this.dataOfficeGridDataResult = process(this.dataOffices, this.dataOfficeState);
  }

  removeEventHandler(index) {
    this.isEnabledSaveAll = false;
    this.onReload();
  }

  doInterval() {
    // this.myInterval = Observable.interval(this.appService.timeInterval).subscribe(() => {
    //   this.onReload();
    // });
  }

  onSearchTextChange(e: any) {
    if (!this.searchOption.SearchText) {
      this.onReload();
    }
  }

  onRemoveSearchText() {
    this.searchOption.SearchText = '';

  }

  async initDisplay() {
    // loai van ban
    // const officeTypeDataRequest = {
    //   pageID: this.appControls.getPage().replace('data', 'portal')
    // }
    const resultOfficeType = await this.appService.doGET('api/Office/GetOfficeType', null);
    if (resultOfficeType && resultOfficeType.Status === 1) {
      for (let i = 0; i < resultOfficeType.Data.length; i++){
        if(resultOfficeType.Data[i].ParentName != resultOfficeType.Data[i].Name && resultOfficeType.Data[i].ParentName != null){
          resultOfficeType.Data[i].Name = resultOfficeType.Data[i].Name + " (" + resultOfficeType.Data[i].ParentName + ")"
        }       
      }
      this.officeTypes = resultOfficeType.Data;
      this.officeTypesFilter = this.officeTypes.slice();
      this.officeTypesSearch = this.officeTypes.slice();
    }

    // co truong muc
    const resultAccountType = await this.appService.doGET('api/Office/GetCategoryType', null);
    if (resultAccountType && resultAccountType.Status === 1) {
      for (let i = 0; i < resultAccountType.Data.length; i++){
        if(resultAccountType.Data[i].ParentName != resultAccountType.Data[i].Name && resultAccountType.Data[i].ParentName != null){
          resultAccountType.Data[i].Name = resultAccountType.Data[i].Name + " (" + resultAccountType.Data[i].ParentName + ")"
        }       
      }
      this.accountTypes = resultAccountType.Data;
      this.accountTypesFilter = this.accountTypes.slice();
      this.accountTypesSearch = this.accountTypes.slice();
    }

    // co quan ban hanh
    const resultPublishType = await this.appService.doGET('api/Office/GetPublishType', null);
    if (resultPublishType && resultPublishType.Status === 1) {
      this.publishTypes = resultPublishType.Data;
      this.publishTypesFilter = this.publishTypes.slice();
      this.publishTypesSearch = this.publishTypes.slice();
    }

    // linh vuc
    const resultFieldType = await this.appService.doGET('api/Office/GetFieldType', null);
    if (resultFieldType && resultFieldType.Status === 1) {
      this.fieldTypes = resultFieldType.Data;
      this.fieldTypesFilter = this.fieldTypes.slice();
      this.fieldTypesSearch = this.fieldTypes.slice();
    }

    this.types = [
      { Name: 'Thư viện điện tử', ID: 0 },
      { Name: 'Thông tin chỉ đạo', ID: 1 }
    ];

    this.priorities = [
      { Name: 'Khẩn', ID: 0 },
      { Name: 'Bình thường', ID: 1 }
    ];
  }

  onSearch() {
    this.getOffices();
    this.isEnabledSaveAll = false;
  }

  onReload() {
    //this.searchOption.SearchText = '';
    this.getOffices();
    this.isEnabledSaveAll = false;
  }

  onClearPost() {
    this.setDefault();
  }

  onAllowSelectMulti() {
    this.setSelectableSettings();
  }

  onAddNewPost() {
    
    this.setDefault();
    this.infoOpened = true;
  }

  async onSavePost() {
    if (this.dataOfficeItem.IsAdd) { this.addPost(); } else { this.updatePost(); }
  }

  async onSaveOffices() {
    this.appComponent.loading = true;
    const dataRequests = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.dataOffices.length; i++) {
      dataRequests.push(this.createDataRequest(this.dataOffices[i]));
    }
    const result = await this.appService.doPOST('api/Office/Saves', dataRequests);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.isEnabledSaveAll = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  createDataRequest(data) {
    const temp = data ? data : this.dataOfficeItem;

    // let fileName = '';
    // if (this.dataFileUrls.length > 0) {
    //   fileName = this.dataFileUrls[0].Url;
    // }
    var files: any[] = [];
    if (this.dataOfficeItem.FileUrl) {
      for (let i = 0; i < this.dataOfficeItem.FileUrl.length; i++) {
        // this.dataOfficeItem.FileUrl[i] = this.dataOfficeItem.FileUrl[i].replace(" ","_")
        files.push(this.dataOfficeItem.FileUrl[i]);
      }
    }
    //
    return {
      ID: temp.ID,
      SerialNumber: temp.SerialNumber,
      OfficeTypeID: temp.OfficeTypeID,
      PublishAt: temp.PublishAt ? this.intl.formatDate(new Date(temp.PublishAt), 'yyyy-MM-ddT00:00:00') : null,
      PublishTypeID: temp.PublishTypeID,
      CategoryTypeID: temp.CategoryTypeID,
      FieldTypeID: temp.FieldTypeID,
      Summary: temp.Summary,
      SignatureBy: temp.SignatureBy,
      FileUrl: JSON.stringify(files),
      CreateAt: temp.CreateAt,
      UpdateAt: temp.UpdateAt,
      DelFlg: temp.DelFlg,
      Type: temp.Type,
      Title: temp.Title,
      Priority: temp.Priority,
      OrderIdx: temp.OrderIdx,
      UrlPath: temp.UrlPath
    };
  }

  onClosePost(status: any) {
    this.enabled = false;
    this.enabledID = false;
    this.infoOpened = true;
  }

  onEditPost() {
    this.enabled = true;
    this.enabledID = false;
    this.infoOpened = true;
  }

  async addPost() {
    this.appComponent.loading = true;   
    if (this.dataOfficeItem.PublishAt){
      if (this.dataOfficeItem.PublishAt.getTime() < this.min || this.dataOfficeItem.PublishAt.getTime() > this.max ){
        this.appSwal.showWarning(this.translate.instant('RangeDate'), false);
      }else {
        const dataRequest = this.createDataRequest(null);
        const result = await this.appService.doPOST('api/Office', dataRequest);
        if (result && result.Status === 1) {
          this.notification.showSuccess(result.Msg);
          this.onReload();
          this.setDefault();
          this.infoOpened = false;
        } else {
          this.appSwal.showWarning(result.Msg, false);
        }
      }
    }else {
      this.appSwal.showWarning("Ngày ban hành đang rỗng", false);
    }
    
    this.appComponent.loading = false;
  }

  async updatePost() {
    this.appComponent.loading = true;
    // tslint:disable-next-line: max-line-length
    const id = this.dataOfficeItem.ID;
    if (this.dataOfficeItem.PublishAt.getTime() < this.min || this.dataOfficeItem.PublishAt.getTime() > this.max){
      this.appSwal.showWarning(this.translate.instant('RangeDate'), false);
    }else {
      const dataRequest = this.createDataRequest(this.dataOfficeItem);
      const result = await this.appService.doPUT('api/Office', dataRequest, { id });
      if (result && result.Status === 1) {
        this.notification.showSuccess(result.Msg);
        this.onReload();
        this.setDefault();
        this.infoOpened = false;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
    
    this.appComponent.loading = false;
  }

  async onDeletePost() {
    if (this.dataOfficeSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }
    this.appComponent.loading = true;
    const dataRequest = {
      IDList: JSON.stringify(this.dataOfficeSelection),
      FlgRevert: false
    };

    const option = await this.appSwal.showWarning(this.translate.instant('AreYouSure'), true);
    if (option) {
      const result = await this.appService.doPOST('api/Office/Deletes', dataRequest);
      if (result && result.Status === 1) {
        this.notification.showSuccess(result.Msg);
        this.onReload();
        //this.onAddNewPost();
        this.enabled = false;
        this.enabledID = false;
        this.dataOfficeSelection = [];
        this.allowMulti = false;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
    this.appComponent.loading = false;
  }

  // getNameByUrl(fileUrl) {
  //   const nameFile = fileUrl.replace(/^.*[\\\/]/, '');

  //   return nameFile;
  // }

  async onDownloadFile(dataItem: any) {

    const dataRequest = {
      url: dataItem.Url
    };
    const result = await this.appService.doDownload('api/Upload/DownloadByUrl', dataRequest);
    if (result) {
      FileSaver.saveAs(result, dataItem.Name);
    }
  }

  onDeleteFile(dataItem: any) {

    for (let i = this.dataFileUrls.length - 1; i >= 0; i--) {
      if (this.dataFileUrls[i].Url === dataItem.Url) {
        this.dataFileUrls.splice(i, 1);
        break;
      }
    }

    // this.bindFileUrls();
  }

  public allData(): ExcelExportData {
    this.dataOffices.forEach(item =>{
      item.PublishAt = this.intl.formatDate(new Date(item.PublishAt), 'dd/MM/yyyy')
    })
    const result: ExcelExportData = {
      data: process(this.dataOffices, {}).data
    };

    return result;
  }

  async onSelectFileToUpload(e: SelectEvent) {
    if (!e.files || e.files.length <= 0) {
      return;
    }

    const extension = e.files[0].extension.toLowerCase();

    this.allowInsertFile = true;

    try {
      const fileData = e.files[0];// await this.file.readFile(e.files[0].rawFile);

      const maxMB = 25;
      const maxSizeKB = 1024 * 1024 * maxMB;
      if (fileData.size > maxSizeKB) {
        this.allowInsertFile = false;
        this.appSwal.showWarning(`Kích thước tập tin không được lớn hơn ${maxMB} MB`, false);
      }
    } catch {
    }

    // tslint:disable-next-line: max-line-length
    if (!extension || (extension.toLowerCase() !== '.doc' && extension.toLowerCase() !== '.docx' && extension.toLowerCase() !== '.pdf'
    && extension.toLowerCase() !== '.xlsx' && extension.toLowerCase() !== '.xls' && extension.toLowerCase() !== '.zip')) {
      this.allowInsertFile = false;
      this.appSwal.showWarning('Vui lòng chọn tập tin có định dạng .doc, .docx, .pdf, .xlsx, .xls, .zip', false);

      return false;
    }
  }

  onSuccessFileToUpload(e: any) {

    if (!this.allowInsertFile) {
      return;
    }

    try {
      // this.dataFileUrls = [];
      // const k = `${e.response.body.Data.DirMedia}${e.response.body.Data.MediaNm[0]}`;
      // this.dataFileUrls.push({
      //   Name: this.getNameByUrl(k),
      //   Url: k
      // });
      this.dataOfficeItem.FileUrl.push(`${e.response.body.Data.DirMedia}${e.response.body.Data.MediaNm[0]}`);
      // this.bindFileUrls();
    } catch {
      this.appSwal.showError(e);
    }
  }

  getFileName(fileUrls) {
    var nameFile = "";
    if (fileUrls != "" && fileUrls != null) {
      var urlArr = fileUrls.split("/");
      if (urlArr.length > 0) {
        nameFile = urlArr[urlArr.length - 1];
        if (nameFile != "" && nameFile != null) {
          var indexOfFirst = nameFile.indexOf("_");
          nameFile = nameFile.substring(indexOfFirst + 1);
        }
      }
    }
    return nameFile;
  }

  onRemoveFileToUpload() {
    this.dataOfficeItem.FileUrl = [];
    this.filesUploadName = '';
    this.filesUpload = [];
  }
  onCloseInfo(e) {
    this.setDefault();
    this.infoOpened = false;
  }

  onRemoveFile(file){
    var isExistedInFile = this.dataOfficeItem.FileUrl.findIndex(x => x == file);
    if (isExistedInFile != -1) {
      this.dataOfficeItem.FileUrl.splice(isExistedInFile, 1);
    }
  }

  titleChange(e : any){
    if (e){
      this.dataOfficeItem.UrlPath = this.cleanAccents(e);
    }    
  } 
  cleanAccents (str: string){
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g,"a"); 
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g,"e"); 
    str = str.replace(/ì|í|ị|ỉ|ĩ/g,"i"); 
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g,"o"); 
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g,"u"); 
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g,"y"); 
    str = str.replace(/đ/g,"d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    // Some system encode vietnamese combining accent as individual utf-8 characters
    // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư    
    str = str.replace(/[!&\/\\#,+()$~%.'":*?<>{} ]/g,'-');     
    str = str.trim();
    return str;
  }
}


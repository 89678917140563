import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-load-data-auto',
  templateUrl: './load-data-auto.component.html',
  styleUrls: ['./load-data-auto.component.css']
})
export class LoadDataAutoComponent implements OnInit {

  constructor(
    public appService: AppService,
  ) { 

  }

  async UpdatePost(){
    const resultPost = await this.appService.doGET('api/Post/updateUrlPathPostAuto', null);
    if (resultPost && resultPost.Status === 1) {
      console.log(resultPost)
    }
  }

  async UpdateMenu(){
    const resultPost = await this.appService.doGET('api/Post/updateUrlPathMenuAuto', null);
    if (resultPost && resultPost.Status === 1) {
      console.log(resultPost)
    }
  }

  async UpdateOffice(){
    const resultPost = await this.appService.doGET('api/Post/updateUrlPathOfficeAuto', null);
    if (resultPost && resultPost.Status === 1) {
      console.log(resultPost)
    }
  }

  ngOnInit() {
  }

}

import { Component, OnInit, OnDestroy, ViewChild, ElementRef, HostListener } from '@angular/core';
import { AppLanguage } from '../../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../services/app.service';
import { SelectableSettings, PageChangeEvent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { AppSwal } from 'src/app/services/app.swal';
import { IntlService } from '@progress/kendo-angular-intl';
import { Notification } from '../../services/app.notification';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
import { AppGuid } from 'src/app/services/app.guid';
import { NullInjector } from '@angular/core/src/di/injector';
import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';
import * as XLSX from 'xlsx';
import { AppFile } from 'src/app/services/app.file';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppUtils } from 'src/app/services/app.utils';
import { AppComponent } from '../../app.component';


@Component({
  selector: 'app-type',
  templateUrl: './type.component.html',
  styleUrls: ['./type.component.css']
})
export class TypeComponent implements OnInit, OnDestroy {

  isSticky = false
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 140;
  }

  user: any;
  loading = false;
  dataTypes = [];
  dataTypeSelectableSettings: SelectableSettings;
  dataTypeSort = {
    allowUnsort: true,
    mode: 'multiple'
  };
  public dataTypeFocus = {
    Name: true
  };
  dataTypeSortByField: SortDescriptor[] = [
    // {
    //   field: 'ParentName',
    //   dir: 'asc'
    // }, {
    //   field: 'OrdinalNumber',
    //   dir: 'asc'
    // }
  ];

  //Config: Constant for paging
  public LBM_NUM_PAGING_SKIP = 0;
  public LBM_NUM_PAGING_TAKE = 50;
  public LBM_NUM_PAGING_BTN = 5;

  dataPageSkip = this.LBM_NUM_PAGING_SKIP;
  dataTypePageSize = this.LBM_NUM_PAGING_TAKE;
  dataTypeSelection: number[] = [];
  dataTypeItem: any;
  dataTypeItemtemp: any;
  myInterval: any;
  pageName: any;
  infoOpened = false;

  public buttonCount = this.LBM_NUM_PAGING_BTN;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;

  public dataTypeState: State = {
    skip: this.dataPageSkip,
    take: this.dataPageSkip + this.dataTypePageSize,
    filter: {
      logic: 'and',
      filters: []
    }
  };
  dataTypeGridDataResult: GridDataResult;

  parentTypes: Array<{ Name: string, ID: string }>;
  parentTypesFilter: Array<{ Name: string, ID: string }>;

  Types: Array<{ Name: string, ID: number }>;
  TypesFilter: Array<{ Name: string, ID: number }>;

  PageOffices: Array<{ Name: string, ID: number }>;


  public uploadSaveUrl = 'saveUrl';
  public uploadRemoveUrl = 'removeUrl';
  public enabled = false;
  public enabledID = false;
  isEnabledSaveAll = false;
  control: any;
  controlDefault = true;
  allowMulti = true;

  searchOption = {
    SearchText: ''
  };

  constructor(
    private translate: TranslateService,
    private language: AppLanguage,
    private appService: AppService,
    private appSwal: AppSwal,
    public intl: IntlService,
    private notification: Notification,
    private guid: AppGuid,
    private file: AppFile,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    private appUtils: AppUtils,
    private appComponent: AppComponent,
  ) {
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
    this.getControl();
    this.setDefault();
    this.get4Type();
    this.getPageByOffice();
    this.getAllTypes();
    this.language.default();
    this.setSelectableSettings();
    this.onReload();
    this.getPageName();
  }

  parentTypesHandleFilter(value) {
    this.parentTypesFilter = this.parentTypes.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  ngOnDestroy(): void {
    if (this.myInterval) { this.myInterval.unsubscribe(); }
  }

  ngOnInit() {
  }
  async getPageName() {
    this.pageName = await this.appControls.getPageName();
  }

  async getControl() {
    this.control = await this.appControls.getControls(this.user.RoleID);
    this.controlDefault = false;
  }

  setSelectableSettings(): void {

    this.allowMulti = !this.allowMulti;
    this.dataTypeSelectableSettings = {
      checkboxOnly: false,
      mode: this.allowMulti ? 'multiple' : 'single'
    };
  }

  onSearchKeyPress(e: any) {
    if (e.keyCode === 13 && this.searchOption.SearchText) {
      this.onSearch();
    }
  }
  public GUID_EMPTY = "00000000-0000-0000-0000-000000000000";
  async getTypes() {
    this.loading = true;
    const dataRequest = {
      searchText: this.searchOption.SearchText
    };

    const result = await this.appService.doGET('api/Type/Search', dataRequest);
    if (result) {
      this.dataTypes = result.Data;
      this.bindTypes();
    }
    this.loading = false;
    this.checkSelectionID();
  }

  async getAllTypes() {
    this.loading = true;
    const dataRequest = {
      searchText: ''
    };

    const result = await this.appService.doGET('api/Type/Search', dataRequest);
    if (result) {

      this.parentTypes = [];
      this.parentTypes.push({
        ID: this.GUID_EMPTY,
        Name: this.translate.instant('TypeRoot')
      });
      result.Data.forEach(item => {
        this.parentTypes.push(item);
      });
      this.parentTypesFilter = this.parentTypes.slice();
    }
    this.loading = false;
  }

  checkSelectionID() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = this.dataTypeSelection.length - 1; i >= 0; i--) {
      const selectedItem = this.dataTypes.find((item) => {
        return item.ID === this.dataTypeSelection[i];
      });
      if (!selectedItem) {
        this.dataTypeSelection.splice(i, 1);
      }
    }
  }

  setDefault() {
    this.dataTypeItem = {
      IsAdd: true,
      ID: this.GUID_EMPTY,
      Name: null,
      ParentID: this.GUID_EMPTY,
      Type: 0,
      LinkUrl: '',
      DelFlg: false,
      DefaultFlg: false,
      PageID: '',
      OrdinalNumber: null
    };
    this.dataTypeItemtemp = Object.assign({}, this.dataTypeItem);
    this.dataTypeSelection = [];
    this.dataTypeFocus.Name = true;
    this.enabled = true;
    this.enabledID = true;
  }

  bindtemp(item) {
    this.dataTypeItemtemp = Object.assign({}, item);
  }

  onTypePageChange(event: PageChangeEvent) {
    this.dataPageSkip = event.skip;
    this.bindTypes();
  }

  onTypeSelectedKeysChange() {

    if (this.dataTypeSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }

    if (this.dataTypeSelection.length > 1) {
      if (this.allowMulti) {
        return;
      }
      this.appSwal.showWarning(this.translate.instant('SelectSingle'), false);
    } else {
      const selectedID = this.dataTypeSelection[0];
      const selectedItem = this.dataTypes.find((item) => {
        return item.ID === selectedID;
      });
      selectedItem.IsAdd = false;
      this.dataTypeItem = selectedItem;
      this.bindtemp(selectedItem);
      this.enabled = false;
      this.enabledID = false;
    }
  }

  bindTypes() {
    this.dataTypeGridDataResult = {
      data: orderBy(this.dataTypes, this.dataTypeSortByField),
      total: this.dataTypes.length
    };

    this.dataTypeGridDataResult = process(this.dataTypes, this.dataTypeState);
  }

  onTypeSortChange(sort: SortDescriptor[]): void {
    this.dataTypeSortByField = sort;
    this.bindTypes();
  }

  public onTypeDataStateChange(state: DataStateChangeEvent): void {
    this.dataTypeSelection = [];
    this.dataTypeState = state;
    this.dataTypeGridDataResult = process(this.dataTypes, this.dataTypeState);
  }

  getColumnIndex(name) {
    const columns = [
      'NameParentType',
      'ParentID',
      'ID',
      'Name',
      'OrdinalNumber',
      'TypeFlg',
      'ButtonFlg'
    ];

    return columns.indexOf(name);
  }

  async selectEventHandler(e: SelectEvent) {
    this.appComponent.loading = true;
    const fileData = (await this.file.readXLSX(e.files[0].rawFile)) as Array<any>;
    let parentType: any;
    this.dataTypes = [];
    for (let i = 1; i < fileData.length; i++) {
      parentType = this.parentTypes.find(item => {
        return this.appUtils.compareString(fileData[i][this.getColumnIndex('ParentID')], item.Name, item.ID);
      });

      if (fileData[i].indexOf(fileData[i][this.getColumnIndex('NameParentType')]) == -1) {
        this.dataTypes.push({
          IsAdd: false,
          ParentID: parentType ? parentType.ID : null,
          ParentName: parentType ? parentType.Name : null,
          ID: fileData[i][this.getColumnIndex('ID')],
          Name: fileData[i][this.getColumnIndex('Name')],
          OrdinalNumber: fileData[i][this.getColumnIndex('OrdinalNumber')],
          TypeFlg: fileData[i][this.getColumnIndex('TypeFlg')],
          ButtonFlg: fileData[i][this.getColumnIndex('ButtonFlg')],
        });
      }
    }
    this.bindTypes();
    this.isEnabledSaveAll = true;
    this.appComponent.loading = false;
  }

  removeEventHandler() {
    this.isEnabledSaveAll = false;
    this.onReload();
  }

  doInterval() {
    this.myInterval = Observable.interval(this.appService.timeInterval).subscribe(() => {
      this.onReload();
    });
  }

  onSearchTextChange(e: any) {
    if (!this.searchOption.SearchText) {
      this.onReload();
    }
  }

  onRemoveSearchText() {
    this.searchOption.SearchText = '';
    this.getTypes();
  }

  onSearch() {
    this.getTypes();
    this.isEnabledSaveAll = false;
  }

  onReload() {
    this.searchOption.SearchText = '';
    this.getTypes();
    this.isEnabledSaveAll = false;
  }

  onClearType() {
    this.setDefault();
  }

  onAllowSelectMulti() {
    this.setSelectableSettings();
  }

  onAddNewType() {

    this.setDefault();
    this.infoOpened = true;
  }

  onSaveType() {
    if (this.dataTypeItem.IsAdd) { this.addType(); } else { this.updateType(); }
  }

  async onSaveTypes() {
    this.appComponent.loading = true;
    const dataRequests = [];
    for (let i = 0; i < this.dataTypes.length; i++) {
      dataRequests.push(this.createDataRequest(this.dataTypes[i]));
    }
    const result = await this.appService.doPOST('api/Type/Saves', dataRequests);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.isEnabledSaveAll = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  createDataRequest(data) {
    
    const temp = data ? data : this.dataTypeItemtemp;
    return {
      ID: temp.ID,
      Name: temp.Name,
      ParentID: temp.ParentID,
      LinkUrl: temp.LinkUrl,
      Type: temp.Type,
      DelFLgFlg: temp.DelFLgFlg,
      DefaultFlg: temp.DefaultFlg,
      PageID: temp.PageID,
      OrdinalNumber: temp.OrdinalNumber
    };
  }

  onCloseType(status: any) {
    this.enabled = false;
    this.enabledID = false;
    this.infoOpened = true;
  }

  onEditType() {
    this.enabled = true;
    this.enabledID = false;
    this.infoOpened = true;
  }

  async get4Type() {
    const result = await this.appService.doGET('api/Enum/GetETypeType', null);
    if (result && result.Status === 1) {
      this.Types = result.Data;
      this.TypesFilter = this.Types.slice();
    }
  }

  async getPageByOffice() {
    const result = await this.appService.doGET('api/Type/GetPageByOffice', null);
    if (result && result.Status === 1) {
      this.PageOffices = result.Data;
    }

  }

  TypesHandleFilter(value) {
    this.TypesFilter = this.Types.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  async addType() {
    this.appComponent.loading = true;
    const dataRequest = this.createDataRequest(null);
    const result = await this.appService.doPOST('api/Type', dataRequest);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.setDefault();
      this.infoOpened = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  async updateType() {
    this.appComponent.loading = true;
    const id = this.dataTypeItem.ID;
    const dataRequest = this.createDataRequest(null);

    const result = await this.appService.doPUT('api/Type', dataRequest, { id });
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.onAddNewType();
      this.infoOpened = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  async onDeleteType() {
    if (this.dataTypeSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }
    this.appComponent.loading = true;
    const dataRequest = {
      IDList: JSON.stringify(this.dataTypeSelection),
      FlgRevert: false
    };

    const option = await this.appSwal.showWarning(this.translate.instant('AreYouSure'), true);
    if (option) {
      const result = await this.appService.doPOST('api/Type/Deletes', dataRequest);
      if (result && result.Status === 1) {
        this.notification.showSuccess(result.Msg);
        this.onReload();
        //this.onAddNewType();
        this.enabled = false;
        this.enabledID = false;
        this.dataTypeSelection = [];
        this.allowMulti = false;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
    this.appComponent.loading = false;
  }
  onCloseInfo(e) {
    this.setDefault();
    this.infoOpened = false;
  }
}

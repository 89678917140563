import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { AppLanguage } from '../../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../services/app.service';
import { SelectableSettings, PageChangeEvent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { AppSwal } from 'src/app/services/app.swal';
import { IntlService } from '@progress/kendo-angular-intl';
import { Notification } from '../../services/app.notification';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
import { AppGuid } from 'src/app/services/app.guid';
import { NullInjector } from '@angular/core/src/di/injector';
import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';
import * as XLSX from 'xlsx';
import { AppFile } from 'src/app/services/app.file';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';

@Component({
  selector: 'app-auto-number',
  templateUrl: './auto-number.component.html',
  styleUrls: ['./auto-number.component.css']
})
export class AutoNumberComponent implements OnInit, OnDestroy {

  user: any;
  loading = false;
  autoNumbers = [];
  autoNumberSelectableSettings: SelectableSettings;
  autoNumberSort = {
    allowUnsort: true,
    mode: 'multiple'
  };
  public autoNumberFocus = {
    Name: true
  };
  autoNumberSortByField: SortDescriptor[] = [];

  public autoNumberState: State = {
    // skip: 0,
    // take: 5,{ field: 'ProductName', operator: 'contains', value: 'Chef' }
    filter: {
      logic: 'and',
      filters: []
    }
  };
  autoNumberGridDataResult: GridDataResult;

  autoNumberSkip = 0;
  autoNumberPageSize = 50;
  autoNumberSelection: number[] = [];
  autoNumberItem: any;
  myInterval: any;
  autoNumberNames: Array<{ Name: string, ID: number }>;
  autoNumberNamesFilter: Array<{ Name: string, ID: number }>;

  public uploadSaveUrl = 'saveUrl';
  public uploadRemoveUrl = 'removeUrl';
  public autoNumberOpened = false;
  isEnabledSaveAll = false;
  data: any;
  control: any;
  controlDefault = true;

  constructor(
    private translate: TranslateService,
    private language: AppLanguage,
    private appService: AppService,
    private appSwal: AppSwal,
    public intl: IntlService,
    private notification: Notification,
    private guid: AppGuid,
    private file: AppFile,
    private authenticationService: AuthenticationService,
    public appControls: AppControls
  ) {
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
    this.getControl();
    this.setDefault();
    this.language.default();
    this.setSelectableSettings();
    this.onReload();
    this.initDisplay();
  }

  handleFilter(value) {
    this.autoNumberNamesFilter = this.autoNumberNames.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  ngOnDestroy(): void {
    if (this.myInterval) { this.myInterval.unsubscribe(); }
  }

  ngOnInit() {
  }

  async getControl() {
    this.control = await this.appControls.getControls(this.user.RoleID);
    this.controlDefault = false;
  }

  setSelectableSettings(): void {
    this.autoNumberSelectableSettings = {
      checkboxOnly: false,
      mode: 'multiple'
    };
  }

  async getAutoNumbers() {
    this.loading = true;
    const result = await this.appService.doGET('api/AutoNumber', null);
    if (result) {
      this.autoNumbers = result.Data;
      this.bindAutoNumbers();
    }
    this.loading = false;
    this.checkSelectionID();
  }

  checkSelectionID() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = this.autoNumberSelection.length - 1; i >= 0; i--) {
      const selectedItem = this.autoNumbers.find((item) => {
        return item.ID === this.autoNumberSelection[i];
      });
      if (!selectedItem) {
        this.autoNumberSelection.splice(i, 1);
      }
    }
  }

  setDefault() {
    this.autoNumberItem = {
      IsAdd: true,
      ID: '',
      FormatString: '',
      Step: 0,
      CurNumber: 0,
      Reset: false,
    };
    this.autoNumberFocus.Name = true;
  }

  onAutoNumberPageChange(event: PageChangeEvent) {
    this.autoNumberSkip = event.skip;
    this.bindAutoNumbers();
  }

  onAutoNumberSelectedKeysChange() {
  }

  bindAutoNumbers() {
    this.autoNumberGridDataResult = {
      data: orderBy(this.autoNumbers, this.autoNumberSortByField),
      total: this.autoNumbers.length
    };
    this.autoNumberGridDataResult = process(this.autoNumbers, this.autoNumberState);
  }

  onAutoNumberSortChange(sort: SortDescriptor[]): void {
    this.autoNumberSortByField = sort;
    this.bindAutoNumbers();
  }

  public onAutoNumberDataStateChange(state: DataStateChangeEvent): void {
    this.autoNumberSelection = [];
    this.autoNumberState = state;
    this.autoNumberGridDataResult = process(this.autoNumbers, this.autoNumberState);
  }

  async selectEventHandler(e: SelectEvent) {

    const fileData = (await this.file.readXLSX(e.files[0].rawFile)) as Array<any>;

    try {
      const fileData = e.files[0];// await this.file.readFile(e.files[0].rawFile);
      const maxMB = 25;
      const maxSizeKB = 1024 * 1024 * maxMB;
      if (fileData.size > maxSizeKB) {
        this.appSwal.showWarning(`Kích thước tập tin không được lớn hơn ${maxMB} MB`, false);
        return false;
      }
    } catch {
      return false;
    }
    // tslint:disable-next-line:prefer-const
    for (let i = 1; i < fileData.length; i++) {
      this.autoNumbers.push({
        ID: fileData[i][0],
        FormatString: fileData[i][1],
        Step: fileData[i][2],
        CurNumber: fileData[i][3],
        Reset : fileData[i][4],
      });
    }
    this.bindAutoNumbers();
    this.isEnabledSaveAll = true;
  }

  removeEventHandler() {
    this.isEnabledSaveAll = false;
    this.onReload();
  }

  doInterval() {
    this.myInterval = Observable.interval(this.appService.timeInterval).subscribe(() => {
      this.onReload();
    });
  }

  async initDisplay() {
    const resultAutoNumber = await this.appService.doGET('api/Enum/GetEAutoNumberName', null);
    if (resultAutoNumber) {
      this.autoNumberNames = resultAutoNumber.Data;
      this.autoNumberNamesFilter = this.autoNumberNames.slice();
    }
  }
  onReload() {
    this.getAutoNumbers();
  }

  onClearAutoNumber() {
    this.setDefault();
  }

  onAddNewAutoNumber() {
    this.autoNumberOpened = true;
    this.setDefault();
  }

  onSaveAutoNumber() {
    if (this.autoNumberItem.IsAdd) { this.addAutoNumber(); } else { this.updateAutoNumber(); }
  }

  async onSaveAutoNumbers() {
    const dataRequests = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.autoNumbers.length; i++) {
      dataRequests.push(this.createDataRequest(this.autoNumbers[i]));
    }
    const result = await this.appService.doPOST('api/AutoNumber/SaveAll', dataRequests);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.isEnabledSaveAll = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
  }

  createDataRequest(data) {
    const temp = data ? data : this.autoNumberItem;
    return {
      ID: temp.ID,
      FormatString: temp.FormatString,
      Step: temp.Step,
      CurNumber: temp.CurNumber,
      Reset: temp.Reset
    };
  }

  onCloseAutoNumber(status) {
    this.autoNumberOpened = false;
  }
  onCloseUser(status) {
    this.autoNumberOpened = false;
    this.autoNumberNamesFilter = this.autoNumberNames.slice();
  }

  onEditAutoNumber() {
    if (this.autoNumberSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }

    if (this.autoNumberSelection.length > 1) {
      this.appSwal.showWarning(this.translate.instant('SelectSingle'), false);
    } else {
      const selectedID = this.autoNumberSelection[0];
      const selectedItem = this.autoNumbers.find((item) => {
        return item.ID === selectedID;
      });

      if (selectedItem) {
        selectedItem.IsAdd = false;
        this.autoNumberItem = selectedItem;
        this.autoNumberOpened = true;
      }
    }
  }

  async addAutoNumber() {
    const dataRequest = this.createDataRequest(null);
    const result = await this.appService.doPOST('api/AutoNumber', dataRequest);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.onAddNewAutoNumber();
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
  }

  async updateAutoNumber() {
    const id = this.autoNumberItem.ID;
    const dataRequest = this.createDataRequest(null);

    const result = await this.appService.doPUT('api/AutoNumber', dataRequest, { id });
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
  }

  async onDeleteAutoNumber() {
    if (this.autoNumberSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }

    const dataRequest = {
      IDSs: this.autoNumberSelection,
      DeleteBy: this.user.UserName
    };

    const option = await this.appSwal.showWarning(this.translate.instant('AreYouSure'), true);
    if (option) {
      const result = await this.appService.doPOST('api/AutoNumber/Deletes', dataRequest);
      if (result && result.Status === 1) {
        this.notification.showSuccess(result.Msg);
        this.onReload();
        this.autoNumberSelection = [];
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-right',
  templateUrl: './right.component.html',
  styleUrls: ['./right.component.css']
})
export class RightComponent implements OnInit {

  listBanners: any[];

  constructor(
    public appService: AppService
  ) {
    this.onReload();
  }

  ngOnInit() {
  }

  async onReload() {
    const resultBanner = await this.appService.doGET('api/Post/GetBannerRight', null);
    if (resultBanner && resultBanner.Status === 1) {
      this.listBanners = resultBanner.Data;
    }
  }

}

import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Output, EventEmitter, HostListener } from '@angular/core';
import { AppLanguage } from '../../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../services/app.service';
import { SelectableSettings, PageChangeEvent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { AppSwal } from 'src/app/services/app.swal';
import { IntlService } from '@progress/kendo-angular-intl';
import { Notification } from '../../services/app.notification';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
import { AppGuid } from 'src/app/services/app.guid';
import { NullInjector } from '@angular/core/src/di/injector';
import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';
import * as XLSX from 'xlsx';
import { AppFile } from 'src/app/services/app.file';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppUtils } from 'src/app/services/app.utils';
import { AppComponent } from '../../app.component';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpProgressEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { of, concat } from 'rxjs';
import { delay } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.css']
})
export class CreateAccountComponent implements OnInit {

  isSticky: boolean = false;
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset > 0;
  }

  otpCode = '';
  isOpenOtp = false;
  isEdit = true;
  dataCommentItem: any;
  title: any;

  constructor(
    private translate: TranslateService,
    private language: AppLanguage,
    private appService: AppService,
    private appSwal: AppSwal,
    public intl: IntlService,
    private notification: Notification,
    private guid: AppGuid,
    private file: AppFile,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    private appUtils: AppUtils,
    private appComponent: AppComponent,
    private domSanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.getPageName();
    this.setDefault();

    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (params && params.title) {
        this.title = params.title;
      }
    });
  }

  ngOnInit() {
  }

  pageName: any;

  setDefault() {
    this.dataCommentItem = {
      FullName: null,
      Password: null,
      UserName: null,
      Address: null,
      Phone: null,
      PhoneOffice: null,
      Email: null,
      otpCode: '',
    };
    this.isOpenOtp = false;
    this.isEdit = true;
    // this.fileSaveUrl = `${this.appService.apiRoot}api/Upload/MediaWeb?accountID=${this.user.UserName}&typeData=files`;
  }

  createDataRequest(data) {
    const temp = data ? data : this.dataCommentItem;
    return {
      FullName: temp.FullName,
      Password: temp.Password,
      UserName: temp.UserName,
      Address: temp.Address,
      Phone: temp.Phone,
      PhoneOffice: temp.PhoneOffice,
      Email: temp.Email,
    };
  }

  createDataRequestMail(data) {
    const temp = data ? data : this.dataCommentItem;
    return {
      email: temp.Email
    };
  }

  async getOTP() {
    const dataRequest = this.createDataRequest(null);
    const email = this.createDataRequestMail(null);
    //var email = this.dataCommentItem.Email;
    const result = await this.appService.doPOST('api/LoginAsk/OTP', dataRequest);
    if (result && result.Status === 1) {
      this.otpCode = result.Data;
      this.isOpenOtp = true;
      this.isEdit = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
  }

  async addUser() {
    if (this.dataCommentItem.otpCode == this.otpCode) {
      this.appComponent.loading = true;
      const dataRequest = this.createDataRequest(null);
      const result = await this.appService.doPOST('api/LoginAsk/addNew', dataRequest);
      if (result && result.Status === 1) {
        // this.notification.showSuccess(result.Msg);
        // this.onAddNewComment();
        const accountInfo = {
          UserName: dataRequest.UserName,
          FullName: dataRequest.FullName
        };
        localStorage.setItem('accountInfoPortal', JSON.stringify(accountInfo));

        this.router.navigate(['portal/comment-list'], { queryParams: { title: this.title } });
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
      this.appComponent.loading = false;
    }
    else {
      this.appSwal.showWarning("Mã OTP Không Chính Xác", false);
    }

  }

  onAddNewComment() {
    this.setDefault();
  }

  async getPageName() {
    this.pageName = await this.appControls.getPageName();
  }

  onBack() {
    this.isOpenOtp = false;
    this.isEdit = true;
    this.dataCommentItem.otpCode = '';
  }
}

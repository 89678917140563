import { Alignment, AlignmentType, Document, HeadingLevel, Packer, Paragraph, TabStopPosition, TabStopType, TextRun, UnderlineType } from "docx";
import { DatePipe } from '@angular/common';
import { AlignService } from '@progress/kendo-angular-popup';

export class DocumentCreator {
    constructor(
        private datePipe: DatePipe
    ) { }

    public create(title, updatehour, opinions) {
        const document = new Document();
        document.addSection({
            children: [
                this.createPrefix(),
                this.createTitle(title.Week, title.Year, title.FromDate, title.ToDate, updatehour, title.UpdateAt, title.UnitName),
                this.createFirstHeading('I. THỰC HIỆN CÔNG TÁC CHUYÊN MÔN', '1. Nội dung công việc theo chương trình công tác, nhiệm vụ chuyên môn', 1000),
                this.createDescription(title.ProfessionalWorkByProgram),
                this.createSubHeading('2. Nội dung công việc theo phân công đột xuất của Thường trực, Ban Thường vụ', 0),
                this.createDescription(title.ProfessionalWorkUnexpected),
                this.createHeading('II. THAM GIA DỰ - HỖ TRỢ HOẠT ĐỘNG CƠ SỞ', 200),
                this.createDescription(title.SupportActive),
                this.createHeading('III. TRỌNG TÂM TUẦN TỚI', 200),
                this.createDescription(title.FocusNextWeek),
                this.createHeading('IV. ĐỀ XUẤT/KIẾN NGHỊ (nếu có)', 200),
                this.createDescription(title.Offer),
                this.createReporter(title.UnitName),
                this.createCenterHeading('Ý KIẾN CỦA PHÓ CHỦ TỊCH PHỤ TRÁCH', 200),
                this.createDescription(title.Opinion),
                this.createReporter(title.OpinionBy),
                this.createCenterHeading('Ý KIẾN CỦA THƯỜNG TRỰC', 200),
                ...opinions.map((opinion) => {
                    const arr: Paragraph[] = [];
                    arr.push(this.createComment(opinion.Opinion));
                    arr.push(this.createCommentator(opinion.OpinionBy));
                    return arr
                    
                        
                    
                     
                }).reduce((prev, curr) => prev.concat(curr), []),
                this.createFileName('Tài liệu đính kèm', title.FileUrl),
                
            ]
        });
        return document;
    }

    public createTitle(week: string, year: string, fromDate: string, toDate: string, hour: string, updateDate: Date, unitName: string): Paragraph {
        if(hour == null || updateDate == null){
            return new Paragraph({
                spacing: {
                    before: 400,
                    after: 0
                },
                alignment: AlignmentType.CENTER,
                heading: HeadingLevel.HEADING_2,
                children: [
                    new TextRun({
                        text: `BÁO CÁO TUẦN: `,
                        bold: true,
                        color: '#000000'
                    }),
                    new TextRun({
                        text: `${week}/${year} - `,
                        color: '#000000'
                    }),
                    new TextRun({
                        text: `${unitName}`,
                        bold: true,
                        color: '#000000'
                    }),
    
                    new TextRun({
                        text: `TỪ NGÀY `,
                        bold: true,
                        color: '#000000'
                    }).break(),
                    new TextRun({
                        text: `${this.datePipe.transform(new Date(fromDate), 'dd/MM/yyyy')}`,
                        color: '#000000'
                    }),
                    new TextRun({
                        text: ` ĐẾN NGÀY `,
                        bold: true,
                        color: '#000000'
                    }),
                    new TextRun({
                        text: `${this.datePipe.transform(new Date(toDate), 'dd/MM/yyyy')}`,
                        color: '#000000'
                    }),
                ],
            });
        } else {
            return new Paragraph({
                spacing: {
                    before: 400,
                    after: 0
                },
                alignment: AlignmentType.CENTER,
                heading: HeadingLevel.HEADING_2,
                children: [
                    new TextRun({
                        text: `BÁO CÁO TUẦN: `,
                        bold: true,
                        color: '#000000'
                    }),
                    new TextRun({
                        text: `${week}/${year} - `,
                        color: '#000000'
                    }),
                    new TextRun({
                        text: `${unitName}`,
                        bold: true,
                        color: '#000000'
                    }),
    
                    new TextRun({
                        text: `TỪ NGÀY `,
                        bold: true,
                        color: '#000000'
                    }).break(),
                    new TextRun({
                        text: `${this.datePipe.transform(new Date(fromDate), 'dd/MM/yyyy')}`,
                        color: '#000000'
                    }),
                    new TextRun({
                        text: ` ĐẾN NGÀY `,
                        bold: true,
                        color: '#000000'
                    }),
                    new TextRun({
                        text: `${this.datePipe.transform(new Date(toDate), 'dd/MM/yyyy')}`,
                        color: '#000000'
                    }),
    
                    new TextRun({
                        text: `Thời gian cập nhật  `,
                        bold: true,
                        color: '#000000'
                    }).break(),
                    new TextRun({
                        text: `${hour}, `,
                        color: '#000000'
                    }),
                    new TextRun({
                        text: `${this.datePipe.transform(new Date(updateDate), 'dd/MM/yyyy')}`,
                        color: '#000000'
                    }),
                ],
            });
        }
        
    }

    public createFirstHeading(text: string, subText: string, before: number): Paragraph {
        return new Paragraph({
            spacing: {
                before: before,
                after: 0
            },
            children: [
                new TextRun({
                    text: `${text}`,
                    color: '#000000',
                    italics: false,
                    bold: true,
                    size: 26
                }),
                new TextRun({
                    text: `${subText}`,
                    color: '#000000',
                    italics: false,
                    bold: true,
                    size: 26
                }).break(),
            ]
        });
    }

    public createHeading(text: string, before: number): Paragraph {
        return new Paragraph({
            spacing: {
                before: before,
                after: 0
            },
            children: [
                new TextRun({
                    text: `${text}`,
                    color: '#000000',
                    italics: false,
                    bold: true,
                    size: 26
                }),
            ]
        });
    }

    public createCenterHeading(text: string, before: number): Paragraph {
        return new Paragraph({
            alignment: AlignmentType.CENTER,
            spacing: {
                before: before,
                after: 0
            },
            children: [
                new TextRun({
                    text: `${text}`,
                    color: '#000000',
                    italics: false,
                    bold: true,
                    size: 26
                }),
            ]
        });
    }

    public createReporter(unitName: string): Paragraph {
        return new Paragraph({
            alignment: AlignmentType.RIGHT,
            spacing: {
                before: 0,
                after: 0
            },
            children: [
                new TextRun({
                    text: `Người báo cáo`,
                    color: '#000000',
                    italics: false,
                    bold: true,
                    size: 26
                }),
                new TextRun({
                    text: `${unitName}`,
                    color: '#000000',
                    italics: false,
                    size: 26
                }).break(),
            ]
        });
    }

    public createCommentator(unitName: string): Paragraph {
        return new Paragraph({

            alignment: AlignmentType.RIGHT,
            children: [
                new TextRun({
                    text: `Người báo cáo`,
                    color: '#000000',
                    italics: false,
                    bold: true,
                    size: 26
                }),
                new TextRun({
                    text: `${unitName}`,
                    color: '#000000',
                    italics: false,
                    size: 26
                }).break(),
            ]
        });
    }

    public createSubHeading(text: string, before: number): Paragraph {
        return new Paragraph({
            spacing: {
                before: before,
                after: 0
            },
            children: [
                new TextRun({
                    text: `${text}`,
                    color: '#000000',
                    italics: false,
                    bold: true,
                    size: 26
                }),
            ]
        });
    }



    public createDescription(text: string): Paragraph {
        return new Paragraph({
            spacing: {
                before: 0,
                after: 0
            },
            children: [
                new TextRun({
                    text: `${text}`,
                    color: '#000000',
                    size: 24
                }),
            ]
        });
    }

    public createComment(text: string): Paragraph {
        return new Paragraph({
            spacing: {
                before: 200,
                after: 0
            },
            children: [
                new TextRun({
                    text: `${text}`,
                    color: '#000000',
                    size: 24
                }),
            ]
        });
    }

    
    public createDescriptionBy(text: string): Paragraph {
        return new Paragraph({
            alignment: AlignmentType.RIGHT,
            children: [
                new TextRun({
                    text: `${text}`,
                    color: '#000000',
                    size: 15
                }).break(),
            ]
        });
    }

    

    // public createPrefix(): Paragraph {
    //     return new Paragraph({
    //         tabStops: [
    //             {
    //                 type: TabStopType.RIGHT,
    //                 position: TabStopPosition.MAX,
    //             },
    //         ],
    //         children: [
    //             new TextRun({
    //                 children: [
    //                     new TextRun({
    //                         text: `TỔNG LIÊN ĐOÀN LAO ĐỘNG VIỆT NAM`,
    //                         color: '#000000',
    //                         size: 20,
    //                     }),
    //                     new TextRun({
    //                         text: `LIÊN ĐOÀN LAO ĐỘNG`,
    //                         color: '#000000',
    //                         size: 20,
    //                         bold: true
    //                     }).break(),
    //                     new TextRun({
    //                         text: `THÀNH PHỐ HỒ CHÍ MINH`,
    //                         color: '#000000',
    //                         size: 20,
    //                         bold: true
    //                     }).break()
    //                 ]
    //             }),
    //             new TextRun({
    //                 children: [
    //                     new TextRun({
    //                         text: `CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM`,
    //                         color: '#000000',
    //                         size: 20,
    //                         bold: true
    //                     }),
    //                     new TextRun({
    //                         text: `Độc lập - Tự do - Hạnh phúc`,
    //                         color: '#000000',
    //                         size: 20,
    //                         bold: true
    //                     }).break()
    //                 ]
    //             }),
    //         ]
    //     });
    // }

    public createPrefix(): Paragraph {
        return new Paragraph({
            heading: HeadingLevel.HEADING_2,
            indent: {
                left: -500,
                right: -1000
            },
            tabStops: [
                {
                    type: TabStopType.RIGHT,
                    position: TabStopPosition.MAX
                },
            ],
            children: [
                new TextRun({
                    text: `TỔNG LIÊN ĐOÀN LAO ĐỘNG VIỆT NAM`,
                    color: '#000000',
                    characterSpacing: -20
                }),
                new TextRun({
                    text: `         CỘNG HÒA XÃ HỘI CHỦ NGHĨA VIỆT NAM`,
                    bold: true,
                    color: '#000000',
                    characterSpacing: -5
                }),
                new TextRun({
                    text: `                LIÊN ĐOÀN LAO ĐỘNG                                          `,
                    color: '#000000',
                    bold: true,
                    characterSpacing: -10
                }).break(),
                new TextRun({
                    text: `Độc lập - Tự do - Hạnh phúc`,
                    color: '#000000',
                    bold: true,
                }),
                new TextRun({
                    text: `            THÀNH PHỐ HỒ CHÍ MINH`,
                    color: '#000000',
                    bold: true,
                    characterSpacing: -10,
                }).break(),
                new TextRun({
                    text: `                                                  _____________________________________`,
                    superScript: true,
                    color: '#000000',
                }),
                new TextRun({
                    text: `                                     _______________`,
                    superScript: true,
                    color: '#000000',
                }).break(),
            ],
        });
    }

    getFileName(fileUrls) {
        var nameFile = "";
        if (fileUrls != "" && fileUrls != null) {
          var urlArr = fileUrls.split("/");
          if (urlArr.length > 0) {
            nameFile = urlArr[urlArr.length - 1];
            if (nameFile != "" && nameFile != null) {
              var indexOfFirst = nameFile.indexOf("_");
              nameFile = nameFile.substring(indexOfFirst + 1);
            }
          }
        }
        return nameFile;
      }

      public createFileName(text, fileUrls): Paragraph{
          if(fileUrls != null && fileUrls != undefined && fileUrls != []){
            return new Paragraph({
                spacing: {
                    before: 200,
                    after: 0
                },
                children: [
                    new TextRun({
                        text: `${text}`,
                        color: '#000000',
                        italics: false,
                        bold: true,
                        size: 26
                    }),
                    ...fileUrls.map((fileUrl) => {
                        var name = this.getFileName(fileUrl);
                        return new TextRun({
                            text: `${name}`,
                            color: '#000000',
                            italics: false,
                            size: 24
                        }).break()
                        
                    }),
                ]
            });
          } else {
            return new Paragraph({
                spacing: {
                    before: 0,
                    after: 0
                },
                children: [
                    new TextRun({
                        text: `${text}`,
                        color: '#000000',
                        italics: false,
                        bold: true,
                        size: 26
                    }),
                ]
            });
          }
        
      }

      

      

}
import { Component, OnInit,OnDestroy, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppService } from 'src/app/services/app.service';
import { AppComponent } from 'src/app/app.component';
import { AppLanguage } from '../../../services/app.language';
import { AppSwal } from 'src/app/services/app.swal';

@Component({
  selector: 'app-organize-chart',
  templateUrl: './organize-chart.component.html',
  styleUrls: ['./organize-chart.component.css']
})
export class OrganizeChartComponent implements OnInit, OnDestroy {

  isSticky: boolean = false;
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset > 0;
  }
  user: any;
  loading = false;
  dataUnits = [];
  content = '';
  dataAccounts = [];
  searchOption = {
    SearchText: ''
  };
  unitDetail = {
    Name: '',
    Description: ''
  }
  accountDetail = {
    AvatarUrl: '',
    Name: '',
    PartName: '',
    Description: '',
    DisplayFlg: false
  };

  nameAcc = '';
  presidentName = '';


  data = {
    type: 'OrgChart',
    title: '',
    accounts: [],
    units: [],
    columnNames: ["Name", "Manager", "Tooltip"],
    options: {
      allowHtml: true,
      nodeClass: 'org-chart-style',
      orientation: 'vertical',
    },
    options1: {
      allowHtml: true,
      nodeClass: 'org-chart-style1',
    },
    // width: 100%,
    height: 900,

  }

  languageName = '';

  languages: Array<any> = [{
    id: 0,
    culture: 'vi-VN',
    text: 'Tiếng Việt'
  }, {
    id: 1,
    culture: 'en-US',
    text: 'English'
  }];

  public opened = false;
  public openedUnitDialog = false;

  contentFlg = false;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    public appService: AppService,
    private appComponent: AppComponent,
    private appSwal: AppSwal,
    private language: AppLanguage
  ) {
    this.authenticationService.getUser();
    this.getLanguageName();
    this.user = this.authenticationService.user;
  }

  topEmployee: any = {
    name: 'Janis Martin',
    cssClass: 'mainchart',
    subordinates: [
      {
        name: 'Matthew Wikes',
        subordinates: [
          {
            name: 'Tina Landry',
            subordinates: []
          }

        ]
      },
      {
        name: 'Patricia Lyons',
        subordinates: [
          {
            name: 'Dylan Wilson',
            subordinates: []
          },
          {
            name: 'Deb Curtis',
            subordinates: []
          }
        ]
      },
      {
        name: 'Larry Phung',
        subordinates: []
      }
    ]
  };
  async getUnits(languageID) {
    this.loading = true;
    const dataRequest = {
      langID: languageID
    };
    const result = await this.appService.doGET('api/Unit/GetUnitByLanguage', dataRequest);
    if (result && result.Status) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < result.Data.length; i++) {
        if (result.Data[i].ParentID !== '') {
          // tslint:disable-next-line:prefer-for-of
          for (let j = 0; j < result.Data.length; j++) {
            if (result.Data[i].ParentID === result.Data[j].ID) {
              result.Data[i].ParentID = result.Data[j].ID
            }
          }
        }
      }

      this.data.units = [];
      this.dataUnits = result.Data;
      var listUnit = [];
      var listTop = this.dataUnits.filter((x) => x.ParentID == null);
      for(let i = 0; i < listTop.length; i++){
        listUnit.push({
          id: listTop[i].ID,
          name: listTop[i].Name,
          parentID: listTop[i].ParentID,
          subordinates: this.getSubordinates(listTop[i].ID, this.dataUnits, 1),
          cssClass: 'topCss'
        });
        var k = 0;
        // var CountObj = {
        //   countMax: 0,
        //   endFlg: false
        // }
        // var tempCount = 0
        // var unitItem = null;
        // do {
        //   var tempUnit = null;
        //   if(unitItem == null || unitItem == undefined){
        //     unitItem = this.dataUnits.find(x => x.ParentID == listTop[i].ID);
        //     tempUnit =  unitItem;
        //   } else{
        //     tempUnit = this.dataUnits.find(x => x.ParentID == unitItem.ID);
        //     unitItem =  tempUnit;
        //   }
          
        //   if(unitItem != null && unitItem != undefined){
        //     tempCount++;
        //   } else{
        //     CountObj.endFlg == true;
        //     if(CountObj.countMax < tempCount){
        //       CountObj.countMax = tempCount
        //     }
        //     break;
        //   }
        // } while(CountObj.endFlg == false);
        // var j = 0;
      }
      this.data.units = listUnit[0];
      var j  = this.topEmployee;
      // for (let i = 0; i < this.dataUnits.length; i++) {
      //   let item = this.dataUnits[i];
      //   this.data.units.push(
      //     [{ v: item.ID, f: '<strong style="ext-transform: uppercase; transform: rotate(270deg) !important;">' + item.Name + '</strong>' },
      //     item.ParentID, item.ID]
      //   );
      // }
    }
    this.loading = false;
  }

  getSubordinates(id, unitList, level){
    var result = [];
    var childList = unitList.filter((x) => x.ParentID == id);
    if(childList != null && childList != undefined && childList.length > 0){
      for(let i = 0; i < childList.length; i++){
        result.push({
          id: childList[i].ID,
            name: childList[i].Name,
            parentID: childList[i].ParentID,
            subordinates: this.getSubordinates(childList[i].ID, unitList, level + 1),
            cssClass:  level == 1 ? 'firstSub': level == 2 ? 'secondSub': null
        })
      }
      return result;
    } else{
      return null;
    }
    
  }

  async getAccounts(languageID) {
    this.loading = true;
    const dataRequest = {
      langID: languageID
    };
    const result = await this.appService.doGET('api/Account/getAccountChart', dataRequest);
    if (result && result.Status) {
      this.data.accounts = [];
      this.dataAccounts = result.Data;
      for (let i = 0; i < this.dataAccounts.length; i++) {
        let item = this.dataAccounts[i];
        this.data.accounts.push(
          [{ v: item.ID, f: '<img class="account-avatar" src="' + this.appService.apiRoot + item.AvatarUrl + '"> <br> <div class="account-name">' + item.PartName + '</div><div class="account-part">' + item.Name + '</div>' },
          item.ParentID, item.ID]
        );
      }
    }
    this.loading = false;
  }

  getUnitDetail(id){
    const dataUnitDetail = this.dataUnits.find((item) => {
      return item.ID === id;
    });
    this.unitDetail = dataUnitDetail;
  }

  getAccountDetail(id) {
    const data = this.dataAccounts.find((item) => {
      return item.ID === id;
    });

    this.accountDetail = data;
  }

  async getMissionOperator(languageID) {
    this.loading = true;
    const dataRequest = {
      langID: languageID
    };

    const result = await this.appService.doGET('api/Post/getMissionOperator', dataRequest);
    if (result.Data) {
      this.content = result.Data.Description;
      this.contentFlg = result.Data.HotFlg;
    }
    this.loading = false;
  }


  onSelectAcc(event) {
    if(event.length != 0){
      this.nameAcc = '';
    
      const { row, column } = event[0];
      const id = this.data.accounts[row][2];
      this.getAccountDetail(id);
      if(this.accountDetail.DisplayFlg){
        this.opened = true;
      }
    }
  }

  onSelectUnit(event) {
    this.nameAcc = '';
    this.openedUnitDialog = true;
    this.getUnitDetail(event.id);

  }
  public closeDialog(status) {
    this.opened = false;
    this.openedUnitDialog = false;
  }

  

  onReload() {
      this.getUnits(this.languageName);
      this.getAccounts(this.languageName);
      this.getMissionOperator(this.languageName);
  }

  ngOnInit(): void {
    
  }

  ngOnDestroy(){
    var datalanguage = {
      id: 0,
      culture: 'vi-VN',
      text: 'Tiếng Việt'
    }
      this.onChangeLanguage(datalanguage);
    }
  

    onChangeLanguage(e: any) {
      this.languageName = e;
      this.onReload();
    }
  
    getLanguageName() {
      let lang =  localStorage.getItem('currentLanguage');
      if(lang == null || lang == undefined){
        lang = "vi-VN";
      }
      this.languageName = lang;
      this.onReload();
    }

}

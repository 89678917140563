import { Component, OnInit, Input, HostListener } from '@angular/core';
import { SelectableSettings, PageChangeEvent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../services/app.service';
import { IntlService } from '@progress/kendo-angular-intl';
import { Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import { AppUtils } from 'src/app/services/app.utils';
import { AppControls } from 'src/app/services/app.controls';
import { AppSwal } from 'src/app/services/app.swal';
@Component({
  selector: 'app-user-office',
  templateUrl: './user-office.component.html',
  styleUrls: ['./user-office.component.css']
})
export class UserOfficeComponent implements OnInit {
  isSticky: boolean = false;
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset > 0;
  }
  @Input() officeDrt = 0;

  docTypeFilter: Array<{ Name: string, ID: string }> = [];
  dataOfficeGridDataResult: GridDataResult;
  dataFileUrlsGridDataResult: GridDataResult;
  dataTreeListType = [];

  public LBM_NUM_PAGING_SKIP = 0;
  public LBM_NUM_PAGING_TAKE = 20;
  public LBM_NUM_PAGING_BTN = 5;
  dataOfficeSelection: number[] = [];
  dataOfficeItem: any;
  myInterval: any;

  dataFileUrls: any[];

  type: 0;
  dataOfficeSort = {
    allowUnsort: true,
    mode: 'multiple'
  };
  dataOfficeSkip = this.LBM_NUM_PAGING_SKIP;
  dataOfficePageSize = this.LBM_NUM_PAGING_TAKE;
  dataOffices = [];
  dataOfficeSortByField: SortDescriptor[] = [
  ];
  public dataOfficeState: State = {
    skip: this.dataOfficeSkip,
    take: this.dataOfficeSkip + this.dataOfficePageSize,
    filter: {
      logic: 'and',
      filters: []
    },
    //group: [{ field: 'TypeName' }]
  };

  user: any;
  loading = false;
  IDActive = "";

  searchOption = {
    SearchText: '',
    OfficeTypeID: null,
    PublishTypeID: null,
    CategoryTypeID: null,
    FieldTypeID: null,
    FromDate: null,
    ToDate: null,
    Type: 0
  };

  officeTypes: Array<{ Name: string, ID: string }>;
  officeTypesFilter: Array<{ Name: string, ID: string }>;
  officeTypesSearch: Array<{ Name: string, ID: string }>;

  publishTypes: Array<{ Name: string, ID: string }>;
  publishTypesFilter: Array<{ Name: string, ID: string }>;
  publishTypesSearch: Array<{ Name: string, ID: string }>;

  fieldTypes: Array<{ Name: string, ID: string }>;
  fieldTypesFilter: Array<{ Name: string, ID: string }>;
  fieldTypesSearch: Array<{ Name: string, ID: string }>;

  urlDownload = this.appService.apiRoot;
  pageName: any;

  isSearchBox = false;
  //isSearchBoxCommand = true;
  //isSearchCommon = false;

  categoryTypeName = '';
  notifyNoRecord = '';
  currentDate = new Date();

  constructor(
    private appService: AppService,
    public intl: IntlService,
    private router: Router,
    private appUtils: AppUtils,
    private appControls: AppControls,
    private appSwal: AppSwal,
    private translate: TranslateService
  ) {
  }

  async ngOnInit() {
    this.searchOption.Type = this.officeDrt;
    this.getPageName();
    
    await this.initDisplay();
    if (this.officeDrt == 1) {
      // this.onSearchOfficesDrt(this.searchOption.CategoryTypeID, null);
      this.getOffLastTwoMonth(this.IDActive);
    }
  }

  async getPageName() {
    this.pageName = await this.appControls.getPageName();
    if(this.pageName.toLowerCase() == "thư viện điện tử"){      
      this.notifyNoRecord = this.translate.instant('UpdatingRecords');
    }else {
      this.notifyNoRecord = this.translate.instant('NoRecords');
    }
  }

  bindOffices() {
    this.dataOfficeGridDataResult = {
      data: orderBy(this.dataOffices, this.dataOfficeSortByField),
      total: this.dataOffices.length
    };
    this.dataOfficeGridDataResult = process(this.dataOffices, this.dataOfficeState);
  }

  bindFileUrls() {
    this.dataFileUrlsGridDataResult = {
      data: this.dataFileUrls,
      total: this.dataFileUrls.length
    };

    this.dataOfficeGridDataResult = process(this.dataOffices, this.dataOfficeState);
  }

  public onPostDataStateChange(state: DataStateChangeEvent): void {
    this.dataOfficeSelection = [];
    this.dataOfficeState = state;
    this.dataOfficeGridDataResult = process(this.dataOffices, this.dataOfficeState);
  }

  onSearch() {
    this.searchOption.CategoryTypeID = null;
    this.categoryTypeName = '';
    this.getOffices();
  }

  onSearchOfficesDrt(categoryTypeID, categoryTypeName) {
    
    this.searchOption.CategoryTypeID = categoryTypeID;

    this.searchOption.SearchText = "";
    this.searchOption.ToDate = null;
    this.searchOption.FromDate = null;
    this.searchOption.OfficeTypeID = null;
    this.searchOption.FieldTypeID = null;
    this.searchOption.PublishTypeID = null;
    if (categoryTypeName) {
      this.categoryTypeName = categoryTypeName;
    }
    this.dataOfficeSkip = 0;
    this.dataOfficeState = {
      skip: this.dataOfficeSkip,
      take: this.dataOfficeSkip + this.dataOfficePageSize,
      filter: {
        logic: 'and',
        filters: []
      },
    };
    this.getOffices();
  }

  onReload() {
    //this.searchOption.SearchText = '';
    this.getOffices();

    // this.isEnabledSaveAll = false;
  }

  async initDisplay() {
    
    if (this.searchOption.Type == 1) {
      await this.getTreeViewTypes();
    }

    // loai van ban
    // const dataRequest = {
    //   pageID: this.appControls.getPage()
    // }
    const resultOfficeType = await this.appService.doGET('api/Office/GetOfficeType', null);
    if (resultOfficeType && resultOfficeType.Status === 1) {
      this.officeTypes = resultOfficeType.Data;
      this.officeTypesFilter = this.officeTypes.slice();
      this.officeTypesSearch = this.officeTypes.slice();
    }

    // co quan ban hanh
    const resultPublishType = await this.appService.doGET('api/Office/GetPublishType', null);
    if (resultPublishType && resultPublishType.Status === 1) {
      this.publishTypes = resultPublishType.Data;
      this.publishTypesFilter = this.publishTypes.slice();
      this.publishTypesSearch = this.publishTypes.slice();
    }

    // linh vuc
    const resultFieldType = await this.appService.doGET('api/Office/GetFieldType', null);
    if (resultFieldType && resultFieldType.Status === 1) {
      this.fieldTypes = resultFieldType.Data;
      this.fieldTypesFilter = this.fieldTypes.slice();
      this.fieldTypesSearch = this.fieldTypes.slice();
    }
  }

  async getOffices() {
    this.loading = true;
    const dataRequest = {
      SearchText: this.searchOption.SearchText,
      OfficeTypeID: this.searchOption.OfficeTypeID,
      CategoryTypeID: this.searchOption.CategoryTypeID,
      PublishTypeID: this.searchOption.PublishTypeID,
      FieldTypeID: this.searchOption.FieldTypeID,
      FromDate: this.searchOption.FromDate ? this.intl.formatDate(new Date(this.searchOption.FromDate), 'yyyy-MM-ddT00:00:00') : null,
      ToDate: this.searchOption.ToDate ? this.intl.formatDate(new Date(this.searchOption.ToDate), 'yyyy-MM-ddT00:00:00') : null,
      Type: this.searchOption.Type
    };

    const result = await this.appService.doPOST('api/Office/Search', dataRequest);
    if (result && result.Status === 1) {
      for (let k = 0; k < result.Data.length; k++) {

        if (result.Data[k].FileUrl == null || result.Data.FileUrl == "") {
          result.Data[k].FileUrl = [];
        }
        else {
          result.Data[k].FileUrl = JSON.parse(result.Data[k].FileUrl);
        }
        if (result.Data[k].CreateAt){
          result.Data[k].CreateAtTime = this.currentDate.getTime() -  new Date(result.Data[k].CreateAt).getTime();
        }
      }
      this.dataOffices = result.Data;
      this.bindOffices();
    }
    this.loading = false;
    this.checkSelectionID();
  }

  checkSelectionID() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = this.dataOfficeSelection.length - 1; i >= 0; i--) {
      const selectedItem = this.dataOffices.find((item) => {
        return item.ID === this.dataOfficeSelection[i];
      });
      if (!selectedItem) {
        this.dataOfficeSelection.splice(i, 1);
      }
    }
  }

  onTabChanged(event) {
    this.searchOption.Type = event.index;
    this.onSearch();
  }

  onOfficeTypeSearchHandleFilter(value) {
    this.officeTypesSearch = this.officeTypes.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  onPublishTypeSearchHandleFilter(value) {
    this.publishTypesSearch = this.publishTypes.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  onFieldTypeSearchHandleFilter(value) {
    this.fieldTypesSearch = this.fieldTypes.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  async downloadFile(fileUrl) {
    const dataRequest = {
      url: fileUrl
    };
    const result = await this.appService.doDownload('api/Upload/DownloadByUrl', dataRequest);
    if (result && result.Status == undefined) {
      FileSaver.saveAs(result, this.appUtils.getNameByUrl(fileUrl));
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
  }

  getUrlDownload(item) {
    // return "http://localhost:8080/Upload/Office/tax.docx";
    let url = this.urlDownload.replace(/\"/g, "") + item;
    url = url.replace(/\"/g, '')
    return url;
  }

  onGoToDetail(dataItem) {
    // this.router.navigate(['portal/admi-proc-detail']);
    // { queryParams: { searchparam: searchString } }
    this.router.navigate(['portal/office-detail'], { queryParams: { title: dataItem.UrlPath } });
  }

  onOpenSearch() {
    this.isSearchBox = !this.isSearchBox;
  }

  async getTreeViewTypes() {
    this.loading = true;
    // const dataRequest = {
    //   pageID: this.appControls.getPage()
    // };
    const result = await this.appService.doGET('api/Type/getTypeTreeView', null);
    if (result && result.Status === 1) {
      this.dataTreeListType = result.Data.List;
      this.IDActive = result.Data.List[result.Data.List.length - 1].ID;
    }
    
    this.loading = false;
  }


  showLinkUrl(itemSelected) {
    this.IDActive = itemSelected.ID;
    this.loading = true;
    this.getOffLastTwoMonth(itemSelected.ID);
  }
  async getOffLastTwoMonth(id){
    const dataRequest = {
      OfficeTypeID: id,
      Type: this.searchOption.Type,
    };
    this.dataOfficeSkip = 0;
    this.dataOfficeState = {
      skip: this.dataOfficeSkip,
      take: this.dataOfficeSkip + this.dataOfficePageSize,
      filter: {
        logic: 'and',
        filters: []
      },
    };
    const result = await this.appService.doPOST('api/Office/SearchLastTwoMonth', dataRequest);
    if (result && result.Status === 1) {
      for (let k = 0; k < result.Data.length; k++) {

        if (result.Data[k].FileUrl == null || result.Data.FileUrl == "") {
          result.Data[k].FileUrl = [];
        }
        else {
          result.Data[k].FileUrl = JSON.parse(result.Data[k].FileUrl);
        }
        if (result.Data[k].CreateAt){
          result.Data[k].CreateAtTime = this.currentDate.getTime() -  new Date(result.Data[k].CreateAt).getTime();
        }
      }
      this.dataOffices = result.Data;
      this.bindOffices();
    }
    this.loading = false;
    this.categoryTypeName = '';
    this.checkSelectionID();
  }

  onOfficePageChange(event: PageChangeEvent) {
    this.dataOfficeSkip = event.skip;
    this.bindOffices();
  }

}

import { Component, OnInit, OnDestroy, ViewChild, ElementRef, HostListener } from '@angular/core';
import { AppLanguage } from '../../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../services/app.service';
import { SelectableSettings, PageChangeEvent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { AppSwal } from 'src/app/services/app.swal';
import { IntlService } from '@progress/kendo-angular-intl';
import { Notification } from '../../services/app.notification';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
import { AppGuid } from 'src/app/services/app.guid';
import { NullInjector } from '@angular/core/src/di/injector';
import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';
import * as XLSX from 'xlsx';
import { AppFile } from 'src/app/services/app.file';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { of } from 'rxjs/observable/of';
import { AppComponent } from 'src/app/app.component';
import { AppControls } from 'src/app/services/app.controls';

@Component({
  selector: 'app-user-role',
  templateUrl: './user-role.component.html',
  styleUrls: ['./user-role.component.css']
})
export class UserRoleComponent implements OnInit, OnDestroy {

  isSticky = false
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 140;
  }

  user: any;
  loading = false;
  userRoles = [];
  userRoleFilter = [];
  searchUserRoleFilter = [];
  searchUserRoles = [];
  userRoleSelectedKeys = [];
  userRoleSelectableSettings: SelectableSettings;
  userRoleSort = {
    allowUnsort: true,
    mode: 'multiple'
  };
  onAllowSelectMulti() {
    this.setSelectableSettings();
  }

  userRoleSortByField: SortDescriptor[] = [];

  searchOption = {
    RoleID: null,
    SearchText: ''
  };

  public userRoleState: State = {
    filter: {
      logic: 'and',
      filters: []
    }
  };
  userRoleGridDataResult: GridDataResult;

  userRoleSkip = 0;
  userRolePageSize = 5;
  userRoleSelection: number[] = [];
  userRoleItem: any;
  myInterval: any;

  roles: Array<{ Name: string, ID: string }>;
  roleFilters: Array<{ Name: string, ID: string }>;
  roleSearchs: Array<{ Name: string, ID: string }>;

  public updateFilePathUrl = this.appService.apiRoot + 'File/Upload';
  public uploadRemoveUrl = 'removeUrl';
  public userRoleOpened = false;
  control: any;
  controlDefault = true;
  allowMulti = true;
  constructor(
    private translate: TranslateService,
    private language: AppLanguage,
    private appService: AppService,
    private appSwal: AppSwal,
    public intl: IntlService,
    private notification: Notification,
    private guid: AppGuid,
    private file: AppFile,
    private authenticationService: AuthenticationService,
    private location: Location,
    private appComponent: AppComponent,
    public appControls: AppControls
  ) {

    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
    this.getControl();
    this.language.default();
    this.setSelectableSettings();
    this.initDisplay();
    this.getPageName();
  }

  ngOnDestroy(): void {
    if (this.myInterval) { this.myInterval.unsubscribe(); }
  }

  ngOnInit() {
  }

  pageName: any;
  
  async getPageName() {
    this.pageName = await this.appControls.getPageName();
  }

  async getControl() {
    this.control = await this.appControls.getControls(this.user.RoleID);
    this.controlDefault = false;
  }

  setSelectableSettings(): void {
    this.allowMulti = !this.allowMulti;
    this.userRoleSelectableSettings = {
      checkboxOnly: false,
      mode: this.allowMulti ? 'multiple' : 'single'
    };
  }

  async getUserRoles() {
    this.appComponent.loading = true;
    if (this.searchOption.RoleID && this.searchOption.RoleID !== this.guid.empty) {
      const dataRequest = {
        id: this.searchOption.RoleID
      };
      this.loading = true;
      const result = await this.appService.doGET('api/RolePage', dataRequest);
      if (result) {
        this.userRoles = result.Data.Items || [];
      }
      this.loading = false;
    } else {
      this.userRoles = [];
    }

    this.userRoleFilter = this.userRoles.slice();
    this.bindUserRoles();
    this.getSearchUserRole();
    this.appComponent.loading = false;
  }

  getSearchUserRole() {
    const result = [];
    this.userRoles.forEach((userRole) => {
      if (!result.find((f) => {
        return f.indexOf(userRole.Name) >= 0;
      })) {
        result.push(userRole.Name);
      }

      if (userRole.Items && userRole.Items.length > 0) {
        userRole.Items.forEach((item) => {
          if (item.Name && !result.find((f) => {
            return f.indexOf(item.Name) >= 0;
          })) {
            result.push(item.Name);
          }
        });
      }
    });

    this.searchUserRoles = result;
    this.searchUserRoleFilter = this.searchUserRoles.slice();
  }

  onUserRolePageChange(event: PageChangeEvent) {
    this.userRoleSkip = event.skip;
    this.bindUserRoles();
  }

  onUserRoleSelectedKeysChange() {
  }

  userRoleHandleSelection({ index }: any) {
    if (index.indexOf('_') < 0) {
      const i = this.intl.parseNumber(index);
      const page = this.userRoleFilter[i];
      if (page.Items && page.Items.length > 0) {
        page.Items.forEach((item) => {
          item.ActiveFlg = !page.ActiveFlg;
        });
      }
    }
    this.bindUserRoles();
  }

  onSearchUserRoleChange() {
    this.userRoleFilter = this.search(this.userRoles, this.searchOption.SearchText);
  }

  onSearchUserRoleFiltering(filter: any) {
    this.searchUserRoleFilter = this.searchUserRoles.filter((s) => s.toLowerCase().indexOf(filter.toLowerCase()) !== -1);
  }

  public search(items: any[], term: string): any[] {
    return items.reduce((acc, item) => {
      if (this.contains(item.Name, term)) {
        acc.push(item);
      } else if (item.Items && item.Items.length > 0) {
        const newItems = this.search(item.Items, term);

        if (newItems.length > 0) {
          acc.push({ ID: item.ID, Name: item.Name, ActiveFlg: item.ActiveFlg, Items: newItems });
        }
      }

      return acc;
    }, []);
  }

  public contains(text: string, term: string): boolean {
    return text.toLowerCase().indexOf(term.toLowerCase()) >= 0;
  }

  bindUserRoles() {
    this.userRoleGridDataResult = {
      data: orderBy(this.userRoles, this.userRoleSortByField),
      total: this.userRoles.length
    };
    this.userRoleGridDataResult = process(this.userRoles, this.userRoleState);
  }

  onUserRoleSortChange(sort: SortDescriptor[]): void {
    this.userRoleSortByField = sort;
    this.bindUserRoles();
  }

  public onUserRoleDataStateChange(state: DataStateChangeEvent): void {
    this.userRoleSelection = [];
    this.userRoleState = state;
    this.userRoleGridDataResult = process(this.userRoles, this.userRoleState);
  }

  onRoleSearchHandleFilter(value) {
    this.roleSearchs = this.roles.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  async onRoleSearchValueChange(e: any) {
    this.roleSearchs = this.roles.slice();
    this.onReload();
  }

  async onServiceSearchValueChange(e: any) {
    this.onReload();
  }

  async initDisplay() {

    const resultrole = await this.appService.doGET('api/role/GetForCombobox', null);
    if (resultrole) {
      this.roles = resultrole.Data;
      this.roleFilters = this.roles.slice();
      this.roleSearchs = this.roles.slice();
    }

    if (this.roles.length > 0) {
      this.searchOption.RoleID = this.roles[0].ID;
      this.onReload();
    }
  }

  onReload() {
    this.getUserRoles();
  }

  async onSaveUserRole() {


    const dataRequests = [];
    let page: any;
    let controls: any;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.userRoles.length; i++) {
      page = this.userRoles[i];
      controls = [];
      if (page.Items && page.Items.length > 0) {
        page.Items.forEach(ctr => {
          if (ctr.ActiveFlg) {
            controls.push(ctr.ID);
          }
        });
      }

      dataRequests.push({
        RoleID: this.searchOption.RoleID,
        PageID: page.ID,
        Value: controls.length > 0 ? JSON.stringify(controls) : '',
        ActiveFlg: page.ActiveFlg
      });
    }

    const result = await this.appService.doPOST('api/RolePage', dataRequests);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
  }

  onBack() {
    this.location.back();
  }

}

import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { IntlService } from '@progress/kendo-angular-intl';
import { Router, ActivatedRoute } from '@angular/router';
import { AppUtils } from 'src/app/services/app.utils';
import { AppControls } from 'src/app/services/app.controls';
import { AppSwal } from 'src/app/services/app.swal';
import { GridDataResult, SelectableSettings, DataStateChangeEvent, PageChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, State, orderBy, process } from '@progress/kendo-data-query';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-meeting-week',
  templateUrl: './meeting-week.component.html',
  styleUrls: ['./meeting-week.component.css']
})
export class MeetingWeekComponent implements OnInit {
  isSticky: boolean = false;
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset > 0;
  }
  dataMeetingGridDataResult: GridDataResult;
  dataMeetings = [];
  dataMeetingSelectableSettings: SelectableSettings;
  dataMeetingSort = {
    allowUnsort: true,
    mode: 'multiple'
  };
  infoOpened = false;
  loading = false;
  dataMeetingSortByField: SortDescriptor[] = [];
  dataMeetingSelection: number[] = [];
  dataMeetingItem: any;
  dataMeetingItemTemp: any;
  public dataMeetingState: State = {
    filter: {
      logic: 'and',
      filters: []
    },
  };

  currentDay = new Date();
  WeekNo: number;
  centralContent = '';
  searchOption = {
    SearchText: '',
    ApproveFlg: -1,
    PublicFlg: -1,
    FromDate: null,
    ToDate: null
  };

  pageName: any;

  public widthSlide = '100%';
  public heightSlide = '100%';
  public slideDayOfWeek : Array<{ Ob: any, ID: string, ObGrid: GridDataResult }> = [];
  responsiveOptions: any;
  @ViewChild('ngcarousel') ngCarousel: NgbCarousel;


  constructor(
    private appService: AppService,
    public intl: IntlService,
    private router: Router,
    private appUtils: AppUtils,
    private appControls: AppControls,
    private appSwal: AppSwal,
    private activatedRoute: ActivatedRoute
  ) {
    this.setSelectableSettings();
    this.setDefault();
    this.initDisplay();
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '768px',
        numVisible: 1,
        numScroll: 1
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((p: any) => {
      this.getMenuName(p.menuID);
    });
  }

  async getMenuName(menuID) {
    const dataRequest = {
      id: menuID,
    };
    const result = await this.appService.doGET('api/Menu', dataRequest);
    if (result && result.Status === 1) {
      this.pageName = result.Data.Name;
    }
  }

  setDefault() {
    this.dataMeetingItem = {
      viewOnly: true,
      IsAdd: true,
      Name: '',
      Admin: null,
      Address: '',
      MeetingDate: null,
      StartAt: null,
      EndAt: null,
      ApproveFlg: false,
      FileUrl: [],
      Description: ''
    };
    this.dataMeetingItemTemp = {
      viewOnly: true,
      IsAdd: true,
      Name: '',
      Admin: null,
      Address: '',
      MeetingDate: null,
      StartAt: null,
      EndAt: null,
      ApproveFlg: false,
      FileUrl: [],
      Description: ''
    };
    this.dataMeetingSelection = [];
  }

  bindMeetings() {
    this.dataMeetingGridDataResult = {
      data: orderBy(this.dataMeetings, this.dataMeetingSortByField),
      total: this.dataMeetings.length
    };
    
    this.dataMeetingGridDataResult = process(this.dataMeetings, this.dataMeetingState);
  }

  onMeetingsortChange(sort: SortDescriptor[]): void {
    this.dataMeetingSortByField = sort;
    this.bindMeetings();
  }

  onMeetingPageChange(event: PageChangeEvent) {
    this.bindMeetings();
  }

  setSelectableSettings(): void {
    this.dataMeetingSelectableSettings = {
      checkboxOnly: false,
      mode: 'single'
    };
  }

  public onMeetingDataStateChange(state: DataStateChangeEvent): void {
    this.dataMeetingSelection = [];
    this.dataMeetingState = state;
    this.dataMeetingGridDataResult = process(this.dataMeetings, this.dataMeetingState);
  }

  async onMeetingselectedKeysChange() {

    const selectedID = this.dataMeetingSelection[0];
    const selectedItem = this.dataMeetings.find((item) => {
      return item.ID === selectedID;
    });
    selectedItem.IsAdd = false;
    selectedItem.MeetingDate = selectedItem.MeetingDate ? new Date(selectedItem.MeetingDate) : null;
    selectedItem.StartAt = selectedItem.StartAt ? new Date(selectedItem.StartAt) : null;
    selectedItem.EndAt = selectedItem.EndAt ? new Date(selectedItem.EndAt) : null;
    this.dataMeetingItem = selectedItem;
    this.bindTemp(this.dataMeetingItem);
    this.infoOpened = true;
  }

  bindTemp(data) {
    this.dataMeetingItemTemp = Object.assign({}, data);
  }

  checkSelectionID() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = this.dataMeetingSelection.length - 1; i >= 0; i--) {
      const selectedItem = this.dataMeetings.find((item) => {
        return item.ID === this.dataMeetingSelection[i];
      });
      if (!selectedItem) {
        this.dataMeetingSelection.splice(i, 1);
      }
    }
  }

  async initDisplay() {
    this.getWeekMeeting();
    //this.getCentralContent();
  }

  // async getCentralContent() {
  //   this.WeekNo = this.getWeekNumber(this.searchOption.FromDate);
  //   const dataRequest = {
  //     ID: this.WeekNo,
  //   };
  //   const result = await this.appService.doGET('api/CentralContent', dataRequest);
  //   if (result && result.Status === 1) {
  //     console.log(result.Data);
  //     for (let i = 0; i < result.Data.length; i++) {
  //       this.centralContent = result.Data[0].Description;
  //     }
  //   }
  // }

  async getWeekMeeting() {
    this.getFirstLastDay();
    this.WeekNo = this.getWeekNumber(this.searchOption.FromDate);
    const dataRequest = {
      SearchText: this.searchOption.SearchText,
      ApproveFlg: this.searchOption.ApproveFlg,
      PublicFlg: this.searchOption.PublicFlg,
      FromDate: this.searchOption.FromDate ? this.intl.formatDate(new Date(this.searchOption.FromDate), 'yyyy-MM-ddT00:00:00') : null,
      ToDate: this.searchOption.ToDate ? this.intl.formatDate(new Date(this.searchOption.ToDate), 'yyyy-MM-ddT00:00:00') : null
    };
    const result = await this.appService.doPOST('api/Meeting/SearchWeekly', dataRequest);
    if (result && result.Status === 1) {       
      const meetings = [];
      const dayOfWeek = [];
      this.slideDayOfWeek = [];
      let idxDayofWeek = "";
      for (let i = 0; i < result.Data.length; i++) {
        result.Data[i].UnitList = JSON.parse(result.Data[i].UnitString);
        result.Data[i].Admin = JSON.parse(result.Data[i].AdminString);
        for(let k = 0; k < result.Data[i].Admin.length; k++){
          if(result.Data[i].ShowAdmin == undefined){
            result.Data[i].ShowAdmin = "";
          }
          if(k != result.Data[i].Admin.length - 1){
            result.Data[i].ShowAdmin = result.Data[i].ShowAdmin + " " + result.Data[i].Admin[k].FullName + ",";
          } else{
            result.Data[i].ShowAdmin = result.Data[i].ShowAdmin + " " + result.Data[i].Admin[k].FullName;
          }
        }
        result.Data[i].RoomMeetingList = JSON.parse(result.Data[i].RoomMeetingString);
        if (result.Data[i].RoomMeetingList){
          for(let j = 0; j < result.Data[i].RoomMeetingList.length; j++){
            if(result.Data[i].RoomMeetingName == undefined){
              result.Data[i].RoomMeetingName = "";
            }
            if(j != result.Data[i].RoomMeetingList.length - 1){
              result.Data[i].RoomMeetingName = result.Data[i].RoomMeetingName + " " + result.Data[i].RoomMeetingList[j].Name + ",";
            } else{
              result.Data[i].RoomMeetingName = result.Data[i].RoomMeetingName + " " + result.Data[i].RoomMeetingList[j].Name;
            }
          }
        } 
        result.Data[i].MemberList = JSON.parse(result.Data[i].MemberString);
        for (let j = 0; j < result.Data[i].MemberList.length; j++) {
          if (result.Data[i].ShowMembers == undefined) {
            result.Data[i].ShowMembers = "";
          }
          if (j != result.Data[i].MemberList.length - 1) {
            result.Data[i].ShowMembers = result.Data[i].ShowMembers + " " + result.Data[i].MemberList[j].FullName + ",";
          } else {
            result.Data[i].ShowMembers = result.Data[i].ShowMembers + " " + result.Data[i].MemberList[j].FullName;
          }
        }
        result.Data[i].MeetingDayName = this.getDayInWeek(result.Data[i].MeetingDate);
        result.Data[i].DayName = this.getDayInWeek(result.Data[i].StartAt);
        result.Data[i].DayHour = this.getHour(result.Data[i].StartAt);
        result.Data[i].StartAtRp = result.Data[i].StartAt;
        if (result.Data[i].FileUrl == null || result.Data[i].FileUrl == "") {
          result.Data[i].FileUrl = [];
        }
        else {
          result.Data[i].FileUrl = JSON.parse(result.Data[i].FileUrl);
        }
        
        if (idxDayofWeek != result.Data[i].DayName){
          idxDayofWeek = result.Data[i].DayName; 
          this.slideDayOfWeek.push({
            ID: idxDayofWeek,
            Ob: [result.Data[i]],
            ObGrid: null
          })
        }else {
          if(this.slideDayOfWeek.length > 0){
            this.slideDayOfWeek[this.slideDayOfWeek.indexOf(this.slideDayOfWeek.find(r=> r.ID == idxDayofWeek))].Ob.push(result.Data[i])
          }          
        }

        if (dayOfWeek.find(dayItem => {
          return dayItem === result.Data[i].DayName;

        })) {
          result.Data[i].DayName = '';
          result.Data[i].StartAtRp = null;
        } else {
          dayOfWeek.push(result.Data[i].DayName);
        }

        if (meetings){
          meetings.push(result.Data[i]);
        }
        
      }
      // this.dataMeetingGridDataResult = result.Data
      this.dataMeetings = meetings;
      
      this.slideDayOfWeek.forEach(r => {
        r.ObGrid = {
          data: orderBy(r.Ob, this.dataMeetingSortByField),
          total: r.Ob.length
        };
        
        r.ObGrid = process(r.Ob, this.dataMeetingState);
      });
      this.bindMeetings();
    } else if (result && result.Status !== 1) {
      if (typeof (result.Msg) != 'undefined' && result.Msg != null) {
        this.appSwal.showError(result.Msg);
      }
    }
    this.checkSelectionID();
  }

  getHour(date) {
    var current_day = new Date(date);

    return ((current_day.getHours() < 10) ? "0" : "") + current_day.getHours() + ":" + ((current_day.getMinutes() < 10) ? "0" : "") + current_day.getMinutes()
  }

  getDayInWeek(date) {
    var current_day = new Date(date);
    var day_name = '';
    switch (current_day.getDay()) {
      case 0:
        day_name = "Chủ nhật";
        break;
      case 1:
        day_name = "Thứ hai";
        break;
      case 2:
        day_name = "Thứ ba";
        break;
      case 3:
        day_name = "Thứ tư";
        break;
      case 4:
        day_name = "Thứ năm";
        break;
      case 5:
        day_name = "Thứ sáu";
        break;
      case 6:
        day_name = "Thứ bảy";
    }
    return day_name
  }

  getFirstLastDay() {
    this.searchOption.FromDate = new Date(this.currentDay.setDate(this.currentDay.getDate() - this.currentDay.getDay() + 1));
    this.searchOption.ToDate = new Date(this.currentDay.setDate(this.searchOption.FromDate.getDate() + 6));;
  }

  getWeekNumber(date): number {
    const today = new Date(date);
    const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
    const pastDaysOfYear = (today.valueOf() - firstDayOfYear.valueOf()) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  }

  onCloseInfo(e) {
    this.setDefault();
    this.infoOpened = false;
  }

  onExportPdf(pdf: any) {
    pdf.saveAs('Lich cong tac tuan.pdf');
  }

   // Move to previous slide
   getToPrev() {
    this.ngCarousel.prev();
  }

  // Move to next slide
  goToNext() {
    this.ngCarousel.next();
  }

  // Pause slide
  stopCarousel() {
    this.ngCarousel.pause();
  }

}

import { Component, OnInit, OnDestroy, ViewChild, ElementRef, HostListener } from '@angular/core';
import { AppLanguage } from '../../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../services/app.service';
import { SelectableSettings, PageChangeEvent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { AppSwal } from 'src/app/services/app.swal';
import { IntlService } from '@progress/kendo-angular-intl';
import { Notification } from '../../services/app.notification';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
import { AppGuid } from 'src/app/services/app.guid';
import { NullInjector } from '@angular/core/src/di/injector';
import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';
import * as XLSX from 'xlsx';
import { AppFile } from 'src/app/services/app.file';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppUtils } from 'src/app/services/app.utils';
import { AppComponent } from '../../app.component';
@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css']
})
export class PageComponent implements OnInit, OnDestroy {

  isStickySearch = false
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isStickySearch = window.pageYOffset >= 140;
  }

  user: any;
  loading = false;
  dataPages = [];
  dataPageSelectableSettings: SelectableSettings;
  dataPageSort = {
    allowUnsort: true,
    mode: 'multiple'
  };
  public dataPageFocus = {
    Name: true
  };
  dataPageSortByField: SortDescriptor[] = [
    // {
    //   field: 'ParentName',
    //   dir: 'asc'
    // }, {
    //   field: 'OrdinalNumber',
    //   dir: 'asc'
    // }
  ];

  //Config: Constant for paging
  public LBM_NUM_PAGING_SKIP = 0;
  public LBM_NUM_PAGING_TAKE = 50;
  public LBM_NUM_PAGING_BTN = 5;

  dataPageSkip = this.LBM_NUM_PAGING_SKIP;
  dataPagePageSize = this.LBM_NUM_PAGING_TAKE;
  dataPageSelection: number[] = [];
  dataPageItem: any;
  myInterval: any;
  pageName: any;
  infoOpened = false;

  public buttonCount = this.LBM_NUM_PAGING_BTN;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;

  public dataPageState: State = {
    skip: this.dataPageSkip,
    take: this.dataPageSkip + this.dataPagePageSize,
    filter: {
      logic: 'and',
      filters: []
    },
    group: [{ field: 'ParentName' }]
  };
  dataPageGridDataResult: GridDataResult;

  parentPages: Array<{ Name: string, ID: string }>;
  parentPagesFilter: Array<{ Name: string, ID: string }>;


  public uploadSaveUrl = 'saveUrl';
  public uploadRemoveUrl = 'removeUrl';
  public enabled = false;
  public enabledID = false;
  isEnabledSaveAll = false;
  control: any;
  controlDefault = true;
  allowMulti = true;

  searchOption = {
    SearchText: ''
  };

  constructor(
    private translate: TranslateService,
    private language: AppLanguage,
    private appService: AppService,
    private appSwal: AppSwal,
    public intl: IntlService,
    private notification: Notification,
    private guid: AppGuid,
    private file: AppFile,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    private appUtils: AppUtils,
    private appComponent: AppComponent,
  ) {
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
    this.getControl();
    this.setDefault();
    this.language.default();
    this.setSelectableSettings();
    this.onReload();
    this.getPageParent();
    this.getPageName();
  }

  parentPagesHandleFilter(value) {
    this.parentPagesFilter = this.parentPages.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  ngOnDestroy(): void {
    if (this.myInterval) { this.myInterval.unsubscribe(); }
  }

  ngOnInit() {
  }
  async getPageName() {
    this.pageName = await this.appControls.getPageName();
  }

  async getControl() {
    this.control = await this.appControls.getControls(this.user.RoleID);
    this.controlDefault = false;
  }

  setSelectableSettings(): void {

    this.allowMulti = !this.allowMulti;
    this.dataPageSelectableSettings = {
      checkboxOnly: false,
      mode: this.allowMulti ? 'multiple' : 'single'
    };
  }

  onSearchKeyPress(e: any) {
    if (e.keyCode === 13 && this.searchOption.SearchText) {
      this.onSearch();
    }
  }

  async getPages() {
    this.loading = true;
    const dataRequest = {
      searchText: this.searchOption.SearchText
    };

    const result = await this.appService.doGET('api/Page/Search', dataRequest);
    if (result) {
      this.dataPages = result.Data;    
      // this.parentPagesFilter = this.parentPages.slice();
      this.bindPages();
    }
    this.loading = false;
    this.checkSelectionID();
  }

  async getPageParent() {
    const dataRequest = {
      searchText: ''
    };
    const result = await this.appService.doGET('api/Page/Search', dataRequest);
    if (result && result.Status === 1) {
      this.parentPages = [];
      this.parentPages.push({
        ID: null,
        Name: 'Root'
      });

      result.Data.forEach(item => {
        if (!item.ParentID) {
          this.parentPages.push(item);
        }
      });

      this.parentPagesFilter = this.parentPages.slice();
    }
  }

  checkSelectionID() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = this.dataPageSelection.length - 1; i >= 0; i--) {
      const selectedItem = this.dataPages.find((item) => {
        return item.ID === this.dataPageSelection[i];
      });
      if (!selectedItem) {
        this.dataPageSelection.splice(i, 1);
      }
    }
  }

  setDefault() {
    this.dataPageItem = {
      IsAdd: true,
      ID: null,
      Name: null,
      ParentID: '',
      OrdinalNumber: 0,
      MenuFlg: true,
      ButtonFlg: false
    };
    this.dataPageFocus.Name = true;
    this.enabled = true;
    this.enabledID = true;
    this.dataPageSelection = [];
  }

  onPagePageChange(event: PageChangeEvent) {
    this.dataPageSkip = event.skip;
    this.bindPages();
  }

  onPageSelectedKeysChange() {

    if (this.dataPageSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }

    if (this.dataPageSelection.length > 1) {
      if (this.allowMulti) {
        return;
      }
      this.appSwal.showWarning(this.translate.instant('SelectSingle'), false);
    } else {
      const selectedID = this.dataPageSelection[0];
      const selectedItem = this.dataPages.find((item) => {
        return item.ID === selectedID;
      });
      selectedItem.IsAdd = false;
      this.dataPageItem = selectedItem;
      this.enabled = false;
      this.enabledID = false;
    }
  }

  bindPages() {
    this.dataPageGridDataResult = {
      data: orderBy(this.dataPages, this.dataPageSortByField),
      total: this.dataPages.length
    };

    this.dataPageGridDataResult = process(this.dataPages, this.dataPageState);
  }

  onPageSortChange(sort: SortDescriptor[]): void {
    this.dataPageSortByField = sort;
    this.bindPages();
  }

  public onPageDataStateChange(state: DataStateChangeEvent): void {
    this.dataPageSelection = [];
    this.dataPageState = state;
    this.dataPageGridDataResult = process(this.dataPages, this.dataPageState);
  }

  getColumnIndex(name) {
    const columns = [
      'NameParentPage',
      'ParentID',
      'ID',
      'Name',
      'OrdinalNumber',
      'MenuFlg',
      'ButtonFlg'
    ];

    return columns.indexOf(name);
  }

  async selectEventHandler(e: SelectEvent) {
    this.appComponent.loading = true;
    
    try {
      const fileData = e.files[0];// await this.file.readFile(e.files[0].rawFile);

      const maxMB = 25;
      const maxSizeKB = 1024 * 1024 * maxMB;
      if (fileData.size > maxSizeKB) {
        this.appSwal.showWarning(`Kích thước tập tin không được lớn hơn ${maxMB} MB`, false);
        return false;
      }
    } catch {
      return false;
    }

    const extension = e.files[0].extension.toLowerCase();
    if (!extension || (extension.toLowerCase() !== '.xlsx' && extension.toLowerCase() !== '.xls' && extension.toLowerCase() !== '.pdf')) {
      this.appSwal.showWarning('Vui lòng chọn tập tin có định dạng .xlsx, .xls.', false);
      return false;
    }

    const fileData = (await this.file.readXLSX(e.files[0].rawFile)) as Array<any>;
    let parentPage: any;
    this.dataPages = [];
    for (let i = 1; i < fileData.length; i++) {
      parentPage = this.parentPages.find(item => {
        return this.appUtils.compareString(fileData[i][this.getColumnIndex('ParentID')], item.Name, item.ID);
      });

      if (fileData[i].indexOf(fileData[i][this.getColumnIndex('NameParentPage')]) == -1) {
        this.dataPages.push({
          IsAdd: false,
          ParentID: parentPage ? parentPage.ID : null,
          ParentName: parentPage ? parentPage.Name : null,
          ID: fileData[i][this.getColumnIndex('ID')],
          Name: fileData[i][this.getColumnIndex('Name')],
          OrdinalNumber: fileData[i][this.getColumnIndex('OrdinalNumber')],
          MenuFlg: fileData[i][this.getColumnIndex('MenuFlg')],
          ButtonFlg: fileData[i][this.getColumnIndex('ButtonFlg')],
        });
      }
    }
    this.bindPages();
    this.isEnabledSaveAll = true;
    this.appComponent.loading = false;
  }

  removeEventHandler() {
    this.isEnabledSaveAll = false;
    this.onReload();
  }

  doInterval() {
    this.myInterval = Observable.interval(this.appService.timeInterval).subscribe(() => {
      this.onReload();
    });
  }

  onSearchTextChange(e: any) {
    if (!this.searchOption.SearchText) {
      this.onReload();
    }
  }

  onRemoveSearchText() {
    this.searchOption.SearchText = '';

  }

  onSearch() {
    this.getPages();
    this.isEnabledSaveAll = false;
  }

  onReload() {
    this.searchOption.SearchText = '';
    this.getPages();
    this.getPageParent();
    this.isEnabledSaveAll = false;
  }

  onClearPage() {
    this.setDefault();
  }

  onAllowSelectMulti() {
    this.setSelectableSettings();
  }

  onAddNewPage() {
    
    this.setDefault();
    this.infoOpened = true;
  }

  onSavePage() {
    if (this.dataPageItem.IsAdd) { this.addPage(); } else { this.updatePage(); }
  }

  async onSavePages() {
    this.appComponent.loading = true;
    const dataRequests = [];
    for (let i = 0; i < this.dataPages.length; i++) {
      let DataPages = this.dataPages[i];
      const dataRequest = {
        searchText: DataPages.Name
      };
      const resultPageListFromServer = await this.appService.doGET('api/Page/Search', dataRequest);
      let isDuplicate = false;
      if (resultPageListFromServer && resultPageListFromServer.Data &&
        resultPageListFromServer.Data.length > 0) {
        resultPageListFromServer.Data.forEach(element => {
          if (element.Name == DataPages.Name) {
            isDuplicate = true;
          }
        });
      }
      if (isDuplicate == false) {
        dataRequests.push(this.createDataRequest(this.dataPages[i]));
      }
    }
    const result = await this.appService.doPOST('api/Page/Saves', dataRequests);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.isEnabledSaveAll = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  createDataRequest(data) {
    const temp = data ? data : this.dataPageItem;
    return {
      ID: temp.ID,
      Name: temp.Name,
      ParentID: temp.ParentID,
      MenuFlg: temp.MenuFlg,
      ButtonFlg: temp.ButtonFlg,
      OrdinalNumber: temp.OrdinalNumber
    };
  }

  onClosePage(status: any) {
    this.enabled = false;
    this.enabledID = false;
    this.infoOpened = true;
  }

  onEditPage() {
    this.enabled = true;
    this.enabledID = false;
    this.infoOpened = true;
  }

  async addPage() {
    this.appComponent.loading = true;
    const dataRequest = this.createDataRequest(null);
    const result = await this.appService.doPOST('api/Page', dataRequest);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.setDefault();
      this.infoOpened = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  async updatePage() {
    this.appComponent.loading = true;
    const id = this.dataPageItem.ID;
    const dataRequest = this.createDataRequest(null);

    const result = await this.appService.doPUT('api/Page', dataRequest, { id });
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.onAddNewPage();
      this.infoOpened = false
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  async onDeletePage() {
    if (this.dataPageSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }
    this.appComponent.loading = true;
    const dataRequest = {
      IDList: JSON.stringify(this.dataPageSelection),
      FlgRevert: false
    };

    const option = await this.appSwal.showWarning(this.translate.instant('AreYouSure'), true);
    if (option) {
      const result = await this.appService.doPOST('api/Page/Deletes', dataRequest);
      if (result && result.Status === 1) {
        this.notification.showSuccess(result.Msg);
        this.onReload();
        //this.onAddNewPage();
        this.enabled = false;
        this.enabledID = false;
        this.dataPageSelection = [];
        this.allowMulti = false;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
    this.appComponent.loading = false;
  }
  onCloseInfo(e) {
    this.setDefault();
    this.infoOpened = false;
  }
}

import { Component, OnInit, Output, Input, EventEmitter, OnDestroy, ElementRef, ViewChild, Renderer2, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { AppService } from '../../services/app.service';
import { AppComponent } from '../../app.component';
import { AppSwal } from '../../services/app.swal';
import { ResourceLoader } from '@angular/compiler';


import { AppLanguage } from '../../services/app.language';

import { AppControls } from 'src/app/services/app.controls';
import * as FileSaver from 'file-saver';
import { IntlService } from '@progress/kendo-angular-intl';
import { ActivatedRoute } from '@angular/router';
import { forEach } from '@angular/router/src/utils/collection';
import { AfterViewInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { t } from '@angular/core/src/render3';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {

  @Output() reloadPosts = new EventEmitter<string>();
  isSticky: boolean = false;
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset > 0;
  }
  @ViewChild('scrollview')  scrollView;
  @ViewChild('scrollAutomation')  scrollNotify;
  @ViewChild('carouselID')  carouselID;
  @ViewChild('carousel')  carousel;
  user: any;
  searchOption = {
    HotFlg: false
  };

  dataHome = {
    PostHots: null,
    BannerTops: null,
    BannerBottoms: null,
    PostByMenus: null,
    PostByNotify: null,
    NewDoc: null,
    ImagePropaganda: null,
    PostHotsTemp: null,
  };
  dataTopBanner = {
    SlideViewData: null,
    GridViewData: null
  };
  dataBottomBanner = {
    SlideViewData: null,
    GridViewData: null
  }

  public width = '100%';
  public height = '200px';
  responsiveOptions: any;
  public FlyinBanner: any;

  positions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  postByMenuIdx = 0;
  public imageDef = '../../assets/images/default-02.png';
  public posterImg = '../../assets/images/poster.png';

  screen = {
    w: 0,
    h: 0
  };

  public autoplay = 0;

  private interval;

  public flyinBanner = true;
  public isMobileDevice = false;
  public widthPostHot = '100%';
  public heightPostHot = '100%';
  public endlessPostHot = true;
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    public appService: AppService,
    private appComponent: AppComponent,
    private appSwal: AppSwal
  ) {

    if(window.innerWidth <= 768) {
      this.isMobileDevice = true;
    }
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '560px',
        numVisible: 2,
        numScroll: 2
      }
    ];
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
    this.onReload();
    // this.TopBanner();
  }

  ngOnInit(): void {
    this.autoplay = 5000;
    this.screen.h = window.innerHeight;
    this.screen.w = window.innerWidth;
    this.getScreenSize();
    // this.scrollAutomation();
  }


  getScreenSize() {
    try {
      const h = (this.screen.w - 34) / 5.6;
      this.height = `${h > 200 ? 200 : h}px`;
    } catch {

    }
  }

  onResize(event) {
    this.screen.w = event.target.innerWidth;
    this.screen.h = event.target.innerHeight;
    this.getScreenSize();
  }

 

  async onReload() {
    const resultPost = await this.appService.doGET('api/Post/GetByHome', null);
    // this.getNewDoc();
    // this.getImageAtHome();
    if (resultPost && resultPost.Status === 1 && resultPost.Data) {
      if (resultPost.Data.PostHots){
        this.dataHome.PostHots = resultPost.Data.PostHots;
      }
      if (resultPost.Data.BannerTops){
        this.dataHome.BannerTops = resultPost.Data.BannerTops;
      }
      if (resultPost.Data.PostByMenus){
        this.dataHome.PostByNotify = resultPost.Data.PostByMenus.filter((r) => r.DATA_MENU.HomeDisplayType === 0);
      }
      if (resultPost.Data.BannerBottoms){
        this.dataHome.BannerBottoms = resultPost.Data.BannerBottoms;
        for (let i = 0; i < this.dataHome.BannerBottoms.length; i++) {
          this.dataHome.BannerBottoms[i].Idx = i;
        }
      }
      if (resultPost.Data.PostHots){
        this.dataHome.PostHotsTemp = resultPost.Data.PostHots;
      }
      if (resultPost.Data.PostByMenus){
        
        if (this.isMobileDevice){

          this.dataHome.PostByMenus = resultPost.Data.PostByMenus;
        }else {
          this.dataHome.PostByMenus = resultPost.Data.PostByMenus;
          // for (let i = 0; i < this.dataHome.PostByMenus.length ; i++) {
          //   if (this.dataHome.PostByMenus[i].Posts && this.dataHome.PostByMenus[i].Posts.length > 5){
          //     var postsTemp = this.dataHome.PostByMenus[i].Posts
          //     this.dataHome.PostByMenus[i].Posts = [];
          //     for (let j = 0; j < postsTemp.length -1 ; j++) {
          //       this.dataHome.PostByMenus[i].Posts[j] = postsTemp[j];
          //     }
          //   }            
          // }
        }
      }
      if (this.dataHome.BannerBottoms){
        if(this.dataHome.BannerBottoms.length >= 6){
          let n = 0;
          let bannerNum = this.dataHome.BannerBottoms.length;
          while (n <= 100){
            this.dataHome.BannerBottoms[n] = this.dataHome.BannerBottoms[n % bannerNum]
            n++;
          }
        }
      }                    
      this.dataHome.ImagePropaganda = resultPost.Data.ResultAlbumPhoto;
      this.FlyinBanner = resultPost.Data.FlyinBanner;
      this.dataTopBanner.SlideViewData = resultPost.Data.dataForSlideViewTop;
      this.dataTopBanner.GridViewData = resultPost.Data.dataForGridViewTop;
      this.dataBottomBanner.SlideViewData = resultPost.Data.dataForSlideViewBottom;
      this.dataBottomBanner.GridViewData = resultPost.Data.dataForGridViewBottom;

      this.dataHome.NewDoc = resultPost.Data.NewDoc;
      if (this.dataHome.NewDoc){
        if(this.dataHome.NewDoc.length > 2){
          let n = 0;
          let bannerNum = this.dataHome.NewDoc.length;
          while (n <= 200){
            this.dataHome.NewDoc[n] = this.dataHome.NewDoc[n % bannerNum]
            n++;
          }
        }
      }
    }
    
  }

  // async TopBanner() {
  //   const result = await this.appService.doGET('api/Multimedia/Picture', null);
  //   if (result && result.Status === 1 && result.Data) {
  //     this.dataTopBanner.SlideViewData = result.Data.dataForSlideViewTop;
  //     this.dataTopBanner.GridViewData = result.Data.dataForGridViewTop;
  //     this.dataBottomBanner.SlideViewData = result.Data.dataForSlideViewBottom;
  //     this.dataBottomBanner.GridViewData = result.Data.dataForGridViewBottom;
  //   }
  // }  

  async getNewDoc() {
    const dataRequest = {
      dataRequestType: 1
    };
    const resultNewDoc = await this.appService.doGET('api/Office/GetOfficeDisplayHome', dataRequest);
    if (resultNewDoc && resultNewDoc.Status === 1) {
      this.dataHome.NewDoc = resultNewDoc.Data;
      if (this.dataHome.NewDoc){
        if(this.dataHome.NewDoc.length > 2){
          let n = 0;
          let bannerNum = this.dataHome.NewDoc.length;
          while (n <= 200){
            this.dataHome.NewDoc[n] = this.dataHome.NewDoc[n % bannerNum]
            n++;
          }
        }
      }
    }
  }

  async getImageAtHome() {
    const resultImage = await this.appService.doGET('api/File/GetImageAtHome', null);    
    if (resultImage && resultImage.Status === 1) {
      if (resultImage.Data){        
        this.dataHome.ImagePropaganda = resultImage.Data.ResultAlbumPhoto;
        this.FlyinBanner = resultImage.Data.FlyinBanner;
      }      
    }
  }

  onClickOfficeItem(item) {
    this.router.navigate(['portal/office-detail'], { queryParams: { title: item.UrlPath } });
  }

  onClickPostItem(item) {
    this.router.navigate(['portal/post-detail'], { queryParams: { title: item.UrlPath } });
  }

  onClickImagePropaganda(item) {
    if(item.LinkUrl){
      window.location.href = item.LinkUrl;
    }else {
      this.router.navigate(['portal/multimedia'], { queryParams: { menuID: item.MenuID } });
    }

  }

  pageNum = 0;
  setPage(e: any) {
    this.pageNum = e.page;
  }

  clickBannerBottom(linkUrl) {
    if (linkUrl) {
      window.open(linkUrl);
    }
  }
  public ngAfterViewInit() {  
    this.interval = setInterval(() => {
            this.scrollView.next();
    }, 6000);
    this.interval = setInterval(() => {
      this.carousel.next();
    }, 5000); 
  }

  // public scrollAutomation() {
  //   this.interval = setInterval(() => {
  //           this.scrollNotify.scrollTo(0, 0);
  //   }, 3000);
  // }

  public mouseoverHotPost(item){
    this.dataHome.PostHots = [];
    this.dataHome.PostHots.push(item);
    this.endlessPostHot = false;
  }
  
  public mouseoutHotPost(){
    this.dataHome.PostHots = this.dataHome.PostHotsTemp;
    this.endlessPostHot = true;
  }

  public mouseover(e: any){
    this.carouselID.stopAutoplay();
  }
  public mouseout(e: any){
    this.carouselID.startAutoplay();
  }

  public offBanner(){
    this.FlyinBanner = false;
  }

  public onClickTitlePost(id){
    this.router.navigate(['portal/post-list'], { queryParams: { title: id } });
    this.reloadPosts.emit(id);
  }
  

}

import { Component, OnInit, Renderer2, HostListener } from '@angular/core';
import { SchedulerEvent, CreateFormGroupArgs, EditMode } from '@progress/kendo-angular-scheduler';
import { FormGroup, FormBuilder, Validators, ValidatorFn } from '@angular/forms';
import { AppGuid } from 'src/app/services/app.guid';
import { AppService } from 'src/app/services/app.service';
import { AppComponent } from 'src/app/app.component';
import { AppSwal } from 'src/app/services/app.swal';
import { Notification } from '../../services/app.notification';
import { IntlService } from '@progress/kendo-angular-intl';
import { isBuffer } from 'util';
import { DomSanitizer } from '@angular/platform-browser';
import * as FileSaver from 'file-saver';
import { AppUtils } from 'src/app/services/app.utils';

const intersects = (startTime1: Date, endTime1: Date, startTime2: Date, endTime2: Date) =>
    (startTime1 < startTime2 && endTime1 > endTime2) ||
    (startTime2 <= startTime1 && startTime1 < endTime2) ||
    (startTime2 < endTime1 && endTime1 <= endTime2);

@Component({
  selector: 'app-portal-meeting',
  templateUrl: './portal-meeting.component.html',
  styleUrls: ['./portal-meeting.component.css']
})
export class PortalMeetingComponent implements OnInit {
  isSticky: boolean = false;
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset > 0;
  }
  //public startTime = '07:00';
  isFile = false;
  dataMeetingItem: any;
  dataMeetingItemtemp: any;
  meetingRoom: Array<{ Name: string, ID: string }>;
  dataRoomID: any[];
  infoOpened = false;
  isAdd = true;
  public selectedDate: Date = new Date(Date.now());
  public formGroup: FormGroup;

  members: Array<{ FullName: string, UserName: string }> = [];
  membersFilter: Array<{ FullName: string, UserName: string }> = [];



  dataScheduler: any[] = [];
  user: any;

  public eventsArr: SchedulerEvent[] = [];
  public events: SchedulerEvent[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private appGuid: AppGuid,
    public appService: AppService,
    private appComponent: AppComponent,
    private appSwal: AppSwal,
    private notification: Notification,
    public intl: IntlService,
    private appUtils: AppUtils,
    //private renderer: Renderer2,
    private domSanitizer: DomSanitizer,
  ) {
    this.getMeeting();
    this.createFormGroup = this.createFormGroup.bind(this);
    this.getRoom();
    this.setDefault();
  }

  setDefault() {

    this.dataMeetingItem = {
      ID: '',
      Name: '',
      RoomMeetingID: null,
      RoomOther: null,
      Room: '',
      StartAt: '',
      EndAt: '',
      MeetingDate: null,
      CreateAt: null,
      ApproveFlg: null,
      DelFlg: null,
      Description: '',
      FileUrl: null,
      Unit: '',
      Admin: '',
      Member: ''
    };
    this.dataMeetingItemtemp = {
      ID: '',
      Name: '',
      RoomMeetingID: null,
      RoomOther: null,
      StartAt: '',
      EndAt: '',
      MeetingDate: null,
      CreateAt: null,
      ApproveFlg: null,
      DelFlg: null,
      Description: '',
      FileUrl: null,
      Unit: '',
      Admin: '',
      Member: ''
    }
  }

  async getRoom() {
    const result = await this.appService.doGET('api/Meeting/GetMeetingRoom', null);
    this.meetingRoom = result.Data;
  }

  async getMeeting() {
    const result = await this.appService.doGET('api/Meeting/GetMeeting', null);
    if (result) {
      if (result && result.Status === 1) {

        this.dataScheduler = result.Data;
        result.Data.forEach(item => {
          var tempStart = this.intl.formatDate(new Date(item.StartAt), 'HH:mm');
          var tempEnd = this.intl.formatDate(new Date(item.EndAt), 'HH:mm');
          const event = {
            id: item.ID,
            title: item.Name + "-" + tempStart + "-" + tempEnd,
            start: new Date(item.StartAt),
            end: new Date(item.EndAt),
            readonly: true,
            delflg: item.DelFlg,
            approveflg: item.ApproveFlg,
            publicflg: item.PublicFlg,
            publicat: new Date(item.PublicAt),
            updateat: new Date(item.UpdateAt),
            fileurl: item.FileUrl
          }
          this.eventsArr.push(event);
        });
        this.events = this.eventsArr;
      }
    }
  }


  ngOnInit() {
  }

  membersHandleFilter(value) {
    this.membersFilter = this.members.filter((s) => s.FullName.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  args: CreateFormGroupArgs;

  public createFormGroup(args: CreateFormGroupArgs): FormGroup {

    const dataItem = args.dataItem;
    this.setDefault();
    this.formGroup = this.formBuilder.group({
      'id': args.isNew ? this.getNextId() : dataItem.id,
      'start': [dataItem.start, Validators.required],
      'end': [dataItem.end, Validators.required],
      'startTimezone': [dataItem.startTimezone],
      'endTimezone': [dataItem.endTimezone],
      'isAllDay': dataItem.isAllDay,
      'title': dataItem.title,
      'RoomMeetingID': dataItem.RoomMeetingID,
      'RoomOther': dataItem.RoomOther

    });
    this.dataMeetingItem.Name = dataItem.title;
    this.dataMeetingItem.MeetingDate = dataItem.start;
    this.dataMeetingItem.StartAt = dataItem.start;
    this.dataMeetingItem.EndAt = dataItem.end;
    this.dataMeetingItem.ID = dataItem.id;

    for (let item of this.dataScheduler) {
      if (this.dataMeetingItem.ID == item.ID) {
        var t = JSON.parse(item.Member);
        // for (let y of t) {
        //   console.log(y.FullName);
        // }
        //this.dataMeetingItem.RoomMeetingID = item.RoomMeeting;
        //this.dataMeetingItem.RoomOther = item.RoomOther;
        this.dataMeetingItem.DelFlg = item.DelFlg;
        this.dataMeetingItem.ApproveFlg = item.ApproveFlg;
        this.dataMeetingItem.Description = item.Description;
        this.dataMeetingItem.FileUrl = item.FileUrl;
        //this.dataMeetingItem.Unit = item.Unit;
        //this.dataMeetingItem.Admin = item.Admin;

        var listMember = JSON.parse(item.Member);
        var listAdmin = JSON.parse(item.Admin);
        var listUnit = JSON.parse(item.Unit);
        var listRoom = JSON.parse(item.Room)
        var countMem = 0;
        var countAd = 0;
        var countUnit = 0;
        var countRooom = 0;
        //lấy member
        for (let member of listMember) {
          this.dataMeetingItem.Member = this.dataMeetingItem.Member + member.FullName;
          countMem = countMem + 1;
          if (countMem != listMember.length) {
            this.dataMeetingItem.Member = this.dataMeetingItem.Member + '; ';
          }
        }
        //lấy Admin
        for (let admin of listAdmin) {
          this.dataMeetingItem.Admin = this.dataMeetingItem.Admin + admin.FullName;
          countAd = countAd + 1;
          if (countAd != listAdmin.length) {
            this.dataMeetingItem.Admin = this.dataMeetingItem.Admin + '; ';
          }
        }
        //lấy Unit
        for (let unit of listUnit) {
          this.dataMeetingItem.Unit = this.dataMeetingItem.Unit + unit.Name;
          countUnit = countUnit + 1;
          if (countUnit != listUnit.length) {
            this.dataMeetingItem.Unit = this.dataMeetingItem.Unit + '; ';
          }
        }
        //lấy Room
        for (let room of listRoom) {
          this.dataMeetingItem.Room = this.dataMeetingItem.Room + room.Name;
          countRooom = countRooom + 1;
          if (countRooom != listRoom.length) {
            this.dataMeetingItem.Room = this.dataMeetingItem.Room + '; ';
          }
        }
        //kiểm tra File
        this.isFile = false;
        if (this.dataMeetingItem.FileUrl) {
          this.isFile = true;
        }
        break;
      }
    }
    return this.formGroup;
  }

  public isEditingSeries(editMode: EditMode): boolean {
    return editMode === EditMode.Series;
  }

  public getNextId(): number {
    const len = this.events.length;

    return (len === 0) ? 1 : this.events[this.events.length - 1].id + 1;
  }

  getUrlDownload(fileUrl) {
    let url = this.appService.apiRoot.replace("\"", "") + fileUrl;
    url = url.replace("\"", '')
    return url;
  }

  async downloadFile(fileUrl) {
    const dataRequest = {
      url: fileUrl
    };
    const result = await this.appService.doDownload('api/Upload/DownloadByUrl', dataRequest);
    if (result) {
      FileSaver.saveAs(result, this.appUtils.getNameByUrl(fileUrl));
    }
  }

  private occupiedSlot(args: any): boolean {
    let occupied = false;

    this.events.find(e => {
      if (e !== args.dataItem && intersects(args.start, args.end, e.start, e.end)) {
        occupied = true;
        return true;
      }
    });

    return occupied;
  }

  public onDragStart(args: any): void {
    this.preventReadonly(args);
  }

  public onDrag(args: any): void {
    if (this.occupiedSlot(args)) {
      args.setHintClass('invalid');
    }
  }

  public onDragEnd(args: any): void {
    if (this.occupiedSlot(args)) {
      args.preventDefault();
    }
  }

  private preventReadonly(args: any): void {
    if (args.dataItem.readonly) {
      args.preventDefault();
    }
  }

  public ngAfterViewChecked() {
    //this.renderer.setStyle(document.querySelector("multi-day-view .k-scheduler-pane"), "display", "none");
  }
}

import { Component, Renderer2, NgZone, AfterViewInit, OnInit, OnDestroy, ViewEncapsulation, ViewChild, ElementRef, Output, EventEmitter, Input } from '@angular/core';
import { AppLanguage } from '../../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../services/app.service';
import { SelectableSettings, PageChangeEvent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { AppSwal } from 'src/app/services/app.swal';
import { IntlService } from '@progress/kendo-angular-intl';
import { Notification } from '../../services/app.notification';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
import { AppGuid } from 'src/app/services/app.guid';
import { NullInjector } from '@angular/core/src/di/injector';
import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';
import * as XLSX from 'xlsx';
import { AppFile } from 'src/app/services/app.file';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppUtils } from 'src/app/services/app.utils';
import { AppComponent } from '../../app.component';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpProgressEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { of, concat } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { WebSocketService } from 'src/app/websocket.service';

@Component({
  selector: 'app-meeting-info',
  templateUrl: './meeting-info.component.html',
  styleUrls: ['./meeting-info.component.css']
})
export class MeetingInfoComponent implements OnInit {

  @Input() meetingdata = null;

  user: any;
  loading = false;
  dataMeetingItemTemp: any;

  admins: Array<{ FullName: string, UserName: string }> = [];
  adminsFilter: Array<{ FullName: string, UserName: string }> = [];
  members: Array<{ FullName: string, UserName: string }> = [];
  membersFilter: Array<{ FullName: string, UserName: string }> = [];
  units: Array<{ Name: string, ID: string }>;
  unitsFilter: Array<{ Name: string, ID: string }>;
  allmembers: Array<{ Name: string, ID: string }> = [];
  allmembersFilter: Array<{ Name: string, ID: string }> = [];
  rooms: Array<{ Name: string, ID: string }>;
  roomsFilter: Array<{ Name: string, ID: string }>;

  control: any;
  controlDefault = true;
  InputingFile = false;
  allowInsertFile = true;

  filesUpload: Array<FileInfo>;
  filesUploadName = "";
  myRestrictions: FileRestrictions = {
    maxFileSize: 1024 * 1024 * 120
  };

  fileName = [];

  public Editor = DecoupledEditor;
  config = {
    toolbar: ['heading',
      '|',
      'bold',
      'italic',
      'link',
      'bulletedList',
      'numberedList',
      '|',
      'indent',
      'outdent',
      '|',
      'insertTable',
      'mediaEmbed',
      'undo',
      'redo']
  }
  SEND_DATA_WHEN_NOTIFY = '';

  public fileSaveUrl: any;
  urlDownload = this.appService.apiRoot;

  currentDay = new Date();

  constructor(private translate: TranslateService,
    private language: AppLanguage,
    private appService: AppService,
    private appSwal: AppSwal,
    public intl: IntlService,
    private notification: Notification,
    private guid: AppGuid,
    private file: AppFile,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    private appUtils: AppUtils,
    private appComponent: AppComponent,
    private domSanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    public wsService: WebSocketService
    ) {
      
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
    this.getControl();
    this.language.default();
    this.setDefault();

    this.SEND_DATA_WHEN_NOTIFY = appService.domainAPI + "api/NotifyWs";
    
     }

  ngOnInit() {
    this.dataMeetingItemTemp = this.meetingdata;
    this.getRoomlist();
    this.getUnit();
    this.onReload();
    this.initDisplay();
  }

  async initDisplay() {
    const result = await this.appService.doGET('api/User/GetListUser', null);
    if (result && result.Status === 1) {
      this.admins = result.Data;
      this.adminsFilter = this.admins.slice();
      this.members = result.Data;
      this.membersFilter = this.members.slice();
    }
  }

  async getControl() {
    this.control = await this.appControls.getControls(this.user.RoleID);
    this.controlDefault = false;
  }

  setDefault() {
    if(this.dataMeetingItemTemp == undefined || this.dataMeetingItemTemp.SchedulerAddNewDate == undefined){
      this.dataMeetingItemTemp = {
        viewOnly: false,
        IsAdd: true,
        Name: '',
        Admin: null,
        Address: '',
        MeetingDate: null,
        StartAt: null,
        EndAt: null,
        ApproveFlg: false,
        FileUrl: [],
        Description: '',
        CentralContent: ''
      };
    } else {
      this.dataMeetingItemTemp = {
        viewOnly: false,
        IsAdd: true,
        Name: '',
        Admin: null,
        Address: '',
        MeetingDate: this.dataMeetingItemTemp.SchedulerAddNewDate,
        StartAt: null,
        EndAt: null,
        ApproveFlg: false,
        FileUrl: [],
        Description: '',
        SchedulerAddNewDate: this.dataMeetingItemTemp.SchedulerAddNewDate,
        CentralContent: ''
      };
    }
    
    this.filesUpload = [];
    this.filesUploadName = "";
    this.fileSaveUrl = `${this.appService.apiRoot}api/Upload/MediaWeb?accountID=${this.user.UserName}&typeData=files`;
  }

  onReload() {
    this.bindMemberList();
    this.membersFilter = this.members.slice();
  }

  bindMemberList() {
    this.members = [];
    for (let i = 0; i < this.admins.length; i++) {
      this.members.push({
        UserName: this.admins[i].UserName,
        FullName: this.admins[i].FullName
      });
    }
  }

  changeAdmin(e) {
    if (e != undefined) {
      if (this.dataMeetingItemTemp.MemberList != undefined) {
        for(let i = 0; i < e.length; i++){
          var isExistedInMember = this.dataMeetingItemTemp.MemberList.findIndex(x => x.UserName == e[i].UserName);
          if (isExistedInMember != -1) {
            this.dataMeetingItemTemp.MemberList.splice(isExistedInMember, 1);
          }
        }
      }
      this.bindMemberList();
      for(let i = 0; i < e.length; i++){
        if(this.members.findIndex(x => x.UserName == e[i].UserName) != -1){
          this.members.splice(this.members.findIndex(x => x.UserName == e[i].UserName), 1);
        }
      }
      this.membersFilter = this.members.slice();
    } else {
      this.bindMemberList();
      this.membersFilter = this.members.slice();
    }
  }

  

  adminsHandleFilter(value) {
    this.adminsFilter = this.admins.filter((s) => s.FullName.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  membersHandleFilter(value) {
    this.membersFilter = this.members.filter((s) => s.FullName.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  unitsHandleFilter(value) {
    this.unitsFilter = this.units.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  roomsHandleFilter(value) {
    this.roomsFilter = this.rooms.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  allmembersHandleFilter(value) {
    this.allmembersFilter = this.allmembers.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  async getUserlist() {
    const result = await this.appService.doGET('api/Account', null);
    if (result && result.Status === 1) {
      this.allmembers = result.Data;
      this.allmembersFilter = this.allmembers.slice();
    }
  }

  async getRoomlist() {
    const result = await this.appService.doGET('api/RoomMeeting', null);
    if (result && result.Status === 1) {
      this.rooms = result.Data;
      this.roomsFilter = this.rooms.slice();
    }
  }

  async getUnit() {
    const resultRole = await this.appService.doGET('api/Unit/GetListUnitSort', null);
    if (resultRole && resultRole.Status === 1) {
      this.units = resultRole.Data;
      this.unitsFilter = this.units.slice();
    }
  }

  async onSelectFileToUpload(e: SelectEvent) {
    if (!e.files || e.files.length <= 0) {
      return;
    }


    const extension = e.files[0].extension.toLowerCase();

    this.allowInsertFile = true;

    try {
      const fileData = e.files[0];
      const maxMB = 25;
      const maxSizeKB = 1024 * 1024 * maxMB;
      if (fileData.size > maxSizeKB) {
        this.allowInsertFile = false;
        this.appSwal.showWarning(`Kích thước tập tin không được lớn hơn ${maxMB} MB`, false);
        return false;
      }
    } catch {
      this.appSwal.showError(e);
    }
  }

  onSuccessFileToUpload(e: any) {
    if (!this.allowInsertFile) {
      return;
    }
    try {
      if(this.dataMeetingItemTemp.FileUrl == undefined){
        this.dataMeetingItemTemp.FileUrl = [];
      }
      this.dataMeetingItemTemp.FileUrl.push(`${e.response.body.Data.DirMedia}${e.response.body.Data.MediaNm[0]}`);

    } catch {
      this.appSwal.showError(e);
    }
  }

  getFileName(fileUrls) {
    var nameFile = "";
    if (fileUrls != "" && fileUrls != null) {
      var urlArr = fileUrls.split("/");
      if (urlArr.length > 0) {
        nameFile = urlArr[urlArr.length - 1];
        if (nameFile != "" && nameFile != null) {
          var indexOfFirst = nameFile.indexOf("_");
          nameFile = nameFile.substring(indexOfFirst + 1);
        }
      }
    }
    return nameFile;
  }

  async onSaveMeeting() {
    if (this.dataMeetingItemTemp.IsAdd) { this.addMeeting(); } else { this.updateMeeting(); }
  }

  async addMeeting() {
    this.appComponent.loading = true;
    var members: any[] = [];
    if (this.dataMeetingItemTemp.MemberList) {
      for (let i = 0; i < this.dataMeetingItemTemp.MemberList.length; i++) {
        members.push(this.dataMeetingItemTemp.MemberList[i].UserName);
      }
    }    
    const dataRequest = {
      Meeting: await this.createDataRequest(null),
      TypeRequest: 'web-app'
    };
    const result = await this.appService.doPOST('api/Meeting/AddMeeting', dataRequest);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.setDefault();
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }        
    
    this.appComponent.loading = false;
  }

  async updateMeeting() {
    this.appComponent.loading = true;
    // tslint:disable-next-line: max-line-length
    var members: any[] = [];
    if (this.dataMeetingItemTemp.MemberList) {
      for (let i = 0; i < this.dataMeetingItemTemp.MemberList.length; i++) {
        members.push(this.dataMeetingItemTemp.MemberList[i].UserName);
      }
    }       
    const dataRequest = {
      Meeting: await this.createDataRequest(null),
      TypeRequest: 'web-app'
    };
    const id = this.dataMeetingItemTemp.ID;
    const result = await this.appService.doPUT('api/Meeting', dataRequest, { id });
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.sendWSMessage(dataRequest.Meeting.ID);
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }       
    
    this.appComponent.loading = false;
  }

  async createDataRequest(data) {
    const temp = data ? data : this.dataMeetingItemTemp;
    var units: any[] = [];    
    if (this.dataMeetingItemTemp.UnitList) {
      for (let i = 0; i < this.dataMeetingItemTemp.UnitList.length; i++) {
        units.push(this.dataMeetingItemTemp.UnitList[i].ID);
      }
    }
    var admins: any[] = [];
    if (this.dataMeetingItemTemp.Admin) {
      for (let i = 0; i < this.dataMeetingItemTemp.Admin.length; i++) {
        admins.push(this.dataMeetingItemTemp.Admin[i].UserName);
      }
    }
    var members: any[] = [];
    if (this.dataMeetingItemTemp.MemberList) {
      for (let i = 0; i < this.dataMeetingItemTemp.MemberList.length; i++) {
        members.push(this.dataMeetingItemTemp.MemberList[i].UserName);
      }
    }
    var rooms: any[] = [];
    if (this.dataMeetingItemTemp.RoomMeetingList) {
      for (let i = 0; i < this.dataMeetingItemTemp.RoomMeetingList.length; i++) {
        rooms.push(this.dataMeetingItemTemp.RoomMeetingList[i].ID);
      }
    }
    var files: any[] = [];
    if (this.dataMeetingItemTemp.FileUrl) {
      for (let i = 0; i < this.dataMeetingItemTemp.FileUrl.length; i++) {
        files.push(this.dataMeetingItemTemp.FileUrl[i]);
      }
    }

    return {
      ID: temp.ID,
      Name: temp.Name,
      Admin: JSON.stringify(admins),
      UnitList: JSON.stringify(units),
      MeetingDate: temp.MeetingDate ? this.intl.formatDate(new Date(temp.MeetingDate), 'yyyy-MM-ddT00:00:00') : null,
      StartAt: temp.StartAt ? this.intl.formatDate(new Date(temp.StartAt), 'yyyy-MM-ddTHH:mm:ss') : null,
      EndAt: temp.EndAt ? this.intl.formatDate(new Date(temp.EndAt), 'yyyy-MM-ddTHH:mm:ss') : null,
      RoomMeetingList: JSON.stringify(rooms),
      MemberList: JSON.stringify(members),
      ApproveFlg: temp.ApproveFlg,
      ApproveBy: temp.ApproveBy,
      ApproveAt: temp.ApproveAt,
      DelFlg: temp.DelFlg,
      DelAt: temp.DelAt,
      DelBy: temp.DelBy,
      CreateAt: temp.CreateAt,
      CreateBy: temp.CreateBy,
      UpdateAt: temp.UpdateAt,
      UpdateBy: temp.UpdateBy,
      PublicFlg: temp.PublicFlg,
      PublicBy: temp.PublicBy,
      PublicAt: temp.PublicAt,
      Description: temp.Description,
      FileUrl: JSON.stringify(files),
      CentralContent: temp.CentralContent,
      Week: temp.MeetingDate ?this.getWeekNumber(temp.MeetingDate): null
    };
  }
  onRemoveFileToUpload() {
    this.dataMeetingItemTemp.FileUrl = [];
    this.filesUploadName = '';
    this.filesUpload = [];
  }

  public valueNormalizerUnit = (text$: Observable<string>) => text$.pipe(map((text: string) => {
    //search for matching item to avoid duplicates
    if(!text.replace(/\s/g, '').length){
      return
    }
    //search in values
    if(this.dataMeetingItemTemp.UnitList == undefined){
      this.dataMeetingItemTemp.UnitList = [];
    }
    const matchingValue: any = this.dataMeetingItemTemp.UnitList.find((item: any) => {
        return item["ID"].toLowerCase() === text.toLowerCase();
    });

    if (matchingValue) {
        return matchingValue; //return the already selected matching value and the component will remove it
    }

    //search in data
    const matchingItem: any = this.unitsFilter.find((item: any) => {
        return item["ID"].toLowerCase() === text.toLowerCase();
    });

    if (matchingItem) {
        return matchingItem;
    } else {
        return {
            ID: text, //generate unique value for the custom item
            Name: text
        };
    }
}));

public valueNormalizerAdmin = (text$: Observable<string>) => text$.pipe(map((text: string) => {
  //search for matching item to avoid duplicates
  if(!text.replace(/\s/g, '').length){
    return
  }
  //search in values
  if(this.dataMeetingItemTemp.Admin == undefined){
    this.dataMeetingItemTemp.Admin = [];
  }
  const matchingValue: any = this.dataMeetingItemTemp.Admin.find((item: any) => {
      return item["UserName"].toLowerCase() === text.toLowerCase();
  });

  if (matchingValue) {
      return matchingValue; //return the already selected matching value and the component will remove it
  }

  //search in data
  const matchingItem: any = this.adminsFilter.find((item: any) => {
      return item["UserName"].toLowerCase() === text.toLowerCase();
  });

  if (matchingItem) {
      return matchingItem;
  } else {
      return {
        UserName: text, //generate unique value for the custom item
          FullName: text
      };
  }
}));

public valueNormalizerMember = (text$: Observable<string>) => text$.pipe(map((text: string) => {
  //search for matching item to avoid duplicates
  if(!text.replace(/\s/g, '').length){
    return
  }
  //search in values
  if(this.dataMeetingItemTemp.MemberList == undefined){
    this.dataMeetingItemTemp.MemberList = [];
  }
  const matchingValue: any = this.dataMeetingItemTemp.MemberList.find((item: any) => {
      return item["UserName"].toLowerCase() === text.toLowerCase();
  });

  if (matchingValue) {
      return matchingValue; //return the already selected matching value and the component will remove it
  }

  //search in data
  const matchingItem: any = this.membersFilter.find((item: any) => {
      return item["UserName"].toLowerCase() === text.toLowerCase();
  });

  if (matchingItem) {
      return matchingItem;
  } else {
      return {
        UserName: text, //generate unique value for the custom item
          FullName: text
      };
  }
}));

public valueNormalizerRoom = (text: Observable<string>) => text.pipe(map((text: string) => {
  //search for matching item to avoid duplicates
  if(!text.replace(/\s/g, '').length){
    return
  }
  //search in values
  if(this.dataMeetingItemTemp.RoomMeetingList == undefined){
    this.dataMeetingItemTemp.RoomMeetingList = [];
  }
  const matchingValue: any = this.dataMeetingItemTemp.RoomMeetingList.find((item: any) => {
      return item["ID"].toLowerCase() === text.toLowerCase();
  });

  if (matchingValue) {
      return matchingValue; //return the already selected matching value and the component will remove it
  }

  //search in data
  const matchingItem: any = this.roomsFilter.find((item: any) => {
      return item["ID"].toLowerCase() === text.toLowerCase();
  });

  if (matchingItem) {
      return matchingItem;
  } else {
      return {
          ID: text, //generate unique value for the custom item
          Name: text
      };
  }
}));

onRemoveFile(file){
  var isExistedInFile = this.dataMeetingItemTemp.FileUrl.findIndex(x => x == file);
  if (isExistedInFile != -1) {
    this.dataMeetingItemTemp.FileUrl.splice(isExistedInFile, 1);
  }
}
async onDownloadFile(file: any) {

  const dataRequest = {
    url: file
  };
  const result = await this.appService.doDownload('api/Upload/DownloadByUrl', dataRequest);
  if (result) {
    FileSaver.saveAs(result, this.getFileName(file));
  }
}

async sendWSMessage(id){
  var dataRequest = {
    meetingID: id
  }
  const result = await this.appService.doGET('api/Meeting/GetAdminMemberList', dataRequest);
  if (result && result.Status == 1) {
    
    this.wsService.sendMessage(result.Data, this.SEND_DATA_WHEN_NOTIFY);
  }
}

getWeekNumber(date): number {
  const today = new Date(date);
  const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
  const pastDaysOfYear = (today.valueOf() - firstDayOfYear.valueOf()) / 86400000;
  return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
}

getUrlDownload(item) {
  const matchingValueMember: any = this.dataMeetingItemTemp.MemberList.find((item: any) => {
    return item["UserName"].toLowerCase() === this.user.UserName.toLowerCase();
  }); 

  const matchingValueAdmin: any = this.dataMeetingItemTemp.Admin.find((item: any) => {
    return item["UserName"].toLowerCase() === this.user.UserName.toLowerCase();
  });
  if(matchingValueMember || matchingValueAdmin){
    let url = this.urlDownload.replace(/\"/g, "") + item;
    url = url.replace(/\"/g, '')
    window.open(url);
  }else {
    this.appSwal.showWarning('Bạn không có quyền để xem tài liệu.', false);
  }
}

}

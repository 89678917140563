import { Component, OnInit, OnDestroy, ViewChild, ElementRef, HostListener } from '@angular/core';
import { AppLanguage } from '../../../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../../services/app.service';
import { SelectableSettings, PageChangeEvent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { AppSwal } from 'src/app/services/app.swal';
import { IntlService } from '@progress/kendo-angular-intl';
import { Notification } from '../../../services/app.notification';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Observable } from 'rxjs/Observable';
import { AppGuid } from 'src/app/services/app.guid';
import { NullInjector } from '@angular/core/src/di/injector';
import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';
import * as XLSX from 'xlsx';
import { AppFile } from 'src/app/services/app.file';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppUtils } from 'src/app/services/app.utils';
import { AppComponent } from '../../../app.component';
import { DomSanitizer } from '@angular/platform-browser';
import { saveAs } from '@progress/kendo-file-saver';
import { Http } from '@angular/http';
import { KendoButtonService } from '@progress/kendo-angular-buttons/dist/es2015/button/button.service';
import { DataSource } from '@angular/cdk/table';


@Component({
  selector: 'app-data-room-meeting',
  templateUrl: './data-room-meeting.component.html',
  styleUrls: ['./data-room-meeting.component.css']
})
export class DataRoomMeetingComponent implements OnInit, OnDestroy {

  isStickySearch = false
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isStickySearch = window.pageYOffset >= 140;
  }

  user: any;
  loading = false;
  dataRoomMeetings = [];
  dataRoomMeetingsTemp = [];
  dataRoomMeetingSelectableSettings: SelectableSettings;
  dataRoomMeetingSort = {
    allowUnsort: true,
    mode: 'multiple'
  };
  public dataRoomMeetingFocus = {
    Name: true
  };
  dataRoomMeetingSortByField: SortDescriptor[] = [];

  public WORKING_NUM_PAGING_SKIP = 0;
  public WORKING_NUM_PAGING_TAKE = 50;
  public WORKING_NUM_PAGING_BTN = 5;

  dataRoomMeetingSkip = this.WORKING_NUM_PAGING_SKIP;
  dataRoomMeetingPageSize = this.WORKING_NUM_PAGING_TAKE;
  dataRoomMeetingSelection: number[] = [];
  dataRoomMeetingItem: any;
  dataRoomMeetingItemtemp: any;
  myInterval: any;
  infoOpened = false;

  public buttonCount = this.WORKING_NUM_PAGING_BTN;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;

  public dataRoomMeetingState: State = {
    skip: this.dataRoomMeetingSkip,
    take: this.dataRoomMeetingSkip + this.dataRoomMeetingPageSize,
    filter: {
      logic: 'and',
      filters: []
    }
  };
  dataRoomMeetingGridDataResult: GridDataResult;

  RoomMeetings: Array<{ Name: string, ID: string }>;
  RoomMeetingsFilter: Array<{ Name: string, ID: string }>;
  RoomMeetingfile: Array<{ Name: string, ID: string }>;
  DeviceRoomMeeting: Array<{ Name: string, ID: string }>;


  public uploadSaveUrl = 'saveUrl';
  public uploadRemoveUrl = 'removeUrl';
  public enabled = false;
  public enabledID = false;
  isEnabledSaveAll = false;
  control: any;
  pageName: any;
  controlDefault = true;
  allowMulti = true;
  InputingFile = false;

  searchOption = {
    SearchText: ''
  };

  constructor(
    private translate: TranslateService,
    private language: AppLanguage,
    private appService: AppService,
    private appSwal: AppSwal,
    public intl: IntlService,
    private notification: Notification,
    private guid: AppGuid,
    private file: AppFile,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    private appUtils: AppUtils,
    private appComponent: AppComponent,
    private domSanitizer: DomSanitizer,
    private http: Http,
  ) {
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
    this.getControl();
    this.setDefault();
    this.language.default();
    this.setSelectableSettings();
    this.onReload();
    this.initDisplay();
    this.getPageName();
  }

  RoomMeetingsHandleFilter(value) {
    this.RoomMeetingsFilter = this.RoomMeetings.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }


  ngOnDestroy(): void {
    if (this.myInterval) { this.myInterval.unsubscribe(); }
  }

  ngOnInit() {
  }
  async getPageName() {
    this.pageName = await this.appControls.getPageName();
  }

  async getControl() {
    this.control = await this.appControls.getControls(this.user.RoleID);
    this.controlDefault = false;
  }

  setSelectableSettings(): void {
    this.allowMulti = !this.allowMulti;
    if (this.InputingFile === true) {
      this.allowMulti = false;
      this.dataRoomMeetingSelectableSettings = {
        enabled: false,
        mode: 'single'
      };
    } else {
      this.dataRoomMeetingSelectableSettings = {
        checkboxOnly: false,
        mode: this.allowMulti ? 'multiple' : 'single'
      };
    }
  }

  setSelectableSettingsFile(): void {
    this.dataRoomMeetingSelectableSettings = {
      enabled: true,
      checkboxOnly: false,
      mode: this.allowMulti ? 'multiple' : 'single'
    };
  }

  onSearchKeyPress(e: any) {
    if (e.keyCode === 13 && this.searchOption.SearchText) {
      this.onSearch();
    }
  }

  async getRoomMeetings() {
    this.loading = true;
    const dataRequest = {
      searchText: this.searchOption.SearchText
    };

    const result = await this.appService.doGET('api/RoomMeeting/Search', dataRequest);
    if (result && result.Status) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < result.Data.length; i++) {
        if (result.Data[i].ParentID !== '') {
          // tslint:disable-next-line:prefer-for-of
          for (let j = 0; j < result.Data.length; j++) {
            if (result.Data[i].ParentID === result.Data[j].ID) {
              result.Data[i].ParentName = result.Data[j].Name
            }
          }
        }
      }

      this.dataRoomMeetings = result.Data;
      this.dataRoomMeetingsTemp = this.dataRoomMeetings;
      this.bindRoomMeetings();
    }
    this.loading = false;
    this.checkSelectionID();

    this.getRoomMeetingParent();
  }

  async getRoomMeetingParent() {
    const result = await this.appService.doGET('api/RoomMeeting', null);
    if (result && result.Status) {
      this.RoomMeetings = result.Data;
      this.RoomMeetingsFilter = this.RoomMeetings.slice();
    }
  }

  checkSelectionID() {
    for (let i = this.dataRoomMeetingSelection.length - 1; i >= 0; i--) {
      const selectedItem = this.dataRoomMeetings.find((item) => {
        return item.ID === this.dataRoomMeetingSelection[i];
      });
      if (!selectedItem) {
        this.dataRoomMeetingSelection.splice(i, 1);
      }
    }
  }

  setDefault() {
    this.dataRoomMeetingItem = {
      IsAdd: true,
      ID: '',
      Name: '',
      Address: '',
      SeatNumber: 0,
      Device: [],
      DATA_DEVICEs: [],
      DelFlg: false,
    };
    this.dataRoomMeetingItemtemp = Object.assign({}, this.dataRoomMeetingItem);

    this.enabled = true;
    this.enabledID = false;
    this.dataRoomMeetingSelection = [];
  }
  bindtemp(item) {
    this.dataRoomMeetingItemtemp = Object.assign({}, item);
  }

  onRoomMeetingPageChange(event: PageChangeEvent) {
    this.dataRoomMeetingSkip = event.skip;
    this.bindRoomMeetings();
  }

  async onRoomMeetingSelectedKeysChange() {
    if (this.dataRoomMeetingSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }
    if (this.dataRoomMeetingSelection.length > 1) {
      if (this.allowMulti) {
        return;
      }
      this.appSwal.showWarning(this.translate.instant('SelectSingle'), false);
    } else {
      if (this.dataRoomMeetings.length > 0 && this.InputingFile === false) {
        const selectedID = this.dataRoomMeetingSelection[0];
        const selectedItem = this.dataRoomMeetings.find((item) => {
          return item.ID === selectedID;
        });
        selectedItem.IsAdd = false;
        this.dataRoomMeetingItem = selectedItem;
        this.bindtemp(this.dataRoomMeetingItem);
      }
    }
  }

  bindRoomMeetings() {
    this.dataRoomMeetingGridDataResult = {
      data: orderBy(this.dataRoomMeetings, this.dataRoomMeetingSortByField),
      total: this.dataRoomMeetings.length
    };

    this.dataRoomMeetingGridDataResult = process(this.dataRoomMeetings, this.dataRoomMeetingState);
  }

  onRoomMeetingSortChange(sort: SortDescriptor[]): void {
    this.dataRoomMeetingSortByField = sort;
    this.bindRoomMeetings();
  }

  public onRoomMeetingDataStateChange(state: DataStateChangeEvent): void {
    this.dataRoomMeetingSelection = [];
    this.dataRoomMeetingState = state;
    this.dataRoomMeetingGridDataResult = process(this.dataRoomMeetings, this.dataRoomMeetingState);
  }

  getColumnIndex(name) {
    const columns = [
      // 'ID',
      'Name',
      'Address',
      'SeatNumber',
      'DeviceListName',
      'DelFlg'
    ];

    return columns.indexOf(name);
  }

  getDeviceIdsFromNames(deviceNameStr) {
		var deviceIds = []
		if (deviceNameStr === '') {
			return
		}
		var deviceNames = deviceNameStr.split(';');
		deviceNames.forEach(name => {
			let deviceId = this.DeviceRoomMeeting.find(item => {
				return this.appUtils.compareString(name, item.Name, item.ID);
			});
			deviceIds.push(deviceId);
		});
		return deviceIds;
  }
  
  async selectEventHandler(e: SelectEvent) {
    this.appComponent.loading = true;
    try {
      const fileData = e.files[0];// await this.file.readFile(e.files[0].rawFile);
      const maxMB = 25;
      const maxSizeKB = 1024 * 1024 * maxMB;
      if (fileData.size > maxSizeKB) {
        this.appSwal.showWarning(`Kích thước tập tin không được lớn hơn ${maxMB} MB`, false);
        return false;
      }
    } catch {
      return false;
    }
    const extension = e.files[0].extension.toLowerCase();
    if (!extension || (extension.toLowerCase() !== '.xlsx' && extension.toLowerCase() !== '.xls' && extension.toLowerCase() !== '.pdf')) {
      this.appSwal.showWarning('Vui lòng chọn tập tin có định dạng .xlsx, .xls.', false);
      return false;
    }
    const fileData = (await this.file.readXLSX(e.files[0].rawFile)) as Array<any>;
    this.InputingFile = true;
    this.setSelectableSettings();
    this.dataRoomMeetings = [];
    const RoomMeetingListTemp = this.RoomMeetings.slice();

    // tslint:disable-next-line:prefer-for-of
    for (let i = 1; i < fileData.length; i++) {
      let deviceNames = fileData[i][this.getColumnIndex('DeviceListName')];
      let address = fileData[i][this.getColumnIndex('Address')];
      let name = fileData[i][this.getColumnIndex('Name')];
      if (this.dataRoomMeetingsTemp.filter(item => item.Name == name).length > 0
				&& this.dataRoomMeetingsTemp.filter(item => item.Address == address).length > 0) {
				this.appSwal.showError(this.translate.instant('DuplicateDataImport'));
				this.isEnabledSaveAll = true;
        this.appComponent.loading = false;
				return
      }
      var deviceIds = [];
			let deviceItems = [];
			if (deviceNames && deviceNames != "") {
				deviceItems = this.getDeviceIdsFromNames(deviceNames);
				if (deviceItems) {
					deviceIds = deviceItems.map(({ ID }) => ID)
				}
      }
      
      
      const obj = {
        IsAdd: false,
        Address: address,
        Name: name,
        DeviceListName: fileData[i][this.getColumnIndex('DeviceListName')],
        DATA_DEVICEs: deviceItems,
        SeatNumber: fileData[i][this.getColumnIndex('SeatNumber')],
        DelFlg: fileData[i][this.getColumnIndex('DelFlg')]
      };

      this.dataRoomMeetings.push(obj);
    }
    this.bindRoomMeetings();
    this.isEnabledSaveAll = true;
    this.appComponent.loading = false;
  }

  removeEventHandler() {
    this.isEnabledSaveAll = false;
    this.onReload();
  }

  onSearchTextChange(e: any) {
    if (!this.searchOption.SearchText) {
      this.onReload();
    }
  }

  onRemoveSearchText() {
    this.searchOption.SearchText = '';
    this.getRoomMeetings();
  }

  async initDisplay() {
    const deviceList = await this.appService.doGET('api/DeviceRoomMeeting', null);
		if (deviceList && deviceList.Status === 1) {
			this.DeviceRoomMeeting = deviceList.Data;
		}

  }

  onSearch() {
    this.getRoomMeetings();
    this.isEnabledSaveAll = false;
  }

  onReload() {
    this.InputingFile = false;
    this.setSelectableSettingsFile();
    this.searchOption.SearchText = '';
    this.getRoomMeetings();
    this.isEnabledSaveAll = false;
  }

  onClearRoomMeeting() {
    this.setDefault();
  }

  onAllowSelectMulti() {
    this.setSelectableSettings();
  }

  async getRoomMeeting() {
    const result = await this.appService.doGET('api/RoomMeeting', null);
    if (result.Status) {
      this.RoomMeetingfile = [];
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < result.Data.length; i++) {
        this.RoomMeetingfile.push({
          ID: result.Data[i].ID,
          Name: result.Data[i].Name
        });
      }
    }
  }

  async onAddNewRoomMeeting() {
    this.enabled = true;
    this.enabledID = true;
    this.setDefault();
    this.infoOpened = true;
    const resultRoomMeeting = await this.appService.doGET('api/RoomMeeting', null);
    if (resultRoomMeeting && resultRoomMeeting.Status === 1) {
      this.RoomMeetings = resultRoomMeeting.Data;
      this.RoomMeetingsFilter = this.RoomMeetings.slice();
    }

  }

  onSaveRoomMeeting() {
    if (this.dataRoomMeetingItem.IsAdd) {
      this.addRoomMeeting();

    } else {
      this.updateRoomMeeting();
    }

  }

  async onSaveRoomMeetings() {
    this.appComponent.loading = true;
    const dataRequests = [];
    
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.dataRoomMeetings.length; i++) {
      // check duplicate data
      let RoomMeeting = this.dataRoomMeetings[i];
      const dataRequest = {
        searchText: RoomMeeting.Name
      };
      const resultRoomMeetingListFromServer = await this.appService.doGET('api/RoomMeeting/Search', dataRequest);
      let isDuplicate = false;
      if (resultRoomMeetingListFromServer && resultRoomMeetingListFromServer.Data &&
        resultRoomMeetingListFromServer.Data.length > 0) {
        resultRoomMeetingListFromServer.Data.forEach(element => {
          if (element.Name == RoomMeeting.Name) {
            isDuplicate = true;
          }
        });
      }
      if (isDuplicate == false) {
        dataRequests.push(this.createDataRequest(this.dataRoomMeetings[i]));
      }
    }
    const result = await this.appService.doPOST('api/RoomMeeting/Saves', dataRequests);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.isEnabledSaveAll = false;
      this.infoOpened = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  createDataRequest(data) {
    const deviceList = [];
    // tslint:disable-next-line: max-line-length
    
    // if (this.dataRoomMeetingItemtemp.Name == "" && this.dataRoomMeetingItemtemp.Device == "") {
		// 	this.dataRoomMeetingItemtemp = data;
		// }
    
		if (typeof (this.dataRoomMeetingItemtemp.DATA_DEVICEs) !== 'undefined' && this.dataRoomMeetingItemtemp.DATA_DEVICEs != null && this.dataRoomMeetingItemtemp.DATA_DEVICEs.length > 0) {
			// tslint:disable-next-line:prefer-for-of
			for (let i = 0; i < this.dataRoomMeetingItemtemp.DATA_DEVICEs.length; i++) {
				deviceList.push(this.dataRoomMeetingItemtemp.DATA_DEVICEs[i].ID);
			}
    }
    const temp = data ? data : this.dataRoomMeetingItemtemp;
    return {
      ID: temp.ID,
      Name: temp.Name,
      Address: temp.Address,
      Device: JSON.stringify(deviceList) ,
      SeatNumber: temp.SeatNumber,
      DelFlg: temp.DelFlg,
    };
  }

  onEditRoomMeeting() {
    this.infoOpened = true;
    this.enabled = true;
    this.enabledID = false;
  }

  async addRoomMeeting() {
    this.appComponent.loading = true;
    const dataRequest = this.createDataRequest(null);
    const result = await this.appService.doPOST('api/RoomMeeting', dataRequest);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.setDefault();
      this.infoOpened = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  async updateRoomMeeting() {
    this.appComponent.loading = true;
    const id = this.dataRoomMeetingItem.ID;
    const dataRequest = this.createDataRequest(null);
    const result = await this.appService.doPUT('api/RoomMeeting', dataRequest, { id });
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.infoOpened = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
      this.onReload();
    }
    this.appComponent.loading = false;
  }

  async onDeleteRoomMeeting() {
    if (this.dataRoomMeetingSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }
    this.appComponent.loading = true;
    const dataRequest = {
      IDList: JSON.stringify(this.dataRoomMeetingSelection),
      FlgRevert: false
    };
    const option = await this.appSwal.showWarning(this.translate.instant('AreYouSure'), true);
    if (option) {
      const result = await this.appService.doPOST('api/RoomMeeting/Deletes', dataRequest);
      if (result && result.Status === 1) {
        this.notification.showSuccess(result.Msg);
        this.onReload();
        this.setDefault();
        this.enabled = false;
        this.enabledID = false;
        this.dataRoomMeetingSelection = [];
        this.allowMulti = false;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
    this.appComponent.loading = false;
  }
  onCloseInfo(e) {
    this.setDefault();
    this.infoOpened = false;
  }


}

import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-service-online',
  templateUrl: './service-online.component.html',
  styleUrls: ['./service-online.component.css']
})
export class ServiceOnlineComponent implements OnInit {

  isSticky: boolean = false;
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset > 0;
  }
  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit, OnDestroy, ViewChild, ElementRef, HostListener } from '@angular/core';
import { AppLanguage } from '../../../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../../services/app.service';
import { SelectableSettings, PageChangeEvent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { AppSwal } from 'src/app/services/app.swal';
import { IntlService } from '@progress/kendo-angular-intl';
import { Notification } from '../../../services/app.notification';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Observable } from 'rxjs/Observable';
import { AppGuid } from 'src/app/services/app.guid';
import { NullInjector } from '@angular/core/src/di/injector';
import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';
import * as XLSX from 'xlsx';
import { AppFile } from 'src/app/services/app.file';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppUtils } from 'src/app/services/app.utils';
import { AppComponent } from '../../../app.component';
import { DomSanitizer } from '@angular/platform-browser';
import { saveAs } from '@progress/kendo-file-saver';
import { Http } from '@angular/http';
import { KendoButtonService } from '@progress/kendo-angular-buttons/dist/es2015/button/button.service';
import { DataSource } from '@angular/cdk/table';

@Component({
  selector: 'app-data-device-room-meeting',
  templateUrl: './data-device-room-meeting.component.html',
  styleUrls: ['./data-device-room-meeting.component.css']
})
export class DataDeviceRoomMeetingComponent implements OnInit , OnDestroy {

  isStickySearch = false
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isStickySearch = window.pageYOffset >= 140;
  }

  user: any;
  loading = false;
  dataDeviceRoomMeetings = [];
  dataDeviceRoomMeetingsTemp = [];
  dataDeviceRoomMeetingSelectableSettings: SelectableSettings;
  dataDeviceRoomMeetingSort = {
    allowUnsort: true,
    mode: 'multiple'
  };
  public dataDeviceRoomMeetingFocus = {
    Name: true
  };
  dataDeviceRoomMeetingSortByField: SortDescriptor[] = [];

  public WORKING_NUM_PAGING_SKIP = 0;
  public WORKING_NUM_PAGING_TAKE = 50;
  public WORKING_NUM_PAGING_BTN = 5;

  dataDeviceRoomMeetingSkip = this.WORKING_NUM_PAGING_SKIP;
  dataDeviceRoomMeetingPageSize = this.WORKING_NUM_PAGING_TAKE;
  dataDeviceRoomMeetingSelection: number[] = [];
  dataDeviceRoomMeetingItem: any;
  dataDeviceRoomMeetingItemtemp: any;
  myInterval: any;
  infoOpened = false;

  public buttonCount = this.WORKING_NUM_PAGING_BTN;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;

  public dataDeviceRoomMeetingState: State = {
    skip: this.dataDeviceRoomMeetingSkip,
    take: this.dataDeviceRoomMeetingSkip + this.dataDeviceRoomMeetingPageSize,
    filter: {
      logic: 'and',
      filters: []
    }
  };
  dataDeviceRoomMeetingGridDataResult: GridDataResult;

  DeviceRoomMeetings: Array<{ Name: string, ID: string }>;
  DeviceRoomMeetingsFilter: Array<{ Name: string, ID: string }>;
  DeviceRoomMeetingfile: Array<{ Name: string, ID: string }>;


  public uploadSaveUrl = 'saveUrl';
  public uploadRemoveUrl = 'removeUrl';
  public enabled = false;
  public enabledID = false;
  isEnabledSaveAll = false;
  control: any;
  pageName: any;
  controlDefault = true;
  allowMulti = true;
  InputingFile = false;

  searchOption = {
    SearchText: ''
  };

  constructor(
    private translate: TranslateService,
    private language: AppLanguage,
    private appService: AppService,
    private appSwal: AppSwal,
    public intl: IntlService,
    private notification: Notification,
    private guid: AppGuid,
    private file: AppFile,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    private appUtils: AppUtils,
    private appComponent: AppComponent,
    private domSanitizer: DomSanitizer,
    private http: Http,
  ) {
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
    this.getControl();
    this.setDefault();
    this.language.default();
    this.setSelectableSettings();
    this.onReload();
    this.initDisplay();
    this.getPageName();
  }

  DeviceRoomMeetingsHandleFilter(value) {
    this.DeviceRoomMeetingsFilter = this.DeviceRoomMeetings.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }


  ngOnDestroy(): void {
    if (this.myInterval) { this.myInterval.unsubscribe(); }
  }

  ngOnInit() {
  }
  async getPageName() {
    this.pageName = await this.appControls.getPageName();
  }

  async getControl() {
    this.control = await this.appControls.getControls(this.user.RoleID);
    this.controlDefault = false;
  }

  setSelectableSettings(): void {
    this.allowMulti = !this.allowMulti;
    if (this.InputingFile === true) {
      this.allowMulti = false;
      this.dataDeviceRoomMeetingSelectableSettings = {
        enabled: false,
        mode: 'single'
      };
    } else {
      this.dataDeviceRoomMeetingSelectableSettings = {
        checkboxOnly: false,
        mode: this.allowMulti ? 'multiple' : 'single'
      };
    }
  }

  setSelectableSettingsFile(): void {
    this.dataDeviceRoomMeetingSelectableSettings = {
      enabled: true,
      checkboxOnly: false,
      mode: this.allowMulti ? 'multiple' : 'single'
    };
  }

  onSearchKeyPress(e: any) {
    if (e.keyCode === 13 && this.searchOption.SearchText) {
      this.onSearch();
    }
  }

  async getDeviceRoomMeetings() {
    this.loading = true;
    const dataRequest = {
      searchText: this.searchOption.SearchText
    };

    const result = await this.appService.doGET('api/DeviceRoomMeeting/Search', dataRequest);
    if (result && result.Status) {

      this.dataDeviceRoomMeetings = result.Data;
      this.dataDeviceRoomMeetingsTemp = result.Data;
      this.bindDeviceRoomMeetings();
    }
    this.loading = false;
    this.checkSelectionID();
  }


  checkSelectionID() {
    for (let i = this.dataDeviceRoomMeetingSelection.length - 1; i >= 0; i--) {
      const selectedItem = this.dataDeviceRoomMeetings.find((item) => {
        return item.ID === this.dataDeviceRoomMeetingSelection[i];
      });
      if (!selectedItem) {
        this.dataDeviceRoomMeetingSelection.splice(i, 1);
      }
    }
  }

  setDefault() {
    this.dataDeviceRoomMeetingItem = {
      IsAdd: true,
      ID: '',
      Name: '',
      Description: '',
      DelFlg: false,
    };
    this.dataDeviceRoomMeetingItemtemp = Object.assign({}, this.dataDeviceRoomMeetingItem);

    this.enabled = true;
    this.enabledID = false;
    this.dataDeviceRoomMeetingSelection = [];
  }
  bindtemp(item) {
    this.dataDeviceRoomMeetingItemtemp = Object.assign({}, item);
  }

  onDeviceRoomMeetingPageChange(event: PageChangeEvent) {
    this.dataDeviceRoomMeetingSkip = event.skip;
    this.bindDeviceRoomMeetings();
  }

  async onDeviceRoomMeetingSelectedKeysChange() {
    if (this.dataDeviceRoomMeetingSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }
    if (this.dataDeviceRoomMeetingSelection.length > 1) {
      if (this.allowMulti) {
        return;
      }
      this.appSwal.showWarning(this.translate.instant('SelectSingle'), false);
    } else {
      if (this.dataDeviceRoomMeetings.length > 0 && this.InputingFile === false) {
        const selectedID = this.dataDeviceRoomMeetingSelection[0];
        const selectedItem = this.dataDeviceRoomMeetings.find((item) => {
          return item.ID === selectedID;
        });
        selectedItem.IsAdd = false;
        this.bindtemp(selectedItem);
        this.dataDeviceRoomMeetingItem = selectedItem;
        
      }
    }
  }

  bindDeviceRoomMeetings() {
    this.dataDeviceRoomMeetingGridDataResult = {
      data: orderBy(this.dataDeviceRoomMeetings, this.dataDeviceRoomMeetingSortByField),
      total: this.dataDeviceRoomMeetings.length
    };

    this.dataDeviceRoomMeetingGridDataResult = process(this.dataDeviceRoomMeetings, this.dataDeviceRoomMeetingState);
  }

  onDeviceRoomMeetingSortChange(sort: SortDescriptor[]): void {
    this.dataDeviceRoomMeetingSortByField = sort;
    this.bindDeviceRoomMeetings();
  }

  public onDeviceRoomMeetingDataStateChange(state: DataStateChangeEvent): void {
    this.dataDeviceRoomMeetingSelection = [];
    this.dataDeviceRoomMeetingState = state;
    this.dataDeviceRoomMeetingGridDataResult = process(this.dataDeviceRoomMeetings, this.dataDeviceRoomMeetingState);
  }

  getColumnIndex(name) {
    const columns = [
      // 'ID',
      'Name',
      'Description',
      'DelFlg'
    ];

    return columns.indexOf(name);
  }

  async selectEventHandler(e: SelectEvent) {
    this.appComponent.loading = true;
    try {
      const fileData = e.files[0];// await this.file.readFile(e.files[0].rawFile);
      const maxMB = 25;
      const maxSizeKB = 1024 * 1024 * maxMB;
      if (fileData.size > maxSizeKB) {
        this.appSwal.showWarning(`Kích thước tập tin không được lớn hơn ${maxMB} MB`, false);
        return false;
      }
    } catch {
      return false;
    }
    const extension = e.files[0].extension.toLowerCase();
    if (!extension || (extension.toLowerCase() !== '.xlsx' && extension.toLowerCase() !== '.xls' && extension.toLowerCase() !== '.pdf')) {

      this.appSwal.showWarning('Vui lòng chọn tập tin có định dạng .xlsx, .xls.', false);

      return false;
    }

    const fileData = (await this.file.readXLSX(e.files[0].rawFile)) as Array<any>;
    this.InputingFile = true;
    this.setSelectableSettings();
    this.dataDeviceRoomMeetings = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 1; i < fileData.length; i++) {

      let deviceName = fileData[i][this.getColumnIndex('Name')];
      let description = fileData[i][this.getColumnIndex('Description')];
      if (this.dataDeviceRoomMeetingsTemp.filter(item => item.Name == deviceName).length > 0
				&& this.dataDeviceRoomMeetingsTemp.filter(item => item.Description == description).length > 0) {
				this.appSwal.showError(this.translate.instant('DuplicateDataImport'));
				this.isEnabledSaveAll = true;
        this.appComponent.loading = false;
				return
			}
    
      const obj = {
        IsAdd: false,
        Name: deviceName,
        Description: description,
        DelFlg: fileData[i][this.getColumnIndex('DelFlg')],
      };

      this.dataDeviceRoomMeetings.push(obj);
    }
    this.bindDeviceRoomMeetings();
    this.isEnabledSaveAll = true;
    this.appComponent.loading = false;
  }

  removeEventHandler() {
    this.isEnabledSaveAll = false;
    this.onReload();
  }



  onSearchTextChange(e: any) {
    if (!this.searchOption.SearchText) {
      this.onReload();
    }
  }

  onRemoveSearchText() {
    this.searchOption.SearchText = '';
    this.getDeviceRoomMeetings();
  }

  async initDisplay() {

  }

  onSearch() {
    this.getDeviceRoomMeetings();
    this.isEnabledSaveAll = false;
  }

  onReload() {
    this.InputingFile = false;
    this.setSelectableSettingsFile();
    this.searchOption.SearchText = '';
    this.getDeviceRoomMeetings();
    this.isEnabledSaveAll = false;
  }

  onClearDeviceRoomMeeting() {
    this.setDefault();
  }

  onAllowSelectMulti() {
    this.setSelectableSettings();
  }

  async getDeviceRoomMeeting() {
    const result = await this.appService.doGET('api/DeviceRoomMeeting', null);
    if (result.Status) {
      this.DeviceRoomMeetingfile = [];
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < result.Data.length; i++) {
        this.DeviceRoomMeetingfile.push({
          ID: result.Data[i].ID,
          Name: result.Data[i].Name
        });
      }
    }
  }

  async onAddNewDeviceRoomMeeting() {
    this.enabled = true;
    this.enabledID = true;
    this.setDefault();
    this.infoOpened = true;
    const resultDeviceRoomMeeting = await this.appService.doGET('api/DeviceRoomMeeting', null);
    if (resultDeviceRoomMeeting && resultDeviceRoomMeeting.Status === 1) {
      this.DeviceRoomMeetings = resultDeviceRoomMeeting.Data;
      this.DeviceRoomMeetingsFilter = this.DeviceRoomMeetings.slice();
    }
  }

  onSaveDeviceRoomMeeting() {
    if (this.dataDeviceRoomMeetingItem.IsAdd) {
      this.addDeviceRoomMeeting();
    } else {
      this.updateDeviceRoomMeeting();
    }

  }

  async onSaveDeviceRoomMeetings() {
    this.appComponent.loading = true;
    const dataRequests = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.dataDeviceRoomMeetings.length; i++) {
      // check duplicate data
      let DeviceRoomMeeting = this.dataDeviceRoomMeetings[i];
      const dataRequest = {
        searchText: DeviceRoomMeeting.Name
      };
      const resultDeviceRoomMeetingListFromServer = await this.appService.doGET('api/DeviceRoomMeeting/Search', dataRequest);
      let isDuplicate = false;
      if (resultDeviceRoomMeetingListFromServer && resultDeviceRoomMeetingListFromServer.Data &&
        resultDeviceRoomMeetingListFromServer.Data.length > 0) {
        resultDeviceRoomMeetingListFromServer.Data.forEach(element => {
          if (element.Name == DeviceRoomMeeting.Name) {
            isDuplicate = true;
          }
        });
      }
      if (isDuplicate == false) {
        dataRequests.push(this.createDataRequest(this.dataDeviceRoomMeetings[i]));
      }
    }
    const result = await this.appService.doPOST('api/DeviceRoomMeeting/Saves', dataRequests);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.isEnabledSaveAll = false;
      this.infoOpened = false;
      
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  createDataRequest(data) {
    const temp = data ? data : this.dataDeviceRoomMeetingItemtemp;
    return {
      ID: temp.ID,
      Name: temp.Name,
      Description: temp.Description,
      DelFlg: temp.DelFlg,
    };
  }

  onEditDeviceRoomMeeting() {
    this.infoOpened = true;
    this.enabled = true;
    this.enabledID = false;
  }

  async addDeviceRoomMeeting() {
    this.appComponent.loading = true;
    const dataRequest = this.createDataRequest(null);
    const result = await this.appService.doPOST('api/DeviceRoomMeeting', dataRequest);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.setDefault();
      
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  async updateDeviceRoomMeeting() {
    this.appComponent.loading = true;
    const id = this.dataDeviceRoomMeetingItem.ID;
    const dataRequest = this.createDataRequest(null);
    const result = await this.appService.doPUT('api/DeviceRoomMeeting', dataRequest, { id });
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.infoOpened = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
      this.onReload();
    }
    this.appComponent.loading = false;
  }

  async onDeleteDeviceRoomMeeting() {
    if (this.dataDeviceRoomMeetingSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }
    this.appComponent.loading = true;
    const dataRequest = {
      IDList: JSON.stringify(this.dataDeviceRoomMeetingSelection),
      FlgRevert: false
    };
    const option = await this.appSwal.showWarning(this.translate.instant('AreYouSure'), true);
    if (option) {
      const result = await this.appService.doPOST('api/DeviceRoomMeeting/Deletes', dataRequest);
      if (result && result.Status === 1) {
        this.notification.showSuccess(result.Msg);
        this.onReload();
        this.setDefault();
        this.enabled = false;
        this.enabledID = false;
        this.dataDeviceRoomMeetingSelection = [];
        this.allowMulti = false;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
    this.appComponent.loading = false;
  }
  onCloseInfo(e) {
    this.setDefault();
    this.infoOpened = false;
  }

}


import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-office-drt',
  templateUrl: './office-drt.component.html',
  styleUrls: ['./office-drt.component.css']
})
export class OfficeDrtComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Input, HostListener } from '@angular/core';
import { AppLanguage } from '../../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../services/app.service';
import { SelectableSettings, PageChangeEvent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { AppSwal } from 'src/app/services/app.swal';
import { IntlService } from '@progress/kendo-angular-intl';
import { Notification } from '../../services/app.notification';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
import { AppGuid } from 'src/app/services/app.guid';
import { NullInjector } from '@angular/core/src/di/injector';
import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';
import * as XLSX from 'xlsx';
import { AppFile } from 'src/app/services/app.file';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppUtils } from 'src/app/services/app.utils';
import { AppComponent } from '../../app.component';
import { delay, map } from 'rxjs/operators';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css'],
})
export class UserComponent implements OnInit, OnDestroy {

  isSticky = false
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 140;
  }

  user: any;
  loading = false;
  dataUsers = [];
  dataUserSelectableSettings: SelectableSettings;
  dataUserSort = {
    allowUnsort: true,
    mode: 'multiple'
  };
  public group: any[] = [{
    field: 'RoleName'
  }];
  public dataUserFocus = {
    Name: true
  };
  dataUserSortByField: SortDescriptor[] = [
    // {
    //   field: 'ParentName',
    //   dir: 'asc'
    // }, {
    //   field: 'OrdinalNumber',
    //   dir: 'asc'
    // }
  ];

  public LBM_NUM_PAGING_SKIP = 0;
  public LBM_NUM_PAGING_TAKE = 50;
  public LBM_NUM_PAGING_BTN = 5;

  dataUserSkip = this.LBM_NUM_PAGING_SKIP;
  dataUserPageSize = this.LBM_NUM_PAGING_TAKE;
  dataUserSelection: number[] = [];
  dataUserItem: any;
  myInterval: any;
  pageName: any;
  infoOpened = false;

  public buttonCount = this.LBM_NUM_PAGING_BTN;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;

  public dataUserState: State = {
    skip: this.dataUserSkip,
    take: this.dataUserSkip + this.dataUserPageSize,
    filter: {
      logic: 'and',
      filters: []
    },
    group: [{ field: 'RoleName' }]
  };
  dataUserGridDataResult: GridDataResult;

  roles: Array<{ Name: string, ID: string }>;
  rolesFilter: Array<{ Name: string, ID: string }>;

  units: Array<{ Name: string, ID: string }>;
  unitsFilter: Array<{ Name: string, ID: string }>;
  members: Array<{ FullName: string, UserName: string }> = [];
  membersFilter: Array<{ FullName: string, UserName: string }> = [];


  public uploadSaveUrl = 'saveUrl';
  public uploadRemoveUrl = 'removeUrl';
  public enabled = false;
  public enabledID = false;
  isEnabledSaveAll = false;
  control: any;
  controlDefault = true;
  allowMulti = true;

  searchOption = {
    SearchText: ''
  };

  @Input() userType = 0;

  public dataUserStateCmt: State = {
    skip: this.dataUserSkip,
    take: this.dataUserSkip + this.dataUserPageSize,
    filter: {
      logic: 'and',
      filters: []
    }
  };

  constructor(
    private translate: TranslateService,
    private language: AppLanguage,
    private appService: AppService,
    private appSwal: AppSwal,
    public intl: IntlService,
    private notification: Notification,
    private guid: AppGuid,
    private file: AppFile,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    private appUtils: AppUtils,
    private appComponent: AppComponent,
  ) {
    
  }

  rolesHandleFilter(value) {
    this.rolesFilter = this.roles.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  unitsHandleFilter(value) {
    this.unitsFilter = this.units.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  membersHandleFilter(value) {
    this.membersFilter = this.members.filter((s) => s.FullName.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  ngOnDestroy(): void {
    if (this.myInterval) { this.myInterval.unsubscribe(); }
  }

  ngOnInit() {
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
    this.getControl();
    this.setDefault();
    this.language.default();
    this.setSelectableSettings();
    this.onReload();
    this.initDisplay();
    this.allData = this.allData.bind(this);
    this.getPageName();
  }
  async getPageName() {
    this.pageName = await this.appControls.getPageName();
  }

  async getControl() {
    this.control = await this.appControls.getControls(this.user.RoleID);
    this.controlDefault = false;
  }

  setSelectableSettings(): void {

    this.allowMulti = !this.allowMulti;
    this.dataUserSelectableSettings = {
      checkboxOnly: false,
      mode: this.allowMulti ? 'multiple' : 'single'
    };
  }

  onSearchKeyPress(e: any) {
    if (e.keyCode === 13 && this.searchOption.SearchText) {
      this.onSearch();
    }
  }

  async getUsers() {
    this.loading = true;
    const dataRequest = {
      searchText: this.searchOption.SearchText
    };
    const result = await this.appService.doGET('api/User/Search', dataRequest);
    //const result = await this.appService.doGET('api/User/Search', null);
    if (result && result.Status === 1) {
      for (let i = 0; i < result.Data.length; i++) {
        if(result.Data[i].MemberList == null || result.Data[i].MemberList == ""){
          result.Data[i].MemberList = [];
        }
        else{
          result.Data[i].MemberList = JSON.parse(result.Data[i].MemberList);
        }
        if(result.Data[i].UnitRpList == null || result.Data[i].UnitRpList == ""){
          result.Data[i].UnitRpList = [];
        }
        else{
          result.Data[i].UnitRpList = JSON.parse(result.Data[i].UnitRpList);
        }
      }
      this.dataUsers = result.Data;
      console.log(this.dataUsers)
      this.bindUsers();
    }
    this.loading = false;
    this.checkSelectionID();
  }

  checkSelectionID() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = this.dataUserSelection.length - 1; i >= 0; i--) {
      const selectedItem = this.dataUsers.find((item) => {
        return item.UserName === this.dataUserSelection[i];
      });
      if (!selectedItem) {
        this.dataUserSelection.splice(i, 1);
      }
    }
  }

  setDefault() {
    this.dataUserItem = {
      IsAdd: true,
      UserName: '',
      Password: '',
      FullName: '',
      Description: '',
      LockFlg: false,
      DelFlg: false,
      RoleID: '',
      UnitID: null
    };


    this.dataUserSelection = []
    this.enabled = true;
    this.enabledID = true;
  }

  onUserPageChange(event: PageChangeEvent) {
    this.dataUserSkip = event.skip;
    this.bindUsers();
  }

  onUserSelectedKeysChange() {

    if (this.dataUserSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }

    if (this.dataUserSelection.length > 1) {
      if (this.allowMulti) {
        return;
      }
      this.appSwal.showWarning(this.translate.instant('SelectSingle'), false);
    } else {
      const selectedID = this.dataUserSelection[0];
      const selectedItem = this.dataUsers.find((item) => {
        return item.UserName === selectedID;
      });
      selectedItem.IsAdd = false;
      this.dataUserItem = selectedItem;
      this.enabled = false;
      this.enabledID = false;
    }
  }

  bindUsers() {
    this.dataUserGridDataResult = {
      data: orderBy(this.dataUsers, this.dataUserSortByField),
      total: this.dataUsers.length
    };
    if(this.userType == 1){
      this.dataUserGridDataResult = process(this.dataUsers, this.dataUserStateCmt);
    }else {
      this.dataUserGridDataResult = process(this.dataUsers, this.dataUserState);
    }
  }

  onUserSortChange(sort: SortDescriptor[]): void {
    this.dataUserSortByField = sort;
    this.bindUsers();
  }

  public onUserDataStateChange(state: DataStateChangeEvent): void {
    this.dataUserSelection = [];
    if(this.userType == 1){
      this.dataUserStateCmt = state;
      this.dataUserGridDataResult = process(this.dataUsers, this.dataUserStateCmt);
    }else {
      this.dataUserState = state;
      this.dataUserGridDataResult = process(this.dataUsers, this.dataUserState);
    }
    
  }

  getColumnIndex(name) {
    const columns = [
      'RoleName',
      'RoleID',
      'UserName',
      'Password',
      'FullName',
      'UnitID',
      'Description',
      'LockFlg',
      'DelFlg'
    ];

    return columns.indexOf(name);
  }

  async selectEventHandler(e: SelectEvent) {
    this.appComponent.loading = true;
    try {
      const fileData = e.files[0];// await this.file.readFile(e.files[0].rawFile);

      const maxMB = 25;
      const maxSizeKB = 1024 * 1024 * maxMB;
      if (fileData.size > maxSizeKB) {
        this.appSwal.showWarning(`Kích thước tập tin không được lớn hơn ${maxMB} MB`, false);
        return false;
      }
    } catch {
      return false;
    }
    const extension = e.files[0].extension.toLowerCase();
    if (!extension || (extension.toLowerCase() !== '.xlsx' && extension.toLowerCase() !== '.xls' && extension.toLowerCase() !== '.pdf')) {
      
      this.appSwal.showWarning('Vui lòng chọn tập tin có định dạng .xlsx, .xls.', false);

      return false;
    }
    const fileData = (await this.file.readXLSX(e.files[0].rawFile)) as Array<any>;
    let role: any;
    let unit: any;
    this.dataUsers = [];
    for (let i = 1; i < fileData.length; i++) {
      role = this.roles.find(item => {
        return this.appUtils.compareString(fileData[i][this.getColumnIndex('RoleID')], item.Name, item.ID);
      });

      unit = this.units.find(item => {
        return this.appUtils.compareString(fileData[i][this.getColumnIndex('UnitID')], item.Name, item.ID);
      });

      if (fileData[i].indexOf(fileData[i][this.getColumnIndex('RoleName')]) === -1) {
        this.dataUsers.push({
          IsAdd: false,
          RoleID: role ? role.ID : null,
          RoleName: role ? role.Name : null,
          UnitID: unit ? unit.ID : null,
          UnitName: unit ? unit.Name : null,

          UserName: fileData[i][this.getColumnIndex('UserName')],
          Password: fileData[i][this.getColumnIndex('Password')],
          FullName: fileData[i][this.getColumnIndex('FullName')],
          Description: fileData[i][this.getColumnIndex('Description')],
          LockFlg: fileData[i][this.getColumnIndex('LockFlg')],
          DelFlg: fileData[i][this.getColumnIndex('DelFlg')],
        });
      }
    }
    this.bindUsers();
    this.isEnabledSaveAll = true;
    this.appComponent.loading = false;
  }

  removeEventHandler() {
    this.isEnabledSaveAll = false;
    this.onReload();
  }

  doInterval() {
    this.myInterval = Observable.interval(this.appService.timeInterval).subscribe(() => {
      this.onReload();
    });
  }

  onSearchTextChange(e: any) {
    if (!this.searchOption.SearchText) {
      this.onReload();
    }
  }

  onRemoveSearchText() {
    this.searchOption.SearchText = '';

  }

  async initDisplay() {
    const resultRole = await this.appService.doGET('api/Role', null);
    if (resultRole && resultRole.Status === 1) {
      this.roles = resultRole.Data;
      this.rolesFilter = this.roles.slice();
    }

    const resultUnit = await this.appService.doGET('api/Unit', null);
    if (resultUnit && resultUnit.Status === 1) {
      this.units = resultUnit.Data;
      this.unitsFilter = this.units.slice();
    }

    const resultMemberList = await this.appService.doGET('api/User/GetListUser', null);
    if (resultMemberList && resultMemberList.Status === 1) {      
      this.members = resultMemberList.Data;
      this.membersFilter = this.members.slice();
    }
  }

  onSearch() {
    if(this.userType == 1){
      this.searchCmtUser();
    }else{
      this.getUsers();
    }    
    this.isEnabledSaveAll = false;
  }

  onReload() {
    this.searchOption.SearchText = '';
    if(this.userType == 1){
      this.searchCmtUser();
    }else{
      this.getUsers();
    }
    this.isEnabledSaveAll = false;
  }

  onClearUser() {
    this.setDefault();
  }

  onAllowSelectMulti() {
    this.setSelectableSettings();
  }

  onAddNewUser() {
    
    this.setDefault();
    this.infoOpened = true;
  }

  onSaveUser() {
    if (this.dataUserItem.IsAdd) { this.addUser(); } else { this.updateUser(); }
  }

  async onSaveUsers() {
    this.appComponent.loading = true;
    const dataRequests = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.dataUsers.length; i++) {
      // check duplicate data
      let user = this.dataUsers[i];
      const dataRequest = {
        searchText: user.UserName
      };
  
      const resultUserListFromServer = await this.appService.doGET('api/User/Search', dataRequest);			
      let isDuplicate = false;
			if (resultUserListFromServer && resultUserListFromServer.Data &&
				resultUserListFromServer.Data.length > 0) {
          resultUserListFromServer.Data.forEach(element => {
					if (element.UserName == user.UserName) {
						isDuplicate = true;
					}
				});
			}
			if (isDuplicate == false) {
				dataRequests.push(this.createDataRequest(this.dataUsers[i]));
			}
      
    }
    const result = await this.appService.doPOST('api/User/Saves', dataRequests);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.isEnabledSaveAll = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  createDataRequest(data) {
    const temp = data ? data : this.dataUserItem;

    var members: any[] = [];
    if (this.dataUserItem.MemberList) {
      for (let i = 0; i < this.dataUserItem.MemberList.length; i++) {
        members.push(this.dataUserItem.MemberList[i]);
      }
    }

    var unitRplist: any[] = [];
    if (this.dataUserItem.UnitRpList) {
      for (let i = 0; i < this.dataUserItem.UnitRpList.length; i++) {
        unitRplist.push(this.dataUserItem.UnitRpList[i]);
      }
    }
    return {
      UserName: temp.UserName,
      Password: temp.Password,
      FullName: temp.FullName,
      Description: temp.Description,
      LockFlg: temp.LockFlg,
      DelFlg: temp.DelFlg,
      RoleID: temp.RoleID,
      UnitID: temp.UnitID,
      MemberList: members.length > 0 ? JSON.stringify(members): null,
      UnitRpList: unitRplist.length > 0 ? JSON.stringify(unitRplist): null
    };
  }

  onCloseUser(status: any) {
    this.enabled = false;
    this.enabledID = false;
    this.infoOpened = true;
  }

  onEditUser() {
    this.enabled = true;
    this.enabledID = false;
    this.infoOpened = true;
  }

  async addUser() {
    this.appComponent.loading = true;
    const dataRequest = this.createDataRequest(null);
    const result = await this.appService.doPOST('api/User', dataRequest);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.setDefault();
      this.infoOpened = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  async updateUser() {
    this.appComponent.loading = true;
    const id = this.dataUserItem.UserName;
    const dataRequest = this.createDataRequest(null);

    const result = await this.appService.doPUT('api/User', dataRequest, { id });
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.onAddNewUser();
      this.infoOpened = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  async onDeleteUser() {
    if (this.dataUserSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }
    this.appComponent.loading = true;
    const dataRequest = {
      IDList: JSON.stringify(this.dataUserSelection),
      FlgRevert: false
    };
    const option = await this.appSwal.showWarning(this.translate.instant('AreYouSure'), true);
    if (option) {
      const result = await this.appService.doPOST('api/User/Deletes', dataRequest);
      if (result && result.Status === 1) {
        this.notification.showSuccess(result.Msg);
        this.onReload();
        //this.onAddNewUser();
        this.enabled = false;
        this.enabledID = false;
        this.dataUserSelection = [];
        this.allowMulti = false;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
    this.appComponent.loading = false;
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData =  {
        data: process(this.dataUsers, { group: this.group }).data,
        group: this.group
    };

    return result;
  }
  onCloseInfo(e) {
    this.setDefault()
    this.infoOpened = false;
  }
  async searchCmtUser() {
    this.loading = true;
    const dataRequest = {
      searchText: this.searchOption.SearchText,
      Type: this.userType
    };
    const result = await this.appService.doGET('api/User/SearchCmtUser', dataRequest);
    //const result = await this.appService.doGET('api/User/Search', null);
    if (result) {
      this.dataUsers = result.Data;
      this.bindUsers();
    }
    this.loading = false;
    this.checkSelectionID();
  }
  async onLockCmtUser() {
    if (this.dataUserSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }
    this.appComponent.loading = true;
    const dataRequest = {
      IDList: JSON.stringify(this.dataUserSelection),
      FlgRevert: false
    };
    const option = await this.appSwal.showWarning(this.translate.instant('AreYouSure'), true);
    if (option) {
      const result = await this.appService.doPOST('api/User/LockCmtUser', dataRequest);
      if (result && result.Status === 1) {
        this.notification.showSuccess(result.Msg);
        this.onReload();
        //this.onAddNewUser();
        this.enabled = false;
        this.enabledID = false;
        this.dataUserSelection = [];
        this.allowMulti = false;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
    this.appComponent.loading = false;
  }

  async onUnLockCmtUser() {
    if (this.dataUserSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }
    this.appComponent.loading = true;
    const dataRequest = {
      IDList: JSON.stringify(this.dataUserSelection),
      FlgRevert: true
    };
    const option = await this.appSwal.showWarning(this.translate.instant('AreYouSure'), true);
    if (option) {
      const result = await this.appService.doPOST('api/User/LockCmtUser', dataRequest);
      if (result && result.Status === 1) {
        this.notification.showSuccess(result.Msg);
        this.onReload();
        //this.onAddNewUser();
        this.enabled = false;
        this.enabledID = false;
        this.dataUserSelection = [];
        this.allowMulti = false;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
    this.appComponent.loading = false;
  }

  public valueNormalizerMember = (text$: Observable<string>) => text$.pipe(map((text: string) => {
    //search for matching item to avoid duplicates
    if(!text.replace(/\s/g, '').length){
      return
    }    
  }));
}

import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { AppLanguage } from '../../services/app.language';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../../services/app.service';
import { SelectableSettings, PageChangeEvent, GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State, process, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { AppSwal } from 'src/app/services/app.swal';
import { IntlService } from '@progress/kendo-angular-intl';
import { Notification } from '../../services/app.notification';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/interval';
import { AppGuid } from 'src/app/services/app.guid';
import { NullInjector } from '@angular/core/src/di/injector';
import { FileRestrictions, SelectEvent, ClearEvent, RemoveEvent, FileInfo } from '@progress/kendo-angular-upload';
import * as XLSX from 'xlsx';
import { AppFile } from 'src/app/services/app.file';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppControls } from 'src/app/services/app.controls';
import { AppUtils } from 'src/app/services/app.utils';
import { AppComponent } from '../../app.component';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpProgressEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { of, concat } from 'rxjs';
import { delay } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import { flatten } from '@angular/core/src/render3/util';

@Component({
  selector: 'app-post-ward',
  templateUrl: './post-ward.component.html',
  styleUrls: ['./post-ward.component.css']
})
export class PostWardComponent implements OnInit {
  //codeimage
  commentInput = null;
  infoOpened = false;
  public abc = '123';
  uploadFlag = {
    banner: false,
    image: false,
    video: false,
  };
  reViewOpened = false;
  postTemp = {
    MenuName: '',
    Title: '',
    Description: '',
    ImageUrls: ''
  };

  public uploadFlgDefault = {
    banner: false,
    image: false,
    video: false,
  };
  pageName: any;
  uploadFlg = this.uploadFlgDefault;
  public websiteDefault = null;
  websiteLink = this.websiteDefault;
  public bannerDef = '../../assets/images/default-01.png';
  public imageDef = '../../assets/images/default-02.png';
  bannerUrl: any;
  public imgSaveUrl: any;
  imgImageDefault: any;
  imgImageMain: any;
  //


  user: any;
  loading = false;
  dataPosts = [];
  dataPostSelectableSettings: SelectableSettings;
  dataPostSort = {
    allowUnsort: true,
    mode: 'multiple'
  };
  public dataPostFocus = {
    Name: true
  };
  dataPostSortByField: SortDescriptor[] = [
    // {
    //   field: 'ParentName',
    //   dir: 'asc'
    // }, {
    //   field: 'OrdinalNumber',
    //   dir: 'asc'
    // }
  ];

  dataLogPostSelectableSettings: SelectableSettings;
  logPostOpened = false;
  //imagesUploadName = "";

  public LBM_NUM_PAGING_SKIP = 0;
  public LBM_NUM_PAGING_TAKE = 50;
  public LBM_NUM_PAGING_BTN = 5;

  dataPostSkip = this.LBM_NUM_PAGING_SKIP;
  dataPostPageSize = this.LBM_NUM_PAGING_TAKE;
  dataPostSelection: number[] = [];
  dataPostItem: any;
  myInterval: any;



  public buttonCount = this.LBM_NUM_PAGING_BTN;
  public info = true;
  public type: 'numeric' | 'input' = 'numeric';
  public pageSizes = true;
  public previousNext = true;

  public dataPostState: State = {
    skip: this.dataPostSkip,
    take: this.dataPostSkip + this.dataPostPageSize,
    filter: {
      logic: 'and',
      filters: []
    },
    //group: [{ field: 'TypeName' }]
  };
  dataPostGridDataResult: GridDataResult;

  dataFileUrlsGridDataResult: GridDataResult;
  dataFileUrlsSelectableSettings: SelectableSettings;
  dataFileUrlsSkip = this.LBM_NUM_PAGING_SKIP;
  dataFileUrlsPageSize = this.LBM_NUM_PAGING_TAKE;
  dataFileUrlsSelection: number[] = [];

  dataLogPostGridDataResult: GridDataResult;
  dataLogPosts: any[];

  dataMenuID: any[];

  menus: Array<{ Name: string, ID: string }>;
  menusFilter: Array<{ Name: string, ID: string }>;
  menusFilterSearch: Array<{ Name: string, ID: string }>;
  filesUpload: Array<FileInfo>;
  dataFileUrls: any[];
  public fileSaveUrl: any;

  public uploadSaveUrl = 'saveUrl';
  public uploadRemoveUrl = 'removeUrl';
  public enabled = false;
  public enabledID = false;
  isEnabledSaveAll = false;
  control: any;
  controlDefault = true;
  allowMulti = true;

  searchOption = {
    SearchText: '',
    PortalId: 14,
    MenuID: ''
  };

  portalIds: any[];

  constructor(
    private translate: TranslateService,
    private language: AppLanguage,
    private appService: AppService,
    private appSwal: AppSwal,
    public intl: IntlService,
    private notification: Notification,
    private guid: AppGuid,
    private file: AppFile,
    private authenticationService: AuthenticationService,
    public appControls: AppControls,
    private appUtils: AppUtils,
    private appComponent: AppComponent,
    public domSanitizer: DomSanitizer,
  ) {
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
    this.imgSaveUrl = `${this.appService.apiRoot}api/Upload/MediaWeb?accountID=${this.user.UserName}&typeData=image`;
    this.getControl();
    this.setDefault();
    this.language.default();
    this.setSelectableSettings();
    this.initDisplay();
    this.onReload();
    this.allData = this.allData.bind(this);
    this.getPageName();
  }

  ngOnDestroy(): void {

  }

  ngOnInit() {
  }
  
  async getPageName() {
    this.pageName = await this.appControls.getPageName();
  }
  async getControl() {
    this.control = await this.appControls.getControls(this.user.RoleID);
    this.controlDefault = false;
  }

  setSelectableSettings(): void {

    this.allowMulti = !this.allowMulti;
    this.dataPostSelectableSettings = {
      checkboxOnly: false,
      mode: this.allowMulti ? 'multiple' : 'single'
    };

    this.dataFileUrlsSelectableSettings = {
      checkboxOnly: false,
      mode: 'single'
    };
  }

  onMenusHandleFilter(value) {
    this.menusFilter = this.menus.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  onMenusSearchHandleFilter(value) {
    this.menusFilterSearch = this.menus.filter((s) => s.Name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }

  onSearchKeyPress(e: any) {
    if (e.keyCode === 13 && this.searchOption.SearchText) {
      this.onSearch();
    }
  }

  public valueChange(value: any): void {
    
  }

  async onGetPost15p() {
    this.loading = true;
    const dataRequest = {
      id: this.searchOption.PortalId
    };

    const result = await this.appService.doGET('api/Post/GetPost15p', dataRequest);
    if (result) {
      this.dataPosts = result.Data;
      this.bindPosts();
    }
    this.loading = false;
    this.checkSelectionID();
  }

  checkSelectionID() {
    // tslint:disable-next-line:prefer-for-of
    for (let i = this.dataPostSelection.length - 1; i >= 0; i--) {
      const selectedItem = this.dataPosts.find((item) => {
        return item.ID === this.dataPostSelection[i];
      });
      if (!selectedItem) {
        this.dataPostSelection.splice(i, 1);
      }
    }
  }

  setDefault() {
    this.dataPostItem = {
      IsAdd: true,
      MenuID: null,
      Title: null,
      Summary: null,
      Description: null,
      ImageUrls: null,
      FileUrls: null,
      CreateAt: new Date(),
      UpdateAt: new Date(),
      MobileFlg: null,
      ApprovedFlg: null,
      ApprovedAt: new Date(),
      ApprovedBy: null,
      OrderIdx: -1,
      HotFlg: false,
      Note: ''
    };
    this.imageDef = '../../assets/images/default-02.png';
    this.imgImageDefault = [
      this.imageDef,
      // this.imageDef,
      // this.imageDef,
      
    ];
    //this.imagesUploadName = "";
    this.imgImageMain = [''];
    this.uploadFlg = this.uploadFlgDefault;
    this.websiteLink = this.websiteDefault;

    this.dataMenuID = [];
    this.dataPostSelection = [];
    this.filesUpload = [];
    this.dataFileUrls = [];
    //this.bindFileUrls();
    this.fileSaveUrl = `${this.appService.apiRoot}api/Upload/MediaWeb?accountID=${this.user.UserName}&typeData=files`;
  }
  imgChange = true;
  async onImageUrlSelectEventHandler(e: SelectEvent, index) {
    if (!e.files || e.files.length <= 0) {
      return false;
    }
    try {
      const fileData = e.files[0];// await this.file.readFile(e.files[0].rawFile);
      const maxMB = 25;
      const maxSizeKB = 1024 * 1024 * maxMB;
      if (fileData.size > maxSizeKB) {
        this.appSwal.showWarning(`Kích thước tập tin không được lớn hơn ${maxMB} MB`, false);
        return false;
      }
    } catch {
      return false;
    }
    // tslint:disable-next-line: max-line-length
    if (e.files[0].extension !== '.jpg' && e.files[0].extension !== '.png' && e.files[0].extension !== '.gif' && e.files[0].extension !== '.jpeg') {
      this.appSwal.showWarning(this.translate.instant('MsgImgOnly'), false);
      return false;
    }

    const fileData = await this.file.readImage(e.files[0].rawFile);
    const base64 = `${fileData}`;
    this.imgImageDefault[index] = this.domSanitizer.bypassSecurityTrustResourceUrl(base64);
    this.imgImageDefault.push(this.imageDef);
    this.imgChange = false;
    //this.imagesUploadName = e.files[0].rawFile.name;
  }

  onImageUrlSuccessEventHandle(e: any, index) {
    try {
      this.uploadFlg.image = true;
      this.imgImageMain[index] = e.response.body.Data.MediaNm[0];
    } catch {
      this.uploadFlg.image = false;
      // this.imgImageDefault[index] = this.imageDef;
    }
  }

  onImageUrlRemoveEventHandler(index) {
    this.imgImageMain[index] = '';
  }

  onPostPageChange(event: PageChangeEvent) {
    this.dataPostSkip = event.skip;
    this.bindPosts();
  }

  async onPostSelectedKeysChange() {

    if (this.dataPostSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }

    if (this.dataPostSelection.length > 1) {
      if (this.allowMulti) {
        return;
      }
      this.appSwal.showWarning(this.translate.instant('SelectSingle'), false);
    } else {
      const selectedID = this.dataPostSelection[0];
      const selectedItem = this.dataPosts.find((item) => {
        return item.ID === selectedID;
      });
      selectedItem.IsAdd = true;
      
      this.dataPostItem = selectedItem;
      /*-------------------------xử lý hình ảnh-------------------------*/
      var stringDomain = "http://www.quan10.gov.vn";

      //--------lấy nội dung bài viết => lấy index đầu cuối cắt chuỗi--------//
      var tempDescription = this.dataPostItem.Description;
      var startIndex = tempDescription.indexOf("src");
      var endIndex = tempDescription.indexOf('.jpg"');

      //--------chuỗi đã cắt lấy vị trí để chèn domain--------//
      var tempURL_Image = tempDescription.substring(startIndex, endIndex + '.jpg"'.length + 1)
      
      var positionDomain = tempURL_Image.indexOf("/Portals");

      //--------chuỗi sau khi gắn domain--------//
      var URL_Image = [tempURL_Image.slice(0, positionDomain), stringDomain, tempURL_Image.slice(positionDomain)].join('');
      

      //--------replace vào nội dung--------//
      do{
        this.dataPostItem.Description = this.dataPostItem.Description.replace('src="/Portals', 'src="http://www.quan10.gov.vn/Portals')
        
      }
      while(this.dataPostItem.Description.indexOf('src="/Portals') > 0)

      do{
        this.dataPostItem.Description = this.dataPostItem.Description.replace('href="/Portals', 'href="http://www.quan10.gov.vn/Portals')
      }
      while(this.dataPostItem.Description.indexOf('href="/Portals') > 0)
      this.dataPostItem.ImageUrls = selectedItem.ImageUrls;

      /*----------------------------------------------------------------*/
      this.imgImageDefault = [];
      //call api get base 64 to render image
      this.imageDef = '../../assets/images/default-02.png';
      this.imgImageDefault = [
        this.imageDef,
        // this.imageDef,
        // this.imageDef,
      ];

      const temp = [];
      if (selectedItem.MenuName) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.menus.length; i++) {
          if (selectedItem.MenuName.indexOf(this.menus[i].Name) >= 0) {
            temp.push(this.menus[i]);
          }
        }
      }
      this.dataMenuID = temp;


      // const fileUrls = JSON.parse(selectedItem.FileUrls);
      // this.dataFileUrls = [];
      // // tslint:disable-next-line:prefer-for-of
      // for (let i = 0; i < fileUrls.length; i++) {
      //   this.dataFileUrls.push({
      //     Name: this.getNameByUrl(fileUrls[i]),
      //     Url: fileUrls[i]
      //   });
      // }
      // this.bindFileUrls();
      //this.imagesUploadName = this.getPostName(this.dataPostItem);

      this.uploadFlg.image = false;
      this.enabled = false;
      this.enabledID = false;
    }
  }

  bindPosts() {
    this.dataPostGridDataResult = {
      data: orderBy(this.dataPosts, this.dataPostSortByField),
      total: this.dataPosts.length
    };

    this.dataPostGridDataResult = process(this.dataPosts, this.dataPostState);
  }

  bindFileUrls() {
    this.dataFileUrlsGridDataResult = {
      data: this.dataFileUrls,
      total: this.dataFileUrls.length
    };

    this.dataPostGridDataResult = process(this.dataPosts, this.dataPostState);
  }

  bindLogPosts() {
    this.dataLogPostGridDataResult = {
      data: this.dataLogPosts,
      total: this.dataLogPosts.length
    };

    this.dataPostGridDataResult = process(this.dataPosts, this.dataPostState);
  }

  onPostSortChange(sort: SortDescriptor[]): void {
    this.dataPostSortByField = sort;
    this.bindPosts();
  }

  public onPostDataStateChange(state: DataStateChangeEvent): void {
    this.dataPostSelection = [];
    this.dataPostState = state;
    this.dataPostGridDataResult = process(this.dataPosts, this.dataPostState);
    //this.onReload();
  }

  removeEventHandler(index) {
    this.isEnabledSaveAll = false;
    this.imgImageMain[index] = '',
      this.onReload();
  }

  doInterval() {
    // this.myInterval = Observable.interval(this.appService.timeInterval).subscribe(() => {
    //   this.onReload();
    // });
  }

  onSearchTextChange(e: any) {
    if (!this.searchOption.SearchText) {
      this.onReload();
    }
  }

  onRemoveSearchText() {
    this.searchOption.SearchText = '';

  }

  async initDisplay() {

    const resultPost = await this.appService.doGET('api/Post/GetMenu', null);
    if (resultPost && resultPost.Status === 1) {
      this.menus = resultPost.Data;
      this.menusFilter = this.menus.slice();
    }

    this.portalIds = [
      { Name: 'Phường 1 Quận 10', ID: 14 },
      { Name: 'Phường 2 Quận 10', ID: 15 },
      { Name: 'Phường 3 Quận 10', ID: 16 },
      { Name: 'Phường 4 Quận 10', ID: 2 },
      { Name: 'Phường 5 Quận 10', ID: 17 },
      { Name: 'Phường 6 Quận 10', ID: 18 },
      { Name: 'Phường 7 Quận 10', ID: 19 },
      { Name: 'Phường 8 Quận 10', ID: 20 },
      { Name: 'Phường 9 Quận 10', ID: 10 },
      { Name: 'Phường 10 Quận 10', ID: 21 },
      { Name: 'Phường 11 Quận 10', ID: 22 },
      { Name: 'Phường 12 Quận 10', ID: 23 },
      { Name: 'Phường 13 Quận 10', ID: 24 },
      { Name: 'Phường 14 Quận 10', ID: 11 },
      { Name: 'Phường 15 Quận 10', ID: 7 }
    ];
  }

  onSearch() {
    this.onGetPost15p();
    this.isEnabledSaveAll = false;
  }

  onReload() {
    this.searchOption.SearchText = '';
    this.onGetPost15p();
    this.isEnabledSaveAll = false;
  }

  onClearPost() {
    this.setDefault();
  }

  onAllowSelectMulti() {
    this.setSelectableSettings();
  }

  onAddNewPost() {
    this.enabled = true;
    this.enabledID = true;
    this.setDefault();
  }

  async onSavePost() {
    if (this.dataPostItem.IsAdd) { this.addPost(); } else { this.updatePost(); }
    this.logPostOpened = false;
  }
  
  onReviewPost() {
    this.reViewOpened = true;
    this.getPostTemp();
  }
  getPostTemp() {
    const menuIDJSON: any[] = [];
    if (this.dataMenuID.length > 0) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.dataMenuID.length; i++) {
        menuIDJSON.push(this.dataMenuID[i].Name);
      }
    }

    this.postTemp.MenuName = this.dataPostItem.IsAdd ? menuIDJSON.join(';') : this.dataPostItem.MenuName;
    this.postTemp.Title = this.dataPostItem.Title;
    this.postTemp.Description = this.dataPostItem.Description;
  }

  async onSendPost() {

    const dataRequest = {
      id: this.dataPostItem.ID
    };
    console.info(dataRequest);

    const option = await this.appSwal.showWarning('Bạn có chắc chắn muốn xuất tin bài này không?', true);
    if (option) {
      const result = await this.appService.doGET('api/Post/Send', dataRequest);
      if (result && result.Status === 1) {
        this.dataPostItem.ApprovedFlg = true;
        this.dataPostItem.ApprovedAt = result.Data.ApprovedAt;
        this.dataPostItem.ApprovedBy = result.Data.ApprovedBy;

        this.notification.showSuccess(result.Msg);

      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
  }

  async onViewLogPost() {

    const dataRequest = {
      id: this.dataPostItem.ID
    };

    const result = await this.appService.doGET('api/Post/GetLog', dataRequest);
    if (result && result.Status === 1) {
      this.dataLogPosts = result.Data;
      this.bindLogPosts();

      this.logPostOpened = true;

    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
  }

  onCloseLogPost(e) {
    this.logPostOpened = false;
  }

  async onCancelPost() {

    const dataRequest = {
      ID: this.dataPostItem.ID
    };

    const option = await this.appSwal.showWarning('Bạn có chắc chắn muốn hủy xuất tin bài này không?', true);
    if (option) {
      const result = await this.appService.doPOST('api/Post/Send', dataRequest);
      if (result && result.Status === 1) {
        this.dataPostItem.ApprovedFlg = false;
        this.dataPostItem.ApprovedAt = null;
        this.dataPostItem.ApprovedBy = null;

        this.notification.showSuccess(result.Msg);

      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
  }


  async onSavePosts() {
    this.appComponent.loading = true;
    const dataRequests = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.dataPosts.length; i++) {
      dataRequests.push(this.createDataRequest(this.dataPosts[i]));
    }
    const result = await this.appService.doPOST('api/Post/Saves', dataRequests);
    //this.dataPostItem.ImageUrls = (this.uploadFlg.image == true) ? this.parseToArrayTxt(this.imgImageMain) : null;
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.isEnabledSaveAll = false;
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  createDataRequest(data) {
    const temp = data ? data : this.dataPostItem;

    // let fileName = '';
    // if (this.dataFileUrls.length > 0) {
    //   fileName = this.dataFileUrls[0].Url;
    // }

    const menuIDJSON: any[] = [];
    if (this.dataMenuID.length > 0) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.dataMenuID.length; i++) {
        menuIDJSON.push(this.dataMenuID[i].ID);
      }
    }

    return {
      ID: temp.ID,
      MenuID: menuIDJSON.length > 0 ? JSON.stringify(menuIDJSON) : null,
      Title: temp.Title,
      Summary: temp.Summary,
      Description: temp.Description,
      ImageUrls: temp.ImageUrls,
      FileUrls: null,
      CreateAt: temp.CreateAt,
      UpdateAt: temp.UpdateAt,
      MobileFlg: temp.MobileFlg,
      ApprovedFlg: true,
      ApprovedAt: this.intl.formatDate(new Date(), 'yyyy-MM-ddT00:00:00'),
      ApprovedBy: this.user.UserName,
      OrderIdx: temp.OrderIdx,
      HotFlg: temp.HotFlg,
      Note: temp.Note
    };
  }

  onClosePost(status: any) {
    this.enabled = false;
    this.enabledID = false;
  }

  onEditPost() {
    this.enabled = true;
    this.enabledID = false;
    //this.logPostOpened = true;
    this.infoOpened = true;
  }

  parseToArrayTxt(arrayObject) {
    var arrayTxt = "";
    for (var i = 0; i < arrayObject.length; i++) {
      if (arrayObject[i] != "" && arrayObject[i] != null && arrayObject[i] != this.imageDef) {
        if (arrayTxt == "") {
          arrayTxt = "[" + "\"" + arrayObject[i] + "\"";
        } else {
          arrayTxt = arrayTxt + "," + "\"" + arrayObject[i] + "\"";
        }
      }
    }

    if (arrayTxt != "") {
      arrayTxt = arrayTxt + "]";
    }

    return arrayTxt;
  }

  async addPost() {
    this.appComponent.loading = true;
    if(this.imgChange == false){
      this.dataPostItem.ImageUrls = (this.uploadFlg.image === true && this.imgImageMain && this.imgImageMain.length > 0)
      ? this.imgImageMain[0] : null;
      //this.dataPostItem.ImageUrls = (this.uploadFlg.image == true) ? this.parseToArrayTxt(this.imgImageMain) : null;
    }
    else{
      this.dataPostItem.ImageUrls = this.dataPostItem.ImageUrls;
    }
    
    const dataRequest = this.createDataRequest(null);
    const result = await this.appService.doPOST('api/Post', dataRequest);
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
      this.onAddNewPost();
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  async updatePost() {
    this.appComponent.loading = true;
    // tslint:disable-next-line: max-line-length
    // this.dataPostItem.ImageUrls = (this.uploadFlg.image === true && this.imgImageMain && this.imgImageMain.length > 0)
    //   ? this.imgImageMain[0] : this.dataPostItem.ImageUrls;
    if(this.imgChange == false){
      this.dataPostItem.ImageUrls = (this.uploadFlg.image === true && this.imgImageMain && this.imgImageMain.length > 0)
      ? this.imgImageMain[0] : null;
      //this.dataPostItem.ImageUrls = (this.uploadFlg.image == true) ? this.parseToArrayTxt(this.imgImageMain) : null;
    }
    else{
      this.dataPostItem.ImageUrls = this.dataPostItem.ImageUrls;
    }
    
    const id = this.dataPostItem.ID;
    const dataRequest = this.createDataRequest(this.dataPostItem);

    const result = await this.appService.doPUT('api/Post', dataRequest, { id });
    if (result && result.Status === 1) {
      this.notification.showSuccess(result.Msg);
      this.onReload();
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  async onDeletePost() {
    if (this.dataPostSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }
    this.appComponent.loading = true;
    const dataRequest = {
      IDList: JSON.stringify(this.dataPostSelection),
      FlgRevert: false
    };

    const option = await this.appSwal.showWarning(this.translate.instant('AreYouSure'), true);
    if (option) {
      const result = await this.appService.doPOST('api/Post/Deletes', dataRequest);
      if (result && result.Status === 1) {
        this.notification.showSuccess(result.Msg);
        this.onReload();
        this.onAddNewPost();
        this.enabled = false;
        this.enabledID = false;
        this.dataPostSelection = [];
        this.allowMulti = false;
      } else {
        this.appSwal.showWarning(result.Msg, false);
      }
    }
    this.appComponent.loading = false;
  }

  getPostName(dataItem) {
    var nameFile = "temp.txt";
    var fileUrls = dataItem.ImageUrls;
    if (fileUrls != "" && fileUrls != null) {
      var listurlArr = JSON.parse(fileUrls);
      if (listurlArr.length > 0) {
        var nameObj = listurlArr[0];
        var urlArr = nameObj.split("/");
        if (urlArr.length > 0) {
          nameFile = urlArr[urlArr.length - 1];
          if (nameFile != "" && nameFile != null) {
            var indexOfFirst = nameFile.indexOf("_");
            nameFile = nameFile.substring(indexOfFirst + 1);
          }
        }
      }
    }

    return nameFile;
  }

  getNameByUrl(fileUrl) {
    const nameFile = fileUrl.replace(/^.*[\\\/]/, '');
    // const lastIdx = fileUrl.lastIndexOf('/');
    // if (lastIdx > 0) {
    //   nameFile = fileUrl.substring(lastIdx + 1);
    // }

    return nameFile;
  }

  async onDownloadFile(dataItem: any) {

    const dataRequest = {
      url: dataItem.Url
    };
    const result = await this.appService.doDownload('api/Upload/DownloadByUrl', dataRequest);
    if (result) {
      FileSaver.saveAs(result, dataItem.Name);
    }
  }

  onDeleteFile(dataItem: any) {

    for (let i = this.dataFileUrls.length - 1; i >= 0; i--) {
      if (this.dataFileUrls[i].Url === dataItem.Url) {
        this.dataFileUrls.splice(i, 1);
        break;
      }
    }

    this.bindFileUrls();
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.dataPosts, {}).data
    };

    return result;
  }

  async onSelectFileToUpload(e: SelectEvent) {
    if (!e.files || e.files.length <= 0) {
      return false;
    }
    try {
      const fileData = await this.file.readFile(e.files[0].rawFile);
    } catch {
      this.appSwal.showError(e);
    }
  }

  onSuccessFileToUpload(e: any) {
    try {
      const k = `${e.response.body.Data.DirMedia}${e.response.body.Data.MediaNm[0]}`;
      this.dataFileUrls.push({
        Name: this.getNameByUrl(k),
        Url: k
      });

      this.bindFileUrls();
    } catch {
      this.appSwal.showError(e);
    }
  }

  onRemoveFileToUpload() {

  }
  onCloseInfo(e) {
    //this.setDefault();
    this.infoOpened = false;
    this.logPostOpened = false;
  }
  onCloseReViewPost(e) {
    this.reViewOpened = false;
  }
}


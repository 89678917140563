import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppComponent } from 'src/app/app.component';
import { AppService } from 'src/app/services/app.service';
import { AppLanguage } from 'src/app/services/app.language';
import { AppSwal } from 'src/app/services/app.swal';

@Component({
  selector: 'app-change-account-password',
  templateUrl: './change-account-password.component.html',
  styleUrls: ['./change-account-password.component.css']
})
export class ChangeAccountPasswordComponent implements OnInit {

  isSticky: boolean = false;
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset > 0;
  }
  loginInfo = {
    UserName: '',
    Password: ''

  };
  dataRequest = {
    UserName: '',
    NewPassword: '',
    ConfirmPassword: '',
    Email: '',
  };
  languageName = '';

  languages: Array<any> = [{
    id: 0,
    culture: 'vi-VN',
    text: 'Tiếng Việt',
    click: (dataItem) => {
      this.onChangeLanguage(dataItem);
    }
  }, {
    id: 1,
    culture: 'en-US',
    text: 'English',
    click: (dataItem) => {
      this.onChangeLanguage(dataItem);
    }
  }];

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private appService: AppService,
    private appComponent: AppComponent,
    private language: AppLanguage,
    private appSwal: AppSwal
  ) {
    this.getLanguageName();
  }

  ngOnInit() {

  }

  async changPass() {
    const id = this.dataRequest.UserName;
    const result = await this.appService.doPUT('api/LoginAsk/ChangePassword', this.dataRequest, {id} );
    if (result && result.Status === 1) {
      this.appSwal.showSuccess(result.Msg, false);
      this.router.navigate(['portal/login-ask']);
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
  }

  AddNew(){
    this.router.navigate(['portal/create-account']);
  }

  ChangePass(){
    this.router.navigate(['portal/change-account-password']);
  }

  onChangeLanguage(dataItem) {
    this.appComponent.switchLanguage(dataItem.culture);
    this.getLanguageName();
  }

  getLanguageName() {
    let lang = this.language.get();
    if (!lang) {
      lang = 'vi-VN';
      this.appComponent.switchLanguage(lang);
    }
    const language = this.languages.find(item => {
      return item.culture === lang;
    });

    if (language) { this.languageName = language.text; }
  }

  onKeyPress(e: any) {
    if (e.keyCode === 13) {
      //this.onLogin();
    }
  }
}

import { Component, OnInit, Renderer2, ChangeDetectorRef  } from '@angular/core';
import { SchedulerEvent, DateChangeEvent } from '@progress/kendo-angular-scheduler';
import { FormGroup, FormBuilder, Validators, ValidatorFn } from '@angular/forms';
import { AppGuid } from 'src/app/services/app.guid';
import { AppService } from 'src/app/services/app.service';
import { AppComponent } from 'src/app/app.component';
import { AppSwal } from 'src/app/services/app.swal';
import { Notification } from '../../services/app.notification';
import { IntlService } from '@progress/kendo-angular-intl';
import { isBuffer } from 'util';
import { DomSanitizer } from '@angular/platform-browser';
import * as FileSaver from 'file-saver';
import { AppUtils } from 'src/app/services/app.utils';
import { AppFile } from 'src/app/services/app.file';
import { AppControls } from 'src/app/services/app.controls';
import { AuthenticationService } from 'src/app/services/authentication.service';


const intersects = (startTime1: Date, endTime1: Date, startTime2: Date, endTime2: Date) =>
  (startTime1 < startTime2 && endTime1 > endTime2) ||
  (startTime2 <= startTime1 && startTime1 < endTime2) ||
  (startTime2 < endTime1 && endTime1 <= endTime2);

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css'],
})
export class ScheduleComponent implements OnInit {
  //public startTime = '07:00';
  isFile = false;
  dataMeetings = [];
  dataMeetingItem: any;
  dataMeetingItemTemp: any;
  meetingRoom: Array<{ Name: string, ID: string }>;
  dataRoomID: any[];
  infoOpened = false;
  isAdd = true;
  public selectedDate: Date = new Date(Date.now());
  public formGroup: FormGroup;
  control: any;
  controlDefault = true;

  members: Array<{ FullName: string, UserName: string }> = [];
  membersFilter: Array<{ FullName: string, UserName: string }> = [];

  startFlg = false;

  dataScheduler: any[] = [];
  user: any;


  public eventsArr: SchedulerEvent[] = [];
  public events: SchedulerEvent[] = [];

  listDateInMonthView = [];
  eventHeight = 1;
  eventHeightPixel = '600px';
  doCalMaxEvent = true;

  constructor(
    private formBuilder: FormBuilder,
    private appGuid: AppGuid,
    public appService: AppService,
    private appComponent: AppComponent,
    private appSwal: AppSwal,
    private notification: Notification,
    public intl: IntlService,
    private appUtils: AppUtils,
    //private renderer: Renderer2,
    private domSanitizer: DomSanitizer,
    private file: AppFile,
    private cdRef : ChangeDetectorRef,
    public appControls: AppControls,
    private authenticationService: AuthenticationService,
  ) {
    this.authenticationService.getUser();
    this.user = this.authenticationService.user;
    this.getControl();
    this.getMeeting();
    this.getRoom();
    this.setDefault();
  }

  async getControl() {
    this.control = await this.appControls.getControls(this.user.RoleID);
    this.controlDefault = false;
  }

  setDefault() {

    this.dataMeetingItem = {
      ID: '',
      Name: '',
      RoomMeetingID: null,
      RoomOther: null,
      Room: '',
      StartAt: '',
      EndAt: '',
      MeetingDate: null,
      CreateAt: null,
      ApproveFlg: null,
      DelFlg: null,
      Description: '',
      FileUrl: null,
      Unit: '',
      Admin: '',
      Member: ''
    };
    this.dataMeetingItemTemp = {
      viewOnly: false,
      IsAdd: true,
      Name: '',
      Admin: null,
      Address: '',
      MeetingDate: null,
      StartAt: null,
      EndAt: null,
      ApproveFlg: false,
      FileUrl: []
    };
  }

  async getRoom() {
    const result = await this.appService.doGET('api/Meeting/GetMeetingRoom', null);
    this.meetingRoom = result.Data;
  }

  async getMeeting() {
    const result = await this.appService.doGET('api/Meeting/GetMeeting', null);
    if (result) {
      if (result && result.Status === 1) {
        this.dataScheduler = result.Data;
        this.eventsArr = [];
        result.Data.forEach(item => {
          var tempStart = this.intl.formatDate(new Date(item.StartAt), 'HH:mm');
          var tempEnd = this.intl.formatDate(new Date(item.EndAt), 'HH:mm');
          const event = {
            id: item.ID,
            title: tempStart + "-" + tempEnd + "-" + item.Name,
            start: new Date(item.StartAt),
            end: new Date(item.EndAt),
            readonly: true,
            delflg: item.DelFlg,
            approveflg: item.ApproveFlg,
            publicflg: item.PublicFlg,
            publicat: new Date(item.PublicAt),
            updateat: new Date(item.UpdateAt),
            fileurl: item.FileUrl,
            meetingdate: item.MeetingDate
          }
          this.eventsArr.push(event);
          item.UnitList = JSON.parse(item.Unit);
          for (let j = 0; j < item.UnitList.length; j++) {
            if (item.ShowUnit == undefined) {
              item.ShowUnit = "";
            }
            if (j != item.UnitList.length - 1) {
              item.ShowUnit = item.ShowUnit + " " + item.UnitList[j].Name + ",";
            } else {
              item.ShowUnit = item.ShowUnit + " " + item.UnitList[j].Name;
            }
          }
          item.Admin = JSON.parse(item.Admin);
          for (let k = 0; k < item.Admin.length; k++) {
            if (item.ShowAdmin == undefined) {
              item.ShowAdmin = "";
            }
            if (k != item.Admin.length - 1) {
              item.ShowAdmin = item.ShowAdmin + " " + item.Admin[k].FullName + ",";
            } else {
              item.ShowAdmin = item.ShowAdmin + " " + item.Admin[k].FullName;
            }
          }
          item.RoomMeetingList = JSON.parse(item.RoomMeeting)[0];
          item.MemberList = JSON.parse(item.Member);
          if (item.FileUrl == null || item.FileUrl == "") {
            item.FileUrl = [];
          }
          else {
            item.FileUrl = JSON.parse(item.FileUrl);
          }

          this.dataMeetings.push(item);
        });
        this.events = this.eventsArr;
        this.startFlg = true;
      }
    }
  }


  ngOnInit() {
  }

  membersHandleFilter(value) {
    this.membersFilter = this.members.filter((s) => s.FullName.toLowerCase().indexOf(value.toLowerCase()) !== -1);
  }



  

  public getNextId(): number {
    const len = this.events.length;
    return (len === 0) ? 1 : this.events[this.events.length - 1].id + 1;
  }

  getUrlDownload(fileUrl) {
    let url = this.appService.apiRoot.replace("\"", "") + fileUrl;
    url = url.replace("\"", '')
    return url;
  }

  async downloadFile(fileUrl) {
    const dataRequest = {
      url: fileUrl
    };
    const result = await this.appService.doDownload('api/Upload/DownloadByUrl', dataRequest);
    if (result) {
      FileSaver.saveAs(result, this.appUtils.getNameByUrl(fileUrl));
    }
  }

  private occupiedSlot(args: any): boolean {
    let occupied = false;
    this.events.find(e => {
      if (e !== args.dataItem && intersects(args.start, args.end, e.start, e.end)) {
        occupied = true;
        return true;
      }
    });
    return occupied;
  }

  public onDragStart(args: any): void {
    this.preventReadonly(args);
  }

  public onDrag(args: any): void {
    if (this.occupiedSlot(args)) {
      args.setHintClass('invalid');
    }
  }

  public onDragEnd(args: any): void {
    if (this.occupiedSlot(args)) {
      args.preventDefault();
    }
  }

  private preventReadonly(args: any): void {
    if (args.dataItem.readonly) {
      args.preventDefault();
    }
  }

  public ngAfterViewChecked() {
    //this.renderer.setStyle(document.querySelector("multi-day-view .k-scheduler-pane"), "display", "none");
  }

  onCloseInfo(e) {
    if(this.dataMeetingItemTemp.viewOnly == true){
      this.infoOpened = false;
      return;
    }
    this.getMeeting();
    this.getRoom();
    this.setDefault();
    this.infoOpened = false;
  }

  addMeeting(date) {
    this.setDefault();
    this.dataMeetingItemTemp.MeetingDate = new Date(date);
    this.dataMeetingItemTemp.SchedulerAddNewDate = new Date(date);
    this.infoOpened = true;
  }

  compareDate(date) {
    var result = false;
    var calendarDate = new Date(date);
    var today = new Date();
    if (calendarDate.getUTCDate() + 1 == today.getUTCDate() && calendarDate.getUTCMonth() == today.getUTCMonth()
      && calendarDate.getUTCFullYear() == today.getUTCFullYear()) {
      result = true;
    } else if (calendarDate < today) {
      result = false;
    } else {
      result = true;
    }
    return result
  }

  // async increaseEventHeight(){
  //   this.eventHeight = this.eventHeight + 1;
  //   this.eventHeightPixel = (this.eventHeight * 600).toString() + "px";
  //   this.getMeeting();
  //   this.createFormGroup = this.createFormGroup.bind(this);
  //   this.getRoom();
  //   this.setDefault();
  // }

  // async decreaseEventHeight(){
  //   if(this.eventHeight > 1){
  //     this.eventHeight = this.eventHeight - 1;
  //   }
  //   this.eventHeightPixel = (this.eventHeight * 600).toString() + "px";
  //   this.getMeeting();
  //   this.createFormGroup = this.createFormGroup.bind(this);
  //   this.getRoom();
  //   this.setDefault();
  // }

  async register(date) {
    if (this.doCalMaxEvent) {
      if (this.listDateInMonthView.length == 42) {
        var newDate = new Date(date);
        if (this.listDateInMonthView[0].getDate() == newDate.getDate()) {
          this.doCalMaxEvent = false;
          return;
        }
        this.listDateInMonthView = [];
      }
      this.listDateInMonthView.push(new Date(date));
      if (this.listDateInMonthView.length == 42) {
        this.calMaxEvent();
      }
    }

    return 0;
  }

  compareDateGreater(date1, date2) {
    var cDate1 = new Date(date1);
    cDate1.setHours(0);
    cDate1.setMinutes(0);
    cDate1.setSeconds(0);
    cDate1.setMilliseconds(0);
    var cDate2 = new Date(date2);
    cDate2.setHours(0);
    cDate2.setMinutes(0);
    cDate2.setSeconds(0);
    cDate2.setMilliseconds(0);
    if (cDate1 >= cDate2) {
      return true;
    } else {
      return false;
    }
  }

  compareDateLeeser(date1, date2) {
    var cDate1 = new Date(date1);
    cDate1.setHours(0);
    cDate1.setMinutes(0);
    cDate1.setSeconds(0);
    cDate1.setMilliseconds(0);
    var cDate2 = new Date(date2);
    cDate2.setHours(0);
    cDate2.setMinutes(0);
    cDate2.setSeconds(0);
    cDate2.setMilliseconds(0);
    if (cDate1 <= cDate2) {
      return true;
    } else {
      return false;
    }
  }

  async calMaxEvent() {
    var events = this.events;
    var dateInMonth = this.listDateInMonthView;
    var listEventInMonth = [];
    var maxEvent = {
      count: 0,
      date: 0,
      month: 0,
      year: 0
    };
    var currentDate = {
      count: 0,
      date: 0,
      month: 0,
      year: 0
    };
    for (let i = 0; i < events.length; i++) {
      if (this.compareDateGreater(events[i].start, dateInMonth[0]) && this.compareDateLeeser(events[i].start, dateInMonth[dateInMonth.length - 1])) {
        listEventInMonth.push(events[i]);
      }
    }
    for (let j = 0; j < listEventInMonth.length; j++) {
      var outerDate = new Date(listEventInMonth[j].start);
      currentDate.date = outerDate.getDate();
      currentDate.month = outerDate.getMonth();
      currentDate.year = outerDate.getFullYear();
      currentDate.count = 0;

      if (maxEvent.date == 0) {
        maxEvent.date = outerDate.getDate();
        maxEvent.month = outerDate.getMonth();
        maxEvent.year = outerDate.getFullYear();
        maxEvent.count = 0;
      }

      for (let k = 0; k < listEventInMonth.length; k++) {
        var innerDate = new Date(listEventInMonth[k].start);
        if (currentDate.date == innerDate.getDate() && currentDate.month == outerDate.getMonth()
          && currentDate.year == outerDate.getFullYear()) {
          currentDate.count = currentDate.count + 1;
        }
      }

      if (currentDate.count > maxEvent.count) {
        maxEvent.date = currentDate.date;
        maxEvent.month = currentDate.month;
        maxEvent.year = currentDate.year;
        maxEvent.count = currentDate.count;
      }
    }
    if (maxEvent.count == 3) {
      this.eventHeightPixel = (maxEvent.count * 261).toString() + "px";
      this.getMeeting();
      this.cdRef.detectChanges();
    } else if (maxEvent.count > 3 && maxEvent.count <= 7) {
      this.eventHeightPixel = (maxEvent.count * 231).toString() + "px";
      this.getMeeting();
      this.cdRef.detectChanges();
    }else if (maxEvent.count > 7 && maxEvent.count <= 11) {
      this.eventHeightPixel = (maxEvent.count * 200).toString() + "px";
      this.getMeeting();
      this.cdRef.detectChanges();
    } else if (maxEvent.count > 11) {
      this.eventHeightPixel = (maxEvent.count * 190).toString() + "px";
      this.getMeeting();
      this.cdRef.detectChanges();
    } else{
      this.eventHeightPixel = '600px';
      this.getMeeting();
      this.cdRef.detectChanges();
    }
  }

  public onDateChange(args: DateChangeEvent): void {
    this.doCalMaxEvent = true;
  }

  onViewInfo(id) {
    var selectedMeeting = this.dataMeetings[this.dataMeetings.findIndex(x => x.ID == id)];
    selectedMeeting.viewOnly = true;
    this.setDefault();
    this.dataMeetingItemTemp = selectedMeeting;
    this.dataMeetingItemTemp.MeetingDate = new Date(selectedMeeting.MeetingDate);
    this.dataMeetingItemTemp.StartAt = new Date(selectedMeeting.StartAt);
    this.dataMeetingItemTemp.EndAt = new Date(selectedMeeting.EndAt);
    this.infoOpened = true;
  }

}

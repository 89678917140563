import { Http, Response, Headers, RequestOptions, URLSearchParams, ResponseContentType } from '@angular/http';
import { Injectable, OnInit } from '@angular/core';
import { resolve, reject } from 'q';
import 'rxjs/add/operator/map';
import { HttpHeaders } from '@angular/common/http';
import { AppLanguage } from './app.language';
import { AppSwal } from './app.swal';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AppService implements OnInit {
    // apiRoot = 'http://localhost:8080/';
    apiRoot = 'http://10.70.55.142:8109/';
    // apiRoot = 'http://123.30.158.155:8003/';
    // apiRoot = 'http://210.2.93.8:8002/';
    
    headers: any;
    timeInterval = 1000 * 30;
    user: any;
    domainAPI = '';
    ngOnInit(): void {
    }
    constructor(
        private http: Http,
        private language: AppLanguage,
        private appSwal: AppSwal,
        private location: Location,
        private router: Router
    ) {
        // tslint:disable-next-line:no-string-literal
        const clientApi = `${this.location['_platformStrategy']._platformLocation.location.origin}/`;
        if (clientApi.indexOf('localhost') >= 0) {
            this.apiRoot = 'http://localhost:8080/';
        
        } else if (clientApi.indexOf('https://ldldhcm.hcmtelecom.vn') >= 0){
            this.apiRoot = 'https://api-ldldhcm.hcmtelecom.vn/';
        }
        else if (clientApi.indexOf('10.70.51.34:8102') >= 0){
            this.apiRoot = 'http://10.70.51.34:8101/';
        }
        else if (clientApi.indexOf('210.2.93.8:8002') >= 0){
            this.apiRoot = 'http://210.2.93.8:8001/';
        }
        else {
            this.apiRoot = 'https://api.congdoantphochiminh.org.vn/';
        }

        this.domainAPI = this.apiRoot;
        var startIdx = this.domainAPI.indexOf("//");
        this.domainAPI = "ws:" + this.domainAPI.substring(startIdx);
    }

    getUser(): any {
        const result = localStorage.getItem('userInformation5S');
        if (result) {
            this.user = JSON.parse(result);
        }
        return this.user;
    }

    getUserName() {
        if (!this.user || !this.user.UserName || localStorage.getItem('userInformation5S') != this.user ) {
            this.getUser();
        }
        return this.user && this.user.UserName ? this.user.UserName : '';
    }

    createHeaders() {
        const token = localStorage.getItem('token');
        // tslint:disable-next-line: deprecation
        this.headers = new Headers();
        this.headers.set('Content-Type', 'application/json');
        this.headers.set('Authorization', `Bearer ${token}`);
        this.headers.set('Accept-Language', this.language.get());
        this.headers.set('UserName', this.getUserName());
    }

    async doGET(methodUrl: any, params: any) {
        this.createHeaders();
        const apiURL = `${this.apiRoot}${methodUrl}`;
        try {
            const data = await this.http.get(apiURL, { headers: this.headers, params })
                .toPromise()
                .then(res => res.json(), err => {
                    if (err.statusText === 'Unauthorized') {
                        this.appSwal.showWarning(this.language.translate.instant('MsgUnauthorized'), false);
                        this.router.navigate(['login']);
                    }
                    return null;
                });
            return data;
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    async doPOST(methodUrl: any, dataRequest: any) {
        this.createHeaders();
        const apiURL = `${this.apiRoot}${methodUrl}`;
        try {
            const data = await this.http.post(apiURL, dataRequest, { headers: this.headers })
                .toPromise()
                .then(res => res.json(), err => console.log(err));
            return data;
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    async doPOSTOPTION(methodUrl: any, dataRequest: any, options: any) {
        const apiURL = `${this.apiRoot}${methodUrl}`;
        try {
            const data = await this.http.post(apiURL, dataRequest, options)
                .toPromise()
                .then(res => res.json(), err => err.json());
            return data;
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    async doPUT(methodUrl: any, dataRequest: any, params: any) {
        this.createHeaders();
        const apiURL = `${this.apiRoot}${methodUrl}`;
        try {
            const data = await this.http.put(apiURL, dataRequest, { headers: this.headers, params })
                .toPromise()
                .then(res => res.json(), err => console.log(err));
            return data;
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    async doDELETE(methodUrl: any, params: any) {
        this.createHeaders();
        const apiURL = `${this.apiRoot}${methodUrl}`;
        try {
            const data = await this.http.delete(apiURL, { headers: this.headers, params })
                .toPromise()
                .then(res => res.json(), err => console.log(err));
            return data;
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    //Edit mark
    createHeadersPostMark() {
        const token = localStorage.getItem('token');
        // tslint:disable-next-line: deprecation
        this.headers = new Headers();
        this.headers.set('Accept', 'application/json');
        this.headers.set('Content-Type', 'application/x-www-form-urlencoded');
        this.headers.set('Authorization', `Bearer ${token}`);
        this.headers.set('Accept-Language', this.language.get());
    }

    async doPOSTMARK(methodUrl: any, dataRequest: any) {
        this.createHeadersPostMark();
        const apiURL = `${this.apiRoot}${methodUrl}`;
        // console.log(apiURL);
        // console.log(this.encodeParams(dataRequest));
        try {
            const data = await this.http.post(apiURL, this.encodeParams(dataRequest), { headers: this.headers })
                .toPromise()
                .then(res => res.json(), err => console.log(err));
            return data;
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    async doDownload(methodUrl: any, params: any) {
        this.createHeaders();
        const apiURL = `${this.apiRoot}${methodUrl}`;
        try {
            const data = await this.http.get(apiURL, { headers: this.headers, params, responseType: ResponseContentType.Blob })
                .toPromise()
                .then(res => res.json(), err => {
                    if (err.statusText === 'Unauthorized') {
                        this.router.navigate(['login']);
                    }
                    return null;
                });
            return data;
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    encodeParams(params: any): string {
        let body = '';
        // tslint:disable-next-line:prefer-const
        // tslint:disable-next-line:forin
        for (const param in params) {
            if (body.length) {
                body += '&';
            }
            body = `${body}${param}=`;
            body = `${body}${encodeURIComponent(params[param])}`;
        }

        return body;
    }
}

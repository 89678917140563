import { Component, OnInit } from '@angular/core';
import { AppControls } from '../services/app.controls';
import { GridDataResult, DataStateChangeEvent, SelectableSettings, PageChangeEvent } from '@progress/kendo-angular-grid';
import { orderBy, process, SortDescriptor, State } from '@progress/kendo-data-query';
import 'hammerjs';
import { AppService } from '../services/app.service';
import { IntlService } from '@progress/kendo-angular-intl';
import { AppSwal } from '../services/app.swal';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  searchData = {
    FromDate: null,
    ToDate: null,
  };
  infoOpened = false;
  dataCommentGridDataResult: GridDataResult;
  dataFileUrlsGridDataResult: GridDataResult;
  dataComments = [];
  data: any[];
  public hot;
  public normal;
  public all;
  dataCommentSelectableSettings: SelectableSettings;
  allowMulti = true;
  dataFileUrlsSelectableSettings: SelectableSettings;
  dataCommentSort = {
    allowUnsort: true,
    mode: 'multiple'
  };
  public enabled = false;
  public enabledID = false;
  dataCommentItem: any;
  dataCommentSortByField: SortDescriptor[] = [
    // {
    //   field: 'ParentName',
    //   dir: 'asc'
    // }, {
    //   field: 'OrdinalNumber',
    //   dir: 'asc'
    // }
  ];
  public LBM_NUM_PAGING_SKIP = 0;
  public LBM_NUM_PAGING_TAKE = 50;
  dataCommentSkip = this.LBM_NUM_PAGING_SKIP;
  dataCommentPageSize = this.LBM_NUM_PAGING_TAKE;
  dataCommentSelection: number[] = [];
  dataFileUrls: any[];
  postItem: any;
  public dataCommentState: State = {
    skip: this.dataCommentSkip,
    take: this.dataCommentSkip + this.dataCommentPageSize,
    filter: {
      logic: 'and',
      filters: []
    },
    //group: [{ field: 'TypeName' }]
  };
  FromDate: any;
  ToDate: any;
  dataUsers: [];
  loading = false;
  dataPostGridDataResult: GridDataResult;
  constructor(
    public appControls: AppControls,
    public appService: AppService,
    public intl: IntlService,
    private appSwal: AppSwal,
    private translate: TranslateService,
  ) { 
   
    this.getPageName();
    //this.FromDate = new Date();
    //this.ToDate = new Date();
    this.getPost();

  }
  
  pageName: any;


  ngOnInit() {
  }
  async getPageName() {
    this.pageName = await this.appControls.getPageName();
  }

  bindComments() {
    this.dataCommentGridDataResult = {
      data: orderBy(this.dataUsers, this.dataCommentSortByField),
      total: this.dataUsers.length
    };

    this.dataCommentGridDataResult = process(this.dataUsers, this.dataCommentState);
  }

  bindFileUrls() {
    this.dataFileUrlsGridDataResult = {
      data: this.dataFileUrls,
      total: this.dataFileUrls.length
    };

    this.dataCommentGridDataResult = process(this.dataComments, this.dataCommentState);
  }

  onCommentsortChange(sort: SortDescriptor[]): void {
    this.dataCommentSortByField = sort;
    this.bindComments();
  }

  public onCommentDataStateChange(state: DataStateChangeEvent): void {
    this.dataCommentSelection = [];
    this.dataCommentState = state;
    this.dataCommentGridDataResult = process(this.dataComments, this.dataCommentState);
  }
  setSelectableSettings(): void {

    this.allowMulti = !this.allowMulti;
    this.dataCommentSelectableSettings = {
      checkboxOnly: false,
      mode: this.allowMulti ? 'multiple' : 'single'
    };

    this.dataFileUrlsSelectableSettings = {
      checkboxOnly: false,
      mode: 'single'
    };
  }
  onCommentPageChange(event: PageChangeEvent) {
    this.dataCommentSkip = event.skip;
    this.bindComments();
  }

  async onCommentselectedKeysChange() {

    if (this.dataCommentSelection.length === 0) {
      this.appSwal.showWarning(this.translate.instant('NoRecordSelected'), false);
      return;
    }

    if (this.dataCommentSelection.length > 1) {
      if (this.allowMulti) {
        return;
      }
      this.appSwal.showWarning(this.translate.instant('SelectSingle'), false);
    } else {
      const selectedID = this.dataCommentSelection[0];
      const selectedItem = this.dataComments.find((item) => {
        return item.ID === selectedID;
      });
      selectedItem.IsAdd = false;

      this.dataCommentItem = selectedItem;

      this.enabled = false;
      this.enabledID = false;


      const dataRequest = {
        id: this.dataCommentItem.PostID
      };

      this.postItem = null;
      const resultRequestMenu = await this.appService.doGET('api/Post/GetById', dataRequest);
      if (resultRequestMenu && resultRequestMenu.Status === 1) {
        this.postItem = resultRequestMenu.Data;
      }
    }
  }

  onSearch() {
    this.infoOpened = true;
  }
  onCloseInfo(e) {
    this.infoOpened = false;
  }
  
  async getPost() {
    const searchData = {
      FromDate: this.intl.formatDate(this.FromDate, 'yyyy-MM-dd'),
      ToDate: this.intl.formatDate(this.ToDate, 'yyyy-MM-dd')
    };

    const result = await this.appService.doPOST('api/Dashboard', searchData);
    if(result && result.Status === 1){
      this.dataUsers = result.Data.res;
      
      this.bindComments();
      this.hot = result.Data.CountPost[0];
      this.normal = result.Data.CountPost[1];
      this.all = result.Data.CountPost[2];
      this.data = [{
        kind: 'Tin nổi bật' + '\n' + this.hot, share: this.hot
      }, {
        kind: 'Tin thường' + '\n' + this.normal, share: this.normal
      }];
    }
    this.loading = false;
    this.infoOpened = false;
  }

  public labelContent(e: any): string {
    return e.category;
  }

}

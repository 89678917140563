import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { AppService } from '../services/app.service';
import { URLSearchParams } from '@angular/http';
import { AppComponent } from '../app.component';
import { AppLanguage } from '../services/app.language';
import { AppSwal } from '../services/app.swal';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginInfo = {
    UserName: '',
    Password: ''
  };

  languageName = '';

  languages: Array<any> = [{
    id: 0,
    culture: 'vi-VN',
    text: 'Tiếng Việt',
    click: (dataItem) => {
      this.onChangeLanguage(dataItem);
    }
  }, {
    id: 1,
    culture: 'en-US',
    text: 'English',
    click: (dataItem) => {
      this.onChangeLanguage(dataItem);
    }
  }];

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private appService: AppService,
    private appComponent: AppComponent,
    private language: AppLanguage,
    private appSwal: AppSwal
  ) {
    this.getLanguageName();
  }

  ngOnInit() {

  }

  async onLogin() {
    this.appComponent.loading = true;
    const result = await this.authenticationService.doSignIn(this.loginInfo.UserName, this.loginInfo.Password);
    if (result.Status === 1) {
      const pageUrlRollback = localStorage.getItem('PageUrlRollback');
      if (pageUrlRollback) {
        localStorage.removeItem('PageUrlRollback');
        this.router.navigate([pageUrlRollback]);
      } else {
        this.router.navigate(['dashboard']);
        // this.router.navigate(['data/post']);
      }
    } else {
      this.appSwal.showWarning(result.Msg, false);
    }
    this.appComponent.loading = false;
  }

  onChangeLanguage(dataItem) {
    this.appComponent.switchLanguage(dataItem.culture);
    this.getLanguageName();
  }

  getLanguageName() {
    let lang = this.language.get();
    if (!lang) {
      lang = 'vi-VN';
      this.appComponent.switchLanguage(lang);
    }
    const language = this.languages.find(item => {
      return item.culture === lang;
    });

    if (language) { this.languageName = language.text; }
  }

  onKeyPress(e: any) {
    if (e.keyCode === 13) {
      this.onLogin();
    }
  }
}
